import { MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';

const MultiLIstKoponSelect = (props) => {

    const [multiSelVal, setMultiSelVal] = useState((props.multiSelVal != null) ?
        props.multiSelVal : "-1");

    const [colorforStat, setColorforStat] = useState("");

    useEffect(() => {
        setMultiSelVal(props.multiSelVal)

    }, [props.multiSelVal])

    const handleChange = (e) => {
        props.changeToStatus(e.target.value)
        setMultiSelVal(e.target.value)
    }


    return (
        <span className={`${colorforStat} 'pointing' `}>
            <select
                className={`selectInBlock`}
                value={multiSelVal}
                onChange={handleChange}>
                <option value={"-1"}>עדכון קופונים</option>
                <option value={"לא פעיל"}>שנה ללא פעיל</option>
                <option value={"פעיל"}>שנה לפעיל</option>
                <option value={"פג תוקף"}>שנה לפג תוקף</option>
                <option value={"שינוי תפוגה"} >שנה תאריך תפוגה</option>
                <option value={"שינוי כמות"} >שנה כמות קופונים</option>
                <option value={"מחק"} >מחק</option>
            </select>
        </span>
    );
}

export default MultiLIstKoponSelect;