import React, { useContext, useEffect, useState } from 'react';
import { Stack, Checkbox } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import Button from '@mui/material/Button';
import BetweenDatesNoHours from '../../../MySections/TimeSections/BetweenDatesNoHours';
import BetweenDates from '../../../MySections/TimeSections/BetweenDates';
import CreateTblOrder from './createTblOrder';
import BuisSelect from '../../OrdersP/OrdersList-list/BuisSelect';
import { BuisListContext } from '../../../MyContexts/BuisList_Context';
import myFunctions from '../../../../myFunctions';
import { UserContext } from '../../../MyContexts/Users_Context';
import { BuisListForTablesContext } from '../../../MyContexts/BuisListForTables_Context';
import BuisSelect_ForTable from './BuisSelect_ForTable';
import TableOrderTypeSelect from '../../../MySections/DTechComps/TableOrderTypeSelect';




const TblBuisTollBarMobi = (props) => {

    const [user] = useContext(UserContext);

    const [buisLstTables, setBuisLstTables] = useContext(BuisListForTablesContext);
    const [thePopUp, setThePopUp] = useState("");
    const [showBetweenDts, setShowBetweenDts] = useState(false);
    const [selectedBtn, setSelectedBtn] = useState(props.selectedBtn);
    const [srchText, setSrchText] = useState("");

    useEffect(() => {
        setSelectedBtn(props.selectedBtn)
    }, [props.selectedBtn])

    const refreshTheList = (selectedBtn) => {

        props.refreshTheList(selectedBtn)
    }

    const cretateTableOrder = () => {
        setThePopUp(<CreateTblOrder
            cancelClick={() => setThePopUp()}
            ordrApproved={() => { props.refreshTheList(selectedBtn); setThePopUp() }}
        />)
    }

    const checkIfEnter = (e) => {
        if (e.key === 'Enter') {
            props.searchTblOrder(srchText);
        }
    }

    return (
        <div className={"buisTollBar buisTollBarMobi"}>
            <div className={"fullRow"}>
                <div className={"refAndCreate"}>
                    <Checkbox
                        icon={<Refresh sx={{ color: 'white' }} />}
                        checkedIcon={<Refresh sx={{ color: 'white' }} />}  
                        sx={{}}
                        className={"RefreshBtnTblOrdr"}
                        onClick={() => {
                            refreshTheList(selectedBtn);
                        }}
                    />

                    <Button className={`btnBigMnu onLeft theTbBtn bigSbtn`}
                        variant="contained" onClick={() => {
                            cretateTableOrder();
                        }}>
                        צור הזמנה
                    </Button>

                </div>
                <div className={"searchWrap onRight"}>
                    <div className="spaceButtomBig ms-form-group my-0 mb-0 has-icon fs-14">
                        <input type="search" className="dtSearchInput ms-form-input" id="dtSearchInput" name="dtSearchInput"
                            placeholder="מספר הזמנה, טלפון או שם לקוח" value={srchText} onChange={(e) => setSrchText(e.target.value)}
                            onKeyUp={(e) => checkIfEnter(e)}
                        />
                        <Button className="dtSearchIcon flaticon-search text-disabled" onClick={() => props.searchTblOrder(srchText)} />
                    </div>
                </div>
            </div>
            {
                (user.permiss === "admin") ?
                    <div className={"fullRow"}>
                        <BuisSelect_ForTable busissList={buisLstTables}
                            defaultValue={myFunctions.getBuisNameByIdInList(buisLstTables, props.userBuis)}
                            firstHead={"כל העסקים"}
                            clss={"buisSelectOnTBARMobi onLeft noMargin"}
                            userSelection={props.userBuis ? props.userBuis : -1}
                            setUserBuis={(newVal) => { props.setUserBuis(newVal) }}
                        />
                    </div>
                    : ""
            }

            <div className={"fullRow"}>

                <Stack direction="row" spacing={2}>

                    <div className={"ReportTypeSelect btnMnuTop onRight badge badge-primary"}>

                        <TableOrderTypeSelect selectedRepo={(selec) => {
                            console.log(selec); (selec !== "11") ?
                                refreshTheList(selec) : setShowBetweenDts(true)
                        }} />

                    </div>
                    <div className={`spaceTop ${(showBetweenDts) ? "" : "hideMe"}`}>
                        <div className={"onRight"}>
                            <BetweenDatesNoHours
                                setStartDTS={(dt) => props.setStartDTS(dt)}
                                setEndDTS={(dt) => props.setEndDTS(dt)} />
                        </div>
                        <div className={"centerText spaceBootmBig"}>
                            <Button className={`btnBigMnu theTbBtn onRight`}
                                variant="contained" onClick={() => refreshTheList(11)}>
                                חפש
                            </Button></div>
                    </div>
                </Stack>
            </div>

            {thePopUp}
        </div>
    )
}

export default TblBuisTollBarMobi;

