import React, { useContext, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputTextSimple from '../../../MySections/DTechComps/InputTextSimple';
import DatePick from '../../../MySections/DTechComps/DatePick';
import DropNumbersSelection from '../../../MySections/DTechComps/DropNumbersSelection';
import DropHouresSelection from '../../../MySections/DTechComps/DropHouresSelection';
import MyTextArea from '../../../MySections/TextEditor/MyTextArea';
import utilsTblOrders from '../../../../MyConns/_Utils/utilsTblOrders';
import InputSet from '../../../MySections/PopUps/InputSet';
import TblOrderStatuselect from './TblOrderStatuselect';
import { WaitingToApprovContext } from '../../../MyContexts/WaitingToApprov_Context';
import { UserContext } from '../../../MyContexts/Users_Context';
import { BuisListContext } from '../../../MyContexts/BuisList_Context';
import myFunctions from '../../../../myFunctions';
import BuisSelect from '../../OrdersP/OrdersList-list/BuisSelect';
import { BuisListForTablesContext } from '../../../MyContexts/BuisListForTables_Context';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const CreateTblOrder = (props) => {
    const [wToImpto, setWToImpto, checkIfItemExistsAndStatus] = useContext(WaitingToApprovContext);

    const [user] = useContext(UserContext);
    const [buisLstTables, setBuisLstTables] = useContext(BuisListForTablesContext);


    const [thePopUp, setThePopUp] = useState("");

    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState("");

    const [orderNUM, setOrderNUM] = useState("");
    const [buisSelected, setBuisSelected] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientPhone, setClientPhone] = useState("");
    const [dinners, setDinners] = useState("");
    const [timeToCome, setTimeToCome] = useState("");
    const [dateToCome, setDateToCome] = useState();
    const [dayToCome, setDayToCome] = useState("");
    const [orderStatus, setOrderStatus] = useState("ממתין לאישור");
    const [remarks, setRemarks] = useState("");
    const [updaterName, setUpdaterName] = useState("");
    const [itmToDisp, setItmToDisp] = useState(0);
    const [ordrApprove, setOrdrApprove] = useState(false);


    const saveMe = () => {
      
        let rep = "";

        if (buisSelected === null || buisSelected === "") { rep = " יש לבחור מסעדה" }
        else if (dinners === null || dinners === "") { rep = " יש לציין מספר סועדים" }
        else if (timeToCome === null || timeToCome === "") { rep = " יש לציין שעת הגעה" }
        else if (dateToCome === null || dateToCome === "") { rep = " יש לציין תאריך הגעה" }
        else if (clientName === null || clientName === "") { rep = " יש לציין שם לקוח" }
        else if (clientPhone === null || clientPhone === "") { rep = " יש לציין טלפון לקוח" }
        if (rep === "") {
            setTheNameAndSaveMe(updaterName)
        }
        else {
            setErr(<p>{rep}</p>);
        }

    };

    const setTheNameAndSaveMe = (nam) => {
        if (updaterName === "") {
            setThePopUp(<InputSet
                Title={"עובד מעדכן"}
                ContentText={"מה שמך?"}
                inLabel=""
                okClick={(nam) => { setTheNameAndSaveMe(nam) }}
                cancelClick={() => setThePopUp()} />)
        }
        else { saveIt(updaterName) }

        setUpdaterName(nam);
        saveIt(nam);
        setThePopUp()
    }



    const saveIt = async (updtrNam) => {

        if (updtrNam === null || updtrNam === "") {
            setThePopUp(<InputSet
                Title={"עובד מעדכן"}
                ContentText={"מה שמך?"}
                inLabel=""
                okClick={(nam) => { setTheNameAndSaveMe(nam) }}
                cancelClick={() => setThePopUp()} />)
        }
        else {
            // props.okClick(inputTxt);

            let objToOrder = {
                buisId: buisSelected,
                dinners: dinners,
                timeToCome: timeToCome,
                dateToCome: dateToCome,
                clientName: clientName,
                clientPhone: clientPhone,
                status: orderStatus,
                remarks: remarks,
                ordererName: updtrNam
            }

            let ordTbl = await utilsTblOrders.orderMyTable(objToOrder);

            checkIfItemExistsAndStatus({ _id: ordTbl._id, status: orderStatus });

            setErr(
                <>
                    <p>ההזמנה נרשמה בהצלחה, מספר הזמנה: {ordTbl.orderNumber}</p>
                    {(ordTbl.sendedSMS && <p>הלקוח קיבל סמס בנוגע לאישור ההזמנה</p>)}
                </>
            )

            setOrdrApprove(true)

            // handleClose();
        }

    };


    const handleClose = () => {
        props.cancelClick();
    };

    return (
        <Dialog open={open} onClose={handleClose} className={"orderPlaceBlockDiag popupZINDEX"}>
            <DialogTitle>{props.Title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.ContentText}
                </DialogContentText>
                <div className={"orderPlaceBlock"}>
                    <h3 className="rightText">הזמנת שולחן</h3>
                    {
                        (user.permiss === "admin") ?
                            <BuisSelect busissList={buisLstTables}
                                defaultValue={myFunctions.getBuisNameByIdInList(buisLstTables, buisSelected)}
                                firstHead={"כל העסקים"}
                                clss={"buisSelectOnTBAR onLeft"}
                                userSelection={buisSelected ? buisSelected : -1}
                                setUserBuis={(newVal) => { setBuisSelected(newVal) }}
                            />
                            : ""
                    }
                    <br /><br />

                    <Accordion disabled={buisSelected ? false : true}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={"row"}>
                                <div className={""}>
                                    <DropNumbersSelection
                                        lbl="סועדים"
                                        Typ="text"
                                        Css="inputsDropDownStyle"
                                        Val={dinners}
                                        placeholder="מספר סועדים"
                                        settingFunc={(val) => setDinners(val)}
                                        maxNumb={50}
                                    />

                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion disabled={dinners > 0 ? false : true}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >

                            <div className={"row"}>
                                <div className={""}>
                                    <DatePick
                                        lbl="לתאריך"
                                        settingFunc={(val) => setDateToCome(val)}
                                        Css="inputsStyle pointing"
                                        selectedDate={dateToCome}
                                    />
                                </div>
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion disabled={dateToCome ? false : true}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >

                            <div className={"row"}>
                                <div className={""}>
                                    <DropHouresSelection
                                        lbl="לשעה"
                                        Typ="text"
                                        Css="inputsDropDownStyle"
                                        Val={timeToCome}
                                        placeholder="לשעה"
                                        settingFunc={(val) => setTimeToCome(val)}
                                        fromHour={10}
                                        toHour={23}
                                    />

                                </div>
                            </div>
                        </AccordionSummary>

                        <AccordionDetails>

                        </AccordionDetails>
                    </Accordion>
                    <Accordion disabled={timeToCome ? false : true}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={"fullRow"}>
                                <h5>פרטי הלקוח והערות</h5>
                                <div className={"row"}>
                                    <div className={"col-md-6 justify-content-center"}>
                                        <InputTextSimple
                                            lbl=""
                                            Typ="text"
                                            Css="inputsStyle"
                                            Val={clientName}
                                            placeholder="שם הלקוח"
                                            settingFunc={(val) => setClientName(val)}
                                        />

                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-md-6 justify-content-center"}>
                                        <InputTextSimple
                                            lbl=""
                                            Typ="text"
                                            Css="inputsStyle"
                                            Val={clientPhone}
                                            placeholder="טלפון"
                                            settingFunc={(val) => setClientPhone(val)}
                                        />

                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-12 justify-content-center"}>
                                        <MyTextArea firstText={remarks}
                                            saveEditorTxt={(content) => { setRemarks(content) }}
                                            cssStyle={"inputsStyle"}
                                            styl={{ width: "100%" }}
                                            placeholder="הערות" />
                                    </div>
                                </div>
                            </div>


                        </AccordionSummary>

                        <AccordionDetails>

                        </AccordionDetails>
                    </Accordion>


                    <div className={"row"}>
                        <div className={"col-md-4 justify-content-center"}>
                            <TblOrderStatuselect inStyle={{
                                width: '205px', height: '30px', marginTop: '12px'
                            }}
                                orderStatus={orderStatus}
                                setOrderStatus={(statBack) => setOrderStatus(statBack)}
                            />

                        </div>
                    </div>


                    <div className='fullRow' style={{ minHeight: '30px' }}>
                        {err !== "" &&
                            <div style={{ fontWeight: 'bold', textAlign: 'center' }} className={"inRed"}>
                                {err}
                            </div>}

                    </div>

                    {!ordrApprove ?
                        <div className={"btnstblOrdrs overflowHid"}>
                            <button type="button" className="onLeft btn btn-danger" onClick={handleClose}>ביטול</button>
                            <button type="button" className="onLeft btn btn-primary spaceMargeLeftbig" onClick={() => saveMe()}>שמור</button>
                        </div>
                        :
                        <div className={"btnstblOrdrs overflowHid"}>
                            <button type="button" className="onLeft btn btn-primary spaceMargeLeftbig" onClick={() => { props.ordrApproved() }}>סגור</button>
                        </div>
                    }

                    {thePopUp}
                </div>


            </DialogContent>

        </Dialog >
    );
}

export default CreateTblOrder;