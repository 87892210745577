import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { Checkbox, MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import utilsUsers from '../../../MyConns/_Utils/utilsUsers';

import Scroll from 'react-scroll';
var scroller = Scroll.scroller;

const GetDeliveryAddress = (props) => {

    const [theErrors, setTheErrors] = useState("");
    const [errorsTxts, setErrorsTxts] = useState("");
    const [showAddNew, setShowAddNew] = useState(false);

    const [theSele, setTheSele] = useState("");

    const [street, setStreet] = useState("");
    const [num, setNum] = useState("");
    const [appartment, setAppartment] = useState("");
    const [ent, setEnt] = useState("");
    const [city, setCity] = useState("");
    const [flor, setFlor] = useState("");

    const [remarks, setRemarks] = useState("");
    const [allAds, setAllAds] = useState([]);

    let theSelectdAdd = props.delAddress || "";

    useEffect(() => {
        getAllAdresses();

    }, [])

    const getAllAdresses = async () => {
        let userAdds = await utilsUsers.getAdresses(props.userId);
        setAddrs(userAdds);
    }


    const removeAddress = async (addsId) => {
        theSelectdAdd = (theSelectdAdd === addsId) ? "" : theSelectdAdd;
        setTheSele(theSelectdAdd);
        let userAdds = await utilsUsers.removeAddress(props.userId, { addToRemove: addsId });
        setAddrs(userAdds);
    }


    const setTheSelectedAdd = (eCheck, adrss, userAdds) => {
        theSelectdAdd = (eCheck) ? adrss : "";
        setTheSele(theSelectdAdd);
        setAddrs(userAdds)
        props.updateAddress(theSelectdAdd)
    }

    //בדוק שכל התאים של הכתובת תקינים, 
    //או שיוצר הערות או שולח לפונקציה הוספת כתובת 
    const saveAddress = () => {
        let stopMe = false;
        let thEr = "";
        let errs = [];
        if (street.trim().length <= 1) {
            thEr = "street";
        }
        if (num.trim().length <= 0) {
            thEr += ",num";
        }

        if (thEr != "") {
            setTheErrors(thEr)
            setErrorsTxts(errs)
        }
        else {
            addNewAddress();
        }
    }

    //הוספת כתובת בדאטה בייס
    const addNewAddress = async () => {

        let citybk = (city != "") ? city : "אשדוד";

        let addAdres = await utilsUsers.addNewAddress(props.userId, { street, num, appartment, ent, flor, citybk, remarks });
        theSelectdAdd = addAdres[addAdres.length - 1]._id;
        setTheSele(theSelectdAdd);
        setAddrs(addAdres);
        //מאפס את כל תיבות הטקסט לאחר הוספת הכתובת
        setStreet("");
        setNum("");
        setAppartment("");
        setEnt("");
        setCity("");
        setFlor("");
        setRemarks("");
        setTheErrors("");

        //מעלים את הוספת כתובת
        setShowAddNew(false)
    }


    const showhideAdress = () => {

        setShowAddNew(!showAddNew)

        if (!showAddNew) {
            setTimeout(function () {
                scroller.scrollTo(`newAddress`, { smooth: true, offset: -120, duration: 1000 });
            }, 20)
        }
    }


    //מציג את כל הכתובות הקיימות ומסמן את הנבחרת
    const setAddrs = (userAdds) => {
        let addresses = userAdds.map((addrs, index) => {
            let chkd = false;

            if (addrs.street === theSelectdAdd.street &&
                addrs.num === theSelectdAdd.num) {
                chkd = true;
            }

            return (
                <tr key={index} className={"firstDelTd pointing"} onClick={() => setTheSelectedAdd(!chkd, addrs, userAdds)}>
                    <td className={"firstDelTd"}>
                        <Checkbox checked={chkd} style={{
                            color: "var(--priceText)",
                        }}
                            onChange={(e) => setTheSelectedAdd(e.target.checked, addrs, userAdds)} />
                    </td>
                    <td className={"addressDelTd"} >
                        {`
            ${addrs.street} 
            ${addrs.num}                  
            `}
                        <br />
                        {`
             ${(addrs.appartment != null && addrs.appartment != "") ? `דירה: ${addrs.appartment}` : ""} 
              ${(addrs.ent != null && addrs.ent != "") ? `כניסה: ${addrs.ent}` : ""} 
             
              ${(addrs.flor != null && addrs.flor != "" && addrs.num != "") ? `קומה: ${addrs.flor}` : ""}
              ${(addrs.city != null && addrs.city != "") ? ` ${addrs.city}` : " אשדוד"}
            `}
                        <br />
                        {`
             ${(addrs.remarks != "") ? `הערות לשליח: ${addrs.remarks}` : ""}
             `}
                    </td>
                    <td>
                        <RemoveCircleOutline onClick={() => removeAddress(addrs._id)} />
                    </td>
                </tr>
            )
        })


        setAllAds(addresses);

    }

    return (
        <div className={props.class}>
            <div className={"bold huge"}>לאיזה כתובת לשלוח?</div>
            {
                (theErrors != "") ?
                    <div className={"errorLine"}>
                        {
                            (!theErrors.includes("address") &&
                                !theErrors.includes("num") &&
                                errorsTxts != "") ?
                                <p className={"vsmall bold"}>- {errorsTxts}</p> :
                                <p className={"big bold"}>{"התאים המסומנים הינם חובה"}</p>

                        }
                    </div>
                    : (errorsTxts != "") ?
                        <div className={"errorLine"}>
                            <p className={"vsmall bold"}>- {errorsTxts}</p> </div> : ""
            }

            <div>
                <table>
                    <tbody>
                        {allAds}
                    </tbody>
                </table>
                <br />
                {(allAds != "") ?
                    <div className="fullWidth spaceButtomBig spaceMargeRightbig pointing" onClick={() => showhideAdress()}>
                        <div className={"bold big addressAddBtn"}>
                            {(showAddNew) ? <RemoveCircleOutline className="plusOnAddRes" /> :
                                <AddCircleOutline className="plusOnAddRes" />}
                            כתובת אחרת
                        </div>
                    </div>
                    : ""
                }
                {(showAddNew || allAds === "") ?
                    <div className="addAddressBlock" id="newAddress">
                        <div className={`usrDet details ${(theErrors.includes("street") ? "errorMe" : "")}`} >
                            <input type={"text"} className={"connInput"} value={street} placeholder={"רחוב"} onChange={(e) => setStreet(e.target.value)} />
                        </div>
                        <div className={"usrDet"}>
                            <div className={`usrDetSmall details ${(theErrors.includes("num") ? "errorMe" : "")}`} >
                                <input type={"text"} className={"connInput"} value={num} placeholder={"בית"} onChange={(e) => setNum(e.target.value)} />
                            </div>
                            <div className={`usrDetSmall details ${(theErrors.includes("apprtment") ? "errorMe" : "")}`} >
                                <input type={"text"} className={"connInput"} value={appartment} placeholder={"דירה"} onChange={(e) => setAppartment(e.target.value)} />
                            </div>
                            <div className={`usrDetSmall details`} >
                                <input type={"text"} className={"connInput"} value={ent} placeholder={"כניסה"} onChange={(e) => setEnt(e.target.value)} />
                            </div>
                            <div className={`usrDetSmall details`} >
                                <input type={"text"} className={"connInput"} value={flor} placeholder={"קומה"} onChange={(e) => setFlor(e.target.value)} />
                            </div>
                        </div>

                        <div className={`usrDet details`} >
                            <input type={"text"} className={"connInput"} value={city} placeholder={"אשדוד"} onChange={(e) => setCity(e.target.value)} />
                        </div>


                        <div className={`usrDet details`} >
                            <textarea className={"connInput"} value={remarks} placeholder={"הערות לשליח"} onChange={(e) => setRemarks(e.target.value)}></textarea>
                        </div>
                        <div className={"details fullWidth"}>
                            <div className={"bold big ConDetailsBtn"} onClick={() => saveAddress()}>שמור כתובת</div>
                        </div>
                    </div>
                    : ""}
            </div>
        </div>)
}

export default GetDeliveryAddress;