import React, { useContext, useEffect, useState } from 'react'
import { TheMasterContext } from '../../../../MyContexts/Master_Context';
import SelectImgComp from '../../../../MySections/ImagesSetter/SelectImgComp';
import SelectImgDelay from '../../../../MySections/ImagesSetter/SelectImgDelay';

const TabSiteDetails = (props) => {
    const [theMaster] = useContext(TheMasterContext);

    const [mainTitle, setMainTitle] = useState(theMaster.mainTitle);
    const [webSiteLogo, setWebSiteLogo] = useState(theMaster.webSiteLogo);
    const [logoDesktop, setLogoDesktop] = useState(theMaster.logoDesktop);
    
    const [waitingImgMaster, setWaitingImgMaster] = useState(theMaster.waitingImgMaster);
    const [imgDelay, setImgDelay] = useState(theMaster.imgDelay);
    const [imgPosRndom, setImgPosRndom] = useState(theMaster.imgPosRndom ? theMaster.imgPosRndom : false);



    useEffect(() => {


    }, [])


    const setTheLogo = (furl) => {
        theMaster["webSiteLogo"] = furl;
        setWebSiteLogo(furl);
    }
    
    const setTheLogoDesktop = (furl) => {
        theMaster["logoDesktop"] = furl;
        setLogoDesktop(furl);
    }
    
    const setTheWaitingImgMaster = (furl) => {
        theMaster["waitingImgMaster"] = furl;
        setWaitingImgMaster(furl);
    }

    const setTheImgDelay = (timeToDelay) => {
        theMaster["imgDelay"] = timeToDelay;
        setImgDelay(timeToDelay);

    }
    const setTheImgPosRndom = () => {
        theMaster["imgPosRndom"] = !imgPosRndom;
        setImgPosRndom(!imgPosRndom);

    }


    return (
        <>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>( כותרת חלון )</label>
                <span className={"explanation mediumst fullROw"}>תופיע בטאב כאשר לא מוגדר אחרת</span>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_6"} placeholder={"אשדוד בתפריט"} value={mainTitle}
                        onChange={(e) => { theMaster["mainTitle"] = e.target.value; setMainTitle(e.target.value) }}
                    />

                </div>
            </div>
           

            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>לוגו אתר</label>
                <span className={"explanation mediumst fullROw"}>מוצג על כפתור התפריט</span>
                <SelectImgComp firstImgToDisp={webSiteLogo ? webSiteLogo : ""} theFunction={(fUrl) => setTheLogo(fUrl)} />
            </div>
            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>לוגו דסקטופ</label>
                <span className={"explanation mediumst fullROw"}> מוצג על כפתור התפריט בדסקטופ</span>
                <SelectImgComp firstImgToDisp={logoDesktop ? logoDesktop : ""} theFunction={(fUrl) => setTheLogoDesktop(fUrl)} />
            </div>
            <br />
            <br />
            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>תמונת המתנה</label>
                <span className={"explanation mediumst fullROw"}>מוצג בזמן המתנת עליית כל הנתונים</span>
                <span className={"explanation mediumst fullROw"}>לכל עמוד יש אופציה כזו בפרטי העמוד</span>

                <SelectImgComp firstImgToDisp={waitingImgMaster ? waitingImgMaster : ""} theFunction={(fUrl) => setTheWaitingImgMaster(fUrl)} />
            </div>
            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>השהיית תמונה</label>
                <span className={"explanation mediumst fullROw"}>זמן השהייה לפני העלמות התמונה</span>

                <SelectImgDelay currentDelay={imgDelay ? imgDelay : ""} theFunction={(fUrl) => setTheImgDelay(fUrl)} />
            </div>
            <div className={"col-md-3 mb-2"}>

                <label className="ms-switch">
                    <input type="checkbox" id={"validationCustom5_3"} checked={imgPosRndom}
                        onChange={() => {
                            setTheImgPosRndom()
                        }} />
                    <span className="ms-switch-slider ms-switch-primary round" />
                </label> <span className={"spHead"}>מיקום רנדומלי</span>
            </div>
        </>

    );
}

export default TabSiteDetails;