import React, { useState, createContext, useEffect } from 'react';
import utilsRests from '../../MyConns/_Utils/utilsRests';
import myFunctions from '../../myFunctions';

export const BuisListForKoponContext = createContext();

export const BuisListForKoponContextProvider = props => {
    const [buisLstKopon, setBuisLstKopon] = useState([]);

    useEffect(() => {
        
        const getBuisLst = async () => {

            if (buisLstKopon.length === 0) {
                let tempolst = await utilsRests.getRestsForKoponListSmall();
                tempolst = myFunctions.sortAsc(tempolst, "buissName");
                tempolst = myFunctions.addIdtag(tempolst);
                setBuisLstKopon(tempolst)
            }
        }

        getBuisLst();
    }, [])



    return (
        <BuisListForKoponContext.Provider value={[buisLstKopon, setBuisLstKopon]}>
            {props.children}
        </BuisListForKoponContext.Provider>
    )
}



