import React, { useEffect } from 'react';
import Topnavigation from '../../../../layouts/Topnavigation';
import Sidenavigation1 from '../../../../layouts/Sidenavigation1';

import Content from './Content';
import { TheMasterContextProvider } from '../../../../MyContexts/Master_Context';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';
import Translator from '../../../../../MyFunctions/Translator';

const InvoiceTable = (props) => {

    useEffect(() => {
    }, [])

    //מציג את כל הנלווים של המוצר
    const getSpecials = (item) => {
        return (item.specials.length > 0) ?
            item.specials.map((special, specialIndex) => {

                let specCount = 0;
                let specc = special.spec.map((spec, specIndex) => {

                    if (spec.addToPrice > -1) {
                        specCount++;

                        return (
                            <div key={specIndex} className={"ordItmLine"}>
                                <span className={"rSideFirst"}></span>
                                <span className={"rSideMiddle"}>
                                    {OrderFunctions.conten(spec, item)}
                                    <span className={"regular"}> {spec.servType}</span>

                                </span>
                                <span className={"bold lSideSmall"}>
                                    {spec.addToPrice > 0 ? "₪" + (parseInt(spec.addToPrice) * parseInt(item.mount)) : ""}
                                </span>
                            </div>
                        )
                    }
                })
                if (specCount > 0) {
                    return (
                        <div key={specialIndex} className={"ordItmSpecLine spacePad"}>
                            <div className={"ordItmOptHead overflowHid bold"} >{special.optionName}</div>
                            {specc}
                        </div>
                    )
                }


            })
            : "";

    }

    const getSaucesLst = () => {
        let rtrn = props.theOrder.ord.StickedSauces?.map((sauce, indx) => {
            return (
                <div key={indx} className={"sucItem"}>

                    <span className={"rSideMiddle"}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={"tdmin10"}>
                                        <span className={"engFont ordItemMount"}>X&nbsp;</span><span className={"ordItemMount"}>
                                            {sauce.mount}&nbsp;</span>
                                    </td>
                                    <td>
                                        <span className={"ordItemMount"}> {sauce.servType}</span>
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </span>
                </div>
            )

        });
        return rtrn
    }

    let sucs = OrderFunctions.getStickedSauces(props.theOrder.ord)
    let saucesPrice = (sucs > 0) ?
        sucs + "  ₪" :
        ""

    //רטבים מוצמדים מוזמנים
    let stickedSauces = (props.theOrder.ord.StickedSauces?.length > 0) ?

        <tr key={"sucess"}>
            <td className="centerText bold">
                {props.theOrder.ord.StickedSauces.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.mount;
                }, 0)}

            </td>
            <td className="textRight bold">
                <span className={"bold fullRow "}>רטבים מוצמדים</span>
                <span className={"veryVerySmall"}>
                    &nbsp;{(props.theOrder.ord.freeSaucess > 0) ? `(${props.theOrder.ord.freeSaucess} ללא עלות)` : ""}
                    {getSaucesLst(props.theOrder.ord.StickedSauces || [])}
                </span>

            </td>
            <td className="textRight"></td>
            <td className="bold"></td>
            <td className="textRight bold">
                {saucesPrice}
            </td>
        </tr>

        : ""

    //מספר סועדים, צופסטיקס וסכום
    let DinersAndChops = (props.theOrder.ord.Dinners > 0) ?

        <tr key={"dnrs"}>
            <td className="centerText bold">
                {props.theOrder.ord.Dinners}
            </td>
            <td className="textRight bold">
                <span className={"bold fullRow "}>סועדים</span>
                <span className={"veryVerySmall"}>
                    <br />
                    {`${(props.theOrder.ord.getfANife) ? "סכום: כן" : ""}`}
                    <br />
                    {`${(props.theOrder.ord.getChupstiks) ? "צופסטיקס: כן" : ""}`}
                </span>

            </td>
            <td className="textRight"></td>
            <td className="bold"></td>
            <td className="textRight bold">
                {/* {OrderFunctions.getStickedSauces(props.theOrder.ord) > 0 ?
                    OrderFunctions.getStickedSauces(props.theOrder.ord) + "  ₪" :
                    ""} */}
            </td>
        </tr>

        : ""




    return (
        <div className="ms-panel-body ordertblBody">

            {/* Invoice Table */}
            <div className="ms-invoice-table table-responsive">
                <table className="table table-hover text-right thead-light">
                    <thead>

                        <tr className="text-capitalize">
                            <th className="text-center w-5">{props.arabic ? Translator.getInArab("כמות") : "כמות"}</th>
                            <th className="textRight">{props.arabic ? Translator.getInArab("שם המוצר") : "שם המוצר"}</th>
                            <th className="textRight">{props.arabic ? Translator.getInArab("נלווים") : "נלווים"}</th>
                            <th className="textRight"></th>
                            <th>{props.arabic ? Translator.getInArab(`סה"כ`) : `סה"כ`}</th>
                        </tr>
                    </thead >
                    <tbody>
                        {props.theOrder.ord?.Orders.map((item, i) => {

                            return (
                                <tr key={i}>
                                    <td className="centerText bold">{item.mount}</td>
                                    <td className="textRight bold">{item.productName}
                                        <div className="textRight onlyOnPrint">{OrderFunctions.theItemSpecials(item)}</div>
                                    </td>
                                    <td className="textRight">{(item.Remarks) ?
                                        <>
                                            <div>{OrderFunctions.theItemSpecials(item)}</div>
                                            <br />
                                            <div className="textRight bold" >
                                                {props.arabic ? Translator.getInArab("הערה") : "הערה"}: {item.Remarks}</div>
                                        </>
                                        : OrderFunctions.theItemSpecials(item)}</td>
                                    <td className="bold" style={{ direction: 'ltr' }}>₪{item.mount > 1 ? parseFloat(item.prodPrice) * item.mount : parseFloat(item.prodPrice)}</td>
                                    <td className="textRight bold" style={{ direction: 'ltr' }}><div className={"bottomTot"} style={{ direction: 'ltr' }}>₪{OrderFunctions.getProdPriceWithSPecials(item)}</div></td>
                                </tr>
                            )
                        })}
                        {stickedSauces}
                        {DinersAndChops}

                        {(props.theOrder.ord?.OrderType === "Deliver") ?
                            <tr className={"specialRow"} key={props.theOrder.ord?.Orders.length + 1}>
                                <td className="text-center"></td>
                                <td colSpan={3} className="textRight bold">{props.arabic ? Translator.getInArab("משלוח") : "משלוח"}</td>
                                <td className="textRight bold">₪{props.theOrder.ord?.OrderDelPrice}</td>
                            </tr>
                            : ""}

                        {(props.theOrder.ord.orderKopon && props.theOrder.ord.orderKopon != "") ?
                            <tr className={"specialRow"} key={props.theOrder.ord?.Orders.length + 1}>
                                <td className="text-center"></td>
                                <td colSpan={3} className="textRight bold">{props.arabic ? Translator.getInArab("קופון") : "קופון"} {props.theOrder.ord.orderKopon.Name} </td>
                                <td className="textRight bold">{props.theOrder.ord.orderKopon.KoponDiscount}{props.theOrder.ord.orderKopon.KoponDiscType === '1' ? "₪" : "%"}</td>
                            </tr>
                            : ""}

                        {(props.theOrder.ord.orderZikoy && props.theOrder.ord.orderZikoy != "") &&
                            props.theOrder.ord.orderZikoy.map((element, index) => {
                                return (
                                    <tr className={"specialRow"} key={`zikoy_${index}`}>
                                        <td className="text-center"></td>
                                        <td colSpan={3} className="textRight bold">
                                            {props.arabic ? Translator.getInArab("זיכוי") : "זיכוי"} {element.Name} </td>
                                        <td className="textRight bold">₪{element.zickoyPrice}-</td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={4} className="bold veryBig textLeft ">{props.arabic ? Translator.getInArab(`סה"כ`) : `סה"כ`}: </td>
                            <td className="bold veryBig">{(props.theOrder.ord != null) ? `₪${OrderFunctions.getOrderTotalPrice(props.theOrder.ord)}` : ""} </td>
                        </tr>
                        <tr>
                            <td colSpan={4} className="bold veryBig textLeft ">{props.arabic ? Translator.getInArab("אופן תשלום") : "אופן תשלום"}:</td>
                            <td className="bold veryBig">
                                {props.arabic ? Translator.getInArab(OrderFunctions.payingTYpe(props.theOrder)) : OrderFunctions.payingTYpe(props.theOrder)}
                            </td>
                        </tr>

                    </tfoot>
                </table >
            </div >
        </div >
    )
}

export default InvoiceTable;