import React, { Component, useEffect } from 'react';
import Swal from 'sweetalert2'


const GoBackCheck = (props) => {
  
    useEffect(() => {
        let countdown = 30;
        const interval = setInterval(() => {
            countdown -= 1;
            if (countdown >= 0) {
                // Update the confirm button text dynamically
                Swal.getConfirmButton().textContent = `(${countdown}) חזור להזמנות`;
            }
            if (countdown === 0) {
                clearInterval(interval);
                Swal.close(); // Close the alert
                props.successFunc(); // Trigger the success function
            }
        }, 1000);

        Swal.fire({
            title: props.title,
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#62b4b8",
            cancelButtonColor: "#00ae00",
            confirmButtonText: `(${countdown}) חזור להזמנות`, // Initial text with countdown
            cancelButtonText: "כן, אני כאן",
            customClass: {
                container: "CheckDelete onTopOfAll",
            },
        }).then(function (result) {
            clearInterval(interval); // Clear interval when user interacts
            if (result.isConfirmed) {
                props.successFunc();
            } else if (result.isDismissed) {
                props.cancelFunc();
            }
        });

        return () => {
            clearInterval(interval); // Cleanup interval on unmount
        };
    }, [props]);

    return <></>;
};



export default GoBackCheck;
