import { Dialog, Button, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExitToApp, FileUpload, CreateNewFolder, HighlightOffOutlined, VisibilityOutlined, CheckCircle } from '@mui/icons-material';
import utilsMediaList from '../../../MyConns/_Utils/utilsMediaList';
import { map } from "jquery";
import ConnUrls from "../../../MyConns/Connections/ConnectURL";
import Swal from "sweetalert2";
import EraseCheck from "../PopUps/EraseCheck";
import InputSet from "../PopUps/InputSet";
import ImagePopUp from "../PopUps/ImagePopUp";
import MySpinner_Wait from "../DTechComps/MySpinner_Wait";


const PageSelectImage = (props) => {

    let [mediaList, setMediaList] = useState([]);
    const [currentDisplay, setCurrentDisplay] = useState([]);
    const [currentPosition, setCurrentPosition] = useState({ fileUrl: "images/Uploads/" });
    const [thePopUp, setThePopUp] = useState("");
    const [selImg, setSelImg] = useState({ item: { fileUrl: props.firstImg } });
    const [thePosi, setThePosi] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);

        if (props.firstImg != null && props.firstImg != "") {
            let fSet = props.firstImg.split('/');
            fSet.pop();
            let jon = fSet.join('/');

            setCurrentPosition({ fileUrl: jon })
        }

    }, [])

    useEffect(() => {
        setLoading(true);

        setTheList()
    }, [currentPosition, selImg])



    const addNewFolder = () => {
        setThePopUp(
            <InputSet Title={"יצירת תיקייה"}
                ContentText={"שם התיקייה"} inLabel={""}
                okClick={(textBack) => createFolderOK(textBack)}
                cancelClick={() => setThePopUp()}
                errorMsg={"על מנת ליצור תיקייה חדשה, חובה לתת לה שם."}
            />)
    }

    const removeFolder = (fileUrl) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את התיקייה?'} sucecssFunc={() => removeFolderConfirmd(fileUrl)} />)
    }

    const removeFile = (fileUrl) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את הקובץ?'} sucecssFunc={() => removeFileConfirmd(fileUrl)} />)
    }

    const uploadNewFile = async (filesToUp) => {

        for (let i = 0; i < filesToUp.length; i++) {
            let mdlist = await utilsMediaList.addNewFile({ fileToUp: filesToUp[i], Path: currentPosition.fileUrl });
        }
        setThePosi(getThePosi());
        setTheList();
        setThePopUp();

    }

    const removeFileConfirmd = async (fileUrl) => {

        let mdlist = await utilsMediaList.removeFile({ StartingPath: fileUrl });
        setThePosi(getThePosi());
        setTheList();

    }

    const removeFolderConfirmd = async (fileUrl) => {

        let mdlist = await utilsMediaList.removeFolder({ StartingPath: fileUrl });
        setThePosi(getThePosi());
        setTheList();

    }


    const setTheList = async () => {
        let theList = await utilsMediaList.getMediaList
            ({ StartingPath: currentPosition.fileUrl });
        mediaList = theList;
        setMediaList(theList)

        getListCurrnetF(mediaList);
        setThePosi(getThePosi());
        setLoading(false);

    }
    const updateFile = async (newFileName, imgItm) => {

        let mdlist = await utilsMediaList.updateFile
            ({ fName: imgItm.fileName, StartingPath: imgItm.fileUrl, newFileName: newFileName });

        setThePosi(getThePosi());
        setTheList();
        setThePopUp();

    }



    const createFolderOK = async (textBack) => {

        let mdlist = await utilsMediaList.createFolder
            ({ FolderName: textBack, Path: currentPosition.fileUrl });
        setThePosi(getThePosi());
        setTheList();
        setThePopUp();
    }
    let imgsCount = 0;
    const getListCurrnetF = (mediaList) => {
        imgsCount++;
        let fldsList =
            mediaList.folderList.map((item, index) => {
                // if (item.extension == "") {

                return (
                    <div key={`${index}_${imgsCount}`} className={"popImagesItemWrap"}>
                        <HighlightOffOutlined sx={{ fontSize: 20 }}
                            className={"pointing removeOnImages"} onClick={() => removeFolder(item.folderUrl)} />
                        <div className={"popImagesItem pointing"} title={item.folderName}
                            onClick={() => setCurrentPosition({ ...item, fileUrl: item.fixPath })}>
                            <img src={ConnUrls.mediasLocalUrl + "/PermanentImages/FolderIcon.png"} alt={item.folderName} />
                            <p className={""}>{item.folderName}</p>
                        </div>
                    </div >
                )

            })

        let filsLst =
            mediaList.fileList.map((item, index) => {


                return (
                    <div key={`${index}#${imgsCount}`} className={"popImagesItemWrap"}>
                        <HighlightOffOutlined sx={{ fontSize: 20 }}
                            className={"pointing removeOnImages"} onClick={() => removeFile(item.fileUrl)} />
                        <VisibilityOutlined sx={{ fontSize: 20 }}
                            className={"pointing ViewOnImages"} onClick={() => openImage(item)} />
                        <div className={"popImagesItem pointing"} title={item.fileName}>
                            <CheckCircle sx={{ fontSize: 40 }}
                                className={`pointing selectedImgIcon ${selImg.item?.fileUrl != item.fileUrl ? "hideMe" : ""}`}
                                onClick={() => setSelImg({})} />
                            <img src={item.fileUrl} alt={item.fileName} onClick={() => setSelImg({ item })} />
                            <p className={""}>{item.fileName}</p>
                        </div>
                    </div>
                )

            })


        let mergedList = fldsList.concat(filsLst);
        setCurrentDisplay(mergedList);

    }



    const goBkOfFolder = () => {
        let tempo = currentPosition.fileUrl.split('/');
        tempo.pop();
        let jon = tempo.join('/');

        setCurrentPosition({ fileUrl: "images/" + jon + "/" });
    }

    const goToSplt = (index) => {

        let tempo = currentPosition.fileUrl.split('/');

        while (index < tempo.length - 1)
            tempo.pop();

        let jon = tempo.join('/');

        setCurrentPosition({ fileUrl: "images/" + jon + "/" });
    }



    const getThePosi = () => {


        currentPosition.fileUrl = currentPosition.fileUrl.startsWith('images/') ? currentPosition.fileUrl.substring(7) : currentPosition.fileUrl

        let spl = currentPosition.fileUrl.split('/');

        let theLnk = spl.map((item, index) => {
            return (<span key={index} className="brd pointing" onClick={() => goToSplt(index)}>{index != 0 ? " > " : ""} {item}</span>)
        })

        return (
            <div className="thePosi bold huge">
                {currentPosition.fileUrl.split('/').length > 1 ?
                    <ExitToApp sx={{ fontSize: 30 }} className={"pointing"} onClick={() => goBkOfFolder()} /> :
                    <ExitToApp sx={{ fontSize: 30, color: "gray" }} className={"pointing"} />}
                {theLnk}

            </div>
        )
    }


    const openImage = (imgItm) => {

        setThePopUp(
            <ImagePopUp Title={imgItm.fileName}
                okClick={(fName) => updateFile(fName, imgItm)}
                cancelClick={() => setThePopUp()}
                imgSrc={imgItm.fileUrl}
                imageName={imgItm.fileName}
                cancelText={"סגור"}
                saveText={"עדכן וסגור"}
            />)
    }


    return (
        <>
            <div className="PageTopLine">
                <div className="IconsLine">
                    <Button variant="contained" onClick={() => addNewFolder()}
                        startIcon={<CreateNewFolder style={{ color: 'white' }} className={"pointing"} />}>
                        יצירת תיקיה
                    </Button>

                    {(currentPosition.fileUrl.split('/').length > 1) ?
                        <>
                            <label className={"uploadBtn css-sghohy-MuiButtonBase-root-MuiButton-root pointing"} htmlFor="raised-button-file">
                                <FileUpload sx={{ fontSize: 20 }} className={"pointing"} title={"העלאת קובץ"} />העלאת קובץ
                            </label>
                            <input
                                accept="image/*"
                                className={""}
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={(event) => { uploadNewFile(event.target.files); setLoading(true) }}
                            />
                        </> : ""
                    }
                </div>
                {thePosi}
            </div>
            <div className={"PageTopLSelectImgBox"}>
                {loading ? <MySpinner_Wait /> :
                    currentDisplay
                }
                {thePopUp}
            </div>

        </>
    )
}

export default PageSelectImage;