import buissTableSettings from "../_ApiConn/apiBuissTableSettings";


const upOrCreate = async (obj) => {
    let token = localStorage.getItem("token");
    let dFromF = await buissTableSettings.upOrCreate({ token ,obj});

    return (dFromF);
}
const getBuissTableSettings = async (buisId) => {
    let token = localStorage.getItem("token");
    let dFromF = await buissTableSettings.getBuissTableSettings({ token ,buisId});

    return (dFromF);
}


export default {
    upOrCreate,
    getBuissTableSettings
   
};