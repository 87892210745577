import codes from "../_ApiConn/apiCodes";


const createCodeNuser = async (obj) => {
    let dFromF = await codes.createCodeNuser(obj);
    return (dFromF);
}
const checkCode = async (nId, codeCheck) => {
    let dFromF = await codes.checkCode(nId, codeCheck);
    return (dFromF);
}

const createCodeSms = async (phoneNum) => {
    let dFromF = await codes.createCodeSms(phoneNum);
    return (dFromF);
}

const checkConnCode = async (nId, codeCheck) => {
    let dFromF = await codes.checkConnCode(nId, codeCheck);
    return (dFromF);
}


const createCodeUpUser = async (obj) => {
    let dFromF = await codes.createCodeUpUser(obj);
    return (dFromF);
}


const checkUpdteCode = async (nId, codeCheck) => {
    let dFromF = await codes.checkUpdteCode(nId, codeCheck);
    return (dFromF);
}

export default {
    createCodeUpUser,
    checkConnCode,
    checkUpdteCode,
    createCodeSms,
    createCodeNuser,
    checkCode
};