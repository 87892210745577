import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'

const getLink = async (lnkName, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "links/" + lnkName, token);
    return (dFromF.data);
}

const getLinks = async (token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "links/getLinks/" + 0, token);
    return (dFromF.data);
}


const updateLinkById = async (id, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "links/linksUp/" + id, token);
    return (dFromF.data);
}

const updateLinks = async (objWithToken) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "links/updateLinks/" + 0, objWithToken);
    return (dFromF.data);
}

export default {
    getLink,
    getLinks,
    updateLinkById,
    updateLinks
};