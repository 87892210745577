import React, { Component, useContext } from 'react';
import Sidenavigation from '../../layouts/Sidenavigation';
import Sidenavigation1 from '../../layouts/Sidenavigation1';
import Topnavigation from '../../layouts/Topnavigation';
import Quickbar from '../../layouts/Quickbar';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';
import ManagmentPSet from './ManagmentPSet';
import { LinksContextProvider } from '../../MyContexts/Links_Context';
import { UserContext } from '../../MyContexts/Users_Context';

const ManagmentPage = (props) => {
    const [user, setUser] = useContext(UserContext)
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>
                <LinksContextProvider>

                    <Sidenavigation1 />
                    {/* <Sidenavigation1 /> */}
                    <main className="body-content">
                        <Topnavigation />
                        {user.permiss === "admin" &&
                            <ManagmentPSet />
                        }
                    </main>
                    {/* <Quickbar /> */}
                </LinksContextProvider>
            </TheMasterContextProvider>

        </div>
    );
}

export default ManagmentPage;