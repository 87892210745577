import React, { useContext, useEffect, useState } from 'react'
import utilsLinks from '../../../../MyConns/_Utils/utilsLinks';
import utilsMaster from '../../../../MyConns/_Utils/utilsMaster';
import { LinksContext } from '../../../MyContexts/Links_Context';
import { TheMasterContext } from '../../../MyContexts/Master_Context';
import SelectImgComp from '../../../MySections/ImagesSetter/SelectImgComp';

const TabSiteLinks = (props) => {
    const [theMaster, setTheMaster] = useContext(TheMasterContext);
    const [Links, setLinks] = useContext(LinksContext);

    const [mainTitle, setMainTitle] = useState(theMaster.mainTitle);
    const [manageLinks, setManageLinks] = useState(theMaster.manageSiteLogo);
    const [theLinks, setTheLinks] = useState([]);

    useEffect(() => {
        getLinks();
    }, [])


    const getLinks = async () => {

        let mst = await utilsLinks.getLinks();
        if (mst.errMsg === "") {
            setTheLinks(mst.links)
        }

    }


    return (
        <>
            {theLinks.map((item, index) => {

                return (

                    <div key={index} className={"col-md-12 mb-2"}>
                        <label htmlFor={"validationCustom1_6"}>{item.name}</label>
                        <div className={"input-group"}>
                            <input type="text" className={"form-control"} value={item.url} id={"validationCustom1_6"} placeholder={"קישור"}
                                onChange={(e) => { theLinks[index].url = e.target.value; setLinks([...theLinks]) }}
                            />

                        </div>
                    </div>
                )

            })
            }



        </>

    );
}

export default TabSiteLinks;