import React, { Component, useContext } from 'react';
import Topnavigation from '../../layouts/Topnavigation';
import Sidenavigation from '../../layouts/Sidenavigation';
import Sidenavigation1 from '../../layouts/Sidenavigation1';

import { TheMasterContextProvider } from '../../MyContexts/Master_Context';
import ImageGallContent from './ImageGallContent';
import { UserContext } from '../../MyContexts/Users_Context';

const ImageGall = (props) => {
    const [user, setUser] = useContext(UserContext)

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>

                <Sidenavigation1 />
                <main className="body-content">
                    <Topnavigation />
                    {user.permiss === "admin" &&
                        <ImageGallContent />
                    }
                </main>

            </TheMasterContextProvider>
        </div>
    );
}

export default ImageGall;