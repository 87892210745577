import { Checkbox, MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import myFunctions from '../../../myFunctions';
import BuisSelect from '../OrdersP/OrdersList-list/BuisSelect';
import UserPermission from '../OrdersP/OrdersList-list/UserPermission';
import EraseCheck from '../../MySections/PopUps/EraseCheck';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MultiBuisSelect from '../OrdersP/OrdersList-list/MultiBuisSelect';

const ListUsersTable = (props) => {
    const [tempoUsersList, setTempoUsersList] = useState(props.usersList);
    const [passwordsLst, setPasswordsLst] = useState(() => Array(tempoUsersList).fill(false));
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {

    }, [props.buisLst])

    useEffect(() => {
        setTempoUsersList(props.usersList)

    }, [props.usersList])

    const updatePermiss = async (newVal, usrId) => {
        let temp = [...tempoUsersList]
        let itmIndx = temp.findIndex(it => it._id === usrId);
        temp[itmIndx].permiss = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)
        //setTempList([...tempList, itm])

    }


    const setUserBuis = async (newVal, usrId) => {
        let temp = [...tempoUsersList]
        let itmIndx = temp.findIndex(it => it._id === usrId);
        temp[itmIndx].buisID = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)


    }

    const blockUser = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].blocked = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)

    }

    const setFirstName = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].firstName = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)

    }
    const setLastName = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].lastName = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)
    }


    const setPassword = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].password = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)
    }


    const setPhone = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].phone = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)
    }

    const setEmail = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].email = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)

    }

    const removeUser = (indx) => {

        setThePopUp(<EraseCheck title={'האם בטוח למחוק את המשתמש?'}
            sucecssFunc={() => removeOptConfirmd(indx)} />)
    }

    const removeOptConfirmd = async (indx) => {

        let temp = [...tempoUsersList];
        let itmeToErease = temp[indx]
        props.addToDelete(itmeToErease)

        temp.splice(indx, 1)

        setTempoUsersList(temp)

        props.updateList(temp)

    }

    const handleTogglePassword = (index) => {
        const updatedPasswordsLst = [...passwordsLst];
        updatedPasswordsLst[index] = !updatedPasswordsLst[index];
        setPasswordsLst(updatedPasswordsLst);
    };


    return (
        <div className="ms-panel-body">
            <div className="table-responsive">
                <table className="table table-hover thead-primary">
                    <thead>
                        <tr>
                            <th scope="col">שם פרטי</th>
                            <th scope="col">שם משפחה</th>
                            <th scope="col">טלפון</th>
                            <th scope="col">מייל</th>
                            <th scope="col">סיסמא</th>
                            <th scope="col">הרשאות</th>
                            <th scope="col">עסק</th>
                            <th scope="col">מחק</th>
                            <th scope="col">חסום</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tempoUsersList.map((item, index) => {
                            let itmBlock = (item.blocked === undefined || item.blocked === false) ? false : true;



                            return (
                                <tr key={item._id} itmid={item._id}>
                                    <td scope="row">
                                        <div className={"textRight"}>
                                            <input type="text" className={"form-control"} placeholder={"שם פרטי"}
                                                value={item.firstName}
                                                onChange={(e) => { setFirstName(index, e.target.value) }} />
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div className={"textRight"}>
                                            <input type="text" className={"form-control"} placeholder={"שם משפחה"}
                                                value={item.lastName}
                                                onChange={(e) => { setLastName(index, e.target.value) }} />
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div className={"textRight"}>
                                            <input type="text" className={"form-control"} placeholder={"טלפון"}
                                                value={item.phone}
                                                onChange={(e) => { setPhone(index, e.target.value) }} />
                                        </div>
                                    </td>
                                    <td scope="row" style={{ direction: 'ltr' }}>
                                        <div className={"textRight"}>
                                            <input type="text" className={"form-control"} placeholder={"אימייל"}
                                                value={item.email}
                                                onChange={(e) => { setEmail(index, e.target.value) }} />
                                        </div>
                                    </td>
                                    <td scope="row" style={{ direction: 'ltr' }}>

                                        <div style={{ position: 'relative' }} className="textRight">
                                            <input
                                                type={passwordsLst[index] ? "text" : "password"}
                                                className="form-control"
                                                placeholder="סיסמא"
                                                value={item.password}
                                                onChange={(e) => setPassword(index, e.target.value)}
                                            />
                                            <Checkbox
                                                icon={<Visibility sx={{ color: 'lightGray' }} />}
                                                checkedIcon={<VisibilityOff sx={{ color: 'lightGray' }} />}
                                                sx={{ position: 'absolute', right: '2px', top: '50%', transform: 'translateY(-50%)' , fontSize: '0.8rem' }}
                                                className={"VisiblPassBtn"}
                                                onClick={() => handleTogglePassword(index)}
                                            />

                                        </div>
                                    </td>
                                    <td className={"textRight pointing specialBlkWr"} >
                                        <UserPermission clss={"buisSelectOnTable onLeft"}
                                            UserPermiss={item.permiss}
                                            key={index}
                                            setUserPermiss={(newVal) => { updatePermiss(newVal, item._id) }}
                                        />
                                    </td>
                                    <td className={"textRight pointing specialBlkWr"} >
                                        <MultiBuisSelect key={index}
                                            firstHead={"כל העסקים"}
                                            clss={"buisSelectOnTable onLeft"}
                                            defaultValue={myFunctions.getBuisNameByIdInList(props.buisLst, item.buisID)}
                                            userSelection={item.buisID ? item.buisID.split(',') : []}
                                            busissList={props.buisLst}
                                            setUserBuis={(newVal) => { setUserBuis(newVal, item._id) }}
                                        />
                                    </td>
                                    <td>
                                        <div className={"divAsIcon pointing"} onClick={() => removeUser(index)}><i className="far fa-trash-alt ms-text-danger" /></div>
                                    </td>
                                    <td>
                                        <input type="checkbox" checked={itmBlock} onChange={(e) => blockUser(index, !itmBlock)} /> <i className="ms-checkbox-check" />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {thePopUp}
        </div>
    )
}

export default ListUsersTable;