import { DeleteForever } from '@mui/icons-material';
import { Checkbox, MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import myFunctions from '../../../myFunctions';
import BuisSelect from '../OrdersP/OrdersList-list/BuisSelect';
import UserPermission from '../OrdersP/OrdersList-list/UserPermission';
import Pagination from '@mui/material/Pagination';

const BlackListInBlocks = (props) => {
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    useEffect(() => { }, []);

    const setFirstName = (e) => { };

    const setLastName = (e) => { };

    const setPhone = (e) => { };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <div className="table-responsive theBigTbl ms-panel-body">
            {props.usersList.slice(startIndex, endIndex).map((item, index) => {
                let itmBlock =
                    item.blocked === undefined || item.blocked === false ? false : true;

                return (
                    <div className={"innnerRow smallPadding"} key={item._id}>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>שם</span>
                            </div>
                            <div className={"fullRow"}>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder={"שם"}
                                    value={item.FullName}
                                    onChange={(e) => {
                                        setFirstName(e);
                                    }}
                                />
                            </div>
                        </div>
                        {/* <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>שם משפחה</span>
                            </div>
                            <div className={"fullRow"}>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder={"שם משפחה"}
                                    value={item.lastName}
                                    onChange={(e) => {
                                        setLastName(e);
                                    }}
                                />
                            </div>
                        </div> */}
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>טלפון</span>
                            </div>
                            <div className={"fullRow"}>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder={"טלפון"}
                                    value={item.phone}
                                    onChange={(e) => {
                                        setPhone(e);
                                    }}
                                />
                            </div>
                        </div>
                        
                        <div className={"fullRow"}>
                            <div className={"halfRow onRight"}>
                                <span className={"orderItmTxt regular bold"}>מחק</span>
                                <Checkbox
                                    icon={<DeleteForever sx={{ color: 'white' }} />}
                                    sx={{}}
                                    className={"ChkMyButton pointing"}
                                    onClick={() => {
                                        props.removeUser(item._id);
                                    }}
                                />
                            </div>

                            <div className={"halfRow onRight"}>
                                <div className={"fullRow"}>
                                    <span className={"orderItmTxt regular bold"}>חסום</span>
                                </div>
                                <div className={"fullRow"}>
                                    <input
                                        type="checkbox"
                                        checked={itmBlock}
                                    />{' '}
                                    <i className="ms-checkbox-check" />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <Pagination
                count={Math.ceil(props.usersList.length / itemsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
            />
        </div>
    );
};

export default BlackListInBlocks;

