import React, { Component, useContext, useEffect } from 'react';
import Sidenavigation from '../../../layouts/Sidenavigation';
import Sidenavigation1 from '../../../layouts/Sidenavigation1';
import Topnavigation from '../../../layouts/Topnavigation';
import ReportsLst from './ReportsLst'
import Quickbar from '../../../layouts/Quickbar';
import { TheMasterContextProvider } from '../../../MyContexts/Master_Context';
import myFunctions from '../../../../myFunctions';
import { BuisListContext } from '../../../MyContexts/BuisList_Context';
import utilsRests from '../../../../MyConns/_Utils/utilsRests';
import { UserContext } from '../../../MyContexts/Users_Context';


const ReportsP = (props) => {
    const [user, setUser] = useContext(UserContext)

    const [buisLst, setBuisLst] = useContext(BuisListContext);


    useEffect(() => {
        myFunctions.setGetCurrnetTab("Set", "pg", "pgReoprtsP");
        // sessionStorage.setItem("pg", "pgReoprtsP")
    }, [])


    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>
                <Sidenavigation1 />
                <main className="body-content">
                    <Topnavigation />
                    {user.permiss === "admin" &&
                        <ReportsLst />
                    }
                </main>
                {/* <Quickbar /> */}
            </TheMasterContextProvider>
        </div>
    );
}

export default ReportsP;
