import React, { Component } from 'react';
import { ShoppingBag, SportsMotorsports } from "@mui/icons-material";
// import AddressLine from "../web_pages/RestaurantPage/RestDetails/addressLine";
import parse from 'html-react-parser';



//תוכן חלוקת המגש - בפיצריות
const conten = (spec, item) => {
    if (spec.splitSelected != null) {
        let bk = "";
        switch (spec.splitSelected) {
            case "lHalf":
                {
                    bk = "חצי שמאל";
                    break;
                }
            case "rHalf":
                {
                    bk = "חצי ימין";
                    break;
                }
            case "rtQuart":
                {
                    bk = "רבע ימין עליון";
                    break;
                }
            case "rbQuart":
                {
                    bk = "רבע ימין תחתון";
                    break;
                }
            case "ltQuart":
                {
                    bk = "רבע שמאל עליון";
                    break;
                }
            case "lbQuart":
                {
                    bk = "רבע שמאל תחתון";
                    break;
                }
            default:
                bk = "כל המגש";
                break;

        }
        return (<span className={"regular"}>{bk}</span>)
    }
    else {
        return (<span className={"regular"}>{spec.addToPrice > 0 ? `X ${item.mount} ` : ""}</span>)
    }

}



// const getOrderTotalPrice = (theOrder) => {

//     let tot = 0;
//     if (theOrder.OrderType === "Deliver") {
//         tot = parseInt(theOrder.OrderDelPrice);
//     }
//     theOrder.Orders.map((item, index) => {

//         if (item.specials.length > 0) {
//             item.specials.map((special, specialIndex) => {
//                 special.spec.map((spec, specIndex) => {
//                     if (parseFloat(spec.addToPrice) > -1) {
//                         tot += parseFloat(spec.addToPrice) * parseInt(item.mount);
//                     }
//                 })
//             })
//         }
//         tot += parseFloat(item.prodPrice) * parseInt(item.mount);

//     })

//     return tot;
// }

const getProdPriceWithSPecials = (item) => {
    let tot = 0;
    let addsPrice = 0;
    if (item.specials.length > 0) {
        item.specials.map((special, specialIndex) => {
            let freeCount = 0;
            //בודק אם יש חינמים
            special.spec.map((spec, specIndex) => {
                if (special.freeAdds != null && parseInt(special.freeAdds) != null > 0) {

                    for (let i = 0; i < spec.itmsMount; i++) {
                        freeCount++;
                        if (freeCount <= parseInt(special.freeAdds)) {

                        }
                        else {
                            if (parseFloat(spec.addToPrice) > -1) {
                                addsPrice += (parseFloat(spec.addToPrice))
                            }
                        }
                    }
                }
                else {

                    if (parseFloat(spec.addToPrice) > -1) {
                        addsPrice += (parseFloat(spec.addToPrice)) * spec.itmsMount
                    }
                }
            })
        })
    }
    tot += (parseFloat(item.prodPrice) + parseFloat(addsPrice)) * parseInt(item.mount);

    return tot;
}


const getOrderTotalPrice = (theOrder) => {

    let tot = 0;

    tot = getOrderPrice(theOrder);

    if (theOrder.OrderType === "Deliver") {
        tot += parseInt(theOrder.OrderDelPrice);
    }


    if (theOrder.orderKopon && theOrder.orderKopon !== "") {

        let koponPrice = (theOrder.orderKopon.KoponDiscType === '1')
            ? parseInt(theOrder.orderKopon.KoponDiscount) * -1
            : parseInt(((getOrderPrice(theOrder) / 100) * parseInt(theOrder.orderKopon.KoponDiscount)) * -1)

        tot += parseInt(koponPrice);
    }


    if (theOrder.orderZikoy && theOrder.orderZikoy.length > 0) {
        theOrder.orderZikoy.forEach(element => {
            let zikoyPrice = parseInt(element.zickoyPrice) * -1;
            tot += parseInt(zikoyPrice);
        });
    }

    return tot;
}

const getOrderPrice = (theOrder) => {
    let tot = 0;

    theOrder.Orders.map((item, index) => {
        let addsPrice = 0;

        if (item.specials.length > 0) {
            item.specials.map((special, specialIndex) => {
                if (special.splitSelection != true) {
                    let freeCount = 0;

                    //בודק אם יש חינמים
                    special.spec.map((spec, specIndex) => {

                        if (special.freeAdds != null && parseInt(special.freeAdds) > 0) {

                            for (let i = 0; i < spec.itmsMount; i++) {
                                freeCount++;
                                if (freeCount <= parseInt(special.freeAdds)) {

                                }
                                else {
                                    if (parseFloat(spec.addToPrice) > -1) {
                                        addsPrice += (parseFloat(spec.addToPrice))
                                    }
                                }
                            }

                        }
                        else {
                            for (let i = 0; i < spec.itmsMount; i++) {
                                if (parseFloat(spec.addToPrice) > -1) {
                                    addsPrice += (parseFloat(spec.addToPrice))
                                }
                            }
                        }
                    })
                }
                else {
                    addsPrice = getPizzaAddonsPrice(special, addsPrice);
                }
            })

        }

        tot += (parseFloat(item.prodPrice) + parseFloat(addsPrice)) * parseInt(item.mount);

    })
    tot += parseFloat(getStickedSauces(theOrder));

    return tot;
}


const theItemSpecials = (item) => {

    let totBk = (item.specials.length > 0) ?
        item.specials.map((special, specialIndex) => {

            let specCount = 0;
            let freeCount = 0;

            if (!special.splitSelection ||
                special.splitSelection == "full"
            ) {
                let specc = special.spec.map((spec, specIndex) => {
                    let addsPrice = 0;

                    if (spec.addToPrice > -1) {
                        specCount++;
                        //בודק אם יש חינמים
                        if (special.freeAdds != null && parseInt(special.freeAdds) > 0) {
                            for (let i = 0; i < spec.itmsMount; i++) {
                                freeCount++;
                                if (freeCount <= parseInt(special.freeAdds)) {
                                }
                                else {
                                    if (parseFloat(spec.addToPrice) > -1) {
                                        addsPrice += parseInt(spec.addToPrice) * item.mount
                                    }
                                }
                            }
                        }
                        else {
                            for (let i = 0; i < spec.itmsMount; i++) {
                                if (parseFloat(spec.addToPrice) > -1) {
                                    addsPrice += (parseFloat(spec.addToPrice))
                                }
                            }
                        }


                        return (
                            <div key={specIndex} className={"ordItmLine ordItmOptHead"}>
                                <span className={"rSideMiddle"}>

                                    <span className={"regular"}> {spec.servType}</span>
                                    <span className={"bold"}>
                                        {item.mount > 1 ? `  (* ${item.mount})` : ""}</span>
                                </span>

                                <span className={"bold lSideSmall"}>
                                    {addsPrice > 0 ? "₪ " + (addsPrice) + " +" : ""}
                                </span>
                            </div>
                        )
                    }
                })
                if (specCount > 0) {
                    return (
                        <div key={specialIndex} className={"ordItmSpecLine spacePadSmall"}>
                            <div className={"ordItmOptHead spacePadSmall bold"} >{special.optionName}</div>
                            {specc}
                        </div>
                    )
                }
            }
            else {
                return (
                    <div key={`pizz1_${specialIndex}`} className={"ordItmLine ordItmOptHead"} >
                        <span className={"rSideMiddle"}>
                            <div className={"ordItmOptHead spacePadSmall bold"}>{special.optionName}</div>
                            {
                                parse(getPizzaSplits(special, []).join(""))
                            }
                        </span>

                        <span className={"bold lSideSmall"}>
                            {`₪ ${getPizzaAddonsPrice(special, 0)}` + " +"}
                        </span>
                    </div >

                )

            }

        })
        : ""
    return totBk;
}


const getPizzaSplits = (ord, rowsDisp) => {

    let lHalf = [];
    let rHalf = [];
    let rtQuart = [];
    let rbQuart = [];
    let ltQuart = [];
    let lbQuart = [];
    let full = [];

    for (let i = 0; i < ord.spec.length; i++) {

        if (ord.spec[i].splitSelected != null) {
            const getSPl = {
                lHalf: () => lHalf.push(ord.spec[i].servType),
                rHalf: () => rHalf.push(ord.spec[i].servType),
                rtQuart: () => rtQuart.push(ord.spec[i].servType),
                rbQuart: () => rbQuart.push(ord.spec[i].servType),
                ltQuart: () => ltQuart.push(ord.spec[i].servType),
                lbQuart: () => lbQuart.push(ord.spec[i].servType),
                full: () => full.push(ord.spec[i].servType)
            }

            getSPl[ord.spec[i].splitSelected]();
        }
    }

    //פונקציה פנימית
    const pushNewString = (placeTxt, arrySplt) => {
        rowsDisp.push(`<span className="headORdTxt">
                    <span className="regular">
                        ${placeTxt}
                    </span>
            ${arrySplt.join(" + ")}
        <span/><br/>`);
    }

    if (full.length > 0) { pushNewString("כל המגש", full); }
    if (rHalf.length > 0) { pushNewString("חצי ימין", rHalf) }
    if (rtQuart.length > 0) { pushNewString("רבע ימין עליון", rtQuart) }
    if (rbQuart.length > 0) { pushNewString("רבע ימין תחתון", rbQuart) }
    if (lHalf.length > 0) { pushNewString("חצי שמאל", lHalf) }
    if (ltQuart.length > 0) { pushNewString("רבע שמאלי עליון", ltQuart) }
    if (lbQuart.length > 0) { pushNewString("רבע שמאלי תחתון", lbQuart) }


    return (rowsDisp)
}

const getPizzaAddonsPrice = (special, addsPrice) => {

    let freeCount = 0; // Tracks the number of free items allocated


    // Separate full items
    let theFulls = special.spec.filter(it =>
        it.splitSelected == null ||
        it.splitSelected == "" ||
        it.splitSelected == "full"
    );

    // Separate split items
    let theSpls = special.spec.filter(it =>
        it.splitSelected != null &&
        it.splitSelected != "" &&
        it.splitSelected != "full"
    );

    // Process the fulls
    for (let i = 0; i < theFulls.length; i++) {
        freeCount++;
        if (freeCount > parseInt(special.freeAdds)) {
            addsPrice += parseFloat(theFulls[i].addToPrice); // Add price, rounded up
        }
    }

    // Check if freeCount is still within the limit after processing fulls
    if (freeCount < parseFloat(special.freeAdds)) {
        for (let i = 0; i < theSpls.length; i++) {
            freeCount += 0.5;

            if (freeCount > parseFloat(special.freeAdds)) {
                addsPrice += parseFloat(theSpls[i].addToPrice); // Add price, rounded up
            }
        }
    }
    else {
        for (let i = 0; i < theSpls.length; i++) {

            addsPrice += parseFloat(theSpls[i].addToPrice);
        }

    }

    return (addsPrice = Math.ceil(parseFloat(addsPrice)))
}

//תוכן חלוקת המגש - בפיצריות
const pizzConten = (spec, item) => {
    if (spec.splitSelected != null) {
        let bk = "";
        switch (spec.splitSelected) {
            case "lHalf":
                {
                    bk = "חצי שמאל";
                    break;
                }
            case "rHalf":
                {
                    bk = "חצי ימין";
                    break;
                }
            case "rtQuart":
                {
                    bk = "רבע ימין עליון";
                    break;
                }
            case "rbQuart":
                {
                    bk = "רבע ימין תחתון";
                    break;
                }
            case "ltQuart":
                {
                    bk = "רבע שמאל עליון";
                    break;
                }
            case "lbQuart":
                {
                    bk = "רבע שמאל תחתון";
                    break;
                }
            default:
                bk = "כל המגש";
                break;

        }
        return (<span className={"regular"}>{bk}</span>)
    }
    else {
        return (<span className={"regular"}>{spec.itmsMount > 0 ? `X ${spec.itmsMount} ` : ""}</span>)
    }

}

// //בודק אם יש רטבים מוצמדים ואם הלקוח עבר את המכסה החינמית
// //במידה ועבר, יוחזר ההפרש שיש לתשלום 
// const getStickedSauces = (theOrder) => {
//     let priceToAdd = 0;
//     let fSauces = (theOrder.freeSaucess != null) ? theOrder.freeSaucess : 0;
//     let countIt = 0;

//     // if (theOrder.StickedSauces != null) {
//     //     theOrder.StickedSauces?.forEach(sauce => {
//     //         for (let i = 0; i < sauce.mount; i++) {
//     //             countIt++;
//     //             if (countIt > fSauces) {
//     //                 priceToAdd += parseFloat((sauce.addToPrice != "") ? sauce.addToPrice : 0);
//     //             }
//     //         }

//     //     });
//     // }
//     return priceToAdd;

// }


// const getOrderPrice = (theOrder) => {
//     let tot = 0;

//     theOrder.Orders.map((item, index) => {

//         if (item.specials.length > 0) {
//             item.specials.map((special, specialIndex) => {
//                 special.spec.map((spec, specIndex) => {
//                     if (parseFloat(spec.addToPrice) > -1) {
//                         tot += parseFloat(spec.addToPrice) * parseInt(item.mount);
//                     }
//                 })
//             })
//         }
//         tot += parseFloat(item.prodPrice) * parseInt(item.mount);

//     })

//     return tot;
// }

const takeAwayBlock = (theTime, Address) => {
    return (
        <>
            <div className={"takeAwayBlock"}>
                <div className={"theImg"}>
                    <ShoppingBag />
                </div>
                {/* <div className={"theImg"} style={{ backgroundImage: `url("/PermanentImages/take.png")` }}></div> */}
                <div className={"txtOrdType"}>
                    <div className={"bold big"} >איסוף עצמי</div>
                    <div className={"regular"} >{theTime}</div>
                    {/* <div className={"regular"} ><AddressLine address={Address} typeDisp="NoIcon" /></div> */}

                </div>


            </div>
        </>
    )
}

//אופן תשלום
const payingTYpe = (theOrder) => {

    switch (theOrder.ord?.payt) {
        case "1":
            return "אשראי";
        case "2":
            return "מזומן";
        case "3":
            return "ביט";
    }
}


const deliveryBlock = (theTime, thePrice) => {
    return (
        <>
            <div className={"takeAwayBlock"}>
                <div className={"theImg"}>
                    <SportsMotorsports />
                </div>
                <div className={"txtOrdType"}>
                    <div className={"bold big"} >משלוח</div>
                    <div className={"regular"} >{theTime}</div>
                    <div className={"regular"} >מחיר משלוח:  ₪{thePrice}</div>
                </div>
            </div>
        </>
    )
}


const clearOrd = () => {
    let ord = { restId: "", currentP: [], Orders: [] };

    let stringOrd = JSON.stringify({ ...ord });
    sessionStorage.setItem("am_order", stringOrd)

    return (ord);

}


//בודק אם יש רטבים מוצמדים ואם הלקוח עבר את המכסה החינמית
//במידה ועבר, יוחזר ההפרש שיש לתשלום 
const getStickedSauces = (theOrder) => {
    let priceToAdd = 0;
    let fSauces = (theOrder.freeSaucess != null) ? theOrder.freeSaucess : 0;
    let countIt = 0;
    if (theOrder.StickedSauces.length > 0) {
        theOrder.StickedSauces.forEach(sauce => {

            for (let i = 0; i < sauce.mount; i++) {
                countIt++;
                if (countIt > fSauces) {
                    priceToAdd += parseFloat((sauce.addToPrice != "") ? parseFloat(sauce.addToPrice) : 0);
                }
            }

        });
    }

    return priceToAdd;

}

export default {
    getOrderTotalPrice,
    getOrderPrice,
    takeAwayBlock,
    deliveryBlock,
    clearOrd,
    conten,
    getProdPriceWithSPecials,
    payingTYpe,
    theItemSpecials,
    getStickedSauces,
    pizzConten
};