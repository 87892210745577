import React, { useContext, useEffect, useState } from 'react'
import { TheMasterContext } from '../../../../MyContexts/Master_Context';
import SelectImgComp from '../../../../MySections/ImagesSetter/SelectImgComp';

import TxtAndLnk from '../../../../MySections/DTechComps/TxtAndLnk';

const TabMainMenu = (props) => {
    const [theMaster] = useContext(TheMasterContext);
    const [botLogo, setBotLogo] = useState(theMaster.mobiMenuBotLogo);
    const [mainMenu, setMainMenu] = useState(theMaster.mainMenu);
    useEffect(() => {


    }, [])


    const setTheBotLogo = (fUrl) => {
        theMaster["mobiMenuBotLogo"] = fUrl;
        setBotLogo(fUrl);

    }
    const setTheMenu = (urlsList) => {
        theMaster["mainMenu"] = urlsList;
        setMainMenu(urlsList);

    }

    return (
        <>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>לוגו לתחתית תפריט הצד</label>
                <div className={"fullShow"}>
                    <SelectImgComp imgCss={"pointing"}
                        firstImgToDisp={botLogo ? botLogo : ""}
                        theFunction={(fUrl) => setTheBotLogo(fUrl)} />
                </div>
            </div>
            
            <div className={"col-md-12 mb-2"}>
                <TxtAndLnk tblHeads={["טקסט", "קישור", "מחק", "מיקום"]}
                    TheList={mainMenu} theFunction={(lst) => setTheMenu(lst)} />

            </div>

        </>

    );
}

export default TabMainMenu;