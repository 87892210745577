

import moment from "moment";
import duration from "moment-duration-format";



class MyDateTime {

    //מכניס תאריך וימים מהתאריך על מנת לקבל תאריך מתאים
    static getSpecificDate = (timeString, daysToAdd) => {

        let tm = moment(timeString).add(daysToAdd, 'days');

        return (
            tm.toString("dd/MM/yyyy 05:00")
        )
    }




    //מכניס תאריך וימים מהתאריך על מנת לקבל תאריך מתאים
    static getFirstDayinMounth = (dateToSet) => {

        let tt = moment(dateToSet).set('date', 1);
        return (
            tt.toString("05:00 01/MM/yyyy")
        )
    }


    //מקבל אובייקט זמן ומסדר את השעה שלו לפי המבוקש
    //{ str: {hh,mm,ss}}
    static setTimeByStr(timeitm, str) {

        let theItm = moment(timeitm)
            .set('hour', str.hh || '00')
            .set('minute', str.mm || '00')
            .set('second', str.ss || '00');


        return theItm.toString("dd/MM/yyyy hh:mm:ss");
    }

    static getTimeAddMins = (timeString, MinToAdd) => {

        return (
            moment(timeString).add(MinToAdd, 'minutes').utc(true).format('DD/MM/YYYY HH:mm:ss')
        )
    }

    static getTimePastTilNow = (timeString) => {

        var seconds = moment().utc(true).diff(timeString, 'seconds')
        var duration = moment.duration(seconds, 'seconds');
        var formatted = duration.format("hh:mm:ss");

        return (
            formatted
        )
    }

    static getColorLineByTime = (timeString, thenTime) => {
        var thenTime = moment(timeString).add(parseInt(thenTime), 'minutes')
        var seconds = moment().utc(true).diff(thenTime, 'seconds')
        var duration = moment.duration(seconds, 'seconds');
        var formatted = duration.format("hh:mm:ss");
        if (formatted.toString().includes("-")) {
            return ("lightGray")
        }
        else {
            return ("red")
        }
    }


    static getTimeLeftTilThen = (timeString, thenTime) => {

        var thenTime = moment(timeString).add(parseInt(thenTime), 'minutes');

        // Calculate the difference in seconds between the current time and thenTime
        var seconds = moment().utc().diff(thenTime, 'seconds');
        
        // Create a moment duration from the seconds
        var duration = moment.duration(seconds, 'seconds');
        
        // Format the duration as "hh:mm:ss"
        var formatted = duration.format("hh:mm:ss");
        
        // Calculate the total hours and then extract days and remaining hours
        var temp = moment().utc().diff(thenTime, 'hours');
        var daysOver = parseInt(temp / 24);
        var hoursLeft = parseInt(temp % 24);

        if (formatted.toString().includes("-")) {
            return (
                {
                    theTm: formatted.toString().replace("-", ""),
                    theCol: "lightGray",
                    theForCol: "black",
                    theEnd: false
                }
            )
        }
        else {
            let bk = (daysOver > 0) ? formatted + " - " + daysOver + " ימים " + hoursLeft + " שעות" : formatted
            return (
                {
                    theTm: bk,
                    theCol: "red",
                    theForCol: "white",
                    theEnd: true
                }
            )
        }

    }


    static getDate = (timeString) => {
        return (
            moment(timeString).utc(true).format('DD/MM/YYYY')
        )
    }
    static getTime = (timeString) => {
        return (
            moment(timeString).utc(true).format('HH:mm:ss')
        )
    }
    static getShortTime = (timeString) => {
        return (
            moment(timeString).utc(true).format('HH:mm')
        )
    }
    static getDateAndTime = (timeString) => {

        return (
            moment(timeString).utc(true).format('DD/MM/YYYY HH:mm:ss')
        )
    }
    static getDateAndTimeShort = (timeString) => {

        return (
            moment(timeString).utc(true).format('DD/MM/YYYY') + " " +moment(timeString).utc(true).format('HH:mm')
        )
    }
    static getDayOfWeek = (timeString) => {

        return (
            moment(timeString).utc(true).format('dddd')
        )
    }
    static getDayOfWeekHeb = (timeString) => {
        switch (moment(timeString).utc(true).format('dddd')) {
            case "Sunday": return "ראשון";
            case "Monday": return "שני";
            case "Tuesday": return "שלישי";
            case "Wednesday": return "רביעי";
            case "Thursday": return "חמישי";
            case "Friday": return "שישי";
            case "Saturday": return "שבת";
            default: return "";
        }

    }

    static getDayOfWeekToHeb = (dayName) => {
        switch (dayName) {
            case "Sunday": return "ראשון";
            case "Monday": return "שני";
            case "Tuesday": return "שלישי";
            case "Wednesday": return "רביעי";
            case "Thursday": return "חמישי";
            case "Friday": return "שישי";
            case "Saturday": return "שבת";
            default: return "";
        }

    }

    static getDayOfWeekLettersHeb = (DayName) => {
        switch (DayName) {
            case "Sunday": return "א";
            case "Monday": return "ב";
            case "Tuesday": return "ג";
            case "Wednesday": return "ד";
            case "Thursday": return "ה";
            case "Friday": return "ו";
            case "Saturday": return "ש";
            default: return "";
        }

    }



}
export default MyDateTime;

