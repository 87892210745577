import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'



const getSpecialPageByName = async (pageName) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "specialP/" + pageName);
    return (dFromF);
}

const updateSpecialPage = async (pageName, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "specialP/upSpecial/" + pageName, obj);
    return (dFromF);
}



export default {
    getSpecialPageByName,
    updateSpecialPage,


}; 