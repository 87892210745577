import gammaApi from "../_ApiConn/apiGamma";



const goGama = async (theOrder) => {
    let dFromF = await gammaApi.goGama(theOrder);

    return (dFromF);
}


const ApproveJ = async (theOrder) => {
    let dFromF = await gammaApi.ApproveJ(theOrder);
    return (dFromF);
}

export default {
    goGama,
    ApproveJ
};