import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AddBoxOutlined, QrCodeScannerOutlined } from '@mui/icons-material';
import InputDubleSet from './InputDubleSet';
import EraseCheck from './EraseCheck';
import utilsRests from '../../../MyConns/_Utils/utilsRests';
import SucessToast from '../Toasts/SucessToast';

const SpecialMakats = (props) => {


    const [open, setOpen] = useState(true);
    const [specialMakatsList, setSpecialMakatsList] = useState([])
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {

        const getSpecialMakats = async () => {
            let theLst = await utilsRests.getRestSpecialMakats(props.restID);
            setSpecialMakatsList(theLst.SpecialMakats)
        }

        getSpecialMakats();
    }, [])

    const saveMe = async () => {
        let theLst = await utilsRests.setRestSpecialMakats(props.restID,specialMakatsList);
        SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
        props.approved();
    };


    const handleClose = () => {
        props.cancelClick();
    };



    const createNewSpecialMakat = () => {
        setThePopUp(
            <InputDubleSet Title={"הוספת פיצול ומקט"}
                ContentText={""} inLabel={"שם הפיצול"} inLabel2={"מקט"}
                okClick={(specialMakat) => createNewSpecialMakatOK(specialMakat)}
                cancelClick={() => setThePopUp()}
                errorMsg={"על מנת ליצור פיצול חדש, חובה לתת לו שם."}
            />)
    }

    const createNewSpecialMakatOK = (specialMakat) => {
        specialMakatsList.push({ specialMakName: specialMakat.inputTxt, spMakat: specialMakat.inputPrc })
    }

    const changeSPMakName = (index, newVal) => {
        let tempo = [...specialMakatsList];
        tempo[index].specialMakName = newVal;

        setSpecialMakatsList(tempo);
    }

    const changeSPMakVal = (index, newVal) => {
        let tempo = [...specialMakatsList];
        tempo[index].spMakat = newVal;
        setSpecialMakatsList(tempo);
    }

    const removeDetOpti = (index) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את הפיצול?'}
            sucecssFunc={() => removeSPMak(index)} />)
    }
    const removeSPMak = (index) => {
        let tempo = [...specialMakatsList];
        tempo.splice(index, 1);
        setSpecialMakatsList(tempo);
    }

    return (
        <Dialog open={open} onClose={handleClose} className={"textSizeDiag LastLstDiag"}>
            <DialogTitle>{props.Title}</DialogTitle>
            <DialogContent>
                <label className={"mnuLblHeaer fullRow"}>מקטים לבחירה מפוצלת</label>
                <Button variant="contained" className={"addDivBtn"} onClick={() => createNewSpecialMakat()}
                    startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                    הוסף פיצול
                </Button>
                {
                    specialMakatsList.map((specialMakat, indx) => {
                        return (
                            <div key={indx} className={"optsDet servTpDiv"}>
                                <>  <div className={"mnuItmOptType"}><label>שם הפיצול</label>
                                    <input type="text" value={specialMakat.specialMakName}
                                        placeholder={"שם הפיצול"}
                                        onChange={(e) => changeSPMakName(indx, e.target.value)} />
                                </div>
                                    <div className={"mnuItmOptPrice"}><label>מקט</label>
                                        <input type="text" value={specialMakat.spMakat}
                                            placeholder={"מקט"}
                                            onChange={(e) => changeSPMakVal(indx, e.target.value)} />
                                    </div>
                                </>
                                <div className={"divAsIcon pointing optidietDel"} onClick={() => removeDetOpti(indx)}><i className="far fa-trash-alt ms-text-danger" /></div>

                            </div>

                        )
                    })
                }

            </DialogContent >
            <DialogActions>
                <button type="button" className="onLeft btn btn-primary" onClick={handleClose}>ביטול</button>
                <button type="button" className="onLeft btn btn-success" onClick={() => saveMe()}>אישור</button>
            </DialogActions>
            {thePopUp}
        </Dialog >
    );
}

export default SpecialMakats;