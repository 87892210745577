import koponsLst from "../_ApiConn/apiKopons";


const getKopons = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await koponsLst.getKopons({ token });
    return (dFromF);
}

const createKopon = async (obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await koponsLst.createKopon({ token,obj });
    return (dFromF);
}

const getKoponsBySearch = async (txtSrch) => {
    const token = localStorage.getItem('token');
    let dFromF = await koponsLst.getKoponsBySearch({ token,txtSrch });
    return (dFromF);
}


const updateKopon = async (obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await koponsLst.updateKopon({ token,obj });
    return (dFromF);
}
const deleteKoponsByIds = async (obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await koponsLst.deleteKoponsByIds({ token,obj });
    return (dFromF);
}



const updateFullKopon = async (obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await koponsLst.updateFullKopon({ token,obj });
    return (dFromF);
}


const getKoponById = async (koponId) => {
    const token = localStorage.getItem('token');
    let dFromF = await koponsLst.getKoponById({ token,koponId });
    return (dFromF);
}


const getActivatorsList = async (activatorsLst) => {
    const token = localStorage.getItem('token');
    let dFromF = await koponsLst.getActivatorsList({ token,activatorsLst });
    return (dFromF);
}

const sendKoponToClient = async (koponId,phonesList) => {
    const token = localStorage.getItem('token');
    let dFromF = await koponsLst.sendKoponToClient({ token,koponId,phonesList });
    return (dFromF);
}

export default {
    sendKoponToClient,
    getActivatorsList,
    getKopons,
    createKopon,
    getKoponsBySearch,
    updateKopon,
    deleteKoponsByIds,
    getKoponById,
    updateFullKopon
    
};