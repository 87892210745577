import React, { useEffect, useState } from 'react';

const DropNumbersSelection = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const numberOptions = Array.from({ length: props.maxNumb }, (_, index) => index + 1);

  return (
    <div style={{ position: 'relative' }}>
      <input
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
        readOnly
        type='text'
        className={"inputsStyle pointing"}
        value={`${props.Val} ${props.lbl}`}
      />

      {isOpen && (
        <div style={{ position: 'absolute',zIndex: 1 }}>
          <select
            className={props.Css}
            value={props.Val}
            onChange={(e) => {
              props.settingFunc(parseInt(e.target.value));
              setIsOpen(false);
            }}
            onBlur={() => setIsOpen(false)}
            size={Math.min(numberOptions.length, 10)}
            
          >
            {numberOptions.map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default DropNumbersSelection;
