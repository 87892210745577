
import React, { useEffect, useState } from 'react';

const InputTextSimple = (props) => {


    useEffect(() => {

    }, [])

    const lbl = (props.lbl && props.lbl !== "") ?
        <p>{props.lbl}</p>
        : "";

    return (<>
        {lbl}
        <input type={props.Typ}
            className={props.Css}
            value={props.Val}
            placeholder={props.placeholder}
            onChange={(e) => { props.settingFunc(e.target.value) }} />
    </>)
}



export default InputTextSimple;