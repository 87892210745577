import React, { Component, useContext, useEffect, useState } from 'react';
import Sidenavigation from '../../layouts/Sidenavigation';
import Sidenavigation1 from '../../layouts/Sidenavigation1';
import Topnavigation from '../../layouts/Topnavigation';
import Restaurentcontent from './Restaurant-list/Restaurantcontent'
import Quickbar from '../../layouts/Quickbar';
import RestSet from './Rests/RestSet';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';

import { MenuContextProvider, RestContextProvider } from '../../MyContexts/Rest_Context';
import TopnavigationSpecial from '../../MySections/DTechComps/TopnavigationSpecial';
import myFunctions from '../../../myFunctions';
import RestSetSmall from './Rests/RestSetSmall';
import { UserContext } from '../../MyContexts/Users_Context';
import { TablesSetterContextProvider } from '../../MyContexts/TablesSetter_Context';

const MyRestSettings = (props) => {

    const [user, setUser] = useContext(UserContext)

    const [loading, setLoading] = useState(true);

    useEffect(() => {

    }, []);



    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            {loading ?
                <TheMasterContextProvider>
                    <Sidenavigation1 />
                    <main className="body-content">
                        <RestContextProvider>
                            <MenuContextProvider>
                                <Topnavigation />
                                {(user?.permiss === "admin") ?
                                    <TablesSetterContextProvider>
                                        <RestSet newRest={props.newRest} />
                                    </TablesSetterContextProvider>
                                    : ""}
                                {(user?.permiss === "restAdmin") ?
                                    <RestSetSmall newRest={props.newRest} />
                                    : ""}
                            </MenuContextProvider>
                        </RestContextProvider>
                    </main>
                    {/* <Quickbar /> */}
                </TheMasterContextProvider>
                : ""}
        </div>
    );
}

export default MyRestSettings;