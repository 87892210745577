import React, { useContext, useEffect, useState } from 'react'

const AccordProdTextArea = (props) => {

    const [prodName, setProdName] = useState(props.prodTxtVal);

    useEffect(() => {
        setProdName(props.prodTxtVal);
    }, [props.prodTxtVal])
    return (
        <div className={`${props.clss ? props.clss : "prodFirst"}`}>
            <label className={"txtARLbl"}>{props.headI}</label>
            <textarea ros={"4"} placeholder={props.headI}
                onChange={(e) => setProdName(e.target.value)}
                onBlur={(e) => props.setProdItm(props.index, props.proIndex, e.target.value)} 
                value={prodName}
                ></textarea>
        </div>

    )
}

export default AccordProdTextArea;



{/* <div className={`${props.clss ? props.clss : "prodFirst"}`}>
<label className={"txtARLbl"}>{props.headI}</label>
<textarea ros={"4"} placeholder={props.headI}
    onChange={(e) => setProdName(e.target.value)}
    onBlur={(e) => props.changeProdProducts(props.index, props.proIndex, e.target.value)}
    defaultValue={prodName}></textarea>
</div> */}
