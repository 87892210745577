import React, { Component, useEffect, useState } from 'react';

import Breadcrumb from './Breadcrumb'
import ReactDOM from 'react-dom'
import { Link, useParams, useHistory } from 'react-router-dom'
import utilsOrders from '../../../../../MyConns/_Utils/utilsOrders';
import MyDateTime from '../../../../MySections/TimeSections/MyDateTime';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';
import OrderStatuselect from '../../OrdersList-list/OrderStatuselect';
import { Button } from '@mui/material';
import PrintTemplate from 'react-print';
import { display } from '@mui/system';
import parse from 'html-react-parser';
import Translator from '../../../../../MyFunctions/Translator';


const InvoicePrint = (props) => {


    const [theOrder, setTheOrder] = useState([]);
    const [theCss, setTheCss] = useState();
    let idParam = useParams();

    const history = useHistory();


    useEffect(() => {
        setTheOrder(props.theOrder);
    }, [props.theOrder])

    const getAddress = () => {
        return (
            (theOrder.ord?.OrderType !== "Take") ?
                <>
                    <div className={"medBig"}>
                        &nbsp;
                        {theOrder.ord?.deliveryAddress.street}
                        &nbsp;
                        &nbsp;
                        {(!theOrder.ord?.deliveryAddress.street.endsWith(theOrder.ord?.deliveryAddress.num)) ?
                            theOrder.ord?.deliveryAddress.num
                            : ""}
                        &nbsp;
                        {(theOrder.ord?.deliveryAddress.appartment) ?
                            `דירה: ${theOrder.ord?.deliveryAddress.appartment}`
                            : ""}
                        &nbsp;
                        &nbsp;
                        {(theOrder.ord?.deliveryAddress.ent) ?
                            `כניסה: ${theOrder.ord?.deliveryAddress.ent}`
                            : ""}
                        &nbsp;
                        &nbsp;
                        {(theOrder.ord?.deliveryAddress.flor) ?
                            `קומה: ${theOrder.ord?.deliveryAddress.flor}`
                            : ""}
                        &nbsp;
                        &nbsp;
                        {theOrder.ord?.deliveryAddress.city || "אשדוד"}


                    </div>
                    <div className={"medBig"}>
                        {(theOrder.ord?.deliveryAddress.remarks) ?
                            `הערות: ${theOrder.ord?.deliveryAddress.remarks}`
                            : ""}
                    </div>

                </> : ""
        )
    }



    const clientDetails = () => {
        return (
            <>
                <div className={""}>
                    <div className={"bold medBig"}>שם הלקוח: {theOrder.usr?.firstName + " " + theOrder.usr?.lastName}</div>
                    <div className={"bold medBig"}>טלפון: {theOrder.usr?.phone}</div>
                    {theOrder.usr?.email != "" ? <div className={"bold"}>אימייל: {theOrder.usr?.email}</div> : ""}
                </div>
                {theOrder.ord?.orderRemarks != null ?
                    <div className={"pointing bold overflowHid"}>
                        <div className="bold smalBig textRight">הערות: </div>
                        <div className="medBig ">{(theOrder.ord?.orderRemarks != null) ? parse(`${theOrder.ord.orderRemarks}`) : ""} </div>
                    </div>
                    : ""}
            </>
        )
    }

    const getSaucesLst = () => {
        let rtrn = props.theOrder.ord.StickedSauces?.map((sauce, indx) => {
            return (
                <div className="specialsDiv">

                    <div className={"semBig"}>
                        <td className={"tdmin10"}>
                            <span className={"engFont ordItemMount"}>X&nbsp;</span><span className={"ordItemMount"}>
                                {sauce.mount}&nbsp;</span>
                        </td>
                        <td>
                            <span className={"ordItemMount"}> {sauce.servType}</span>
                        </td>
                        <td>

                        </td>
                    </div>
                </div>
            )

        });
        return rtrn
    }


    let totSauces =
        props.theOrder.ord.StickedSauces.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.mount;
        }, 0)


    //רטבים מוצמדים מוזמנים
    let stickedSauces = (props.theOrder.ord.StickedSauces?.length > 0) ?

        <div className={"itemOrd spaceTopBig"}>
            <div className="bold detailsDiv onRight">
                <div className={"fullRow"}>
                    <div className={"medBig prName onRight mainHd"}>{totSauces} X רטבים מוצמדים</div>
                    {/* <div className="bold lSideSmall 
                    smalBig onLeft textLeft">₪{item.mount > 1 ? parseFloat(item.prodPrice) * item.mount : parseFloat(item.prodPrice)}</div> */}
                    <span className={"veryVerySmall"}>
                        {(props.theOrder.ord.freeSaucess > 0) ? `(${props.theOrder.ord.freeSaucess} ללא עלות)` : ""}
                        {getSaucesLst(props.theOrder.ord.StickedSauces || [])}
                    </span>
                </div>
                {/* </td> */}
                <div className="specialsDiv">
                    <div className="textRight bold">
                        {OrderFunctions.getStickedSauces(props.theOrder.ord) > 0 ?
                            OrderFunctions.getStickedSauces(props.theOrder.ord) + "  ₪" :
                            ""}
                    </div>
                </div>
            </div>
        </div>
        : ""

    //מספר סועדים, צופסטיקס וסכום
    let DinersAndChops = (props.theOrder.ord.Dinners > 0) ?
        <div className={"itemOrd spaceTopBig"}>
            <div className="bold detailsDiv onRight">
                <div className={"fullRow medBig prName onRight mainHd"}>{`${props.theOrder.ord.Dinners} X סועדים`}</div>
                <div className={"fullRow semBig"}>
                    {`${(props.theOrder.ord.getfANife) ? "סכום: כן" : ""}`}
                </div>
                <div className={"fullRow semBig"}>
                    {`${(props.theOrder.ord.getChupstiks) ? "צופסטיקס: כן" : ""}`}
                </div>
            </div>
        </div >

        : ""

    return (
        <>
            <div className="printOrderPage">
                <div className={``}>

                    <h3 className={"centerText spaceButtomBig"}>{`${theOrder.rst?.buissName}`}</h3>

                    <h6 className={"textRight mediBig"}>{`הזמנה: ${theOrder.ord?.orderNUM}`}</h6>

                    <div className={"textRight bold mediBig"}>תאריך ההזמנה: {MyDateTime.getDateAndTime(theOrder.ord?.dateCreated)}</div>
                    <div className="textRight clientLine">
                        {clientDetails()}
                    </div>
                    <div className={"dottedLine spaceTopBig"}></div>
                    <div className="delorPick">
                        <div className={"textRight bold smalBig fullRow"}>
                            אופן תשלום: {OrderFunctions.payingTYpe(theOrder)}
                        </div>
                    </div>
                    <div className={"dottedLine spaceTopBig"}></div>
                    <div className="delorPick">
                        <div className={"textRight bold smalBig"}>
                            {(theOrder.ord?.OrderType === "Deliver") ? "משלוח:" : "איסוף"}
                        </div>
                    </div>

                    <div className="addressLine fullRow big">
                        {(theOrder.ord?.OrderType === "Deliver") ?
                            getAddress() : ""}
                    </div>

                    <div className={"dottedLine"}></div>

                    <div className={"spaceTopBig"}>

                        {theOrder.ord?.Orders.map((item, i) => {
                            return (
                                <div key={i}>
                                    <div className={"itemOrd spaceTopBig"}>
                                        <div className="bold detailsDiv onRight">
                                            <div className={"fullRow"}>
                                                <div className={"medBig prName onRight mainHd"}>{item.mount} X {item.productName}</div>
                                                <div className="bold lSideSmall smalBig onLeft textLeft">₪{item.mount > 1 ? parseFloat(item.prodPrice) * item.mount : parseFloat(item.prodPrice)}</div>
                                            </div>
                                            <div className="specialsDiv">
                                                {(item.Remarks != "" && item.Remarks != null) ?
                                                    <>
                                                        <div className={"semBig"}>{OrderFunctions.theItemSpecials(item)}</div>
                                                        <div className="textRight bold semBig" >
                                                            {props.arabic ? Translator.getInArab("הערה") : "הערה"}: {item.Remarks}</div>

                                                    </>
                                                    :
                                                    <div className={"semBig"}>{OrderFunctions.theItemSpecials(item)}</div>
                                                }
                                            </div>
                                            <div className={"spaceTopBig fullRow overflowHid minSpacePx "}></div>
                                        </div>
                                        <div className="bold inTot onLeft semBig">₪ {OrderFunctions.getProdPriceWithSPecials(item)}</div>
                                    </div>
                                    <div className={"dottedLine spacePadBig"}></div>
                                </div>
                            )
                        })
                        }
                        {stickedSauces &&
                            <div className={"dottedLine"}></div>
                        }
                        {DinersAndChops &&
                            <div className={"dottedLine"}></div>
                        }

                        {theOrder.ord?.orderKopon && theOrder.ord?.orderKopon != "" &&
                            <div className={"itemOrd spaceTopBig"}>
                                <div className="bold detailsDiv onRight">
                                    <div className={"fullRow"} key={"kop"}>
                                        <div className={"medBig prName onRight mainHd"}>{props.arabic ? Translator.getInArab("קופון") : "קופון"} {theOrder.ord.orderKopon.Name} </div>
                                        <div className="bold inTot onLeft semBig">{theOrder.ord.orderKopon.KoponDiscount}{theOrder.ord.orderKopon.KoponDiscType === '1' ? "₪" : "%"}</div>

                                    </div>
                                </div>
                                <div className={"spaceTopBig fullRow overflowHid minSpacePx "}></div>
                                <div className={"dottedLine spacePadBig"}></div>
                            </div>
                        }


                        {theOrder.ord?.orderZikoy && theOrder.ord?.orderZikoy != "" &&
                            theOrder.ord.orderZikoy.map((element, index) => {
                                return (
                                    <div key={`ziko_${index}`} className={"itemOrd spaceTopBig"}>
                                        <div className={"spaceTopBig"}>

                                            <div className="bold detailsDiv onRight">
                                                <div className={"fullRow"} key={`zikoy_${index}`}>
                                                    <div className={"medBig prName onRight mainHd"}>{props.arabic ? Translator.getInArab("זיכוי") : "זיכוי"} {element.Name} </div>
                                                    <div className="bold inTot onLeft semBig">₪{element.zickoyPrice}-</div>

                                                </div>
                                            </div>
                                            <div className={"spaceTopBig fullRow overflowHid minSpacePx "}></div>
                                            <div className={"dottedLine spacePadBig"}></div>
                                        </div>
                                    </div>
                                )
                            })
                        }


                        <div className={"spaceTopBig"}></div>

                        <div className={"dottedLine spacePadBig"}></div>
                        {(theOrder.ord?.OrderType === "Deliver") ?
                            <>
                                <div className={"itemOrd "} key={theOrder.ord?.Orders.length + 1}>
                                    <div className="bold detailsDiv onRight">
                                        <div className={"semBig onRight"}>{props.arabic ? Translator.getInArab("משלוח") : "משלוח"}</div>
                                        <div className="bold semBig inTot onLeft">₪ {theOrder.ord?.OrderDelPrice}</div>

                                    </div>
                                </div>
                                <div className={"dottedLine spaceButtomBig"}></div>

                            </>
                            : ""}
                    </div>
                    <div>
                        <div className={"itemOrd spaceTopBig overflowHid"}>
                            <div className="bold textLeft mainHd">{(theOrder.ord != null) ?
                                `${(props.arabic) ? Translator.getInArab(`סה"כ`) : `סה"כ`}: ₪${OrderFunctions.getOrderTotalPrice(theOrder.ord)}` : ""} </div>
                        </div>
                        <div className={"itemOrd spaceTopBig overflowHid"}>
                            <div className="bold textLeft subHd">{(theOrder.ord) ?
                                (props.arabic ? Translator.getInArab("אופן תשלום") : "אופן תשלום: ") +
                                (props.arabic ? Translator.getInArab(OrderFunctions.payingTYpe(theOrder)) :
                                    OrderFunctions.payingTYpe(theOrder)) : ""} </div>
                        </div>
                        <div className={"itemOrd spaceTopBig overflowHid"}>
                            <div className="bold textLeft subHd">{(theOrder.ord != null) ?
                                `סטטוס תשלום: ${theOrder.ord?.paymentStatus}` : ""} </div>
                        </div>
                        {(theOrder.ord?.orderRemarks != null) ?
                            <div className={"itemOrd spaceTopBig overflowHid"}>
                                <div className="subHd bold">{(theOrder.ord?.orderRemarks != null) ? parse(`${theOrder.ord.orderRemarks}`) : ""} </div>
                            </div>
                            : ""}

                    </div>
                </div>
                <div className={"spaceTopBig"}></div>
                <div className="centerText printingCloser">
                    <div className={"spTxt"}>ההזמנה בוצעה על ידי מערכת אשדוד בתפריט</div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>


        </>

    );
}


export default InvoicePrint;
