import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { RestContext } from '../../../../MyContexts/Rest_Context';
import RichTextComp from '../../../../MySections/TextEditor/RichText';

const TabSales = (props) => {

    const [rest] = useContext(RestContext);

    const [buisPopUp, setBuisPopUp] = useState();
   
    useEffect(() => {
    }, [])

    const activatePopUp = (activate) => {
        let tempPop = {
            active: activate,
            content: rest.buisPopUp.content,
            dispTime: rest.buisPopUp.dispTime,
        }
        setBuisPopUp(tempPop);
        rest["buisPopUp"] = tempPop;

    }


    const checkForPopUpCahnges = (content, textToCheck) => {
        if (content != textToCheck) {
            props.updateRest({
                ...rest, buisPopUp: {
                    active: rest.buisPopUp.active,
                    content: content,
                    dispTime: rest.buisPopUp.dispTime,
                }
            })
        }
    }

    const checkForSalesCahnges = (content, textToCheck) => {
        if (content != textToCheck) {
            props.updateRest({
                ...rest, buisPopUp: {
                    active: rest.buisPopUp.active,
                    content: content,
                    dispTime: rest.buisPopUp.dispTime,
                }
            })
        }
    }

    return (
        <>
            <div className="col-md-12 mb-2">
                <div className={"col-md-12 mb-2 tblHours fullRow"}>
                    <div className={"input-group switchMe onRight"}>
                        <span className={"spHead onRight bold"}>פופ אפ עסקי</span>
                        <label className="ms-switch">
                            <input type="checkbox" id={"validationCustom8_1"} checked={rest.buisPopUp?.active}
                                onChange={() => activatePopUp(!rest.buisPopUp?.active)} />
                            <span className="ms-switch-slider ms-switch-primary round" />
                        </label>
                    </div>
                </div>
                {rest.buisPopUp?.active ?
                    <div className="input-group">
                        <div className={"col-md-12 mb-2"}>
                            <label htmlFor={"validationCustom8_3"}>תוכן הפופ אפ</label>
                            <div className={"input-group"}>
                                <RichTextComp firstText={rest.buisPopUp.content}
                                    saveEditorTxt={(content) => { rest["buisPopUp"] = content; setBuisPopUp(content) }} />
                            </div>
                        </div>
                    </div>
                    : ""}

            </div>
            {/* 
            <div className="col-md-12 mb-2">
                <label htmlFor="validationCustom8_2">buisSale</label>
                <div className="input-group">
                    <input type="text" className="form-control" id="validationCustom04" placeholder={"active"} value={Rest.buisSale?.active} />
                    <input type="text" className="form-control" id="validationCustom04" placeholder={"content"} value={Rest.buisSale?.content} />
                    <input type="text" className="form-control" id="validationCustom04" placeholder={"prodsOnSale"} value={Rest.buisSale?.prodsOnSale} />
                </div>
            </div> */}

        </>

    );
}

export default TabSales;