import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { QrCodeScannerOutlined } from '@mui/icons-material';

const LaksLst = (props) => {


    const [open, setOpen] = useState(true);
    const [theFullMenu, setTheFullMenu] = useState(props.fullMenu);
    const [prodsList, setProdsList] = useState([]);
    const [theDialog, setTheDialog] = useState();
    const [err, setErr] = useState(false);


    useEffect(() => {

        const getTheLst = () => {
            let theLst = [];

            theFullMenu.forEach((divi) => {
                divi.Products.forEach((product) => {

                    (product.prodOptions || []).forEach((prodOption) => {

                        (prodOption.options || []).forEach((option) => {

                            let exist = theLst.some((opt) => {
                                return opt.servType === option.servType;
                            })
                            if (!exist)
                                theLst.push(option);
                        });
                    });
                });
            });

            return theLst;
        }

        setProdsList(getTheLst());


    }, [])

    const saveMe = () => {

        theFullMenu.forEach((divi) => {
            divi.Products.forEach((product) => {

                (product.prodOptions || []).forEach((prodOption) => {

                    (prodOption.options || []).forEach((option) => {

                        let ind = (prodsList.findIndex(opt => opt.servType === option.servType));
                        if (ind != -1) {
                            option.instock = prodsList[ind].instock;

                        }
                    });
                });
            });
        });

        props.UpdateTheBuis(theFullMenu);

    };

    const checkUncheckProdItm = (checkd, prodIndx, diviIndx) => {
        let tempoMnu = [...theFullMenu];
        tempoMnu[diviIndx].Products[prodIndx].inStock = checkd;
        setTheFullMenu(tempoMnu)
    }

    const handleClose = () => {
        props.cancelClick();
    };



    const inOrOutStockProd = (itm, indx) => {
        if (itm.instock != null) {
            itm.instock = !itm.instock;
        }
        else {
            itm.instock = false;
        }

        let tempo = [...prodsList];
        tempo[indx] = itm;
        setProdsList(tempo)

    }


    return (
        <Dialog open={open} onClose={handleClose} className={"textSizeDiag LastLstDiag"}>
            <DialogTitle>{props.Title}</DialogTitle>
            <DialogContent>
                <h4>מוצרים:</h4>
                {
                    prodsList.map((itm, indx) => {
                        return (
                            <div key={indx} className={"fullRow spaceMargeTopSmall"} onClick={() => inOrOutStockProd(itm, indx)}>
                                <label className="ms-checkbox-wrap onRight pointing" >
                                    <input type="checkbox"
                                        checked={(itm.instock != null ? itm.instock : true)} />
                                    <i className="ms-checkbox-check" />
                                </label>
                                <span className={"onRight spacePaddingRightVeryBig pointing"}>{itm.servType}</span>
                            </div>
                        )
                    })
                }
                <br />
                <h4>מנות:</h4>
                {
                    theFullMenu?.map((divi, diviIndx) => {
                        return (
                            divi.Products.map((produc, proIndex) => {
                                return (
                                    <div key={proIndex} className={"fullRow spaceMargeTopSmall"} onClick={() => checkUncheckProdItm(
                                        !produc.inStock,
                                        proIndex,
                                        diviIndx)}>
                                        <label className="ms-checkbox-wrap onRight pointingt">
                                            <input type="checkbox" checked={produc.inStock} />
                                            <i className="ms-checkbox-check" />
                                        </label>
                                        <span className={"onRight spacePaddingRightVeryBig pointing"}>{produc.prodName}</span>
                                    </div>
                                )
                            })
                        )
                    })
                }

            </DialogContent >
            <DialogActions>
                <button type="button" className="onLeft btn btn-primary" onClick={handleClose}>ביטול</button>
                <button type="button" className="onLeft btn btn-success" onClick={() => saveMe()}>אישור</button>
            </DialogActions>
        </Dialog >
    );
}

export default LaksLst;