import React, { useContext, useEffect, useState } from 'react';
import utilsTblOrders from '../../../../MyConns/_Utils/utilsTblOrders';
import { WaitingToApprovContext } from '../../../MyContexts/WaitingToApprov_Context';
import { Checkbox } from '@mui/material';
import Button from '@mui/material/Button';
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';
import TblOrderStatuselect from './TblOrderStatuselect';
import ShowTableOrder from './showTableOrder';
import SucessToast from '../../../MySections/Toasts/SucessToast';

const ListTblOrdersTableMobile = (props) => {
    const [wToImpto, setWToImpto, checkIfItemExistsAndStatus] = useContext(WaitingToApprovContext);
    const [selectAll, setSelectAll] = useState(false);
    const [thePopUp, setThePopUp] = useState("");


    useEffect(() => {

    }, [])


    const orderBythis = (byWhat) => {
        props.orderBythis(byWhat);
    }

    const rowCellClick = (ordId) => {
        setThePopUp(<ShowTableOrder
            ordId={ordId}
            cancelClick={() => setThePopUp()}
            ordrApproved={() => { props.refreshTheList(); setThePopUp() }}
        />)

    }

    const setNewStatus = async (orderId, statBack, cancelReason) => {
        let updteme = await utilsTblOrders.updateTableStatus(orderId, statBack, cancelReason);

        checkIfItemExistsAndStatus({ _id: orderId, status: statBack });

        if (updteme.sendedSMS) {
            if (statBack === "מאושר")
                SucessToast({ position: "toast-bottom-right", text: "עודכן, הלקוח קיבל סמס אישור הזמנה." });
            else if (statBack === "מבוטל")
                SucessToast({ position: "toast-bottom-right", text: "עודכן, הלקוח קיבל סמס על ביטול הזמנה." });
        }


        props.refreshTheList();
    }


    const getRemrksAnrequests = (itm) => {
        let bk = "";
        bk = itm.remarks !== "" ? itm.remarks + ". " : "";
        if (itm.tabelsLocationsReq.length > 0) { bk += itm.tabelsLocationsReq.join(',') + ". " }
        if (itm.specialsListReq.length > 0) { bk += itm.specialsListReq.join(',') + ". " }

        return bk;
    }


    return (
        <>
            {props.theList.map((item, i) => {
                let theremarks = getRemrksAnrequests(item);
                return (
                    <div key={item._id} className="table-responsive specTableBlock ms-panel-body">
                        <div className={`orderItminList big pointing
                                     ${item.status === "ממתין לאישור" ? "tableOrdWaiting" : ""}`}

                            itmid={item._id} id={item.tblOrderNum}>

                            {/* <Checkbox checked={multiSelect[i]?.checked || false} onChange={() => { updateMulti(i) }} className="chkBx" /> */}

                            <div className={'orderItmFlo onLeft'}>
                                <Button className={`btnMediumMnuSmall theSBtn`} variant="contained"
                                    onClick={() => rowCellClick(item._id)}>
                                    הצג הזמנה
                                </Button>
                            </div>
                            <div className={'orderItmblkIN'}>
                                <div className={"fullRow spaceButtom"}>
                                    <span className={"orderItmTxt veryBig bold"}> הזמנה {item.tblOrderNum}</span>
                                </div>
                                <br />
                                <div className={"fullRow spaceButtom"}>
                                    <span className={"orderItmTxt smallFont"}>שם המסעדה:</span>&nbsp;
                                    <span className={"orderItmTxt"}>{item.buisName}</span>
                                </div>
                                <div className={"fullRow spaceButtom"}>
                                    <span className={"orderItmTxt smallFont"}>מספר סועדים:</span>&nbsp;
                                    <span className={"orderItmTxt"}>{item.dinners}</span>
                                </div>
                                <div className={"fullRow spaceButtom"}>
                                    <span className={"orderItmTxt smallFont"}>תאריך הגעה:</span>&nbsp;
                                    <span className={"orderItmTxt"}>{MyDateTime.getDate(item.dateToCome)}</span>
                                </div>
                                <div className={"fullRow spaceButtom"}>
                                    <span className={"orderItmTxt smallFont"}>יום:</span>&nbsp;
                                    <span className={"orderItmTxt"}>{MyDateTime.getDayOfWeekHeb(item.dateToCome)}</span>
                                </div>
                                <div className={"fullRow spaceButtom"}>
                                    <span className={"orderItmTxt smallFont"}>שעת הגעה:</span>&nbsp;
                                    <span className={"orderItmTxt"}>{item.timeToCome}</span>
                                </div>
                                <div className={"fullRow spaceButtom"}>
                                    <span className={"orderItmTxt smallFont"}>שם הלקוח:</span>&nbsp;
                                    <span className={"orderItmTxt"}>{item.clientName}</span>
                                </div>
                                <div className={"fullRow spaceButtom"}>
                                    <span className={"orderItmTxt smallFont"}>טלפון:</span>&nbsp;
                                    <span className={"orderItmTxt"}><a href={`tel:${item.clientPhone}`} className={"orderItmTxt regular"}>{item.clientPhone}</a></span>
                                </div>
                                <div className={"fullRow spaceButtom"}>
                                    <span className={"orderItmTxt smallFont"}>הוזמן ב:</span>&nbsp;
                                    <span className={"orderItmTxt"}>{MyDateTime.getDateAndTime(item.dateCreated)}</span>
                                </div>
                                <div className={"fullRow spaceButtom"}>
                                    <span className={"orderItmTxt smallFont"}>הערות:</span>&nbsp;
                                    <span className={"orderItmTxt"}>{theremarks.trim() !== "" ? "יש" : "אין"}</span>
                                </div>


                                <div className={"fullRow spaceButtom"}>

                                    <div className={"statusCell pointing"} >
                                        <TblOrderStatuselect orderStatus={item.status}
                                            setOrderStatus={(statBack, cancelReason) => setNewStatus(item._id, statBack, cancelReason)}
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
            )}
            {thePopUp}
        </>

    )
}

export default ListTblOrdersTableMobile;