import medias from "../_ApiConn/apiMedia";

const FormData = require('form-data');

const getMediaList = async (obj) => {
    let dFromF = await medias.getMediaList(obj);
    return (dFromF.data);
}


const updateFile = async (obj) => {
    let dFromF = await medias.updateFile(obj);
    return (dFromF.data);
}


const createFolder = async (obj) => {
    let dFromF = await medias.createFolder(obj);
    return (dFromF.data);
}



const removeFolder = async (obj) => {

    let dFromF = await medias.removeFolder(obj);
    return (dFromF.data);
}

const removeFile= async (obj) => {

    let dFromF = await medias.removeFile(obj);
    return (dFromF.data);
}


const addNewFile = async (obj) => {

    const data = new FormData();
    data.append('type', 'newFile');
    data.append('file', obj.fileToUp, obj.fileToUp.name);
    data.append('folderUrl', obj.Path);

    let dFromF = await medias.addNewFile(data);
    return (dFromF.data);
}

export default {
    getMediaList,
    updateFile,
    createFolder,
    removeFolder,
    addNewFile,
    removeFile
};