
import React, { useState, createContext, useEffect } from 'react';
import myFunctions from '../../myFunctions';

export const UserContext = createContext();

export const UserContextProvider = props => {
    const [user, setUser] = useState({});

    useEffect(() => {
        checkTok();
    }, [])


    const checkTok = async () => {
        let chk = await myFunctions.connCheck();
        if (chk) {
            setUser(chk.user);
        }
        else {
            let user = {
                firstName: "No",
                lastName: "No",
                email: "No",
                rest: "No",
                permiss: null
               
            }
            setUser(user);
        }
    }

    const triggerCheckTok = () => {
        checkTok(); 
        console.log('checkTok');
    };

    return (
        <UserContext.Provider value={[user, setUser,triggerCheckTok]}>
            {props.children}
        </UserContext.Provider>
    )
}



