import React, { useContext, useEffect, useState } from 'react';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import utilsBuissTableSettings from '../../../MyConns/_Utils/utilsBuissTableSettings';
import { RestContext } from '../../MyContexts/Rest_Context';
import { UserContext } from '../../MyContexts/Users_Context';
import { Button } from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import TableItemSet from './TableItemSet';
import TablesItemsList from './TablesItemsList';
import { TablesSetterContext } from '../../MyContexts/TablesSetter_Context';
import myFunctions from '../../../myFunctions';





const BuissTableSettings = (props) => {

    const [rest] = useContext(RestContext);
    const [tbls, setTbls] = useContext(TablesSetterContext);
    // const [tbls, setTbls] = useState({})
    const [loading, setLoading] = useState(false);
    const [buisReservation, setBuisReservation] = useState(rest.buisReservation);
    const [numberOptions] = useState(myFunctions.getTimesList());

    useEffect(() => {
        getBuissTables();

    }, [])

    //מושך רשימת שולחנות לעסק
    const getBuissTables = async () => {
        if (tbls.buisId === "") {

            let buisTbls = await utilsBuissTableSettings.getBuissTableSettings(rest._id);

            if (buisTbls) {
                setTbls(buisTbls)

            }
            else {
                let tempo = {
                    buisId: rest._id,
                    tabelsLocations: "",
                    specialsList: "",
                    fromOpening: "00:00",
                    toClosing: "00:00",
                    tables: []
                }
                setTbls(tempo)

            }
        }
    }


    //מוסיף אובייקט שולחן
    const addTableSet = () => {
        let tempo = { ...tbls }

        let tblItm =
        {
            tableName: "",
            tableDinMax: "",
            canMargeWithTables: "",
            fromOpening: "",
            toClosing: "",
            catchingTime: "",
            selectedLocations: [],
            specials: []
        }

        tempo.tables.push(tblItm);

        setTbls(tempo)
    }



    return (
        <>
            {loading ?
                <MySpinner_Wait />
                :
                <>

                    <div className={"col-md-12 mb-2 tblHours fullRow"}>
                        <div className={"input-group switchMe onRight"}>
                            <label className="ms-switch">
                                <input type="checkbox" id={"validationCustom5_58"} checked={buisReservation}
                                    onChange={() => { rest["buisReservation"] = !buisReservation; setBuisReservation(!buisReservation) }} />
                                <span className="ms-switch-slider ms-switch-primary round" />
                            </label> <span className={"spHead onRight"}>הזמנת מקום</span>
                        </div>


                    </div>
                    {(buisReservation) &&
                        <>

                            <div className={"col-md-12 mb-2"}>
                                <div className={"col-md-6 mb-2 onRight"}>
                                    <label htmlFor={"validationCustom078"}>מיקומי שולחנות</label>
                                    <span className={"explanation mediumst fullROw"}>יש להפריד עם פסיק (,)</span>
                                    <div className={"input-group"}>
                                        <input type={"text"} className={"form-control"}
                                            id={"validationCustom078"} placeholder={"יש להפריד בפסיק"}
                                            value={tbls.tabelsLocations}
                                            onChange={(e) => {
                                                let tempTbls = { ...tbls, tabelsLocations: e.target.value };
                                                setTbls({ ...tempTbls });

                                            }} />
                                    </div>
                                </div>
                                <div className={"col-md-6 mb-2 onRight"}>
                                    <label htmlFor={"validationCustom079"}>מאפיינים מיוחדים</label>
                                    <span className={"explanation mediumst fullROw"}>יש להפריד עם פסיק (,)</span>
                                    <div className={"input-group"}>
                                        <input type={"text"} className={"form-control"}
                                            id={"validationCustom079"} placeholder={"יש להפריד בפסיק"} value={tbls.specialsList}
                                            onChange={(e) => {
                                                let tempTbls = { ...tbls, specialsList: e.target.value };
                                                setTbls({ ...tempTbls });

                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-12 mb-2"}>
                                <div className={"col-md-2 mb-2 onRight spaceMargeLeftbig spaceMargeRightbig"}>
                                    <label htmlFor={"setGroupkoponName_1Cs"}>הפרש מזמן פתיחה</label>
                                    <div className={"input-group"}>
                                        <select
                                            className={"timsFromToTables"}
                                            value={tbls.fromOpening}
                                            onChange={(e) => {
                                                let tempTbls = { ...tbls, fromOpening: e.target.value };
                                                setTbls({ ...tempTbls });

                                            }}
                                        >
                                            {numberOptions?.map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className={"col-md-2 mb-2 onRight spaceMargeRightbig"}>
                                    <label htmlFor={"setGroupkoponName_1Cs"}>הפרש מזמן סגירה</label>
                                    <div className={"input-group"}>
                                        <select
                                            className={"timsFromToTables"}
                                            value={tbls.toClosing}
                                            onChange={(e) => {
                                                let tempTbls = { ...tbls, toClosing: e.target.value };
                                                setTbls({ ...tempTbls });

                                            }}
                                        >
                                            {numberOptions?.map((time) => (
                                                <option key={time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                            </div>



                            <div className={"col-md-12 mb-2"}>
                                <Button className={"addDivBtn"} variant="contained" onClick={() => { addTableSet() }}
                                    startIcon={<Add style={{ color: 'white' }} />}>
                                    הוסף שולחן
                                </Button>

                                {tbls.tables?.length > 0 &&
                                    <div className={"col-md-12 mb-2"}>
                                        <br />
                                        <h5 className='bold'>מערך שולחנות:</h5>

                                        <TablesItemsList />
                                    </div>
                                }

                            </div>
                        </>
                    }

                </>
            }
        </>

    )
}




export default BuissTableSettings;


