import React, { useState, useEffect } from 'react'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { TextField } from '@mui/material';
import frLocale from 'date-fns/locale/he';
import { DatePicker, DateTimePicker } from '@mui/lab';
import MyDateTime from './MyDateTime';

const BetweenDatesNoHours = (props) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();


    useEffect(() => {

        var today = new Date();
        var tomoroow = new Date();
        tomoroow.setDate(tomoroow.getDate() + 1);

        today.setHours(5);
        today.setMinutes(0);
        today.setSeconds(0);

        tomoroow.setHours(5);
        tomoroow.setMinutes(0);
        tomoroow.setSeconds(0);

        setStartDate(today)
        setEndDate(tomoroow)
        
        props.setStartDTS(today.toString("HH:mm dd/MM/yyyy"))
        props.setEndDTS(tomoroow.toString("HH:mm dd/MM/yyyy"))
    }, [])




    const updateSEDate = (e, startOrEnd) => {
        if (startOrEnd === 1) {
            setStartDate(e)
            props.setStartDTS(e)
        }
        else if (startOrEnd === 2) {
            setEndDate(e)
            props.setEndDTS(e)
        }

    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
            <table className={"tabTable tableBordSpace"}>
                <tbody>
                    {

                        <tr key={"dts"} style={{ marginTop: "10px" }}>
                            {/* <td style={{ width: "20%" }}>{MyDateTime.getDayOfWeekToHeb(item.Day)}</td> */}
                            <td>
                                <table className={"tblOpHours"}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <DatePicker
                                                    label="מתאריך"
                                                    value={startDate}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(e) => updateSEDate(e, 1)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    className={"tmPick"}
                                                />
                                            </td>
                                            <td>
                                                <DatePicker
                                                    label="עד תאריך"
                                                    value={endDate}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(e) => updateSEDate(e, 2)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    className={"tmPick"}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </td>

                        </tr>

                    }
                </tbody>
            </table>

        </LocalizationProvider>
    );
}

export default BetweenDatesNoHours;
