import { CheckCircle, HighlightOffOutlined } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import ConnUrls from '../../../../../MyConns/Connections/ConnectURL';
import { RestContext } from '../../../../MyContexts/Rest_Context';
import PopSelectImage from '../../../../MySections/ImagesSetter/PopSelectImage';
import PopSelectMultiImages from '../../../../MySections/ImagesSetter/PopSelectMultiImages';

const TabImages = (props) => {
    const [rest] = useContext(RestContext);

    const [buissMainImg, setBuissMainImg] = useState(rest.buissMainImg);
    const [buissMainImgDeskTop, setBuissMainImgDeskTop] = useState(rest.buissMainImgDeskTop);
    const [busiSliderImges, setBusiSliderImges] = useState(rest.busiSliderImges);
    const [dispSliderImges, setDispSliderImges] = useState();

    const [diag, setDiag] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
    }, [])

    useEffect(() => {
        SettingSliderImges();
    }, [busiSliderImges])

    const setTheImg = (mobOrDesk, TheUrl) => {
        if (mobOrDesk === "mob") {
            rest["buissMainImg"] = TheUrl;
            setBuissMainImg(TheUrl);
            setDiag();
        }
        else if (mobOrDesk === "desk") {
            rest["buissMainImgDeskTop"] = TheUrl;
            setBuissMainImgDeskTop(TheUrl);
            setDiag();
        }
    }


    ///לתמונה הראשית
    const selectImgClickOpen = (mobOrdesk) => {
        setDiag(
            <Dialog onClose={() => setDiag()} open={true} className={"popUpWrapMedia"}>
                <div className={"inDiag"}>
                    <HighlightOffOutlined sx={{ fontSize: 40 }}
                        className={"pointing closeDiagBtn"} onClick={() => setDiag()} />
                    <PopSelectImage firstImg={buissMainImg} handleClose={() => setDiag()}
                        selectedImg=
                        {(fUrl) => {
                            setTheImg(mobOrdesk, fUrl)
                        }} />

                </div>
            </Dialog>
        )

    };


    ///לגלריית התמונות
    const selectSliderImgs = () => {
        setDiag(
            <Dialog onClose={() => setDiag()} open={true} className={"popUpWrapMedia"}>
                <div className={"inDiag"}>
                    <HighlightOffOutlined sx={{ fontSize: 40 }}
                        className={"pointing closeDiagBtn"} onClick={() => setDiag()} />
                    <PopSelectMultiImages imgsList={busiSliderImges} handleClose={() => setDiag()}
                        imagesArray=
                        {(urlsList) => {
                            rest["busiSliderImges"] = urlsList;
                            setBusiSliderImges(urlsList);
                            setDiag();
                        }} />

                </div>
            </Dialog>
        )

    };



    const SettingSliderImges = () => {
        {
            let bk = "";
            if (busiSliderImges.length > 0) {
                bk = busiSliderImges.map((item, index) => {
                    return (
                        <div key={index} className={"sldGalItm"}>
                            <div className={"mediumImgDisp pointing"}>
                                <HighlightOffOutlined sx={{ fontSize: 20 }}
                                    className={"removeOnImages"}
                                    onClick={() => { removeThisFromGall(index) }} />
                                <img src={ConnUrls.servMediasUrl + item} />
                            </div>
                        </div>
                    )
                })
            }

            setDispSliderImges(bk);
        }
    }



    const removeThisFromGall = (index) => {
        let temp = [...busiSliderImges];
        temp.splice(index, 1);

        rest["busiSliderImges"] = temp;
        setBusiSliderImges(temp);

    }

    return (
        <div className={"form-row tabsInputs spaceMargeRightbig"}>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom10"} className={"input-group"}>תמונה ראשית</label>
                <div className={"input-group"} onClick={() => selectImgClickOpen("mob")} >
                    <img className={"mediumImgDisp pointing"}
                        src={buissMainImg ? ConnUrls.servMediasUrl + buissMainImg :
                            ConnUrls.mediasLocalUrl + "/PermanentImages/AddImageBlk.png"} />
                </div>

            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom10"} className={"input-group"}>תמונה ראשית דסקטופ</label>
                <div className={"input-group"} onClick={() => selectImgClickOpen("desk")} >
                    <img className={"mediumImgDisp pointing"}
                        src={buissMainImgDeskTop ? ConnUrls.servMediasUrl + buissMainImgDeskTop :
                            ConnUrls.mediasLocalUrl + "/PermanentImages/AddImageBlk.png"} />
                </div>

            </div>
            <div className={"col-md-12 mb-2 tabsInputs"}>
                <label htmlFor={"validationCustom10"} className={"input-group"}>
                    גלריית תמונות
                </label>
                <div className={"sldGalItm"} onClick={selectSliderImgs} >
                    <img className={"mediumImgDisp pointing"}
                        src={ConnUrls.mediasLocalUrl + "/PermanentImages/AddImageBlk.png"} />
                </div>
                {dispSliderImges}
            </div>

            {diag}
        </div>

    )
}

export default TabImages;