import React, { useEffect, useState } from 'react';
import { Checkbox, ListItemText, TextField, Autocomplete } from '@mui/material';
import myFunctions from '../../../../myFunctions';

const MultiBuisSelect = (props) => {
    const [busissList, setBusissList] = useState(props.busissList || []);
    const [userSelection, setUserSelection] = useState(
        props.userSelection ? props.userSelection.map(usrsel => myFunctions.getBuisNameByIdInList(props.busissList, usrsel)) : []
    );
    const [buisOpts, setBuisOpts] = useState([]);

    useEffect(() => {
        setTheOpts();
    }, [busissList]);

    const selChanged = (e) => {
        if (e) {
            let tempo = [...userSelection]
            let exist = tempo.some(sel => sel === e.label)
            if (!exist) {
                let item = myFunctions.getBuisNameByIdInList(props.busissList, e.value)
                tempo.push(item)
                setUserSelection(tempo.filter(sel => sel !== "כל העסקים"));
            }
            else {
                tempo = tempo.filter(sel => sel !== e.label && sel !== "כל העסקים")
                setUserSelection(tempo);
            }
            let filterd = busissList.filter(item => tempo.includes(item.buissName));
            filterd = filterd.filter(item => !tempo.includes("כל העסקים"));
            props.setUserBuis(filterd.map(item => item._id).join(','));
        }
        else {
            setUserSelection(["כל העסקים"]);
            props.setUserBuis("-1");
        }
    };

    const setTheOpts = () => {
        const buisOpts = busissList.map((item, index) => ({
            key: index,
            value: item._id,
            label: item.buissName
        }));

        setBuisOpts(buisOpts);
    };

    const sortedBuisOpts = (buisOptstosort) => {
        return buisOptstosort.sort((a, b) => {
            // If a is selected and b is not selected, a should come before b
            if (userSelection.some(sel => sel === a.label) && !userSelection.some(sel => sel === b.label)) {
                return -1;
            }
            // If b is selected and a is not selected, b should come before a
            else if (!userSelection.some(sel => sel === a.label) && userSelection.some(sel => sel === b.label)) {
                return 1;
            }
            // Otherwise, maintain the original order
            else {
                return 0;
            }
        });
    }

    return (
        <Autocomplete
            className={`MultiBuisSelect selectInBlock ${props.clss ? props.clss : ""}`}
            disablePortal
            value={userSelection.join(',')}
            options={sortedBuisOpts(buisOpts)}
            sx={{ maxWidth: "300px" }}
            onChange={(event, e) => {
                selChanged(e);
            }}
            renderInput={(params) => <TextField {...params} className={"specialTxt"} placeholder='כל העסקים' title={userSelection.join(',')} />}
            renderOption={(props, option) => (
                <li {...props} title={option.label}>
                    <Checkbox checked={userSelection.some(sel => sel === option.label)} />
                    <ListItemText primary={option.label} />
                </li>
            )}
        />
    );
};

export default MultiBuisSelect;
