import React, { useContext, useEffect, useState } from 'react'
import { TheMasterContext } from '../../../../MyContexts/Master_Context';
import { Accordion, Card } from 'react-bootstrap';
import ImgAndTxtCARD from '../../../../MySections/DTechComps/ImgAndTxtCARD';
import SelectImgComp from '../../../../MySections/ImagesSetter/SelectImgComp';
import Scroll from 'react-scroll';
var scroller = Scroll.scroller;

const TabGallerys = (props) => {
    const [theMaster] = useContext(TheMasterContext);

    const [mainTopImages, setMainTopImages] = useState(theMaster.mainTopImages);
    const [loginTopImages, setLoginTopImages] = useState(theMaster.loginTopImages);
    const [mobiMenuTopImages, setMobiMenuTopImages] = useState(theMaster.mobiMenuTopImages);
    const [deskFotterImages, setDeskFotterImages] = useState(theMaster.deskFotterImages);
    const [delorpickImg, setDelorpickImg] = useState(theMaster.delorpickImg);
    const [connsectBySMSImg, setConnsectBySMSImg] = useState(theMaster.connsectBySMSImg);
    const [codeSMSImg, setCodeSMSImg] = useState(theMaster.codeSMSImg);
    const [suceccufulyconnectedImg, setSuceccufulyconnectedImg] = useState(theMaster.suceccufulyconnectedImg);
    const [orderApprovImg, setOrderApprovImg] = useState(theMaster.orderApprovImg);
    const [orderUnApprovImg, setOrderUnApprovImg] = useState(theMaster.orderUnApprovImg);



    const [bootomBaners, setBootomBaners] = useState(theMaster.bootomBaners);
    const [bootomDeskTopBaners, setBootomDeskTopBaners] = useState(theMaster.bootomDeskTopBaners);


    const [mainGallDisp, setMainGallDisp] = useState();


    useEffect(() => {


    }, [])

    const scrolltoMe = (elem) => {
        scroller.scrollTo(elem, { smooth: true, offset: -100, duration: 2000 });
    }

    const setTheTopImages = (urlsList) => {
        theMaster["mainTopImages"] = urlsList;
        setMainTopImages(urlsList);

    }
    const setTheMobiMenuTopImages = (urlsList) => {
        theMaster["mobiMenuTopImages"] = urlsList;
        setMobiMenuTopImages(urlsList);

    }
    const setTheDeskFotterImages = (urlsList) => {
        theMaster["deskFotterImages"] = urlsList;
        setDeskFotterImages(urlsList);

    }


    const setTheLoginTopImages = (urlsList) => {
        theMaster["loginTopImages"] = urlsList;
        setLoginTopImages(urlsList);

    }
    const setTheBootomBaners = (urlsList) => {
        theMaster["bootomBaners"] = urlsList;
        setBootomBaners(urlsList);
    }

    const setTheBootomDeskTopBaners = (urlsList) => {
        theMaster["bootomDeskTopBaners"] = urlsList;
        setBootomDeskTopBaners(urlsList);

    }
 
    return (
        <>

            <div className={"col-md-12 mb-2"}>

                <Accordion defaultActiveKey="main" className="has-gap ms-accordion-chevron menuSectionsAcord accordion">

                    <div className={"col-md-12 mb-2"}>
                        <ImgAndTxtCARD Head={"גלריית תמונות ראשית"} tblHeads={["תמונה", "טקסט על התמונה", "מחק", "מיקום"]}
                            TheList={mainTopImages} theFunction={(lst) => setTheTopImages(lst)} eventKey={"elme_0"} />
                    </div>
                    <div className={"col-md-12 mb-2"}>
                        <ImgAndTxtCARD Head={"גלריית תמונות תפריט צד"} tblHeads={["תמונה", "טקסט על התמונה", "מחק", "מיקום"]}
                            TheList={mobiMenuTopImages} theFunction={(lst) => setTheMobiMenuTopImages(lst)} eventKey={"elme_1"} />

                    </div>

                    <div className={"col-md-12 mb-2"}>
                        <ImgAndTxtCARD Head={"גלריית תמונות פוטר"} tblHeads={["תמונה", "טקסט על התמונה", "מחק", "מיקום"]}
                            TheList={deskFotterImages} theFunction={(lst) => setTheDeskFotterImages(lst)} eventKey={"elme_2"} />

                    </div>
                    <div className={"col-md-12 mb-2"}>
                        <ImgAndTxtCARD Head={"גלריית תמונות עמוד לוג אין"} tblHeads={["תמונה", "טקסט על התמונה", "מחק", "מיקום"]}
                            TheList={loginTopImages} theFunction={(lst) => setTheLoginTopImages(lst)} eventKey={"elme_3"} />

                    </div>
                    <div className={"col-md-12 mb-2"}>
                        <ImgAndTxtCARD Head={"באנרים בתחתית"} tblHeads={["תמונה", "קישור בלחיצה", "מחק", "מיקום"]}
                            TheList={bootomBaners} theFunction={(lst) => setTheBootomBaners(lst)} eventKey={"elme_4"} />
                    </div>
                    <div className={"col-md-12 mb-2"}>
                        <ImgAndTxtCARD Head={"באנרים בתחתית - דסקטופ"} tblHeads={["תמונה", "קישור בלחיצה", "מחק", "מיקום"]}
                            TheList={bootomDeskTopBaners} theFunction={(lst) => setTheBootomDeskTopBaners(lst)} eventKey={"elme_5"} />
                    </div>
                </Accordion>
            </div>

        </>

    );
}

export default TabGallerys;