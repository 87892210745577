import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RichTextComp from '../TextEditor/RichText';
import SunEditor, { buttonList } from "suneditor-react";
import OrderStatuselect from '../../MyPages/OrdersP/OrdersList-list/OrderStatuselect';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import GetDeliveryAddress from '../DTechComps/GetDeliveryAddress';
import utilsGamma from "../../../MyConns/_Utils/utilsGamma"
import Crdit_Problem from "./Pop_Fixed/Crdit_Problem"
import PopUpIframe from "./PopUpIframe"
import { Helmet } from 'react-helmet';
import MySpinner_Wait from '../DTechComps/MySpinner_Wait';

import ErrorToast from '../Toasts/ErrorToast';
import utilsUsers from '../../../MyConns/_Utils/utilsUsers';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';
import InputDubleSet from './InputDubleSet';

const ZikoyOnOrder = (props) => {

    const [open, setOpen] = useState(true);
    const [theOrder, setTheOrder] = useState(props.theOrder);
    const [inputTxt, setInputTxt] = useState("");
    const [inputPrc, setInputPrc] = useState("");
    const [err, setErr] = useState(false);

    const saveMe = () => {

        if (inputTxt !== "" && inputPrc !== "") {
            let zikoy = {
                Name: inputTxt,
                zickoyPrice: inputPrc
            }
            let ziko = theOrder.ord.orderZikoy ? [...theOrder.ord.orderZikoy] : [];
            if (theOrder.ord.orderZikoy) {
                ziko = [...theOrder.ord.orderZikoy];
            }
            ziko.push(zikoy)
            theOrder.ord.orderZikoy = ziko;
            props.okClick(theOrder);

        }
        else {
            setErr(true);
        }
    };


    const handleClose = () => {
        props.cancelClick();
    };


    return (
        <Dialog open={open} onClose={handleClose} className={"textSizeDiag"}>
            <DialogTitle>{props.Title}</DialogTitle>
            <DialogContent>
                <DialogContentText component="div">
                    {props.ContentText}
                </DialogContentText>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={props.inLabel}
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => { setInputTxt(e.target.value); setErr(false) }}
                />
                <TextField
                    margin="dense"
                    id="zikoyPrice"
                    label={props.inLabel2}
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={(e) => { setInputPrc(e.target.value); }}
                />
                {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}
            </DialogContent>
            <DialogActions>
                <button type="button" className="onLeft btn btn-primary" onClick={handleClose}>ביטול</button>
                <button type="button" className="onLeft btn btn-success" onClick={() => saveMe()}>שמור</button>
            </DialogActions>
        </Dialog>
    )
}

export default ZikoyOnOrder;