import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { RestContext } from '../../../../MyContexts/Rest_Context';
import CRTypeSelect from '../../../../MySections/DTechComps/CRTypeSelect';
import RichTextComp from '../../../../MySections/TextEditor/RichText';

const TabMoney = (props) => {

    const [rest, setRest] = useContext(RestContext);

    const [buisPopUp, setBuisPopUp] = useState();

    const [CRType, setcRType] = useState(rest.CRType);
    // const [gammaCnt, setGammaCnt] = useState(rest.gammaCnt);
    // const [gammaSecret, setGammaSecret] = useState(rest.gammaSecret);
    // const [gammaJ5, setGammaJ5] = useState(rest.gammaJ5);
    const [cardComCnt, setCardComCnt] = useState(rest.cardComCnt);
    const [payPrecent, setPayPrecent] = useState(rest.payPrecent);
    const [payRemarks, setPayRemarks] = useState(rest.payRemarks);
    const [cashRegId, setCashRegId] = useState(rest.cashRegId);
    const [costtiket, setCosttiket] = useState(rest.costtiket);
    const [restaurantId, setRestaurantId] = useState(rest.restaurantId);
    const [menuRevision, setMenuRevision] = useState(rest.menuRevision);
    const [paymentOptions, setPaymentOptions] = useState(rest.paymentOptions);


    const [payFreeText, setPayFreeText] = useState(rest.payFreeText);

    useEffect(() => {
    }, [])

    const activatePopUp = (activate) => {
        let tempPop = {
            active: activate,
            content: rest.buisPopUp.content,
            dispTime: rest.buisPopUp.dispTime,
        }
        setBuisPopUp(tempPop);
        rest["buisPopUp"] = tempPop;

    }


    const checkForPopUpCahnges = (content, textToCheck) => {
        if (content != textToCheck) {
            props.updateRest({
                ...rest, buisPopUp: {
                    active: rest.buisPopUp.active,
                    content: content,
                    dispTime: rest.buisPopUp.dispTime,
                }
            })
        }
    }

    const checkForSalesCahnges = (content, textToCheck) => {
        if (content != textToCheck) {
            props.updateRest({
                ...rest, buisPopUp: {
                    active: rest.buisPopUp.active,
                    content: content,
                    dispTime: rest.buisPopUp.dispTime,
                }
            })
        }
    }

    const setPayTheFreeText = (content) => {
        rest.payFreeText = content;
        setPayFreeText(content);
        //חובה על ריץ' טקסט
        setRest({ ...rest });
    }


    const setPayOptions = (e) => {
        rest.paymentOptions = e.target.value;
        setPaymentOptions(e.target.value);
        //חובה על ריץ' טקסט
        setRest({ ...rest });
    }

    return (
        <>
            <div className={"fullRow"}>
                <div className={"row noMargin"}>
                    <div className={"col-2 mb-3 onRight"}>
                        <label htmlFor={"validationCustom1_7"}>סוג קופה</label>
                        <div className={"CRTypeSelect"} style={{ margin: "unset" }}>
                            <CRTypeSelect restCRT={CRType ? CRType : "tablet"} onLeft={"onRight"}
                                CRTUpdate={(CRT) => { rest["CRType"] = CRT; setcRType(CRT) }} />
                        </div>
                    </div>

                    {/* {not tablet} */}
                    {(CRType !== "tablet" &&
                        <>
                            <div className={"col mb-3 onRight"}>
                                <label htmlFor={"validationCustom1_7"}>{`${(CRType === "beeComCloud") ? "cashReg_Id" : "מזהה לקופה"}`}</label>
                                <div className={"input-group"}>
                                    <input type="text" className={"form-control"} id={"validationCustom1_Cas"} placeholder={"cashRegId"} value={cashRegId}
                                        onChange={(e) => { rest["cashRegId"] = e.target.value; setCashRegId(e.target.value) }} />

                                </div>
                            </div>

                            {/* {presto} */}
                            {(CRType === "presto" &&
                                <div className={"col mb-3 onRight"}>
                                    <label htmlFor={"validationCustom1_7"}>presto costtiket</label>
                                    <div className={"input-group"}>
                                        <input type="text" className={"form-control"} id={"validationCustom1_costtiket"} placeholder={"costtiket"} value={costtiket}
                                            onChange={(e) => { rest["costtiket"] = e.target.value; setCosttiket(e.target.value) }} />

                                    </div>

                                </div>
                            )}
                            {/* {beeComCloud} */}
                            {(CRType === "beeComCloud" &&
                                <>
                                    <div className={"col mb-3 onRight"}>
                                        <label htmlFor={"validationCustom1_99"}>restaurant_Id</label>
                                        <div className={"input-group"}>
                                            <input type="text" className={"form-control"} id={"validationCustom1_restaurantId"} placeholder={"restaurantId"} value={restaurantId}
                                                onChange={(e) => { rest["restaurantId"] = e.target.value; setRestaurantId(e.target.value) }} />

                                        </div>

                                    </div>
                                    <div className={"col mb-3 onRight"}>
                                        <label htmlFor={"validationCustom1_rev9"}>menuRevision</label>
                                        <div className={"input-group"}>
                                            <input type="text" className={"form-control"} id={"validationCustom1_rev9"} placeholder={"menuRevision"} value={menuRevision}
                                                onChange={(e) => { rest["menuRevision"] = e.target.value; setMenuRevision(e.target.value) }} />

                                        </div>

                                    </div>
                                </>


                            )}

                        </>
                    )}
                </div>
            </div>
            {/* <div className={"col-md-4 mb-3"}>

                <label htmlFor={"validationCustom1_6"}>gamma clientId</label>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_6"} placeholder={"clientId"} value={gammaCnt}
                        onChange={(e) => { rest["gammaCnt"] = e.target.value; setGammaCnt(e.target.value) }} />

                </div>
            </div>
            <div className={"col-md-4 mb-3"}>

                <label htmlFor={"validationCustom1_7"}>gamma Secret</label>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_7"} placeholder={"clientSecret"} value={gammaSecret}
                        onChange={(e) => { rest["gammaSecret"] = e.target.value; setGammaSecret(e.target.value) }} />
                </div>
            </div>
            <div className={"col-md-4 mb-3"}>

                <div className={"input-group switchMe"}>

                    <label className="ms-switch">
                        <input type="checkbox" id={"validationCustom5_3"} checked={gammaJ5}
                            onChange={(e) => { rest["gammaJ5"] = !gammaJ5; setGammaJ5(!gammaJ5) }} />
                        <span className="ms-switch-slider ms-switch-primary round" />
                    </label> <span className={"spHead bold"}>  gamma J5</span>

                </div>
            </div> */}
            <div className={"fullRow"}>
                <div className={"row noMargin"}>
                    <div className={"col mb-3 onRight"}>
                        <div className={"input-group switchMe inPayTab"}>
                            <label className="ms-switch">
                                <input type="checkbox" id={"validationCustom5_3"} checked={cardComCnt}
                                    onChange={(e) => { rest["cardComCnt"] = !cardComCnt; setCardComCnt(!cardComCnt) }} />
                                <span className="ms-switch-slider ms-switch-primary round" />
                            </label> <span className={"spHead bold"}>חיוב בקארדקום</span>

                        </div>
                    </div>

                    <div className={"col mb-3 onRight"}>
                        <label htmlFor={"validationCustom1_20"}>אחוזי חיוב</label>
                        <div className={"input-group"}>
                            <input type="text" className={"form-control"} id={"validationCustom1_20"} placeholder={"אחוזי חיוב"} value={payPrecent}
                                onChange={(e) => { rest["payPrecent"] = e.target.value; setPayPrecent(e.target.value) }} />
                        </div>
                    </div>
                    <div className={"col mb-3 onRight"}>

                        <label htmlFor={"validationCustom1_27"}>הערות חיוב</label>
                        <div className={"input-group"}>
                            <input type="text" className={"form-control"} id={"validationCustom1_27"} placeholder={"הערות חיוב"} value={payRemarks}
                                onChange={(e) => { rest["payRemarks"] = e.target.value; setPayRemarks(e.target.value) }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"fullRow"}>
                <div className={"row noMargin"}>
                    <div className={"col-3 mb-3 onRight"}>
                        <span className={"spHead bold"}>אפשרויות חיוב לקוחות</span>
                        <select className="form-control" name="reminder-time" onChange={(e) => setPayOptions(e)} value={paymentOptions}>
                            <option value="creditAndCash">אשראי + מזומן</option>
                            <option value="onlyCredit">רק אשראי</option>
                            <option value="onlyCash">רק מזומן</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className={"col-md-12 mb-3"}>
                <div className={"input-group"}>

                    <label>טקסט נוסף להערות</label>
                    <div className={"input-group"}>
                        <RichTextComp firstText={payFreeText}
                            saveEditorTxt={(content) => {
                                rest["payFreeText"] = content;
                                setPayTheFreeText(content)
                            }} />
                    </div>
                </div>
            </div>

        </>

    );
}

export default TabMoney;