import React, { useContext, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { MobiOrNotContext } from '../../../../MyContexts/MobiOrNotContext';

const CancelOrderTableReason = (props) => {

  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);

  const [open, setOpen] = useState(true);
  const [inputTxt, setInputTxt] = useState("");
  const [selectedTxt, setSelectedTxt] = useState("");
  const [err, setErr] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState("1");
  const [selectedReason, setSelectedReason] = useState("");

  let txtxs = {
    lbl1: "עקב עומס, לא ניתן לשמור שולחן לשעה המבוקשת, ניתן לנסות לבחור שעה אחרת.",
    lbl2: "עקב עומס, לא ניתן לשמור שולחן לתאריך המבוקש, ניתן לנסות לבחור תאריך אחר.",
    lbl3: "המסעדה סגורה בתאריך המבוקש.",
    lbl4: "סיבה אחרת"
  }


  const getLbl = (selected) => {
    const theTxtxs = {
      "1": txtxs.lbl1,
      "2": txtxs.lbl2,
      "3": txtxs.lbl3,
      "4": txtxs.lbl4
    }

    let txBack = theTxtxs[selected] || theTxtxs["1"];

    return txBack;

  }

  const saveMe = () => {
    if (selectedBtn !== "4") {
      props.okClick(getLbl(selectedBtn))
      handleClose();
    }
    else if
      (inputTxt !== "") {
      props.okClick(inputTxt);

      handleClose();
    }
    else {
      setErr(true);
    }
  };

  const selectedChanged = (sel) => {
    setSelectedBtn(sel)
    if (sel !== "4") {
      setSelectedTxt(getLbl(sel))

    }
    else {
      setSelectedTxt(inputTxt)
    }
  }

  const handleClose = () => {
    props.cancelClick();
  };

  return (
    <Dialog open={open}
      className={`textSizeDiag ${props.diagCss} ${mobiOrNot && "mobiPop"}`}>
      <div className={`fullRow bold centerText spaceTopVeryBig`}>{props.Title}</div>
      <DialogContent>
        <DialogContentText component="div">
          <RadioGroup
            onChange={(e) => selectedChanged(e.target.value)}
            row
            value={selectedBtn}
            name=" סיבות ביטול הזמנת שולחן"
          >
            <FormControlLabel value="1" className={"fullRow mediumFont specialRdb spaceMargeTopSmall"}
              control={<Radio onClick={() => { setInputTxt("") }} />}
              label={getLbl("1")} />
            <FormControlLabel value="2" className={"fullRow mediumFont specialRdb spaceMargeTopSmall"}
              control={<Radio onClick={() => { setInputTxt("") }} />}
              label={getLbl("2")} />
            <FormControlLabel value="3" className={"fullRow mediumFont specialRdb spaceMargeTopSmall"}
              control={<Radio onClick={() => { setInputTxt("") }} />}
              label={getLbl("3")} />
            <FormControlLabel value="4" className={"fullRow mediumFont specialRdb spaceMargeTopSmall"}
              control={<Radio onClick={() => { }} />}
              label={getLbl("4")} />
          </RadioGroup>

          <div style={{ width: "90%", margin: "0 auto" }}>
            <TextField
              onFocus={() => { selectedChanged("4") }}
              sx={{ border: 1, borderRadius: '15px', padding: '10px' }}

              margin="dense"
              id="name"
              label={props.inLabel}
              fullWidth
              multiline
              InputProps={{ disableUnderline: true }}
              placeholder={"הקלד סיבה"}
              rows={4}
              variant="standard"
              value={inputTxt}
              onChange={(e) => {
                setInputTxt(e.target.value);
                setErr(false)
              }}
            />
          </div>
          {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <button type="button" className="onLeft btn btn-danger" onClick={handleClose}>ביטול</button>
        <button type="button" className="onLeft btn btn-primary" onClick={() => saveMe()}>{props.okCLickTxt || "שמור"}</button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelOrderTableReason;