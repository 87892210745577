import React, { useEffect, useState } from 'react';

const DropHouresSelection = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [numberOptions, setNumberOptions] = useState([]);

  useEffect(() => {
    getTimesList();
  }, [])

  // Calculate the number of quarter-hour increments
  const getTimesList = () => {
    let timeSlots = [];
    let strttm = props.fromHour;
    let endttm = props.toHour - 1;
    for (let hour = strttm; hour <= endttm; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        timeSlots.push(`${formattedHour}:${formattedMinute}`);
      }
    }

    setNumberOptions(timeSlots);
  }

  return (
    <div style={{ position: 'relative' }}>
      <input
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
        readOnly
        type='text'
        className={"inputsStyle pointing"}
        value={`${props.lbl} ${props.Val}`}
      />

      {isOpen && (
        <div style={{ position: 'absolute', zIndex: 1 }}>
          <select
            className={props.Css}
            value={props.Val}
            onChange={(e) => {
              props.settingFunc(e.target.value);
              setIsOpen(false);
            }}
            onBlur={() => setIsOpen(false)}
            size={Math.min(numberOptions.length, 10)}
          >
            {numberOptions.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default DropHouresSelection;
