import users from "../_ApiConn/apiUsers";


const createUser = async (obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await users.createUser(obj);
    return (dFromF);
}
const updateUser = async (userId, obj) => {

    const token = localStorage.getItem('token');
    let dFromF = await users.updateUser(userId, { token, obj });
    return (dFromF);

}

const updateClientsList = async (tempList) => {
    const token = localStorage.getItem('token');
    let dFromF = await users.updateClientsList({ tempList, token });
    return (dFromF);
}

const clear24Stuck = async (clientId) => {
    
    const token = localStorage.getItem('token');
    let dFromF = await users.clear24Stuck({ clientId, token });
    return (dFromF);
}



const getUsers = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await users.getUsers({ token });
    return (dFromF);
}


const searchUser = async (txtSrch) => {
    const token = localStorage.getItem('token');
    let dFromF = await users.searchUser({ token,txtSrch });
    return (dFromF);
}

const getUserById = async (id) => {
    const token = localStorage.getItem('token');
    let dFromF = await users.getUserById({ token, id });
    return (dFromF);
}



const removeUser = async (id) => {
    const token = localStorage.getItem('token');
    let dFromF = await users.removeUser({ id, token });
    return (dFromF);
}



const getAdresses = async (id) => {
    let dFromF = await users.getAdresses(id);
    return (dFromF);
}


const addNewAddress = async (id, address) => {
    let dFromF = await users.addNewAddress(id, address);
    return (dFromF);
}



const removeAddress = async (id, address) => {
    let dFromF = await users.removeAddress(id, address);
    return (dFromF);
}


export default {
    removeAddress,
    addNewAddress,
    getAdresses,
    updateUser,
    createUser,
    getUsers,
    updateClientsList,
    removeUser,
    getUserById,
    clear24Stuck,
    searchUser

};