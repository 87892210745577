import axios from "axios";
import myFunctions from "../../myFunctions";
import ConnUrls from '../Connections/ConnectURL'


const getMaster = async (token) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "masterBack", { token });
    return (dFromF);
}



const updateTheMaster = async (token,obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "masterBack/upMastBack", {token,obj});
    return (dFromF);
}

const updateTheMasterByItm = async (itmToGet,token,obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "masterBack/" + itmToGet, {token,obj});
    return (dFromF);
}

const setDelayOpening = async (typeToDelay, delayObj, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "masterBack/setDelayOpening/", { typeToDelay, delayObj, token });
    return (dFromF.data);
}


export default {
    getMaster,
    updateTheMaster,
    updateTheMasterByItm,
    setDelayOpening
};