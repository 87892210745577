import React, { useContext, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import utilsTblOrders from '../../../../MyConns/_Utils/utilsTblOrders';
import myFunctions from '../../../../myFunctions';
import { BuisListForTablesContext } from '../../../MyContexts/BuisListForTables_Context';
import { UserContext } from '../../../MyContexts/Users_Context';
import { WaitingToApprovContext } from '../../../MyContexts/WaitingToApprov_Context';
import DatePick from '../../../MySections/DTechComps/DatePick';
import DropHouresSelection from '../../../MySections/DTechComps/DropHouresSelection';
import DropNumbersSelection from '../../../MySections/DTechComps/DropNumbersSelection';
import InputTextSimple from '../../../MySections/DTechComps/InputTextSimple';
import InputSet from '../../../MySections/PopUps/InputSet';
import MyTextArea from '../../../MySections/TextEditor/MyTextArea';
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';
import BuisSelect_ForTable from './BuisSelect_ForTable';
import TblOrderStatuselect from './TblOrderStatuselect';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { MobiOrNotContext } from '../../../MyContexts/MobiOrNotContext';

const ShowTableOrder = (props) => {

    const [wToImpto, setWToImpto, checkIfItemExistsAndStatus] = useContext(WaitingToApprovContext);

    const [user] = useContext(UserContext);
    const [BuisListForTables, setBuisListForTables] = useContext(BuisListForTablesContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [buisSelected, setBuisSelected] = useState("");
    const [thePopUp, setThePopUp] = useState("");
    const [err, setErr] = useState("");
    const [updaterName, setUpdaterName] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientPhone, setClientPhone] = useState("");
    const [dinners, setDinners] = useState("");
    const [timeToCome, setTimeToCome] = useState("");
    const [dateToCome, setDateToCome] = useState();
    const [orderStatus, setOrderStatus] = useState("");
    const [remarks, setRemarks] = useState("");
    const [ordererName, setOrdererName] = useState("");
    const [tblOrderNum, setTblOrderNum] = useState("");

    const [tabelsLocationsReq, setTabelsLocationsReq] = useState([]);
    const [specialsListReq, setSpecialsListReq] = useState([]);
    const [cancelReason, setCancelReason] = useState("");


    const [ordrApprove, setOrdrApprove] = useState(false);
    const [theDispTableOrder, setTheDispTableOrder] = useState({});

    const deskStyle = {
        st1: { width: "100%", margin: "10px auto", padding: "10px", border: "1px solid lightgray", borderRadius: "15px" },
        st2: { width: "calc(100% - 10px)", margin: "10px auto", padding: "10px", border: "1px solid lightgray", borderRadius: "15px" },
        st3: { width: "100%", margin: "10px auto", padding: "10px" }
    }
    const mobiStyle = {
        st1: { width: "100%", margin: "10px auto", padding: "10px", border: "1px solid lightgray", borderRadius: "15px" },
        st02: { width: "100%", margin: "0 auto", padding: "10px", border: "1px solid lightgray", borderRadius: "15px" },
        st2: { width: "100%", margin: "10px auto", padding: "10px", border: "1px solid lightgray", borderRadius: "15px" },
        st3: { width: "90%", margin: "10px auto", padding: "10px" }
    }

    useEffect(() => {

        const getTheTableOrder = async () => {
            let thord = await utilsTblOrders.getTableOrder(props.ordId);
            setDinners(thord.dinners);
            setBuisSelected(thord.buisId)
            setDateToCome(new Date(thord.dateToCome));
            setTimeToCome(thord.timeToCome);
            setClientName(thord.clientName);
            setClientPhone(thord.clientPhone);
            setOrdererName(thord.ordererName);
            setRemarks(thord.remarks);
            setOrderStatus(thord.status);
            setTblOrderNum(thord.tblOrderNum);
            setTheDispTableOrder(thord);
            setTabelsLocationsReq(thord.tabelsLocationsReq);
            setSpecialsListReq(thord.specialsListReq);
            setCancelReason(thord.cancelReason);
        }

        getTheTableOrder();

    }, [])

    useEffect(() => {

        setOrdrApprove(false)
        setErr("")
    }, [buisSelected, clientName, clientPhone, dinners,
        timeToCome, dateToCome, orderStatus, remarks])



    const saveMe = () => {

        if (updaterName === "") {
            setThePopUp(<InputSet
                Title={"עובד מעדכן"}
                ContentText={"מה שמך?"}
                inLabel=""
                okClick={(nam) => { setTheNameAndSaveMe(nam) }}
                cancelClick={() => setThePopUp()} />)
        }
        else { saveIt(updaterName) }

    };

    const setTheNameAndSaveMe = (nam) => {
        setUpdaterName(nam);
        saveIt(nam);
        setThePopUp()
    }

    const saveIt = async (updtrNam) => {

        let rep = "";
        if (buisSelected === null || buisSelected === "") { rep = " יש לבחור מסעדה" }
        else if (dinners === null || dinners === "") { rep = " יש לציין מספר סועדים" }
        else if (timeToCome === null || timeToCome === "") { rep = " יש לציין שעת הגעה" }
        else if (dateToCome === null || dateToCome === "") { rep = " יש לציין תאריך הגעה" }
        else if (clientName === null || clientName === "") { rep = " יש לציין שם לקוח" }
        else if (clientPhone === null || clientPhone === "") { rep = " יש לציין טלפון לקוח" }


        if (rep === "") {
            // props.okClick(inputTxt);

            let objToUpdate = {
                buisId: buisSelected,
                dinners: dinners,
                timeToCome: timeToCome,
                dateToCome: dateToCome,
                clientName: clientName,
                clientPhone: clientPhone,
                status: orderStatus,
                remarks: remarks,
                ordererName: ordererName,
                lastUpdaterBy: updtrNam,
                tabelsLocationsReq: tabelsLocationsReq,
                specialsListReq: specialsListReq,
                cancelReason: cancelReason
            }

            let ordTbl = await utilsTblOrders.updateTableOrder(props.ordId, objToUpdate);

            checkIfItemExistsAndStatus({ _id: ordTbl._id, status: orderStatus });

            setErr(

                <>
                    <p>ההזמנה עודכנה בהצלחה, מספר הזמנה: {tblOrderNum}</p>
                    {
                        ordTbl.sendedSMS && orderStatus === "מאושר" && <p>הלקוח קיבל סמס בנוגע לאישור ההזמנה</p>
                    }
                    {
                        ordTbl.sendedSMS && orderStatus === "מבוטל" && <p>הלקוח קיבל סמס על ביטול הזמנה</p>
                    }
                </>
            )

            setOrdrApprove(true)

            // handleClose();
        }
        else {
            setErr(<p>{rep}</p>);
        }
    }

    const setNewOrderStatus = (newStatus, canclReason) => {
        setOrderStatus(newStatus)
        setCancelReason(canclReason)
    }

    const handleClose = () => {
        props.cancelClick();
    };

    return (
        <Dialog open={true} className={`orderPlaceBlockDiag popupZINDEX heightByComps ${mobiOrNot && "mobiPop"}`}>
            <DialogTitle>{props.Title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.ContentText}
                </DialogContentText>
                <div className={"orderPlaceBlock"}>
                    <h3 className="rightText">הזמנת שולחן {theDispTableOrder.tblOrderNum}</h3>
                    {
                        (user.permiss === "admin") ?
                            <>
                                <BuisSelect_ForTable key={props.ordId}
                                    firstHead={"כל העסקים"}
                                    clss={`${!mobiOrNot ? "buisSelectOnTBAR onLeft" : "buisSelectOnTBARMobi"}`}
                                    defaultValue={myFunctions.getBuisNameByIdInList(BuisListForTables, buisSelected)}
                                    userSelection={buisSelected ? buisSelected : -1}
                                    busissList={BuisListForTables}
                                    setUserBuis={(newVal) => { setBuisSelected(newVal) }}
                                />
                            </>
                            : ""
                    }
                    <br />

                    <div style={!mobiOrNot ? deskStyle.st1 : mobiStyle.st1}>
                        <h6 className={"bold"}>סועדים ומועד הגעה</h6>
                        <div className="row">
                            <div className="col-md-4">
                                <div>
                                    <DropNumbersSelection
                                        lbl="סועדים"
                                        Typ="text"
                                        Css="inputsDropDownStyle"
                                        Val={dinners}
                                        placeholder="מספר סועדים"
                                        settingFunc={(val) => setDinners(val)}
                                        maxNumb={50}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <DatePick
                                        lbl="לתאריך"
                                        settingFunc={(val) => setDateToCome(val)}
                                        Css="inputsStyle pointing"
                                        selectedDate={dateToCome}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <DropHouresSelection
                                        lbl="לשעה"
                                        Typ="text"
                                        Css="inputsDropDownStyle"
                                        Val={timeToCome}
                                        placeholder="לשעה"
                                        settingFunc={(val) => setTimeToCome(val)}
                                        fromHour={10}
                                        toHour={23}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={!mobiOrNot ? deskStyle.st1 : mobiStyle.st1}>
                        <h6 className={"bold"}>פרטי הלקוח</h6>
                        <div className="row">
                            <div className="col-md-4">
                                <div className={""}>
                                    <InputTextSimple
                                        lbl=""
                                        Typ="text"
                                        Css="inputsStyle"
                                        Val={clientName}
                                        placeholder="שם הלקוח"
                                        settingFunc={(val) => setClientName(val)}
                                    />

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className={""}>
                                    <InputTextSimple
                                        lbl=""
                                        Typ="text"
                                        Css="inputsStyle"
                                        Val={clientPhone}
                                        placeholder="טלפון"
                                        settingFunc={(val) => setClientPhone(val)}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 spaceTop">
                                <h6 className={"bold"}>הערות</h6>
                                <div className="col-md-12" style={{ padding: "0px" }}>
                                    <MyTextArea firstText={remarks}
                                        saveEditorTxt={(content) => { setRemarks(content) }}
                                        cssStyle={"inputsStyle"}
                                        styl={{ width: "100%" }}
                                        placeholder="הערות" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ margin: "10px auto" }}>
                        <div className="col-md-12 halfBlockWrap">
                            <div className="col-md-6 onRight halfBlock">
                                <div style={!mobiOrNot ? deskStyle.st2 : mobiStyle.st02}>
                                    <h6 className={"bold"}>העדפות שולחן</h6>
                                    <div>
                                        <p className={"noMargin"}>{tabelsLocationsReq.length > 0 ? tabelsLocationsReq.join(',') : "אין העדפות"}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 onRight halfBlock">
                                <div style={!mobiOrNot ? deskStyle.st2 : mobiStyle.st2}>
                                    <h6 className={"bold"}>מאפיינים מיוחדים</h6>
                                    <div>
                                        <p className={"noMargin"}>{specialsListReq.length > 0 ? specialsListReq.join(',') : "אין בקשות מיוחדות"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ margin: "10px auto" }}>
                        <div className="col-md-12 halfBlockWrap">
                            <div className="col-md-6 onRight halfBlock">
                                <div style={!mobiOrNot ? deskStyle.st2 : mobiStyle.st02}>
                                    <h6 className={"bold"}>סטטוס:</h6>
                                    <div className={"col-md-12"}>
                                        <TblOrderStatuselect inStyle={{
                                            width: '205px', height: '30px', marginTop: '12px'
                                        }}
                                            orderStatus={orderStatus}
                                            setOrderStatus={(statBack, cancelReason) => setNewOrderStatus(statBack, cancelReason)}
                                        />

                                    </div>
                                    {orderStatus === "מבוטל" && cancelReason &&
                                        <div className={"col-md-12"}>
                                            <p><span className='bold'>סיבת הביטול: </span><span>{cancelReason}</span></p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 onRight halfBlock">
                                <div style={!mobiOrNot ? deskStyle.st2 : mobiStyle.st2}>

                                    <div className={"col-md-12 justify-content-center"}>

                                        <p><span className='bold'>במקור הוזמנה על ידי: </span><span>{theDispTableOrder.ordererName}</span></p>
                                        <p><span className='bold'>מועד ההזמנה: </span><span>{MyDateTime.getDateAndTime(theDispTableOrder.dateCreated)}</span></p>
                                        <p><span className='bold'>עדכון אחרון בוצע על ידי: </span><span>{theDispTableOrder.lastUpdaterBy}</span></p>
                                        <p><span className='bold'>עדכון אחרון בוצע ב: </span><span>{MyDateTime.getDateAndTime(theDispTableOrder.lastUpdate)}</span></p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    {err !== "" &&
                        <div style={!mobiOrNot ? deskStyle.st3 : mobiStyle.st3}>
                            <div style={{ fontWeight: 'bold', textAlign: 'center' }} className={"inRed"}>
                                {err}
                            </div>
                        </div>
                    }
                    <div style={!mobiOrNot ? deskStyle.st3 : mobiStyle.st3}>
                        {!ordrApprove ?
                            <div className={"btnstblOrdrs overflowHid"}>
                                <button type="button" className="onLeft btn btn-primary spaceMargeLeftbig" onClick={() => saveMe()}>שמור</button>
                                <button type="button" className="onLeft btn btn-danger" onClick={handleClose}>ביטול</button>

                            </div>
                            :
                            <div className={"btnstblOrdrs overflowHid"}>
                                <button type="button" className="onLeft btn btn-primary spaceMargeLeftbig" onClick={() => { props.ordrApproved() }}>סגור</button>
                            </div>
                        }
                    </div>
                </div>

                {thePopUp}

            </DialogContent>

        </Dialog >
    );
}

export default ShowTableOrder;