import utilsRests from "../../../../../MyConns/_Utils/utilsRests";
import OrderFunctions from "../../../../../MyFunctions/OrderFunctions";


const getTheTotOfCreditcard = (OrdrsBk) => {
    let totClear = 0;
    let totCancels = 0;
    OrdrsBk.forEach(element => {
        if (element.payt !== "2") {
            if (element.orderStatus !== "מבוטל") {
                totClear += parseFloat(OrderFunctions.getOrderTotalPrice(element));
            } else {
                totCancels += parseFloat(OrderFunctions.getOrderTotalPrice(element));
            }
        }
    });

    return ({ totClear, totCancels })
}

const getTheTotOfCash = (OrdrsBk) => {
    let totClear = 0;
    let totCancels = 0;
    OrdrsBk.forEach(element => {
        if (element.payt === "2") {
            if (element.orderStatus !== "מבוטל") {
                totClear += parseFloat(OrderFunctions.getOrderTotalPrice(element));
            } else {
                totCancels += parseFloat(OrderFunctions.getOrderTotalPrice(element));
            }
        }
    });

    return ({ totClear, totCancels })
}

const getTheTotal = (OrdrsBk) => {
    let totClear = 0;
    let totCancels = 0;
    OrdrsBk.forEach(element => {
        if (element.orderStatus !== "מבוטל") {
            totClear += parseFloat(OrderFunctions.getOrderTotalPrice(element));
        } else {
            totCancels += parseFloat(OrderFunctions.getOrderTotalPrice(element));
        }
    });

    return ({ totClear, totCancels })
}




const sumToPass = async (ordrs, buissId) => {
    let totOfCreditcard = getTheTotOfCreditcard(ordrs)
    let totOfAll = getTheTotal(ordrs)

    const getThePrec = async (buissId) => {
        let py = await utilsRests.getRestPayPrecent(buissId)
        return parseInt(py.payPrecent !== '' ? py.payPrecent : '7')
    }

    let payPrecent = await getThePrec(buissId);

    return ({
        totWithCrdit:
            parseInt(totOfCreditcard.totClear -
                (
                    (totOfAll.totClear * payPrecent / 100) +
                    ((totOfAll.totClear * payPrecent / 100) * 0.17)
                ))
        ,
        percantToPass:
            parseInt((totOfAll.totClear * payPrecent / 100) +
                ((totOfAll.totClear * payPrecent / 100) * 0.17))
    })
}

const getTheTotOfDelivery = (OrdrsBk) => {
    let totClear = 0;
    let totCancels = 0;
    OrdrsBk.forEach(element => {
        if (element.OrderType === "Deliver") {
            if (element.orderStatus !== "מבוטל") {
                totClear += parseFloat(OrderFunctions.getOrderTotalPrice(element));
            } else {
                totCancels += parseFloat(OrderFunctions.getOrderTotalPrice(element));
            }
        }
    });

    return ({ totClear, totCancels })
}

const getTheTotOfTakaway = (OrdrsBk) => {
    let totClear = 0;
    let totCancels = 0;
    OrdrsBk.forEach(element => {
        if (element.OrderType != "Deliver") {
            if (element.orderStatus !== "מבוטל") {
                totClear += parseFloat(OrderFunctions.getOrderTotalPrice(element));
            } else {
                totCancels += parseFloat(OrderFunctions.getOrderTotalPrice(element));
            }
        }
    });

    return ({ totClear, totCancels })
}

export default {
    getTheTotOfCreditcard,
    getTheTotOfCash,
    getTheTotal,
    sumToPass,
    getTheTotOfDelivery,
    getTheTotOfTakaway
}