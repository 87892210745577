import React, { useEffect } from 'react';
import Topnavigation from '../../../../layouts/Topnavigation';
import Sidenavigation1 from '../../../../layouts/Sidenavigation1';

import Content from './Content';
import { TheMasterContextProvider } from '../../../../MyContexts/Master_Context';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';
import Translator from '../../../../../MyFunctions/Translator';

const InvoiceMobiTable = (props) => {

    useEffect(() => {
    }, [])

    //מציג את כל הנלווים של המוצר
    const getSpecials = (item) => {
        return (item.specials.length > 0) ?
            item.specials.map((special, specialIndex) => {

                let specCount = 0;
                let specc = special.spec.map((spec, specIndex) => {

                    if (spec.addToPrice > -1) {
                        specCount++;

                        return (
                            <div key={specIndex} className={"ordItmLine"}>
                                <span className={"rSideFirst"}></span>
                                <span className={"rSideMiddle"}>                               
                                    {OrderFunctions.conten(spec, item)}
                                    <span className={"regular"}> {spec.servType}</span>

                                </span>
                                <span className={"bold lSideSmall"}>
                                    {spec.addToPrice > 0 ? "₪" + (parseInt(spec.addToPrice) * parseInt(item.mount)) : ""}
                                </span>
                            </div>
                        )
                    }
                })
                if (specCount > 0) {
                    return (
                        <div key={specialIndex} className={"ordItmSpecLine spacePad"}>
                            <div className={"ordItmOptHead overflowHid bold"} >{special.optionName}</div>
                            {specc}
                        </div>
                    )
                }


            })
            : "";

    }
    const getSaucesLst = () => {
        let rtrn = props.theOrder.ord.StickedSauces?.map((sauce, indx) => {
            return (
                <div key={indx} className={"sucItem"}>

                    <span className={"rSideMiddle"}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={"tdmin10"}>
                                        <span className={"engFont ordItemMount"}>X&nbsp;</span><span className={"ordItemMount"}>
                                            {sauce.mount}&nbsp;</span>
                                    </td>
                                    <td>
                                        <span className={"ordItemMount"}> {sauce.servType}</span>
                                    </td>
                                    <td>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </span>
                </div>
            )

        });
        return rtrn
    }

    let sucs = OrderFunctions.getStickedSauces(props.theOrder.ord)
    let saucesPrice = (sucs > 0) ?
        sucs + "  ₪" :
        ""

    //רטבים מוצמדים מוזמנים
    let stickedSauces = (props.theOrder.ord.StickedSauces?.length > 0) ?

        <tr key={"sucess"}>
            <td className="centerText bold">
                {props.theOrder.ord.StickedSauces.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.mount;
                }, 0)}

            </td>
            <td className="textRight bold">
                <span className={"bold fullRow "}>רטבים מוצמדים</span>
                <span className={"veryVerySmall"}>
                    &nbsp;{(props.theOrder.ord.freeSaucess > 0) ? `(${props.theOrder.ord.freeSaucess} ללא עלות)` : ""}
                    {getSaucesLst(props.theOrder.ord.StickedSauces || [])}
                </span>

            </td>
            <td className="textRight"></td>
            <td className="bold"></td>
            <td className="textRight bold">
                {saucesPrice}
            </td>
        </tr>

        : ""

    //מספר סועדים, צופסטיקס וסכום
    let DinersAndChops = (props.theOrder.ord.Dinners > 0) ?

        <tr key={"dnrs"}>
            <td className="centerText bold">
                {props.theOrder.ord.Dinners}
            </td>
            <td className="textRight bold">
                <span className={"bold fullRow "}>סועדים</span>
                <span className={"veryVerySmall"}>
                    <br />
                    {`${(props.theOrder.ord.getfANife) ? "סכום: כן" : ""}`}
                    <br />
                    {`${(props.theOrder.ord.getChupstiks) ? "צופסטיקס: כן" : ""}`}
                </span>

            </td>
            <td className="textRight"></td>
            <td className="bold"></td>
            <td className="textRight bold">
                {/* {OrderFunctions.getStickedSauces(props.theOrder.ord) > 0 ?
              OrderFunctions.getStickedSauces(props.theOrder.ord) + "  ₪" :
              ""} */}
            </td>
        </tr>

        : ""

    return (
        <div className="col-md-12 overflowHid ordertblBody">

            {/* Invoice Table */}
            <div className="ms-invoice-table table-responsive">
                <div className="table table-hover text-right thead-light">

                    <div>
                        {props.theOrder.ord?.Orders.map((item, i) => {

                            return (
                                <div key={i} className="invoiceMobiTblBlock">
                                    <div className="textRight bold big">
                                        {item.productName}
                                        <br />
                                        <div className="textRight onlyOnPrint">{OrderFunctions.theItemSpecials(item)}</div>
                                    </div>
                                    <div className={"fullRow"}>
                                        <div className="textRight onRight big">{props.arabic ? `${Translator.getInArab("כמות")}:` : "כמות:"}</div>
                                        <div className="textRight onRight bold spaceMargeRightbig big">{item.mount}</div>
                                        <div className="textRight onLeft bold " style={{ direction: 'ltr' }}>₪{item.mount > 1 ? parseFloat(item.prodPrice) * item.mount : parseFloat(item.prodPrice)}</div>
                                        <div className="textRight onLeft spaceMargeLeftbig"></div>
                                    </div>

                                    <div className="textRight">{(item.Remarks) ?
                                        <>
                                            <div>{OrderFunctions.theItemSpecials(item)}</div>
                                            <br />
                                            <div className="textRight bold" >{props.arabic ? Translator.getInArab("הערה") : "הערה"}: {item.Remarks}</div>
                                        </>
                                        : OrderFunctions.theItemSpecials(item)}</div>

                                    <div className={"fullRow textLeft"}>
                                        <div className="textLeft onLeft bold" style={{ direction: 'ltr' }}>₪{OrderFunctions.getProdPriceWithSPecials(item)}</div>
                                        <div className="textLeft onLeft spaceMargeLeftbig">{props.arabic ? Translator.getInArab(`סה"כ`) : `סה"כ`}</div>

                                    </div>

                                    <div className={"dottedLine spaceButtomBig"}></div>

                                </div>

                            )
                        })}
                        {stickedSauces}
                        {DinersAndChops}
                        {(props.theOrder.ord?.OrderType === "Deliver") ?
                            <div className="invoiceMobiTblBlock">
                                <div className={"fullRow"}>
                                    <div className="textRight onRight">{props.arabic ? Translator.getInArab("משלוח") : "משלוח"}</div>
                                    <div className="textRight onRight bold spaceMargeRightbig"></div>
                                </div>
                                <div className={"fullRow textLeft"}>
                                    <div className="textLeft onLeft  bold">₪{props.theOrder.ord?.OrderDelPrice}</div>
                                    <div className="textLeft onLeft spaceMargeRightbig">{props.arabic ? Translator.getInArab(`סה"כ`) : `סה"כ`}</div>
                                </div>
                                <div className={"dottedLine spaceButtomBig"}></div>

                            </div>

                            : ""}


                        {(props.theOrder.ord.orderKopon && props.theOrder.ord.orderKopon != "") &&
                            <div className="invoiceMobiTblBlock">
                                <div className={"fullRow"} key={props.theOrder.ord?.Orders.length + 1}>

                                    <div className="textRight onRight">{props.arabic ? Translator.getInArab("קופון") : "קופון"} {props.theOrder.ord.orderKopon.Name} </div>
                                    <div className="textLeft onLeft  bold">{props.theOrder.ord.orderKopon.KoponDiscount}{props.theOrder.ord.orderKopon.KoponDiscType === '1' ? "₪" : "%"}</div>
                                    <div className={"dottedLine spaceButtomBig"}></div>
                                </div>
                            </div>
                        }


                        {(props.theOrder.ord.orderZikoy && props.theOrder.ord.orderZikoy != "") &&
                            props.theOrder.ord.orderZikoy.map((element, index) => {
                                return (
                                    <div className="invoiceMobiTblBlock">
                                        <div className={"fullRow"} key={`zikoy_${index}`}>
                                            <div className="textRight onRight">{props.arabic ? Translator.getInArab("זיכוי") : "זיכוי"} {element.Name} </div>
                                            <div className="textLeft onLeft  bold">₪{element.zickoyPrice}-</div>

                                        </div>
                                        <div className={"dottedLine spaceButtomBig"}></div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <br />
                    <br />
                    <div className={"fullRow"}>
                        <div className="bold veryBig textLeft onLeft">{(props.theOrder.ord != null) ? `₪${OrderFunctions.getOrderTotalPrice(props.theOrder.ord)}` : ""} </div>
                        <div colSpan={4} className="bold veryBig textLeft onLeft spaceMargeLeftbig">{props.arabic ? Translator.getInArab(`סה"כ`) : `סה"כ`}: </div>

                    </div>
                    <div className={"fullRow"}>
                        <div className="bold veryBig textLeft onLeft ">{props.arabic ? Translator.getInArab(OrderFunctions.payingTYpe(props.theOrder)) : OrderFunctions.payingTYpe(props.theOrder)} </div>
                        <div colSpan={4} className="bold textLeft veryBig onLeft spaceMargeLeftbig">{props.arabic ? Translator.getInArab("אופן תשלום") : "אופן תשלום"}:</div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceMobiTable;