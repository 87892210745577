import React, { Component, useContext, useEffect, useState } from 'react';
import MyDateTime from '../TimeSections/MyDateTime';
import Button from '@mui/material/Button';
import { DoneOutlined } from '@mui/icons-material';
import utilsRests from '../../../MyConns/_Utils/utilsRests';
import { RestContext } from '../../MyContexts/Rest_Context';
import SucessToast from '../Toasts/SucessToast';
import InfoToast from '../Toasts/InfoToast';

const CloseDelay = (props) => {

    const [rest] = useContext(RestContext);

    const [delaydVal, setDelaydVal] =
        useState((props.closeDelay.delaydVal !== null &&
            props.closeDelay.delaydVal !== "" &&
            props.closeDelay.delaydVal !== undefined) ?
            props.closeDelay.delaydVal : "0");

    const [delayCnt, setDelayCnt] =
        useState((props.closeDelay.delayCnt !== null &&
            props.closeDelay.delayCnt !== "" &&
            props.closeDelay.delayCnt !== undefined) ?
            props.closeDelay.delayCnt : "");

    const [times, setTimes] = useState(["00:00:00"]);
    const [intervalId, setIntervalId] = useState(null);
    const [blockDelayColor, setBlockDelayColor] = useState(false)

    //מפעיל טיימר לדיליי במידה ומוגדר
    useEffect(() => {
      
        if (delaydVal !== "0") {
            // let delayCnt = getCounterDelay(closeDelay)
            getTimes(delayCnt)
        }
    }, []);


    // Clear the interval when the component is unmounted
    useEffect(() => {
        return () => {
            if (intervalId !== null) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);


    //נבחר מועד תזמון
    //רק אם כפתור ביטול השהייה נלחץ 
    //update server == true
    //אם לא והטיימר הסתיים, השרת יודע לעדכן לבד.
    const handleChange = (trValue, updateServer) => {
        if (trValue !== "0") {
            let theDelayCnt = getCounterDelay(trValue)
            getTimes(theDelayCnt)
            setDelayCnt(theDelayCnt)
            setDelaydVal(trValue)
            props.delayUpdate({ delaydVal: trValue, delayCnt: theDelayCnt })
            if (trValue !== delaydVal && updateServer) {
                fastRestUp({ delaydVal: trValue, delayCnt: theDelayCnt });
            }
        }
        else {
            setDelaydVal(trValue)
            setDelayCnt("")
            props.delayUpdate({ delaydVal: trValue, delayCnt: "" })
            if (trValue !== delaydVal && updateServer) {
                fastRestUp({ delaydVal: trValue, delayCnt: "" });
            }

        }

    }

    //מעדכן השהיות ישירות לדאטא בייס
    const fastRestUp = async (obj) => {

        let rstLst = await utilsRests.setDelayClosing(rest._id || rest.id, props.typeToDelay, obj);

        let msbk = props.typeToDelay === "closeDeliverDelay" ? "משלוחים" : "איסופים";
        msbk = obj.delaydVal !== "0" ? "השהיית ה" + msbk + " החלה" : "השהיית ה" + msbk + " הסתיימה";

        if (rstLst.msg) {
            InfoToast({ position: "toast-bottom-right", text: msbk });

        }

    }

    //מושך את התזמון לדיליי לפי בחירת המשתמש
    const getCounterDelay = (val) => {
        const now = new Date();

        if (val === "1") {
            const thirtyMinutesLater = new Date(now.getTime() + 30 * 60000);
            return thirtyMinutesLater;
        } else if (val === "2") {
            const sixtyMinutesLater = new Date(now.getTime() + 60 * 60000);
            return sixtyMinutesLater;
        } else if (val === "3") {
            const ninetyMinutesLater = new Date(now.getTime() + 90 * 60000);
            return ninetyMinutesLater;
        } else if (val === "4") {
            const oneHundredTwentyMinutesLater = new Date(now.getTime() + 120 * 60000);
            return oneHundredTwentyMinutesLater;
        } else if (val === "5") {
            const tomorrowAt5AM = new Date(now);
            tomorrowAt5AM.setDate(now.getDate() + 1);
            tomorrowAt5AM.setHours(5, 0, 0, 0);
            return tomorrowAt5AM;
        } else if (val === "6") {
            const nextSundayAt5AM = new Date(now);
            nextSundayAt5AM.setDate(now.getDate() + (7 - now.getDay() + 1) % 7);
            nextSundayAt5AM.setHours(5, 0, 0, 0);
            return nextSundayAt5AM;
        }

        return now;
    }

    //יוצר טיימר ספירה לאחור עד למועד פקיעת הדיליי
    const getTimes = (valOfIt) => {
        if (intervalId !== null) {
            clearInterval(intervalId);
            setIntervalId(null);
        }

        if (valOfIt !== "") {
            let tick = 0;
            const newIntervalId = setInterval(() => {
                tick++;
                const timeLeft = MyDateTime.getTimeLeftTilThen(valOfIt, new Date());
                let tempTm = timeLeft.theTm;
                if (timeLeft.theTm.split(":").length < 2) {
                    tempTm = "00:00:" + tempTm;
                }
                else if (timeLeft.theTm.split(":").length < 3) {
                    tempTm = "00:" + tempTm;
                }
                setTimes(tempTm);
                if (tick == 8) {
                    setBlockDelayColor((prevBlockDelayColor) => !prevBlockDelayColor);
                    tick = 0;

                }

                // Check if the time is up, then clear the interval
                if (timeLeft.theEnd) {
                    clearInterval(newIntervalId);
                    setIntervalId(null);
                    handleChange("0", false)

                    let msbk = props.typeToDelay === "closeDeliverDelay" ? "משלוחים" : "איסופים";
                    msbk = "השהיית ה" + msbk + " הסתיימה";
                    InfoToast({ position: "toast-bottom-right", text: msbk });
                }
            }, 100);

            setIntervalId(newIntervalId);
        }

    }

    return (
        <>

            {(delaydVal !== "0") ?
                <div className={"col-md-6 onRight radiusBord-10"}
                    style={{ backgroundColor: `${blockDelayColor ? "White" : "Orange"}` }}>

                    <div className={"centerText"}>מושהה לעוד</div>
                    <div className={"centerText bold"}> {times} </div>
                    <div className={"centerText"}>
                        <Button className={"releaseDelayBtn"} variant="contained"
                            onClick={() => handleChange("0", true)}
                            startIcon={<DoneOutlined style={{ color: 'white' }} />}>
                            שחרר השהייה
                        </Button>
                    </div>
                </div>
                :
                <div className={"col-md-6 onRight noPaddOnMobile"}>
                    <div className={"CloseDelaydrp"} >
                        <select
                            className={`${props.onLeft} selectInBlock`}
                            value={delaydVal}
                            onChange={(e) => handleChange(e.target.value, true)}>
                            <option value={"0"}>השהייה</option>
                            <option value={"1"} >לחצי שעה</option>
                            <option value={"2"}>לשעה</option>
                            <option value={"3"}>לשעה וחצי</option>
                            <option value={"4"} >לשעתיים</option>
                            <option value={"5"} >לכל היום</option>
                            <option value={"6"} >עד תחילת השבוע הקרוב</option>
                        </select>
                    </div>
                </div>
            }

        </>
    );
}

export default CloseDelay;