
const refreshTheTblList = (btnInt) => {

    const functionMap = {
        5: 'waitingForApprov',
        6: 'showTodaysAll ',
        7: 'showTomorowAll',
        8: 'showYesterdayAll',
        9: 'showMounth',
        10: 'setShowBetweenDts',
        11: 'showByDates',
        12: 'showAllOpen',
        13: 'showAllOrders',
        14: 'fromTodayToEnd'
    };
    sessionStorage.setItem("ordTblLstBtn", btnInt)
    return functionMap[btnInt];


}
export { refreshTheTblList };