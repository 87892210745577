
import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card, Nav, Tab } from "react-bootstrap";
import { useParams } from 'react-router';
import Breadcrumb from './Breadcrumb'
// import TabBuisDetails from './parts/TabBuisDetails';
// import TabImages from './parts/TabImages';
// import TabMaps from './parts/TabMaps';
// import TabNetwork from './parts/TabNetwork';
// import TabSales from './parts/TabSales';
// import TabPageDetails from './parts/TabPageDetails';
// import TabOpeningHours from './parts/TabOpeningHours';

import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import { HomePContext } from '../../MyContexts/HomeP_Context';

import { useHistory } from "react-router-dom";
import TabPageDetails from '../compsForAllPages/TabPageDetails';
import RichTextComp from '../../MySections/TextEditor/RichText';
import SelectImgComp from '../../MySections/ImagesSetter/SelectImgComp';
import SucessToast from '../../MySections/Toasts/SucessToast';
import EraseCheck from '../../MySections/PopUps/EraseCheck';
import utilsLandingPage from '../../../MyConns/_Utils/utilsLandingPage';
// import BasicPopUp from '../../MySections/PopUps/BasicPopUp';
// import SucessToast from '../../MySections/Toasts/SucessToast';
// import TabPageDetails from '../compsForAllPages/TabPageDetails';
// import TabHPDetails from './parts/TabHPDetails';
// import TabMainRests from './parts/TabMainRests';
// import TabFavoRests from './parts/TabFavoRests';


const LandPageP = (props) => {
    const history = useHistory();

    const [currSingle, setCurrSingle] = useState();
    const [loading, setLoading] = useState(true);

    let params = useParams();
    const [thePopUp, setThePopUp] = useState("");
    const [mainTitle, setMainTitle] = useState("");
    const [addressLine, setAddressLine] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [wahtsUpNum, setWahtsUpNum] = useState("");

    const [fax, setFax] = useState("");
    const [subj, setSubj] = useState("");

    const [mainImage, setMainImage] = useState("");
    const [mainContent, setMainContent] = useState("");
    const [pageName, setPageName] = useState("");
    const [inLinkToPage, setInLinkToPage] = useState("");
    const [typeOfLandPage, setTypeOfLandPage] = useState();

    const [linkChanged, setLinkChanged] = useState(false);



    useEffect(() => {
        if (props.newp) {
            createNewP();
        }
        else {
            getSinglePage();
        }
    }, [])

    const createNewP = async () => {
        //יוצר עמוד חדש
        let theSingle = await utilsLandingPage.createLandingPage();
        if (theSingle != null) {
            props.upLandingList(theSingle)
        
            history.push(theSingle.inLinkToPage)
        }
    }
    const tempUpdate = (upRest) => {
        // setHomeP(upRest);

    }

    const getSinglePage = async () => {

        let theSingle = await utilsLandingPage.getLandingPageById(props.pageId);
        setTypeOfLandPage(theSingle.typeOfLandPage || "בחר")

        setCurrSingle(theSingle);
        setLoading(false)

    }


    const updateTheLandingP = async () => {

        if (currSingle._id != null) {

            let theSingle = await utilsLandingPage.updateLandingP(currSingle);

            if (linkChanged) {
                document.location.href = currSingle.inLinkToPage;
            }
            else {
                SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
            }
        }

    }

    const removeSinglePage = () => {
        if (currSingle._id != null) {
            setThePopUp(<EraseCheck title={'האם בטוח למחוק את העמוד?'} sucecssFunc={() => removePageConfirmd(currSingle._id)} />)
        }
    }

    const removePageConfirmd = async () => {
        let theSingle = await utilsLandingPage.deleteLandingPage(currSingle._id);
        history.push('/');

    }

    const updatePDetails = (key, val) => {
        currSingle[key] = val;
    }


    const handlePTypeChange = (e) => {
        setTypeOfLandPage(e.target.value)
        currSingle.typeOfLandPage = e.target.value;
    }



    return (
        <div className="ms-content-wrapper SetBlock">
            <Breadcrumb />
            {/* Active Orders Graph */}
            <div className="row">
                <div className="col-md-12">
                    {loading ? "loading" :

                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <div className="col-md-6 mb-6 onLeft">
                                    <button type="button" className="onLeft btn btn-primary" onClick={() => removeSinglePage()}>מחק</button>
                                </div>
                                <div className={"col-md-12 mb-6 onRight"}>
                                    <label className={"bold"}>שם העמוד</label>
                                    <div className={"input-group"}>
                                        <input type="text" className={"Big-form-control inputSPMain"}
                                            placeholder={"שם העמוד"} value={(currSingle.pageName) ? currSingle.pageName : ""}
                                            onChange={(e) => { currSingle["pageName"] = e.target.value; setPageName(e.target.value); setLinkChanged(true) }} />
                                    </div>
                                </div>

                                <div className={"col-md-12 mb-6 onRight"}>
                                    <label className={"bold"}>קישור</label>
                                    <div className={"input-group"}>
                                        <input type="text" className={"Big-form-control inputSPMain"}
                                            placeholder={"קישור"} value={(currSingle.inLinkToPage) ? currSingle.inLinkToPage : ""}
                                            onChange={(e) => { currSingle["inLinkToPage"] = e.target.value; setInLinkToPage(e.target.value); setLinkChanged(true) }} />
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel-body clearfix">
                                    <Tab.Container defaultActiveKey="tab2">
                                        <Nav variant="tabs" className="nav nav-tabs d-flex nav-justified mb-2">
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab2">תוכן</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab1">פרטי העמוד</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>


                                            {/*תוכן*/}
                                            <Tab.Pane eventKey="tab2">
                                                <div className="form-row tabsInputs ">
                                                    <div className={"col-md-12 mb-6"}>
                                                        <label htmlFor={"validationCustom1_1"}>תבנית העמוד</label>
                                                        <div className={"input-group"}>
                                                            <select value={typeOfLandPage}
                                                                onChange={handlePTypeChange}>
                                                                <option value={"בחר"}>בחר</option>
                                                                <option value={"appsLinksePage"}>קישורי האפליקציות</option>
                                                                <option value={"test"}>בדיקה</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className={"col-md-3 mb-6"}>
                                                        <label htmlFor={"validationCustom1_6"}>תמונת רקע</label>
                                                        <span className={"explanation mediumst fullROw"}></span>
                                                        <SelectImgComp firstImgToDisp={currSingle.mainImage ? currSingle.mainImage : ""}
                                                            theFunction={(fUrl) => { currSingle["mainImage"] = fUrl; setMainImage(fUrl) }} />

                                                    </div>

                                            </Tab.Pane>

                                            {/* פרטי העמוד */}
                                            <Tab.Pane eventKey="tab1">
                                                <div className="form-row tabsInputs ">
                                                    <TabPageDetails pgToSet="pgPageDetails" thePage={currSingle} thePageUpdate={(key, val) => updatePDetails(key, val)} />
                                                </div>
                                            </Tab.Pane>

                                        </Tab.Content>
                                    </Tab.Container>
                                </div>



                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="ms-panel-header approveLine">
                <button type="button" className="onLeft btn btn-primary" onClick={() => updateTheLandingP()}>שמור</button>
            </div>
            {thePopUp}
        </div>
    );
}



export default LandPageP;