import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PopUpMsg = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState((props.errorMsg === "") ? false : true);

    useEffect(() => {

    }, [])

    const saveMe = () => {
        props.okClick();
        handleClose();

    };


    const handleClose = () => {
        // props.cancelClick();
    };

    return (
        <Dialog open={open} onClose={handleClose} className={`textSizeDiag popDiagBlock ${props.cssStyl ? props.cssStyl : ""}`}>
            <DialogTitle className={"theTitlePop"}>
                {props.Title.map((txtItm, index) => {
                    return (
                        <span key={index} >{txtItm}</span>
                    )
                })}</DialogTitle>
            <DialogContent>

                 <DialogContentText component="div">
                    {props.ContentText.map((txtItm, index) => {
                        return (
                            <span key={index}>{txtItm}<br /></span>
                        )
                    })}
                </DialogContentText>

                {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}

            </DialogContent>
            <DialogActions >

                <button type="button" className="centerMe btn btn-success btnPopRegular pointing" onClick={() => { props.okClick(); handleClose(); }}>
                    {(props.BtnTxt != null) ? props.BtnTxt : "המשך"}</button>
            </DialogActions>
            <DialogActions >
                {props.btnClick2 != null ?
                    <>
                        <span>&nbsp;&nbsp;</span>
                        <button type="button" className="btn-success btnPopRegular btnPopRegularSec pointing" onClick={() => { props.btnClick2(); handleClose(); }}>
                            {(props.BtnTxt2 != null) ? props.BtnTxt2 : "ביטול"}</button>
                    </>
                    : ""}
            </DialogActions>
        </Dialog >

    );
}

export default PopUpMsg;