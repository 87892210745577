import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card, Nav, Tab } from "react-bootstrap";
import { useParams } from 'react-router';
import utilsHomePage from '../../../MyConns/_Utils/utilsHomePage';
import Breadcrumb from './Managment-list/Breadcrumb'


import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import { HomePContext } from '../../MyContexts/HomeP_Context';

import { useHistory } from "react-router-dom";

import SucessToast from '../../MySections/Toasts/SucessToast';
// import TabPageDetails from '../compsForAllPages/TabPageDetails';
// import TabHPDetails from '../parts/TabHPDetails';
// import TabMainRests from '../parts/TabMainRests';
// import TabFavoRests from '../parts/TabFavoRests';
import TabSiteDetails from './Managment-list/TabSiteDetails';
import { TheMasterContext } from '../../MyContexts/Master_Context';
import utilsMaster from '../../../MyConns/_Utils/utilsMaster';
import TabSiteLinks from './Managment-list/TabSiteLinks';
import utilsLinks from '../../../MyConns/_Utils/utilsLinks';
import { LinksContext } from '../../MyContexts/Links_Context';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';



const ManagmentPSet = (props) => {
    const history = useHistory();

    const [theMaster, setTheMaster] = useContext(TheMasterContext);
    const [Links, setLinks] = useContext(LinksContext);

    const [loading, setLoading] = useState(false);
    let idParam = useParams();
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {

    }, [])

    const tempUpdate = (upRest) => {

    }

    const getMaster = async () => {

        let logo = theMaster.webSiteLogo;
        setLoading(false);
    }


    const updateMaster = async () => {
        let mst = await utilsMaster.updateTheMaster(theMaster);
        // setRest(rstLst);
        setTheMaster(mst)

        let lnks = await utilsLinks.updateLinks(Links);


    SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
}








return (
    <div className="ms-content-wrapper SetBlock">
        <Breadcrumb />
        {/* Active Orders Graph */}
        <div className="row">
            <div className="col-xl-12">
                <div className="ms-panel">
                    <div className="ms-panel-header">
                        <div><h6>הגדרות</h6></div>
                        <div className={"buisTollBar"}>


                        </div>
                    </div>
                    {loading ? <MySpinner_Wait />
                        : 
                        <div className="col-md-12">

                            <div className="ms-panel-body clearfix">
                                <Tab.Container defaultActiveKey="tab2">
                                    <Nav variant="tabs" className="nav nav-tabs d-flex nav-justified mb-2">
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab2">מסגרת</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tab3">קישורים</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                                    <Nav.Link eventKey="tab3">עסקים מקודמים</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="tab4">האהובים ביותר</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="tab1">פרטי העמוד</Nav.Link>
                                                </Nav.Item> */}
                                    </Nav>
                                    <Tab.Content>
                                        {/*מסגרת*/}
                                        <Tab.Pane eventKey="tab2">
                                            <div className="form-row tabsInputs ">
                                                <TabSiteDetails />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tab3">
                                            <div className="form-row tabsInputs ">
                                                <TabSiteLinks />
                                            </div>
                                        </Tab.Pane>
                                      
                                    </Tab.Content>
                                </Tab.Container>
                            </div>



                        </div>}
                </div>
            </div>
        </div>
        <div className="ms-panel-header approveLine">
            <button type="button" className="onLeft btn btn-primary" onClick={() => updateMaster()}>שמור</button>
        </div>
        {thePopUp}
    </div>
);
}


export default ManagmentPSet;