

const Arabic = (order) => {

    let tempoOrd = { ...order }
    tempoOrd.ord.Orders.forEach((elem, index) => {
        tempoOrd.ord.Orders[index].productName = getInArab(elem.productName);

        tempoOrd.ord.Orders[index].specials.forEach((special, ind) => {
            tempoOrd.ord.Orders[index].specials[ind].optionName = getInArab(special.optionName);

            tempoOrd.ord.Orders[index].specials[ind].spec.forEach((spec, indi) => {
                tempoOrd.ord.Orders[index].specials[ind].spec[indi].servType = getInArab(spec.servType);
            })



        })


    });

    return tempoOrd;

}

const getInArab = (theStr) => {

    let bk = theArabsTexts.findIndex(txt => txt.heb === theStr.trim());

    return (bk != -1) ? theArabsTexts[bk].arab : theStr;

}

const getInHeb = (theStr) => {

    let bk = theArabsTexts.findIndex(txt => txt.arab === theStr.trim());

    return (bk != -1) ? theArabsTexts[bk].heb : theStr;

}

const BackToHeb = (order) => {
    let tempoOrd = { ...order }

    tempoOrd.ord.Orders.forEach((elem, index) => {
        tempoOrd.ord.Orders[index].productName = getInHeb(elem.productName);

        tempoOrd.ord.Orders[index].specials.forEach((special, ind) => {
            tempoOrd.ord.Orders[index].specials[ind].optionName = getInHeb(special.optionName);
            tempoOrd.ord.Orders[index].specials[ind].spec.forEach((spec, indi) => {
                tempoOrd.ord.Orders[index].specials[ind].spec[indi].servType = getInHeb(spec.servType);
            })
        })


    });

    return tempoOrd;

}


let theArabsTexts = [
    { heb: "6 סוגי סלטים", arab: "6 أنواع من السلطات" },
    { heb: "צלחת חומוס פטריות", arab: "طبق فطر حمص" },
    { heb: "טורטייה אנטריקוט", arab: "انتركوت تورتيلا" },
    { heb: "רגיל (140 גר')", arab: "عادي (140 جم)" },
    { heb: "מוגדל (200 גר')", arab: "متضخم (200 جم)" },
    { heb: "טריפל (300 גר')", arab: "ثلاثي (300 جم)" },
    { heb: "מרובע (400 גר')", arab: "مربع (400 جم)" },
    { heb: "אבוקדו", arab: "افوكادو" },
    { heb: "חומוס", arab: "الحمص" },
    { heb: "צ'ימיצ'ורי", arab: "تشيميتشوري" },
    { heb: "חציל במיונז", arab: "باذنجان في مايونيز" },
    { heb: "סלסה", arab: "الصلصا" },
    { heb: "כרוב סגול", arab: "الملفوف الأرجواني" },
    { heb: "כרוב לבן", arab: "ملفوف أبيض" },
    { heb: "סלט עגבניות פיקנטי", arab: "سلطة طماطم حارة" },
    { heb: "חסה", arab: "خَسّ" },
    { heb: "חמוצים", arab: "مخللات" },
    { heb: "ליח'", arab: "وحدة" },
    { heb: "יחידה", arab: "وحدة" },
    { heb: "כמות", arab: "كمية" },
    { heb: "שם המוצר", arab: "اسم المنتج" },
    { heb: "נלווים", arab: "مصاحب" },
    { heb: "אופן תשלום", arab: "طرق الدفع" },
    { heb: "הערה", arab: "ملاحظات" },

    { heb: "מזומן", arab: "نقدا" },
    { heb: "אשראי", arab: "ائتمان" },
    { heb: 'סה"כ', arab: "مجموع" },
    { heb: "חריף", arab: "حار" },
    { heb: "בצל", arab: "بصل" },
    { heb: "בצל מטוגן", arab: "بصل مقلي" },
    { heb: "גודל המנה", arab: "حجم الحصة" },
    { heb: "סלטים לבחירה", arab: "السلطات للاختيار من بينها" },
    { heb: "רטבים לבחירה", arab: "اختيار الصلصات" },
    { heb: "צ'ילי מתוק", arab: "الفلفل الحلو" },
    { heb: "צ'ילי חריף", arab: "فلفل حار" },
    { heb: "רוטב שום", arab: "صلصة الثوم" },
    { heb: "אלף האיים", arab: "الألف جزيرة" },
    { heb: "ספייסי מיונז", arab: "مايونيز حار" },
    { heb: "שזיפים", arab: "برقوق" },
    { heb: "ברביקיו", arab: "حفل شواء" },
    { heb: "נפאלי", arab: "النيبالية" },
    { heb: "קטשופ", arab: "كاتشب" },
    { heb: "מיונז", arab: "مايونيز" },
    { heb: "תוספת בתשלום", arab: "رسوم اضافية" },
    { heb: "פטריות פורטובלו", arab: "فطر بورتوبيللو" },
    { heb: "ביצת עין", arab: "بيض مقلي" },
    { heb: "טורטייה אנטריקוט טלה", arab: "تورتيلا لحم الضأن" },
    { heb: "טורטייה פרגיות", arab: "تورتيلا برقيوت" },
    { heb: "טורטייה חזה עוף", arab: "تورتيلا صدر دجاج" },
    { heb: "טורטייה קציצות טלה", arab: "كرات لحم تورتيلا لحم ضأن" },
    { heb: "טורטייה מיקס", arab: "خليط التورتيلا" },
    { heb: "טורטייה שניצלונים", arab: "شنيتزل تورتيلا" },
    { heb: "טורטייה מרגז", arab: "تورتيلا ميرزا" },
    { heb: "טורטייה פורטובלו", arab: "بورتوبيلو تورتيلا" },
    { heb: "טורטייה חביתה", arab: "عجة تورتيلا" },
    { heb: "טורטייה סלטים", arab: "سلطة التورتيلا" },
    { heb: "צלחת אנטריקוט", arab: "لوحة انتريكوت" },
    { heb: "תוספת לבחירה", arab: "إضافة للاختيار من بينها" },
    { heb: "צ'יפס", arab: "بطاطس مقلية" },
    { heb: "צ'יפס בטטה", arab: "شبس بطاطا" },
    { heb: "טבעות בצל", arab: "حلقات البصل" },
    { heb: "כדורי פירה", arab: "كرات البطاطس المهروسة" },
    { heb: "הום פרייז", arab: "انفرايز" },
    { heb: "פוטטוס", arab: "بوتيتوس" },
    { heb: "ללא טורטיה", arab: "لا تورتيلا" },
    { heb: "תוספת טורטיה", arab: "تورتيلا اضافية" },
    { heb: "צלחת פרגיות", arab: "صحن برقيوت" },
    { heb: "צלחת מיקס", arab: "صحن للخلط" },
    { heb: "צלחת חזה עוף", arab: "طبق صدر دجاج" },
    { heb: "צלחת קציצות טלה", arab: "طبق من كرات لحم الضأن" },
    { heb: "צלחת שניצל", arab: "لوحة شنيتزل" },
    { heb: "צלחת מרגז", arab: "طبق ميرزا" },
    { heb: "תוספות בצד", arab: "إضافات على الجانب" },
    { heb: "רטבים בתשלום", arab: "الصلصات المدفوعة" },
    { heb: "שתיה קלה", arab: "مشروب غازي" },
    { heb: "פחית קוקה קולה", arab: "علبة كوكاكولا" },
    { heb: "פחית קולה זירו", arab: "علبة كوكاكولا زيرو" },
    { heb: "פחית ספרייט", arab: "علبة سبرايت" },
    { heb: "פחית פאנטה", arab: "علبة فانتا" },
    { heb: "פיוז טי אפרסק", arab: "فيوز شاي خوخ" },
    { heb: "מים בטעם אפרסק", arab: "ماء بنكهة الخوخ" },
    { heb: "מים בטעם ענבים", arab: "ماء بنكهة العنب" },
    { heb: "מים בטעם תפוח", arab: "مياه بنكهة التفاح" },
    { heb: "מים מינרלים", arab: "مياه معدنية" },
    { heb: "מאלטי בירה שחורה", arab: "البيرة السوداء الشعيرية" },
    { heb: "קוקה קולה גדול", arab: "كوكا كولا كبيرة" },
    { heb: "קולה זירו גדול", arab: "كوكاكولا زيرو كبيرة" },
    { heb: "נסטי גדול", arab: "بيج شرير" },
    { heb: "משלוח", arab: "شحن" },
    { heb: "רטבים לבחירה בצד (2 ללא עלות)", arab: "الصلصات على الجانب" },
    { heb: "רטבים לבחירה (2 ללא עלות)", arab: "الصلصات على الجانب" },
    { heb: "ליד המנה (2 ללא עלות)", arab: "بجانب الطبق" },


//שווארמה
    { heb: "פיתה", arab: "خبز البيتا" },
    { heb: "סוג בשר לבחירה (ניתן לשלב 2 סוגים)", arab: "نوع لحم قابل للاختيار (يمكن دمج نوعين)" },
    { heb: "עגל", arab: "عجل" },
    { heb: "הודו", arab: "ديك رومي" },
    { heb: "פרגיות", arab: "باذنجان" },
    { heb: "פרגית", arab: "باذنجان" },
    { heb: "ממרחים וסלטים בתוך המנה", arab: "مشروبات وسلطات في الطبق" },
    { heb: "חומוס", arab: "حمص" },
    { heb: "טחינה", arab: "طحينة" },
    { heb: "עמבה", arab: "الأميبا" },
    { heb: "סחוג", arab: "حار" },
    { heb: "עגבניה", arab: "طماطم" },
    { heb: "מלפפון", arab: "خيار" },
    { heb: "בצל", arab: "بصل" },
    { heb: "ירק", arab: "خضروات" },
    { heb: "כרוב לבן", arab: "ملفوف أبيض" },
    { heb: "בגט", arab: "باجيت" },
    { heb: "לאפה", arab: "ليخبز" },
    { heb: "סוג בשר לבחירה(ניתן לשלב 2 סוגים)", arab: "نوع لحم قابل للاختيار (يمكن دمج نوعين)" },
    { heb: "צלחת", arab: "صحن" },
    { heb: "חמגשית שווארמה - ניתן לבחור מיקס", arab: "صحن شاورما" },
    { heb: "סוג לחם לבחירה", arab: "نوع الخبز" },
    { heb: "שתיה", arab: "مشروب" },
    { heb: "פחית קולה", arab: "علبة كوكاكولا" },
    { heb: "פחית קולה זירו", arab: "علبة كوكاكولا زيرو" },
    { heb: "פחית ספרייט", arab: "علبة سبرايت" },
    { heb: "פחית ספרייט זירו", arab: "علبة سبرايت زيرو" },
    { heb: "פחית ספרינג מנגו", arab: "علبة سبرايت مانغو" },
    { heb: "פחית שוופס תות שדה", arab: "علبة فراولة شوفس" },
    { heb: "פחית ספרינג תפוח", arab: "علبة سبرايت تفاح" },
    { heb: "פחית ספרינג אפרסק", arab: "علبة سبرايت خوخ" },
    { heb: "קולה זכוכית", arab: "كوكاكولا زجاجة" },
    { heb: "קולה זירות זכוכית", arab: "كوكاكولا زيرو زجاجة" },
    { heb: "ספרייט זכוכית", arab: "سبرايت زجاجة" },
    { heb: "ספרייט זירו זכוכית", arab: "سبرايت زيرو زجاجة" },
    { heb: "קולה 1.5 ליטר", arab: "كوكاكولا 1.5 لتر" },
    { heb: "קולה זירו 1.5 ליטר", arab: "كوكاكولا زيرو 1.5 لتر" },
    { heb: "קופון", arab: "qasima" }

]



export default {
    Arabic,
    BackToHeb,
    getInArab

};