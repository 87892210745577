import React, { Component } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'


const SucessToast = (props) => {

    // toastr.remove();
    toastr.options.positionClass = props.position;
    if (props.timeOut) { toastr.options.timeOut = props.timeOut }

    toastr.success(props.text);
    return (
        <></>
    )
}

export default SucessToast;
