import master from "../_ApiConn/apiMaster";


const getMaster = async () => {
    const token = localStorage.getItem('token');
        let dFromF = await master.getMaster(token);
        return (dFromF.data[0]);
}

const updateTheMaster = async (obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await master.updateTheMaster(token, obj);
    return (dFromF.data);
}


const updateTheMasterByItm = async (itmToGet, obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await master.updateTheMasterByItm(itmToGet, token, obj);
    return (dFromF.data);
}



const setDelayOpening = async ( typeToDelay, obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await master.setDelayOpening( typeToDelay, obj, token);
    return (dFromF);
}


export default {
    getMaster,
    updateTheMaster,
    updateTheMasterByItm,
    setDelayOpening

};