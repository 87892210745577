import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'



const upOrCreate = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "buissTblsSet/upOrCreate", obj);
    return (dFromF.data);
}


const getBuissTableSettings = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "buissTblsSet/getSets", { params: obj });
    return (dFromF.data);
}


export default {
    upOrCreate,
    getBuissTableSettings
};