import React, { useContext, useEffect, useState } from 'react'
import { RestContext } from "../../../../../MyContexts/Rest_Context";
import Button from '@mui/material/Button';
import { Accordion, Card } from 'react-bootstrap';
import EraseCheck from '../../../../../MySections/PopUps/EraseCheck';
import { AddBoxOutlined } from '@mui/icons-material';
import InputDubleSet from '../../../../../MySections/PopUps/InputDubleSet';
import utilsSilo from '../../../../../../MyConns/_Utils/utilsSilo';
import AccordProdTextArea from './AccordProdTextArea';
import AccordProdTextInput from './AccordProdTextInput';
import AccordProdNumInput from './AccordProdNumInput';


const AccordSauces = (props) => {

    const [rest] = useContext(RestContext);
    const [sauces, setSauces] = useState(rest.Sauces != null ? rest.Sauces : [])
    const [saucesMakat, setSaucesMakat] = useState(rest.SaucesMakat != null ? rest.SaucesMakat : "")
    const [thePopUp, setThePopUp] = useState("");

    let optis = "";
    // let index = props.index;
    // let proIndex = props.proIndex;
    // let OptINdex = props.OptINdex;
    // let prodOpt = props.prodOpt;

  


    const changeProdOptDetPrice = (divIndex, newValue) => {

        let tempMnu = [...sauces];
        tempMnu[divIndex].addToPrice = newValue;
        setSauces(tempMnu)
        props.UpdateTheSaucess(tempMnu);
    }

    const changeProdOptCashRegMakat = (divIndex, newValue) => {

        let tempMnu = [...sauces];
        tempMnu[divIndex].cashRegMakat = newValue;
        setSauces(tempMnu)
        props.UpdateTheSaucess(tempMnu);
    }

    const changeSaucessMakat = (newValue) => {
    
        setSaucesMakat(newValue)
        props.UpdateSaucessMakat(newValue);
    }

  

    const removeDetOpti = (diviIndex) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את הרוטב?'} sucecssFunc={() => removeDetOption(diviIndex)} />)
    }

    const removeDetOption = (diviIndex) => {
        let tempMnu = [...sauces];
        tempMnu.splice(diviIndex, 1);
        setSauces(tempMnu)
        props.UpdateTheSaucess(tempMnu);
    }

    const removeOpti = (diviIndex, prodIndex, OptINdex) => {
        setThePopUp(<EraseCheck title={'האם להסיר את כל הרטבים מהרשימה?'} sucecssFunc={() => removeOptio()} />)
    }

    const removeOptio = (diviIndex, prodIndex, OptINdex) => {
        let tempMnu = [];
        setSauces();
        props.UpdateTheSaucess(tempMnu);
    }


    const createNewDetOption = () => {
        setThePopUp(
            <InputDubleSet Title={"הוספת אופציה"}
                ContentText={""} inLabel={"שם האופציה"} inLabel2={"תוספת מחיר"}
                okClick={(textBack) => createNewDetOptionOk(textBack)}
                cancelClick={() => setThePopUp()}
                errorMsg={"על מנת ליצור אופציה חדשה, חובה לתת לה שם."}
            />)
    }

    const createNewDetOptionOk = (textBack) => {

        let newDetOpt = {
            servType: textBack.inputTxt,
            addToPrice: textBack.inputPrc,
            Makat: "",
        };
        sauces.push(newDetOpt)

        props.UpdateTheSaucess(sauces)
    }


    const changeProdOptDetName = (divIndex, newValue) => {

        let tempMnu = [...sauces];
        tempMnu[divIndex].servType = newValue;
        setSauces(tempMnu)
        props.UpdateTheSaucess(tempMnu);
    }


    const setUpOpti = (index, proIndex, OptINdex) => {

        let tempMnu = [...sauces];

        let temp = array_move([...tempMnu[index].Products[proIndex].prodOptions], OptINdex, OptINdex - 1);

        tempMnu[index].Products[proIndex].prodOptions = temp;

        props.UpdateTheSaucess(tempMnu);

    }

    const setDownOpti = (index, proIndex, OptINdex) => {

        let tempMnu = [...sauces];
        let temp = array_move([...tempMnu[index].Products[proIndex].prodOptions], OptINdex, OptINdex + 1);

        tempMnu[index].Products[proIndex].prodOptions = temp;

        props.UpdateTheSaucess(tempMnu);

    }

    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    return (
        <Card>
            <Accordion.Collapse className="collapseparent" eventKey={1}>
                <Card.Body>
                    <div className={""}>
                        <div className={"mnuItmOptPrice"}><label>מקט רטבים לקופה</label><input type="text" value={saucesMakat} placeholder={"מקט לקופה"}
                            onChange={(e) => changeSaucessMakat(e.target.value)} />
                        </div>
                        <div className={"prodOptDetWrap"}>
                            <div className={"fullRow"}>
                                <div className={"onRight"} >&nbsp;&nbsp;
                                    <Button variant="contained" onClick={() => createNewDetOption()}
                                        startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                                        הוסף רוטב
                                    </Button>
                                </div>
                            </div>
                            {
                                sauces?.map((item, index) => {
                                    optis = (index === 0) ? "" : optis;
                                    optis = (optis === "") ? item.servType : optis + " , " + item.servType;
                                    return (
                                        <div key={index} className={"optsDet servTpDiv"}>
                                            <div className={"mnuItmOptType"}><label>שם הרוטב</label>
                                                <input type="text" value={item.servType} placeholder={"שם הרוטב"}
                                                    onChange={(e) => changeProdOptDetName(index, e.target.value)} />
                                            </div>
                                            <div className={"mnuItmOptPrice"}><label>תוספת מחיר</label><input type="text" value={item.addToPrice} placeholder={"תוספת מחיר"}
                                                onChange={(e) => changeProdOptDetPrice(index, e.target.value)} />
                                            </div>
                                            <div className={"mnuItmOptPrice"}><label>מקט לקופה</label><input type="text" value={item.cashRegMakat} placeholder={"מקט לקופה"}
                                                onChange={(e) => changeProdOptCashRegMakat(index, e.target.value)} />
                                            </div>

                                            <div className={"divAsIconLeft pointing optidietDel"}
                                                onClick={() => removeDetOpti(index)}><i className="far fa-trash-alt ms-text-danger" /></div>

                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
            <Accordion.Toggle as={Card.Header} className={"redCard"} eventKey={1}>
                <span>רטבים להצמדה</span><span className={"noBoldvSmall"}>{optis}</span>
            </Accordion.Toggle>

            <div className={"stpBtns"}>
                <div className={"acoordBtns"}>
                    <div className={"acoordIcon acord1 pointing"} onClick={() => removeOpti()}><i className="far fa-trash-alt theSecIconsColor onLeft" /></div>
                    {/* {OptINdex != 0 && props.prodsOptLength > 1 ? <div className={"acoordIcon acord2 pointing"} onClick={() => setUpOpti(index, proIndex, OptINdex)} title={"הקפץ למעלה"}><i className="far fa-caret-square-up theSecIconsColor onLeft" /></div> : ""}
                    {OptINdex < props.prodsOptLength - 1 ? <div className={"acoordIcon acord3 pointing"} onClick={() => setDownOpti(index, proIndex, OptINdex)} title={"הקפץ למטה"}><i className="far fa-caret-square-down theSecIconsColor onLeft" /></div> : ""} */}
                </div>
                {/* <div className={"saveToSilo"}>
                    <div className={"acoordIcon acordbt pointing"} onClick={() => saveToSilo(index, proIndex, OptINdex)} title={"שמור למאגר"}><i className="far fa-save theSecIconsColor onLeft" /></div>
                </div> */}
            </div>

            {thePopUp}
        </Card >
    )
}

export default AccordSauces;