
import React, { useState, createContext, useEffect } from 'react';
import { Swiper } from 'swiper/react/swiper';
import { SwiperSlide } from 'swiper/react/swiper-slide';

import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { ContactlessOutlined, SwipeSharp } from '@mui/icons-material';
import SwiperCore, { Pagination } from 'swiper';
import ConnUrls from '../../../MyConns/Connections/ConnectURL';

SwiperCore.use([Pagination]);


const TopBigSlides = (props) => {

  const [timgs, setTimgs] = useState(props.TopImages);

  useEffect(() => {

  }, []);




  return (
    <div className={"HPTopImages"}>
      <Swiper
        loop={true}
        freeMode={false}
        className={"HPTopSwiper"}
        spaceBetween={0}
        slidesPerView={1}
        pagination={true}
      >
        {
          timgs.map((item, index) => {
            return (
              <SwiperSlide key={index} className={"HPTopImagesLst"}>

                {/* <Link to={`/cat/${item._id}`}> */}
                <div className={"HPTImgWrap"}>
                  <img className={"HPTopImage"} src={`${ConnUrls.servMediasUrl}${item.img}`} />
                  <div className={"HPTopimgTxt bold big pointing"} >
                    {parse(`${item.txt}`)}
                  </div>

                </div>
                {/* </Link> */}
              </SwiperSlide>
            )

          })
        }

      </Swiper>

    </div>
  )

}

export default TopBigSlides;



