

const mediaprint = () => {
    return (
        `<style>
 .body{
    padding:0;
 }
 .medBig, .medBig p, .medBig div, .medBig span,
 .mediBig, .mediBig p, .mediBig div, .mediBig span,
 .smalBig, .smalBig p, .smalBig div, .smalBig span,
 .medBig, .medBig p, .medBig div, .medBig span,
 .semBig, .semBig p, .semBig div, .semBig span,
{
    font-size: 100% !important;
    font-family: 'tahoma', 'sans-serif' !important;
}


.dottedLine 
{
    margin-top:5px;
    margin-bottom:5px;
}
.addressLine {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    overflow: hidden;
}
h6 {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.printingCloser {
    /* background-color: black; */
    max-width: 80mm;
    margin: 0 auto;
    overflow: hidden;
    direction: rtl;
    text-align: right;
}

.no_print {
    display: none;
}

th.blackOnPrint {
    color: black !important;
    background-color: lightgray !important;
}

.onlyOnPrint {
    display: block;
}

.printOrderPage {
    display: block;
    max-width: 80mm;
    float: right;
    text-align: right;
    font-family: 'tahoma', 'sans-serif' !important;
    direction: rtl;

}


.dottedLine {
    border-bottom: 1px dotted black;
}

.addressLine {
    padding-bottom: 5px;

}

.addressLine div {
    float: right;
    margin-right: 3px;
    font-weight: bold;
}

.itemOrd {
    overflow: hidden;
    position: relative;
}

.inTot {
    position: absolute;
    bottom: 0;
    left: 0;
}

.mountDiv {
    width: 5%;
}

.detailsDiv {
    width: 100%;
    text-align: right;
}

.priceDiv {
    width: 7%;
}


.semBig, .semBig p, .semBig div, .semBig span {
    font-size: 18px;
    font-family: 'tahoma', 'sans-serif' !important;
    
}

.mediBig {
    font-size: 17px;
    font-family: 'tahoma', 'sans-serif' !important;
}
.ordItmSpecLine,.ordItmOptHead ,.delorPick
{
    width: 100%!important;
    overflow: hidden;
}
.ordItmOptHead 
{
    padding-top:10px;
    font-weight:bold !important;
}
.mainHd
{
    font-weight: bold;
    font-size: 19px !important;
}
.delorPick
{
direction:rtl;
}
.subHd
{
    font-weight: bold;
    font-size: 15px !important;
    }
.smalBig {
    font-size: 14px;
    width: 20%;
    font-family: 'tahoma', 'sans-serif' !important;
}

.medBig, .medBig p, .medBig div, .medBig span {
    font-size: 16px;
    font-family: 'tahoma', 'sans-serif' !important;
}

.verBig {
    /* font-size: 30px; */
}

.mountDiv {
    min-width: 30px;
    line-height: 100%;
}

.itemOrd {
    overflow: hidden;
    width: 100%;
}

.inTot {
    float: left;
    position: relative;
    text-align: left;
    
}
.spTxt
{
    font-size: 12px;
    text-align: center;
    font-family: 'tahoma', 'sans-serif' !important;
    padding-top: 15px;
}
.ordItmLine .rSideMiddle {
    width: 100%;
}
.ordItmLine {
    overflow: hidden;
}
.ordItmSpecLine {
    /* line-height: 2.4rem; */
}

.prName {
    width: 78%;
    float: right;
}

.ms-content-wrapper {
    padding: 0px 0px 0px 5px !important;

}   
.textRight 
{
    direction:rtl;
}
.fullRow
{
    width:100%;
}
</style>`
    )
}
export default { mediaprint } 