import React, { Component } from 'react';
import Swal from 'sweetalert2'


const MultiUpdateCheck = (props) => {     

    Swal.fire({
        title: props.title,
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: 'כן',
        cancelButtonText: 'ביטול',
        customClass: {
            container: "CheckDelete"
        }
    }).then(function (result) {
        if (result.value) {
            props.sucecssFunc();
            Swal.fire({
                title: 'עודכן בהצלחה',
                text: '',
                icon: 'success',
                customClass: {
                    container: "CheckDelete"
                }
            });
        }
    });

    return (
        <></>

    )
}

export default MultiUpdateCheck;
