import React, { useContext, useEffect } from 'react';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';
import Sidenavigation1 from '../../layouts/Sidenavigation1';
import Topnavigation from '../../layouts/Topnavigation';
import TblOrderLst from './TblOrderslist-list/TblOrderLst';
import { UserContext } from '../../MyContexts/Users_Context';


const TblOrderslist = (props) => {
    const [user, setUser] = useContext(UserContext)

    useEffect(() => {
        sessionStorage.setItem("pg", "pgBuisDetails")
        
    }, [])
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>
                <Sidenavigation1 />
                <main className="body-content">
                    <Topnavigation />
                    <TblOrderLst />
                </main>
                {/* <Quickbar /> */}
            </TheMasterContextProvider>
        </div>
    );
}

export default TblOrderslist;
