import React, { useContext, useEffect, useState } from 'react'
import { TheMasterContext } from '../../../../MyContexts/Master_Context';
import SelectImgComp from '../../../../MySections/ImagesSetter/SelectImgComp';
import SelectImgDelay from '../../../../MySections/ImagesSetter/SelectImgDelay';

const TabPopUps = (props) => {
    const [theMaster] = useContext(TheMasterContext);

    const [oopsPopImg, setOopsPopImg] = useState(theMaster.oopsPopImg);
    const [defaultPopImg, setDefaultPopImg] = useState(theMaster.defaultPopImg);


    const [delorpickImg, setDelorpickImg] = useState(theMaster.delorpickImg);
    const [connsectBySMSImg, setConnsectBySMSImg] = useState(theMaster.connsectBySMSImg);
    const [codeSMSImg, setCodeSMSImg] = useState(theMaster.codeSMSImg);
    const [suceccufulyconnectedImg, setSuceccufulyconnectedImg] = useState(theMaster.suceccufulyconnectedImg);
    const [orderApprovImg, setOrderApprovImg] = useState(theMaster.orderApprovImg);
    const [orderUnApprovImg, setOrderUnApprovImg] = useState(theMaster.orderUnApprovImg);
    const [nowCloseImg, setNowCloseImg] = useState(theMaster.nowCloseImg);
    const [registerImg, setRegisterImg] = useState(theMaster.registerImg);
    const [welcomeImg, setWelcomeImg] = useState(theMaster.welcomeImg);
    const [successUpdate, setSuccessUpdate] = useState(theMaster.successUpdate);
    const [remarkIconPopImg, setRemarkIconPopImg] = useState(theMaster.remarkIconPopImg);
    

    useEffect(() => {


    }, [])


    const setImage = (val, imgName, imgeFuncSet) => {
        theMaster[imgName] = val;
        imgeFuncSet(val);
    }

    return (
        <>

            <div className={"col-md-12 mb-2"}>

                <div className={"col-md-3 mb-2"}>
                    <label htmlFor={"validationCustom1_6"}>תמונת פופ אפ</label>
                    <span className={"explanation mediumst fullROw"}>התמונה שתוצג בכל הפופאפים האחרים </span>

                    <SelectImgComp firstImgToDisp={defaultPopImg ? defaultPopImg : ""} theFunction={(fUrl) => setImage(fUrl, "defaultPopImg", setDefaultPopImg)} />
                </div>

                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>סוג ההזמנה משלוח/איסוף</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={delorpickImg} theFunction={(fUrl) => setImage(fUrl, "delorpickImg", setDelorpickImg)} />

                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>ההזמנה בוצעה</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={orderApprovImg} theFunction={(fUrl) => setImage(fUrl, "orderApprovImg", setOrderApprovImg)} />

                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_18"}>התראה</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={remarkIconPopImg} theFunction={(fUrl) => setImage(fUrl, "remarkIconPopImg", setRemarkIconPopImg)} />

                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_78"}>ההזמנה לא בוצעה</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={orderUnApprovImg} theFunction={(fUrl) => setImage(fUrl, "orderUnApprovImg", setOrderUnApprovImg)} />

                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>התחברות בסמס</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={connsectBySMSImg} theFunction={(fUrl) => setImage(fUrl, "connsectBySMSImg", setConnsectBySMSImg)} />

                </div>
            </div>
            <div className={"col-md-12 mb-2"}>

                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>הקלדת קוד</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={codeSMSImg} theFunction={(fUrl) => setImage(fUrl, "codeSMSImg", setCodeSMSImg)} />

                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>התחברת בהצלחה</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={suceccufulyconnectedImg} theFunction={(fUrl) => setImage(fUrl, "suceccufulyconnectedImg", setSuceccufulyconnectedImg)} />

                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>אופססס</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={oopsPopImg} theFunction={(fUrl) => setImage(fUrl, "oopsPopImg", setOopsPopImg)} />

                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>סגור כעת</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={nowCloseImg} theFunction={(fUrl) => setImage(fUrl, "nowCloseImg", setNowCloseImg)} />

                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>הרשמה</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={registerImg} theFunction={(fUrl) => setImage(fUrl, "registerImg", setRegisterImg)} />

                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom146"}>ברוכים הבאים</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={welcomeImg} theFunction={(fUrl) => setImage(fUrl, "welcomeImg", setWelcomeImg)} />

                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label htmlFor={"validationCustom1_6"}>הפרטים עודכנו בהצלחה</label>
                    <SelectImgComp imgCss={"betweenImgDisp pointing"} firstImgToDisp={successUpdate} theFunction={(fUrl) => setImage(fUrl, "successUpdate", setSuccessUpdate)} />

                </div>
            </div>



        </>

    )
}

export default TabPopUps;