import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import utilsSpecialPage from '../../../MyConns/_Utils/utilsSpecialPage';
import { RestContext } from '../../MyContexts/Rest_Context';
import SelectImgComp from '../../MySections/ImagesSetter/SelectImgComp';
import SelectImgDelay from '../../MySections/ImagesSetter/SelectImgDelay';

const SpecialPaegDetails = (props) => {


    const [mainHead, setMainHead] = useState("");

    const [sEOdescription, setSEOdescription] = useState("");
    const [sEOkeywords, setSEOkeywords] = useState("");
    const [msg, setMsg] = useState("");

    let thepage = {};

    useEffect(() => {
        getThePage()
    }, [])

    const getThePage = async () => {
        let tempPage = await utilsSpecialPage.getSpecialPageByName(props.thePageName)
        setMainHead(tempPage.mainHead)
        setSEOdescription(tempPage.sEOdescription)
        setSEOkeywords(tempPage.sEOkeywords)
        thepage = tempPage;
    }


    // const thePageUpdate = (key, val) => {
    //     thepage[key] = val;

    // }

    const savepage = async () => {

        let tempPage = await utilsSpecialPage.updateSpecialPage(props.thePageName, {
            mainHead,
            sEOdescription,
            sEOkeywords
        });
        (tempPage) ? setMsg("נשמר בהצלחה") : setMsg("בעייה בשמירה")
    }

    return (
        <>
            <div className={"col-md-12 mb-2"}>
                <div className={"input-group"}>
                    <p className={""} id={"validationCustom01"} >{props.thePageName}</p>
                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom01"}>כותרת</label>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"sa"} placeholder={"כותרת"} value={mainHead}
                        onChange={(e) => { setMainHead(e.target.value) }} required />

                </div>
            </div>

            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom02"}>description תיאור</label>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom02"} placeholder={"SEO"} value={sEOdescription}
                        onChange={(e) => { setSEOdescription(e.target.value) }} />
                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom03"}>keywords</label>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom03"} placeholder={"SEO KEYS"} value={sEOkeywords}
                        onChange={(e) => { setSEOkeywords(e.target.value) }} />
                </div>
            </div>

            <div className={"col-md-12 mb-2"}>

                <div className={"input-group"}>
                    <button value={"שמור"} onClick={() => savepage()} >שמור</button>&nbsp;&nbsp;
                    <p>{msg}</p>
                </div>
            </div>
        </>

    );
}

export default SpecialPaegDetails;