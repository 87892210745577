import React, { Component, useContext, useEffect } from 'react';
import Sidenavigation from '../../layouts/Sidenavigation';
import Sidenavigation1 from '../../layouts/Sidenavigation1';
import Topnavigation from '../../layouts/Topnavigation';
import TopnavigationSpecial from '../../MySections/DTechComps/TopnavigationSpecial';

import Quickbar from '../../layouts/Quickbar';
import { HomePContextProvider } from '../../MyContexts/HomeP_Context';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';
import HomePSet from './HomePSet';
import myFunctions from '../../../myFunctions';
import { UserContext } from '../../MyContexts/Users_Context';



const HomePage = (props) => {

    const [user, setUser] = useContext(UserContext)
    useEffect(() => {
        if (!myFunctions.setGetCurrnetTab("Get", "pg").includes("_HP")) {
            myFunctions.setGetCurrnetTab("Set", "pg", "tab5_HP")
        }
    }, [])

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>


                <Sidenavigation1 />
                <main className="body-content">
                    <Topnavigation />
                    {user.permiss === "admin" &&
                        <HomePContextProvider>
                            <HomePSet />
                        </HomePContextProvider>
                    }
                </main>
                {/* <Quickbar /> */}


            </TheMasterContextProvider>


        </div>
    );
}

export default HomePage;