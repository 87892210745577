import React, { Component, useContext, useEffect } from 'react';
import Sidenavigation1 from '../../layouts/Sidenavigation1';
import Topnavigation from '../../layouts/Topnavigation';
import Restaurentcontent from '../RestsP/Restaurant-list/Restaurantcontent'
import Quickbar from '../../layouts/Quickbar';
import RestSet from '../RestsP/Rests/RestSet';
import { RestContextProvider } from '../../MyContexts/Rest_Context';
import CategurysList from './Categurys-list/CategurysList';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';
import TopnavigationSpecial from '../../MySections/DTechComps/TopnavigationSpecial';
import myFunctions from '../../../myFunctions';
import { UserContext } from '../../MyContexts/Users_Context';

const MyCategurys = (props) => {
    const [user, setUser] = useContext(UserContext)

    useEffect(() => {

    }, [])

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>
                <Sidenavigation1 />
                <main className="body-content">
                    <Topnavigation />
                    {user.permiss === "admin" &&
                        <CategurysList />
                    }
                </main>
                {/* <Quickbar /> */}


            </TheMasterContextProvider>
        </div>
    );
}

export default MyCategurys;