import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RichTextComp from '../TextEditor/RichText';
import SunEditor, { buttonList } from "suneditor-react";

const TextBoxSet = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState(false);
    const [canConti, setCanConti] = useState(false);

    let theCount = null;

    useEffect(() => {
        setInputTxt(props.textFirst);
        setTimeout(() => {
            setCanConti(true)
        }, 2000);
    }, [])


    const saveMe = () => {

        props.okClick(inputTxt);
        handleClose();

    };


    const handleClose = () => {
        props.cancelClick();
    };



    const setTheInput = (content) => {

        setInputTxt(content);

        // setErr(false);
    }

    return (
        <Dialog open={open} className={"TextBoxSetWrap"}>
            <DialogTitle>{props.Title}</DialogTitle>
            <DialogContent>

                <SunEditor lang="he" onChange={(content) => setTheInput(content)}
                    setDefaultStyle="font-family: Arial; font-size: 20px; direction:rtl" formats="Div"
                    // defaultValue={props.firstText}
                    setContents={inputTxt}
                    setOptions={{
                        height: (props.height != null) ? props.height : 200,
                        width: "100%",
                        buttonList: [

                            ["undo", "redo"],
                            // ["font", "fontSize", "formatBlock"],
                            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                            ["removeFormat"],
                            "/",
                            ["fontColor", "hiliteColor"],
                            ["outdent", "indent"],
                            ["align", "horizontalRule", "list", "table"],
                            ["link", "image", "video"],
                            ["codeView"],
                        ]
                    }} />

                {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}
            </DialogContent>
            <DialogActions>
                <button type="button" className="onLeft btn btn-primary" onClick={handleClose}>ביטול</button>
                {canConti ?
                    <button type="button" className={`onLeft btn btn-success`} onClick={() => saveMe()}>שמור</button>
                    : ""
                }

            </DialogActions>
        </Dialog>
    );
}

export default TextBoxSet;