import { Checkbox, MenuItem, Select } from '@mui/material';
import React, { Component, useContext, useEffect, useState } from 'react';
import { RestContext } from '../../MyContexts/Rest_Context';
import { TheMasterContext } from '../../MyContexts/Master_Context';

const CityesDelOptions = (props) => {
    const [theMaster] = useContext(TheMasterContext);

    const [deliveryCitys, setDeliveryCitys] = useState((props.deliveryCitys != null) ? props.deliveryCitys : ["אשדוד"]);

    const citselectchanged = (cityname, chk) => {
        let tempo = [...deliveryCitys];
        if (chk) {
            tempo.push(cityname)
        }
        else {
            tempo = tempo.filter(it => it != cityname)
        }
        setDeliveryCitys(tempo);
        props.updateDelCitys(tempo);
    }



    return (
        <div className={"onRight cityesSelect"}>
            <div className='bold'>ערי חלוקה: </div>
            {
                theMaster.cityesToDeliver?.map((itm, indx) => {
                    let checkd = (deliveryCitys.indexOf(itm) !== -1)
                    return (
                        <div key={indx} className='fullRow'>
                            <Checkbox 
                                checked={checkd}
                                onChange={() => citselectchanged(itm, !checkd)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                className={"blackCheck onRight"}
                            />
                            <span className='bold onRight'>{itm}</span>

                        </div>
                    )
                })
            }
        </div >
    )
}

export default CityesDelOptions;