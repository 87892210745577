import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'

const getRests = async (token) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest", {
        params: {
            token: token,
            // Other query parameters if needed
        },
    });
    return (dFromF.data);
}

const getRestById = async (id, obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest/" + id, {
        params: {
            token: obj.token,
        },
    });
    return (dFromF.data);
}

const updateRest = async (id, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/updat/" + id, obj);
    return (dFromF.data);
}

const deleteRest = async (id) => {
    let dFromF = await axios.delete(ConnUrls.ApiUrl + "rest/" + id);
    return (dFromF);
}

const createRest = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "rest", obj);
    return (dFromF.data);
}

const getClearRest = async () => {

    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest/newFile");

    return (dFromF.data);
}

const getRestsListSmall = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/lstRest", obj);
    return (dFromF.data);
}

const getRestsForTablesListSmall = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/lstRestForTables", obj);
    return (dFromF.data);
}

const getRestsForKoponListSmall = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/lstRestForKopn", obj);
    return (dFromF.data);
}


const getRestSmDetails = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/lstRestSmDetails", obj);
    return (dFromF.data);
}


const getRestPayPrecent = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/getRestPayPrecent", obj);
    return (dFromF.data);
}


const updaterestStatus = async (restId, stat, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/statUpdate/" + restId, { stat, token });
    return (dFromF.data);
}

const updateDelveryDoing = async (restId, doingOrNot, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/delvUpdate/" + restId, { doingOrNot, token });
    return (dFromF.data);
}

const updateOnlineBuis = async (restId, onOff, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/onlineUpdate/" + restId, { onOff, token });
    return (dFromF.data);
}

const updateTakeAwayDoing = async (restId, doingOrNot, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/takeUpdate/" + restId, { doingOrNot, token });
    return (dFromF.data);
}

const updateBuisReservation = async (restId, doingOrNot, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/buisReservUpdate/" + restId, { doingOrNot, token });
    return (dFromF.data);
}

const updateKoponBuiss = async (restId, doingOrNot, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/KoponBuissUpdate/" + restId, { doingOrNot, token });
    return (dFromF.data);
}


const updaterestUrl = async (restId, stat, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/updaterstUrl/" + restId, { stat, token });
    return (dFromF.data);
}


const updaterestCRType = async (restId, CRT, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/updateCRT/" + restId, { CRT, token });
    return (dFromF.data);
}

const setDelayClosing = async (restId, typeToDelay, delayObj, token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/setDelayClosing/" + restId, { typeToDelay, delayObj, token });
    return (dFromF.data);
}


const getRestSpecialMakats = async (id, obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest/getRestSpecialMakats/" + id, {
        params: {
            token: obj.token,
        },
    });
    return (dFromF.data);
}


const setRestSpecialMakats = async (id, token, makatsLst) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/setRestSpecialMakats/" + id, { token, makatsLst });
    console.log(dFromF.data);
    return (dFromF.data);
}

export default {
    getRestPayPrecent,
    updateKoponBuiss,
    getRestsForKoponListSmall,
    updateBuisReservation,
    getRestsForTablesListSmall,
    getRests,
    getRestById,
    updateRest,
    deleteRest,
    createRest,
    getClearRest,
    getRestsListSmall,
    getRestSmDetails,
    updaterestStatus,
    updateDelveryDoing,
    updateOnlineBuis,
    updateTakeAwayDoing,
    updaterestCRType,
    updaterestUrl,
    setDelayClosing,
    getRestSpecialMakats,
    setRestSpecialMakats

};