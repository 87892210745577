import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const getDeliveryDetails = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "delivDet/all", {params: obj});
    return (dFromF.data);
}


const getDeliveryDetailsByOrdNum = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "delivDet/getByOrdNum", {params: obj});
    return (dFromF.data);
}

const updateDeliveryDetails = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "delivDet/updatDeDetails", obj);
    return (dFromF.data);
}



export default {
    getDeliveryDetails,
    updateDeliveryDetails,
    getDeliveryDetailsByOrdNum,
};