import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import utilsSilo from '../../../MyConns/_Utils/utilsSilo';
import { Checkbox, FormControlLabel } from '@mui/material';
import EraseCheck from '../PopUps/EraseCheck';

const SelectFromSilo = (props) => {


    const [contentList, setContentList] = useState([]);
    const [tempCont, setTempCont] = useState([]);
    const [loading, setLoading] = useState(true);
    const [theCheks, setTheCheks] = useState([]);
    const [displyList, setDisplyList] = useState();
    const [thePopUp, setThePopUp] = useState("");
    const [searchTxt, setSearchTxt] = useState("");



    useEffect(() => {
        getAllFromSilo();

    }, [])

    useEffect(() => {
        setTheDisplay(tempCont);
    }, [tempCont, theCheks])

    const getAllFromSilo = async () => {
        let siloLst = await utilsSilo.getAllFromSilo();
        // let bk = tempCont.reverse().map((item, index) => {
        setContentList(siloLst.reverse());
        setTempCont(siloLst);
        setLoading(false);
    }


    const clearSilo = () => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק הכל?'}
            sucecssFunc={async () => { await utilsSilo.clearSilo(); getAllFromSilo(); }} />)

    }
    const reversTheList = () => {
        let tempo = [...tempCont.reverse()]
        setTempCont(tempo);

    }

    const clearItmsFromSilo = async () => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את הנבחרים?'}
            sucecssFunc={async () => { await utilsSilo.clearItmsFromSilo(theCheks); getAllFromSilo(); }} />)

    }

    const setChkCat = (item, checkOrNot) => {

        let tempCats = [...theCheks];
        if (!checkOrNot) {
            let bk = tempCats.findIndex(el => el._id === item._id);

            if (bk != -1) {
                tempCats.splice(bk, 1);
            }
        }
        else {
            tempCats.push(item);
        }

        setTheCheks(tempCats)
    }


    const removeOpti = (id) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק?'}
            sucecssFunc={() => removeOptConfirmd(id)} />)
    }

    const removeOptConfirmd = async (id) => {

        let siloLst = await utilsSilo.removeFromSilo(id);
        getAllFromSilo();
    }



    const setTheDisplay = (theTempCont) => {

        let counter = 0;
        let bk = theTempCont.map((item, index) => {

            let chck = (theCheks.findIndex(el => el._id === item._id) != -1) ? true : false;

            counter++;

            return (
                <div key={index} className={"col-md-12"}>
                    <div className={"divAsIcon pointing"} onClick={() => removeOpti(item._id)}><i className="far fa-trash-alt ms-text-danger" /></div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={chck}
                                onChange={() => setChkCat(item, !chck)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                className={"blackCheck"}
                            />
                        }
                        label={`${item.optionName} : ${item.options.map(it => it.servType)}`}
                    />

                </div>

            )
        })
        setDisplyList(bk);
    }


    const serachTheOpts = (e) => {
        let tempo = [...contentList];
        let theTempo = tempo.filter(itm => itm.optionName.startsWith(e.target.value));
        setTempCont(theTempo);
        setSearchTxt(e.target.value);
        setTheDisplay(theTempo);
    }

    return (
        <>
            {loading ? "Loading" :
                <>
                    <div className={"PopTopLine"}>
                        <input type={"text"} placeholder={"חפש לפי שם הנלווה"} value={searchTxt} onChange={(e) => serachTheOpts(e)} className={"buisSearchTxt"} />
                        <button type="button" className="onLeft btn btn-primary" onClick={() => reversTheList()}><i className="far fa-list-alt ms-text-danger whiteDang" />&nbsp;&nbsp; הפוך סדר</button>
                        <button type="button" className="onLeft btn btn-primary" onClick={() => clearSilo()}><i className="far fa-trash-alt ms-text-danger whiteDang" />&nbsp;&nbsp; נקה מאגר</button>
                        <button type="button" className="onLeft btn btn-primary" onClick={() => clearItmsFromSilo()}><i className="far fa-trash-alt ms-text-danger whiteDang" />&nbsp;&nbsp; הסר את הנבחרים</button>

                    </div>
                    <div className="popSelectImgBox">
                        {displyList}
                    </div>
                    <div className="bottomBtnsline">
                        <button type="button" className="onLeft btn btn-success btn-primary" onClick={() => props.setTheList(theCheks)}>שמור</button>
                        <button type="button" className="onLeft btn btn-primary" onClick={() => props.handleClose()}>סגור</button>
                    </div>

                </>
            }
            {thePopUp}
        </>
    );
}

export default SelectFromSilo;