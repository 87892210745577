import React, { Component, useEffect, useState } from 'react';
import { Accordion, Card } from "react-bootstrap";
import SelectImgComp from '../ImagesSetter/SelectImgComp';
import { AddBoxOutlined, ArrowCircleUp } from "@mui/icons-material";
import Button from '@mui/material/Button';
import EraseCheck from '../PopUps/EraseCheck';
import Scroll from 'react-scroll';
var scroller = Scroll.scroller;

const ImgAndTxt = (props) => {

    const [contentList, setContentList] = useState(props.TheList);
    const [displyList, setDisplyList] = useState();
    const [thePopUp, setThePopUp] = useState("");


    useEffect(() => {
        setTheDisplay();
    }, [contentList, props])

  
    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };


    const setUp = (index) => {
        let temp = array_move([...contentList], index, index - 1);

        setContentList(temp);
        props.theFunction(temp);
    }

    const setDown = (index) => {
        let temp = array_move([...contentList], index, index + 1);

        setContentList(temp);
        props.theFunction(temp);
    }

    const removeItm = (index) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את השורה?'} sucecssFunc={() => removeItmConfirmd(index)} />)
    }
    const removeItmConfirmd = async (index) => {
        let temp = [...contentList]
        temp.splice(index, 1);

        setContentList(temp);
        props.theFunction(temp);
    }

    const setTheTxt = (index, e) => {
        let temp = [...contentList]
        temp[index].txt = e.target.value;

        setContentList(temp);
        props.theFunction(temp);
    }
    const setTheLnk = (index, e) => {
        let temp = [...contentList]
        temp[index].lnk = e.target.value;

        setContentList(temp);
        props.theFunction(temp);

    }
    const createNewItm = () => {
        let temp = [...contentList]
        temp.push({ txt: "", lnk: "" });

        setContentList(temp);
        props.theFunction(temp);

    }



    const setTheDisplay = () => {
        let counter = 0;
        let bk = contentList.map((item, index) => {
            counter++;

            return (

                <tr key={index}>
                    <td className="l2row">
                        {index != 0 && contentList.length > 1 ? <div className={"iconsOnLeft pointing"} onClick={() => setUp(index)} title={"הקפץ למעלה"}><i className="far fa-caret-square-up theIconsColor" /></div> : ""}
                        {index < contentList.length - 1 ? <div className={"iconsOnLeft pointing"} onClick={() => setDown(index)} title={"הקפץ למטה"}><i className="far fa-caret-square-down theIconsColor" /></div> : ""}
                    </td>
                    <td className="mrow">
                        <textarea rows="2" className={"SelectTxtLnkInput"} value={item.txt} onChange={(e) => setTheTxt(index, e)} ></textarea>
                    </td>
                    <td className="mrow">
                        <textarea rows="2" className={"SelectTxtLnkInput"} value={item.lnk} onChange={(e) => setTheLnk(index, e)} ></textarea>
                    </td>
                    <td className="lrow">
                        <div className={"iconsOnLeft pointing"} onClick={() => removeItm(index)}><i className="far fa-trash-alt theIconsColor" /></div>
                    </td>

                </tr>

            )
        })
        setDisplyList(bk);
    }


    return (
        <>

            <div>
                <div className={"smallBtnsLine"}>
                    <Button className={"btnSPc"} variant="contained" onClick={() => createNewItm()}
                        startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                        הוסף
                    </Button>
                </div>
                <Accordion defaultActiveKey="0" className="has-gap ms-accordion-chevron">
                    <Card className={"SelectTxtLnk"}>
                        <div className="table-responsive">
                            <table className="table table-hover thead-primary">
                                <thead>
                                    <tr>
                                        <th scope="col" className={"centerText l2row"}>{props.tblHeads[3] ? props.tblHeads[3] : ""}</th>
                                        <th scope="col" className={"centerText"}>{props.tblHeads[0] ? props.tblHeads[0] : ""}</th>
                                        <th scope="col" className={"centerText"}>{props.tblHeads[1] ? props.tblHeads[1] : ""}</th>
                                        <th scope="col" className={"centerText"}>{props.tblHeads[2] ? props.tblHeads[2] : ""}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displyList}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </Accordion>
            </div>
            {thePopUp}
        </>
    );
}

export default ImgAndTxt;