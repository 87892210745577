import React, { Component, useContext, useEffect, useState } from 'react';
import Sidenavigation from '../../layouts/Sidenavigation';
import Sidenavigation1 from '../../layouts/Sidenavigation1';
import Topnavigation from '../../layouts/Topnavigation';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';

import { MenuContextProvider } from '../../MyContexts/Rest_Context';
import myFunctions from '../../../myFunctions';
import utilsUsers from '../../../MyConns/_Utils/utilsUsers';
import BlackListPage from './BlackListPage';
import { UserContext } from '../../MyContexts/Users_Context';


const BlackListP = (props) => {
    const [user, setUser] = useContext(UserContext)
    const [thePage, setThePage] = useState();
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {

        ///  myFunctions.connCheck();

    }, [])
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">

            <TheMasterContextProvider>
                <Sidenavigation1 />
                <main className="body-content">
                    <MenuContextProvider>
                        <Topnavigation />
                        {user.permiss === "admin" &&
                            <BlackListPage />
                        }
                    </MenuContextProvider>
                </main>
                {/* <Quickbar /> */}
            </TheMasterContextProvider>

        </div>
    );
}

export default BlackListP;