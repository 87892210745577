import React, { useState, useEffect } from 'react'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { TextField } from '@mui/material';
import  MyDateTime  from  './MyDateTime';
import frLocale from 'date-fns/locale/fr';

// import Login from '../Login/Login';
// import useToken from '../Login/useToken';


const OpeningHours = (props) => {

    const [openingHours, setOpeningHours] = useState();

    useEffect(() => {

    }, [props.theHours]);





    const updateHours = (index, val, OpenOrClose) => {

        let tempOpenHours = props.theHours;
        let tempHours =  tempOpenHours[index] ;
        (OpenOrClose === "Open") ? tempHours.Open = val : tempHours.Close = val;
        tempOpenHours[index] = tempHours;

        props.SetTheHours(tempOpenHours);

    }


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale} timeZone="Asia/Jerusalem">
            <table className={"tabTable tableBordSpace"}>
                <tbody>
                    {
                        props.theHours?.map((item, index) => {
                            return (
                                <tr key={index} style={{marginTop:"10px"}}>
                                    <td style={{width:"20%"}} className={"centerText"}>{MyDateTime.getDayOfWeekToHeb(item.Day)}</td>
                                    <td>
                                        <table className={"tblOpHours"}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <TimePicker
                                                        ampm={false}
                                                        label="פתיחה"
                                                        value={item.Open}
                                                        onChange={(e) => updateHours(index, e, "Open")}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        className={"tmPick"}
                                                    />
                                                </td>
                                                <td>
                                                    <TimePicker
                                                        ampm={false}
                                                        label="סגירה"
                                                        value={item.Close}
                                                        onChange={(e) => updateHours(index, e, "Close")}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        className={"tmPick"}
                                                    />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </td>

                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

        </LocalizationProvider>
    )
}


export default OpeningHours;
