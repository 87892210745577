import rests from "../_ApiConn/apiRests";


const getRests = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await rests.getRests(token);
    return (dFromF);
}

const createRest = async (obj) => {
    let dFromF = await rests.createRest(obj);
    return (dFromF);
}

const getRestById = async (id) => {
    const token = localStorage.getItem('token');
    let dFromF = await rests.getRestById(id, { token });
    return (dFromF);
}

const updateRest = async (id, obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await rests.updateRest(id, { ...obj, token });
    return (dFromF);
}
const deleteRest = async (id) => {
    let dFromF = await rests.deleteRest(id);
    return (dFromF);
}

const getClearRest = async () => {
    let dFromF = await rests.getClearRest();
    return (dFromF);
}


const getRestsListSmall = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await rests.getRestsListSmall({ token });
    return (dFromF);
}


const getRestsForTablesListSmall = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await rests.getRestsForTablesListSmall({ token });
    return (dFromF);
}


const getRestsForKoponListSmall = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await rests.getRestsForKoponListSmall({ token });
    return (dFromF);
}


const getRestSmDetails = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await rests.getRestSmDetails({ token });
    return (dFromF);
}

const getRestPayPrecent = async (buisId) => {
    const token = localStorage.getItem('token');
    let dFromF = await rests.getRestPayPrecent({ token, buisId });
    return (dFromF);
}



const updaterestStatus = async (restid, stat) => {

    const token = localStorage.getItem('token');
    let dFromF = await rests.updaterestStatus(restid, stat, token);
    return (dFromF);
}



const updateOnlineBuis = async (restid, onOff) => {

    const token = localStorage.getItem('token');
    let dFromF = await rests.updateOnlineBuis(restid, onOff, token);
    return (dFromF);
}

const updateDelveryDoing = async (restid, doingOrNot) => {

    const token = localStorage.getItem('token');
    let dFromF = await rests.updateDelveryDoing(restid, doingOrNot, token);
    return (dFromF);
}


const updateTakeAwayDoing = async (restid, doingOrNot) => {

    const token = localStorage.getItem('token');
    let dFromF = await rests.updateTakeAwayDoing(restid, doingOrNot, token);
    return (dFromF);
}

const updateBuisReservation = async (restid, doingOrNot) => {

    const token = localStorage.getItem('token');
    let dFromF = await rests.updateBuisReservation(restid, doingOrNot, token);
    return (dFromF);
}

const updateKoponBuiss = async (restid, doingOrNot) => {

    const token = localStorage.getItem('token');
    let dFromF = await rests.updateKoponBuiss(restid, doingOrNot, token);
    return (dFromF);
}



const updaterestUrl = async (restid, stat) => {

    const token = localStorage.getItem('token');
    let dFromF = await rests.updaterestUrl(restid, stat, token);
    return (dFromF);
}




const updaterestCRType = async (restid, CRT) => {

    const token = localStorage.getItem('token');
    let dFromF = await rests.updaterestCRType(restid, CRT, token);
    return (dFromF);
}

const setDelayClosing = async (restid, typeToDelay, delayObj) => {

    const token = localStorage.getItem('token');
    let dFromF = await rests.setDelayClosing(restid, typeToDelay, delayObj, token);
    return (dFromF);
}


const getRestSpecialMakats = async (id) => {
    const token = localStorage.getItem('token');
    let dFromF = await rests.getRestSpecialMakats(id, { token });
    return (dFromF);
}



const setRestSpecialMakats = async (id, makatsLst) => {
    const token = localStorage.getItem('token');
    let dFromF = await rests.setRestSpecialMakats(id, token, makatsLst);
    return (dFromF);
}

export default {
    updateKoponBuiss,
    getRestPayPrecent,
    getRestsForKoponListSmall,
    getRestsForTablesListSmall,
    updateBuisReservation,
    getRests,
    getRestSmDetails,
    getRestById,
    updateRest,
    deleteRest,
    createRest,
    getClearRest,
    getRestsListSmall,
    updaterestStatus,
    updateDelveryDoing,
    updateOnlineBuis,
    updateTakeAwayDoing,
    updaterestCRType,
    updaterestUrl,
    setDelayClosing,
    getRestSpecialMakats,
    setRestSpecialMakats

};