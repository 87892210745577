import React, { useContext, useEffect, useState } from 'react'
import { TheMasterContext } from '../../../../MyContexts/Master_Context';
import RichTextComp from '../../../../MySections/TextEditor/RichText';

import SelectImgComp from '../../../../MySections/ImagesSetter/SelectImgComp';
import SelectImgDelay from '../../../../MySections/ImagesSetter/SelectImgDelay';
import { TextField } from '@mui/material';

const TabSiteMessages = (props) => {
    const [theMaster] = useContext(TheMasterContext);

    const [siteMessages, setSiteMessages] = useState(theMaster.siteMessages);
    const [buisInMsgs, setBuisInMsgs] = useState(theMaster.buisInMsgs);


    useEffect(() => {


    }, [])


    const setTheMessages = (index, content) => {

        let tempMsgs = [...siteMessages];

        if (!tempMsgs[index]) {
            let itm = { content: content, active: false, timing: 5000 }
            tempMsgs.push(itm)
        }
        else {
            let itm = tempMsgs[index];
            itm.content = content;
            tempMsgs[index] = itm;
        }

        theMaster["siteMessages"] = tempMsgs;
        setSiteMessages(tempMsgs);
    }

    const setTheBuisInMsgs = (index, content) => {

        let tempMsgs = [...buisInMsgs];
        if (!tempMsgs[index]) {
            let itm = { content: content, active: false, timing: 5000 }
            tempMsgs.push(itm)
        }
        else {
            let itm = tempMsgs[index];
            itm.content = content;
            tempMsgs[index] = itm;
        }

        theMaster["buisInMsgs"] = tempMsgs;
        setBuisInMsgs(tempMsgs);
    }

    const setTheSiteMsgTiming = (index, e) => {
        let tempMsgs = [...siteMessages];

        if (!tempMsgs[index]) {
            let itm = { content: "", active: false, timing: e.target.value || 5000 }
            tempMsgs.push(itm)
        }
        else {
            let itm = tempMsgs[index];
            itm.timing = e.target.value || 5000;

            tempMsgs[index] = itm;
        }

        // Update the master object and state
        theMaster["siteMessages"] = tempMsgs;
        setSiteMessages(tempMsgs);

    }

    const setSiteMsgsActiveLink = (index) => {

        let tempMsgs = [...siteMessages];

        if (!tempMsgs[index]) {
            let itm = { content: "", active: false, timing: 5000 }
            tempMsgs.push(itm)
        }
        else {
            let itm = tempMsgs[index];
            itm.active = !itm.active;

            tempMsgs[index] = itm;
        }

        // Update the master object and state
        theMaster["siteMessages"] = tempMsgs;
        setSiteMessages(tempMsgs);
    }


    const setSiteBuisMsgsActiveLink = (index) => {

        let tempMsgs = [...buisInMsgs];

        if (!tempMsgs[index]) {
            let itm = { content: "", active: false, timing: 5000 }
            tempMsgs.push(itm)
        }
        else {
            let itm = tempMsgs[index];
            itm.active = !itm.active;

            tempMsgs[index] = itm;
        }

        // Update the master object and state
        theMaster["buisInMsgs"] = tempMsgs;
        setBuisInMsgs(tempMsgs);
    }

    const setTheBuisMsgTiming = (index, e) => {
        let tempMsgs = [...buisInMsgs];

        if (!tempMsgs[index]) {
            let itm = { content: "", active: false, timing: e.target.value || 5000 }
            tempMsgs.push(itm)
        }
        else {
            let itm = tempMsgs[index];
            itm.timing = e.target.value || 5000;

            tempMsgs[index] = itm;
        }

        // Update the master object and state
        theMaster["buisInMsgs"] = tempMsgs;
        setBuisInMsgs(tempMsgs);

    }


    return (
        <>
            <div className={"col-md-12 mb-2 fullRow grayFrame"}>

                <div className={"col-md-8 mb-2 onRight "}>
                    <div className={"col-md-8 mb-2 onRight"}>
                        <label htmlFor={"validationCustom1_6"}>הודעה קצרה למשתמשים</label>
                        <span className={"explanation mediumst fullROw"}>הודעה שתגלוש לתצוגה למשך זמן מוגדר</span>
                        <span className={"explanation mediumst fullROw"}></span>
                    </div>
                    <div className={"col-md-4 mb-2 onRight"}>
                        <label className="ms-switch">
                            <input type="checkbox" id={"validationCustom5_88"} checked={siteMessages[0].active}
                                onChange={() => {
                                    setSiteMsgsActiveLink(0)
                                }} />
                            <span className={`ms-switch-slider ms-switch-primary round ${!siteMessages[0].active ? "disabledSwitch" : ""}`} />
                        </label> <span className={"spHead"}>{`${siteMessages[0].active ? "פעיל" : "לא פעיל"}`}</span>
                    </div>

                    <div className={"fullRow"}>
                        <RichTextComp firstText={siteMessages[0].content} width={"100%"}
                            saveEditorTxt={(content) => setTheMessages(0, content)} />
                    </div>

                </div>


                <div className={"col-md-4 mb-2 onRight "}>

                    <label htmlFor={"validationCustom1_6"}>תזמון סגירת הודעה אוטומטית</label>
                    <span className={"explanation mediumst fullROw"}>הזמן בשניות שההודעה תעלם אוטמטית</span>
                    <span className={"explanation mediumst fullROw"}>1 שניה = 1000</span>
                    <div className={"col-md-6 mb-2 noPadding spaceMargeTopbig onRight"}>
                        <TextField type="number" inputProps={{ min: "2000", step: "100" }} className={"txtField"} id="validationCustom5_337"
                            size="small" label="תזמון במילישניות" value={siteMessages[0].timing}
                            onChange={(e) => setTheSiteMsgTiming(0, e)} />
                    </div>

                </div>



            </div>
            <div className={"col-md-12 mb-2 fullRow grayFrame"}>

                <div className={"col-md-8 mb-2 onRight "}>
                    <div className={"col-md-8 mb-2 onRight"}>

                        <label htmlFor={"validationCustom1_6"}>הודעה קצרה לעסקים</label>
                        <span className={"explanation mediumst fullROw"}>הודעה שתגלוש לתצוגה למשך זמן מוגדר</span>
                        <span className={"explanation mediumst fullROw"}></span>
                    </div>

                    <div className={"col-md-4 mb-2 onRight"}>
                        <label className="ms-switch">
                            <input type="checkbox" id={"validationCustom5_88"} checked={buisInMsgs[0].active}
                                onChange={() => {
                                    setSiteBuisMsgsActiveLink(0)
                                }} />
                            <span className={`ms-switch-slider ms-switch-primary round ${!buisInMsgs[0].active ? "disabledSwitch" : ""}`} />
                        </label> <span className={"spHead"}>{`${buisInMsgs[0].active ? "פעיל" : "לא פעיל"}`}</span>
                    </div>
                    <div className={"fullRow"}>
                        <RichTextComp firstText={buisInMsgs[0].content} width={"100%"}
                            saveEditorTxt={(content) => setTheBuisInMsgs(0, content)} />
                    </div>

                </div>



                <div className={"col-md-4 mb-2 onRight "}>

                    <label htmlFor={"validationCustom1_6"}>תזמון סגירת הודעה אוטומטית</label>
                    <span className={"explanation mediumst fullROw"}>הזמן בשניות שההודעה תעלם אוטמטית</span>
                    <span className={"explanation mediumst fullROw"}>1 שניה = 1000</span>
                    <div className={"col-md-6 mb-2 noPadding spaceMargeTopbig onRight"}>
                        <TextField type="number" inputProps={{ min: "2000", step: "100" }} className={"txtField"} id="validationCustom5_347"
                            size="small" label="תזמון במילישניות" value={buisInMsgs[0].timing}
                            onChange={(e) => setTheBuisMsgTiming(0, e)} />
                    </div>

                </div>



            </div >
        </>

    );
}

export default TabSiteMessages;