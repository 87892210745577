import React, { useContext, useEffect, useState } from 'react'
import OpeningHours from '../../../../MySections/TimeSections/OpeningHours';
import { RestContext } from '../../../../MyContexts/Rest_Context';


const TabOpeningHours = (props) => {
    const [rest] = useContext(RestContext);
    const [openHours, setOpenHours] = useState(rest.OpeningHours);
    const [deliveryHours, setDeliveryHours] = useState(rest.DeliveryHours);

    useEffect(() => {
    }, [])

    return (
        <>
            <div className={"col-md-6 mb-2 tblHours fullRow"}>
                <label className={"fullRow centerText"}>שעות פתיחה</label>
                <OpeningHours theHours={openHours}
                    SetTheHours={(hoursBk) => { rest["OpeningHours"] = hoursBk; setOpenHours(hoursBk) }} />
            </div>
            <div className={"col-md-6 mb-2 tblHours fullRow"}>
            <label className={"fullRow centerText"}>שעות משלוחים</label>
                <OpeningHours theHours={deliveryHours}
                    SetTheHours={(hoursBk) => { rest["DeliveryHours"] = hoursBk; setDeliveryHours(hoursBk) }} />

                {/* SetTheHours={(hoursBk) => props.updateRest({ ...props.Rest, DeliveryHours: hoursBk })} /> */}
            </div>
        </>

    );
}

export default TabOpeningHours;