
import React, { useEffect } from 'react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import SunEditor, { buttonList } from "suneditor-react";
import parse from 'html-react-parser';


const RichTextComp = (props) => {

    return (
        <>
            <SunEditor lang="he" onChange={(content) => props.saveEditorTxt(content)}
                setDefaultStyle="font-family: arial; font-size: 15px; direction:rtl" formats="Div"
                // defaultValue={props.firstText}
                setContents={props.firstText}
                setOptions={{
                    height: (props.height != null) ? props.height : 200,
                    width: props.width ? props.width : "80%",
                    buttonList: [

                        ["undo", "redo"],
                        // ["font", "fontSize", "formatBlock"],
                        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                        ["removeFormat"],
                        "/",
                        ["fontColor", "hiliteColor"],
                        ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "table"],
                        ["link", "image", "video"],
                        ["codeView"],
                    ]
                }} />

        </>

    )
}

export default RichTextComp