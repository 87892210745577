import React, { useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const InputSet = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState(false);

    const saveMe = () => {

        if (inputTxt !== "") {
            props.okClick(inputTxt);

            handleClose();
        }
        else {
            setErr(true);
        }
    };


    const handleClose = () => {
        props.cancelClick();
    };

    return (
        <Dialog open={open}
            className={`textSizeDiag ${props.diagCss}`}>
            <DialogTitle>{props.Title}</DialogTitle>
            <DialogContent>
                <DialogContentText component="div">
                    {props.ContentText}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={props.inLabel}
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(e) => { setInputTxt(e.target.value); setErr(false) }}
                />

                {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}
            </DialogContent>
            <DialogActions>
                <button type="button" className="onLeft btn btn-primary" onClick={handleClose}>ביטול</button>
                <button type="button" className="onLeft btn btn-success" onClick={() => saveMe()}>{props.okCLickTxt || "שמור"}</button>
            </DialogActions>
        </Dialog>
    );
}

export default InputSet;