import React, { useState, createContext, useEffect } from 'react';

export const RestContext = createContext();

export const RestContextProvider = props => {
    const [rest, setRest] = useState([]);

    useEffect(() => {
    }, [rest])


    return (
        <RestContext.Provider value={[rest, setRest]}>
            {props.children}
        </RestContext.Provider>
    )
}

export const TheMenuContext = createContext();

export const MenuContextProvider = props => {
    const [theMenu, setTheMenu] = useState([]);

    useEffect(() => {
       
    }, [theMenu])


    return (
        <TheMenuContext.Provider value={[theMenu, setTheMenu]}>
            {props.children}
        </TheMenuContext.Provider>
    )
}

