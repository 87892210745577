import { MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';

const ReportTypeSelect = (props) => {

    const [reportType, setReportType] = useState((props.reportType != null) ? props.reportType : 6);


    const handleChange = (e) => {

        setReportType(e.target.value);
        (e.target.value != 12) ?
            props.selectedRepo(e.target.value)
            :
            props.showAllOpen()
    }

    return (
        <select
            className={`${props.onLeft} selectInBlockMobi`}
            value={reportType}
            onChange={handleChange}>

            <option value={6}>פתוחות היום</option>
            <option value={7}>הכל מהיום</option>

            <option value={8}>אתמול</option>

            <option value={9}>מתחילת החודש</option>
            <option value={10}>לפי תאריכים</option>
            <option value={12}>פתוחות הכל</option>

        </select>
    );
}

export default ReportTypeSelect;