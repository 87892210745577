import React, { Component, useContext, useEffect, useState } from 'react';

import { ArrowCircleDownSharp } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material"
import { Link } from "react-router-dom";
import { UserContext } from "../../../MyContexts/Users_Context";
import TopBigSlides from "../../../MySections/DTechComps/TopBigSlides";
import { useHistory } from 'react-router';
import utilsUsers from "../../../../MyConns/_Utils/utilsUsers";
import InputSet from "../../../MySections/DTechComps/InputSet";
import utilsCodes from "../../../../MyConns/_Utils/utilsCodes";
import PopUpRegular from "../../../MySections/DTechComps/PopUpRegular";
import PopUpMsg from "../../../MySections/DTechComps/PopUpMsg";
import myFunctions from '../../../../myFunctions';


const UserDetails = (props) => {
   
    const [user, setUser] = useContext(UserContext);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");

    const [readIt, setReadIt] = useState(false);
    const [thePopUp, setThePopUp] = useState("");


    const history = useHistory();
    // const [topSlides, setTopSlides] = useState();

    const [theErrors, setTheErrors] = useState("");
    const [errorsTxts, setErrorsTxts] = useState("");


    let jumcodeChek = 0;
    let tryLoop = -1;

    useEffect(() => {
     
        // getToPSldData();
    }, [])

    // const getToPSldData = async () => {
    //     setTopSlides(<TopBigSlides TopImages={master.mainTopImages} />)
    // }

    const saveUser = () => {

        let stopMe = false;
        let thEr = "";
        let errs = [];
        if (firstName.trim().length <= 1) {
            thEr = "firstName";
        }
        if (lastName.trim().length <= 1) {
            thEr += ",lastName";
        }
        if (!myFunctions.validPhone(phone)) {
            thEr += ",phone";
            errs = (errs === "") ? "יש להזין מספר טלפון תקין" : errs;

        }
        if (!myFunctions.validEmail(email)) {
            thEr += ",email";
            errs = (errs === "") ? "יש להזין כתובת מייל חוקית" : errs;
        }
        if (!myFunctions.validPassword(password)) {
            thEr += ",password";
            errs = (errs === "") ? "יש להזין סיסמא חזקה יותר.\n על הסיסמא להכיל מינמום 6 תווים, אות גדולה באנגלית, אות קטנה באנגלית ותו מיוחד ( לדוגמא: ! או $) " : errs;
        }
        if (!readIt) {
            thEr += ",readIt";
            errs = (errs === "") ? "יש לאשר את קריאת התקנון" : errs;
        }

        if (thEr === "") {
            createUserApply();
        }

        setTheErrors(thEr)
        setErrorsTxts(errs)

    }

    const createUserApply = async () => {
        tryLoop++;
        // console.log(tryLoop)
        let newUsr = { firstName, lastName, phone, email, password, readIt, tryLoop }
        let codeReply = await utilsCodes.createCodeNuser(newUsr);

        if (codeReply.errormsg != null) {
            setErrorsTxts(codeReply.errormsg);
            setThePopUp();
            if (codeReply.loopUp === true) setPhone("");
        }
        else {
            // console.log(codeReply.nId)
            jumcodeChek++;
            setThePopUp(
                <PopUpRegular key={jumcodeChek} Title={["אימות פרטים"]}
                    ContentText={["ברגעים אלו נשלח אליך קוד לטלפון", "נא להקיש את הקוד"]} inLabel={""}
                    okClick={(codeCheck) => checkForCode(codeCheck, codeReply.nId)}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => createUserApply()}
                    errorMsg={""}
                />
            )
        }
    }

    const checkForCode = async (codeCheck, nId) => {
        let newUsr = { firstName, lastName, phone, email, password, readIt, tryLoop }
        let codeReply = await utilsCodes.checkCode(nId, { codeCheck, newUsr });
        // console.log(codeReply)

        if (codeReply.errMsg != "Created") {
            jumcodeChek++;
            setThePopUp(
                <PopUpRegular key={jumcodeChek} Title={["אימות פרטים"]}
                    ContentText={["ברגעים אלו נשלח אליך קוד לטלפון", "נא להקיש את הקוד"]} inLabel={""}
                    okClick={(codeCheck) => checkForCode(codeCheck, nId)}
                    cancelClick={() => setThePopUp()}
                    recreateCode={() => createUserApply()}
                    errorMsg={codeReply.errMsg}
                />)
        }
        else {
            setThePopUp(
                <PopUpMsg key={jumcodeChek} Title={["ברוכים הבאים"]}
                    ContentText={[`היי ${firstName}`, "אנחנו ממש שמחים שהצטרפת אלינו!"]} inLabel={""}
                    okClick={() => goToBack()}
                    errorMsg={""} />
            );
            setUser(codeReply);


        }
    }

    const goToBack = () => {
        history.goBack();

        // if (document.referrer.indexOf(window.location.host) === -1) {
        // }
        // else {
        //      history.push('/'); }
    }

    return (
        <div className={"loginPage"}>
            <div className={"headOnFixC SecondFont bold big"}>
                הרשמה
                <div className={`backSd`}>
                    <ArrowCircleDownSharp onClick={() => goToBack()} />
                </div>
            </div>
            <br /><br /><br /><br />

            {/* {topSlides} */}

            <div className={"firstMastSec"}>
                <div className={"insideFrame"}>


                    <div className={"bold huge"}>הפרטים שלך</div>
                    <br />
                    {
                        (theErrors != "") ?
                            <div className={"errorLine"}>
                                {(!theErrors.includes("firstName") &&
                                    !theErrors.includes("lastName") &&
                                    errorsTxts != "") ?
                                    <p className={"vsmall bold"}>- {errorsTxts}</p> :
                                    <p className={"big bold"}>{"כל התאים המסומנים הינם חובה"}</p>}

                            </div>
                            : (errorsTxts != "") ?
                                <div className={"errorLine"}>
                                    <p className={"vsmall bold"}>- {errorsTxts}</p> </div> : ""
                    }


                    {/* <div className={"bold regular"}>על מנת להמשיך יש צורך להתחבר</div> */}
                    <div className={`usrDet details ${(theErrors.includes("firstName") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={firstName} placeholder={"שם פרטי"} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("lastName") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={lastName} placeholder={"שם משפחה"} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("phone") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={phone} placeholder={"טלפון נייד (לקבלת SMS)"} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("email") ? "errorMe" : "")}`} >
                        <input type={"text"} className={"connInput"} value={email} placeholder={"אימייל"} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
                    </div>
                    <br />
                    <div className={`usrDet details ${(theErrors.includes("password") ? "errorMe" : "")}`} >
                        <input type={"password"} className={"connInput"} value={password} placeholder={"סיסמא"} onChange={(e) => setPassword(e.target.value)} autoComplete="new-password" />
                    </div>
                    <br />
                    <div className={`usrDetChk  details ${(theErrors.includes("readIt") ? "errorMe" : "")}`} >
                        <Checkbox checked={readIt} onChange={() => setReadIt(readIt ? false : true)} className="chkBx" />
                        <div className={"readItTxt"}><span>קראתי ואני מסכים/ה לתנאי </span><Link className={"readItLink"} to={""}>התקנון</Link></div>
                    </div>
                    <br />

                    <div className={"details"}>
                        <div className={"bold big ConDetailsBtn"} onClick={() => saveUser()}>הרשם</div>
                        {/* <input className={"bold big ConDetailsBtn"} type="submit" value="הרשם" /> */}
                    </div>


                </div>

            </div>
            {thePopUp}
        </div >
    )

}

export default UserDetails