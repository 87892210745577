import { Checkbox, MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import OrderFunctions from '../../../../MyFunctions/OrderFunctions';
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';
import OrderStatuselect from './OrderStatuselect';

const ListOrdersTable = (props) => {

    const [dispList, setDispList] = useState(props.theList);

    const [hideCancels, setHideCancels] = useState(true);


  
    useEffect(() => {
        setTheDispList(hideCancels);
    }, [props.theList])

    const orderBythis = (byWhat, ordUserOrBuiss) => {
        props.orderBythis(byWhat, ordUserOrBuiss);
    }
    const rowCellClick = (ordId) => {
        props.rowCellClick(ordId);
    }

    const setNewStatus = (orderId, statBack, buisId) => {
        props.setNewStatus(orderId, statBack, buisId);
    }


    const setDisplayCancels_Changed = (chkOrNOt) => {

        setHideCancels(chkOrNOt)
        setTheDispList(chkOrNOt)

    }

    const setTheDispList = (hideCancelsCheck) => {
        if (hideCancelsCheck) {
            setDispList(props.theList.filter(item => item.ord.orderStatus !== "מבוטל"))
        }
        else {
            setDispList(props.theList)
        }
    }

    

    return (
        <div className="row lstOrderTbls">
            <div className="col-xl-12">

                <div className={"fullRow no_print"}>
                    <Checkbox
                        checked={hideCancels}
                        onChange={(e) => setDisplayCancels_Changed(e.target.checked)}
                        className={"chkBx"}
                    /><label className={"pointing"} onClick={() => setDisplayCancels_Changed(!hideCancels)}>הסתר ביטולים בטבלא</label>
                </div>

                <div className="table-responsive ms-panel-body">
                    <table className="table table-hover thead-primary">
                        <thead>
                            <tr>
                                <th scope="col" className={"pointing  no_print"} onClick={() => { }}>_</th>
                                <th scope="col" className={"pointing blackOnPrint"} onClick={() => orderBythis("orderNUM", "ord")}>מספר הזמנה</th>
                                <th scope="col" className={"pointing blackOnPrint"} onClick={() => orderBythis("buissName", "buiss")}>שם העסק</th>
                                <th scope="col" className={"pointing blackOnPrint"} onClick={() => orderBythis("payt", "ord")}>תשלום</th>
                                <th scope="col" className={"pointing blackOnPrint"} onClick={() => orderBythis("firstName", "theUser")}>שם הלקוח</th>
                                <th scope="col" className={"pointing blackOnPrint"} onClick={() => orderBythis("deliveryAddress.street", "ord")}>יעד</th>
                                <th scope="col" className={"pointing blackOnPrint"} onClick={() => orderBythis("orderStatus", "ord")}>סטטוס הזמנה</th>
                                <th scope="col" className={"pointing blackOnPrint"} onClick={() => orderBythis("dateCreated", "ord")}>מועד ההזמנה</th>
                                {/* <th scope="col" className={"pointing no_print"}>תזמון</th> */}
                                <th scope="col" className={"pointing blackOnPrint"}>מחיר</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dispList.map((item, i) => (
                                    <tr key={item.ord._id} className={"pointing"} itmid={item.ord._id}>
                                        <td className={"no_print"} scope="row">
                                            <Checkbox checked={false} onChange={() => { }} className="chkBx" />
                                        </td>
                                        <td scope="row" onClick={() => rowCellClick(item.ord._id)}>{item.ord.orderNUM}</td>
                                        <td onClick={() => rowCellClick(item.ord._id)}>{item.buiss.buissName}</td>
                                        <td onClick={() => rowCellClick(item.ord._id)}>{OrderFunctions.payingTYpe(item)}</td>
                                        <td onClick={() => rowCellClick(item.ord._id)}> {item.theUser.firstName}  {item.theUser.lastName}</td>

                                        <td onClick={() => rowCellClick(item.ord._id)}>
                                            {(item.ord.OrderType === "Deliver") ?
                                                `${item.ord.deliveryAddress.street} ${item.ord.deliveryAddress.num}`
                                                :
                                                "TAKE"}
                                        </td>
                                        <td className={"statusCell no_print pointing"} >
                                            <OrderStatuselect orderStatus={item.ord.orderStatus}
                                                setOrderStatus={(statBack) => setNewStatus(item.ord._id, statBack, item.buiss._id)}
                                            />
                                        </td>
                                        <td className={"statusCell onlyOnPrint "}>
                                            {item.ord.orderStatus}
                                        </td>
                                        <td onClick={() => rowCellClick(item.ord._id)}>{MyDateTime.getDateAndTime(item.ord.dateCreated)}</td>

                                        <td onClick={() => rowCellClick(item.ord._id)}>₪{OrderFunctions.getOrderTotalPrice(item.ord)}</td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ListOrdersTable;