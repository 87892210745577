import React, { Component, useEffect, useContext } from 'react';
import Sidenavigation1 from '../../layouts/Sidenavigation1';
import Topnavigation from '../../layouts/Topnavigation';
import OrderLst from './OrdersList-list/OrderLst'
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';
import myFunctions from '../../../myFunctions';


const Orderslist = (props) => {


    useEffect(() => {
        myFunctions.setGetCurrnetTab("Set", "pg", "pgBuisDetails");

    }, [])
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>
                    <Sidenavigation1 />
                    <main className="body-content">
                        <Topnavigation />
                        <OrderLst />
                    </main>
                    {/* <Quickbar /> */}
            </TheMasterContextProvider>
        </div>
    );
}

export default Orderslist;
