import { Dialog, Button, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { HighlightOffOutlined } from '@mui/icons-material';
import utilsMediaList from '../../../MyConns/_Utils/utilsMediaList';
import PopSelectImage from "./PopSelectImage";
import ConnUrls from "../../../MyConns/Connections/ConnectURL";


///בחירת תמונה
const SelectImgComp = (props) => {

    const [diag, setDiag] = useState("");


    const selectImgClickOpen = (firstImgToDisp, theFunction) => {
        setDiag(
            <Dialog onClose={() => setDiag()} open={true} className={"popUpWrapMedia"}>
                <div className={"inDiag"}>
                    <HighlightOffOutlined sx={{ fontSize: 40 }}
                        className={"pointing closeDiagBtn"} onClick={() => setDiag()} />
                    <PopSelectImage firstImg={firstImgToDisp} handleClose={() => setDiag()}
                        selectedImg=
                        {(fUrl) => {
                            theFunction(fUrl);
                            setDiag();
                        }} />

                </div>
            </Dialog>
        )

    };


    return (
        <>
            <div className={"input-group"} id={`imgcomp${props.indx}`}>
                <img className={props.imgCss ? props.imgCss : "mediumImgDisp pointing"}  
                onClick={() => selectImgClickOpen(props.firstImgToDisp ? props.firstImgToDisp : "", props.theFunction)} 
                    src={props.firstImgToDisp ? ConnUrls.servMediasUrl + props.firstImgToDisp :
                        ConnUrls.mediasLocalUrl + "/PermanentImages/AddImageBlk.png"} />
            </div>
            {diag}

        </>
    )
}

export default SelectImgComp;