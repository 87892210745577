import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import TextField from '@mui/material/TextField';
import OpeningHours from '../../../../MySections/TimeSections/OpeningHours';
import { Accordion, Card } from 'react-bootstrap';
import { RestContext } from '../../../../MyContexts/Rest_Context';
import CityesDelOptions from '../../../../MySections/DTechComps/CityesDelOptions';
import { UserContext } from '../../../../MyContexts/Users_Context';
import { Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import BuissTableSettings from '../../../TblOrdersP/BuissTableSettings';
import CloseDelay from '../../../../MySections/DTechComps/CloseDelay';


const TabOrdersOptions = (props) => {

    const [rest] = useContext(RestContext);
    const [user] = useContext(UserContext);

    const [buisReservation, setBuisReservation] = useState(rest.buisReservation);
    const [buissIsOnline, setBuissIsOnline] = useState(rest.buissIsOnline);

    const [takeAway, setTakeAway] = useState(rest.TakeAway);
    const [delivery, setDelivery] = useState(rest.Delivery);
    const [deliveryHours, setDeliveryHours] = useState(rest.DeliveryHours);
    const [sendiRestName, setSendiRestName] = useState(rest.sendiRestName);
    const [buisGetKopns, setBuisGetKopns] = useState(rest.buisGetKopns);
    const [closeTakeDelay, setCloseTakeDelay] = useState(rest.closeTakeDelay || { delaydVal: "0" });
    const [closeDeliverDelay, setCloseDeliverDelay] = useState(rest.closeDeliverDelay || { delaydVal: "0" });



    const [tableSettings, setTableSettings] = useState("");


    useEffect(() => {
        if (!buisReservation) {
            setTableSettings(false)
        }
    }, [buisReservation])

   
    return (
        <>
            <div className={"col-md-12 mb-2 spaceMargeRightbig"}>
                <div className={"fullRow spaceTopBig"} style={{ color: "black" }}>
                    <label htmlFor={"validationCustom5_96"}>אופן פעילות</label>
                </div>
                {user.permiss === "admin" ?
                    <>
                        <div className={"col-md-12 onRight"}>
                            <div className={"col-md-3 mb-2 tblHours onRight"}>
                                <div className={"input-group switchMe onRight"}>
                                    <label className="ms-switch">
                                        <input type="checkbox" id={"validationCustom5_1"} checked={buissIsOnline}
                                            onChange={() => { rest["buissIsOnline"] = !buissIsOnline; setBuissIsOnline(!buissIsOnline) }} />
                                        <span className="ms-switch-slider ms-switch-primary round" />
                                    </label> <span className={"spHead onRight"}>פעיל אונליין</span>
                                </div>
                            </div>
                            <div className={"col-md-3 mb-2 tblHours onRight"}>
                                <div className={"input-group switchMe onRight"}>
                                    <label className="ms-switch">
                                        <input type="checkbox" id={"validationCustom5_1"} checked={buisGetKopns}
                                            onChange={() => { rest["buisGetKopns"] = !buisGetKopns; setBuisGetKopns(!buisGetKopns) }} />
                                        <span className="ms-switch-slider ms-switch-primary round" />
                                    </label> <span className={"spHead onRight"}>קופונים</span>
                                </div>
                            </div>
                        </div>
                    </>
                    : ""}

                <div className={"col-md-12 mb-2 tblHours fullRow"}>
                    <div className={"col-md-3 onRight"}>
                        <div className={"input-group switchMe onRight"}>
                            <label className="ms-switch">
                                <input type="checkbox" id={"validationCustom5_31"} disabled={closeTakeDelay.delaydVal !== "0"} checked={takeAway.Doing}
                                    onChange={() => {
                                        rest["TakeAway"] = { Doing: !takeAway.Doing, Time: takeAway.Time };
                                        setTakeAway({ Doing: !takeAway.Doing, Time: takeAway.Time })

                                    }} />
                                <span className={`ms-switch-slider ms-switch-primary round ${closeTakeDelay.delaydVal !== "0" ? "disabledSwitch" : ""}`} />
                            </label> <span className={"spHead"}>איסוף</span>
                        </div>
                        <div className={"onRight"}>
                            {(takeAway.Doing && closeTakeDelay.delaydVal === "0" &&
                                <TextField className={"onRight txtField"} id="validationCustom5_32"
                                    size="small" label="זמן הכנה (דק')" value={takeAway.Time}
                                    onChange={(e) => {
                                        rest["TakeAway"] = { Doing: takeAway.Doing, Time: e.target.value };
                                        setTakeAway({ Doing: takeAway.Doing, Time: e.target.value })
                                    }} />
                            )}
                        </div>
                    </div>

                    <div className={"col-md-6 onRight"}>
                        {(takeAway.Doing &&
                            <CloseDelay key={'CloseDelaydrpTake'} closeDelay={closeTakeDelay} onLeft={"onRight"} typeToDelay={"closeTakeDelay"}
                                delayUpdate={(selected) => { rest["closeTakeDelay"] = selected; setCloseTakeDelay(selected); }} />
                        )}
                    </div>

                </div>
                <div className={"col-md-12 mb-2 tblHours fullRow spaceBottomBig"}>
                    <div className={"col-md-3 onRight"}>
                        <div className={"input-group switchMe onRight"}>
                            <label className="ms-switch">
                                <input type="checkbox" id={"validationCustom5_3"} disabled={closeDeliverDelay.delaydVal !== "0"} checked={delivery.Doing}
                                    onChange={() => {
                                        rest["Delivery"] = { Doing: !delivery.Doing, Time: delivery.Time, Price: delivery.Price, MiniDelPrice: delivery.MiniDelPrice };
                                        setDelivery({ Doing: !delivery.Doing, Time: delivery.Time, Price: delivery.Price, MiniDelPrice: delivery.MiniDelPrice })
                                    }} />
                                <span className={`ms-switch-slider ms-switch-primary round ${closeDeliverDelay.delaydVal !== "0" ? "disabledSwitch" : ""}`} />
                            </label> <span className={"spHead"}>משלוחים</span>
                        </div>

                        <div className={"onRight"}>
                            {(delivery.Doing && closeDeliverDelay.delaydVal === "0" &&
                                <TextField className={"onRight txtField"} id="validationCustom5_33"
                                    size="small" label="זמן הכנה (דק')" value={delivery.Time}
                                    onChange={(e) => {
                                        rest["Delivery"] = { Doing: delivery.Doing, Time: e.target.value, Price: delivery.Price, MiniDelPrice: delivery.MiniDelPrice };
                                        setDelivery({ Doing: delivery.Doing, Time: e.target.value, Price: delivery.Price, MiniDelPrice: delivery.MiniDelPrice })
                                    }} />
                            )}
                        </div>

                    </div>

                    <div className={"col-md-6 onRight"}>
                        {(delivery.Doing &&
                            <CloseDelay key={'CloseDelaydrpDeliver'} closeDelay={closeDeliverDelay} onLeft={"onRight"} typeToDelay={"closeDeliverDelay"}
                                delayUpdate={(selected) => { rest["closeDeliverDelay"] = selected; setCloseDeliverDelay(selected); }} />
                        )}
                    </div>
                </div>

                {delivery.Doing &&
                    <>
                        <div className={"col-md-12 onRight"}>
                            <div className={"col-md-3 mb-2 onRight"}>
                                <TextField className={"onRight txtField"}
                                    id="validationCustom5_37" size="small" label="דמי משלוח (₪)" value={delivery.Price}
                                    onChange={(e) => {
                                        rest["Delivery"] = { Doing: delivery.Doing, Time: delivery.Time, Price: e.target.value, MiniDelPrice: delivery.MiniDelPrice };
                                        setDelivery({ Doing: delivery.Doing, Time: delivery.Time, Price: e.target.value, MiniDelPrice: delivery.MiniDelPrice })
                                    }} />
                            </div>
                            <div className={"col-md-3 mb-2 onRight"}>
                                <TextField className={"onRight txtField"} id="validationCustom5_39" size="small" label="מינימום מחיר הזמנה(₪)" value={delivery.MiniDelPrice}
                                    onChange={(e) => {
                                        rest["Delivery"] = { Doing: delivery.Doing, Time: delivery.Time, Price: delivery.Price, MiniDelPrice: e.target.value };
                                        setDelivery({ Doing: delivery.Doing, Time: delivery.Time, Price: delivery.Price, MiniDelPrice: e.target.value })
                                    }} />
                            </div>
                            <div className={"col-md-3 mb-2 onRight"}>
                                <CityesDelOptions deliveryCitys={rest["deliveryCitys"]}
                                    updateDelCitys={(citysBack) => rest["deliveryCitys"] = citysBack} />
                            </div>
                        </div>
                        {user.permiss === "admin" &&
                            <div className={"col-md-6 mb-2 onRight"} style={{ color: "black" }}>
                                <label htmlFor={"validationCustom1_96"}>סנדימן</label>
                                <div className={"input-group"}>
                                    <input type="text" className={"form-control"} id={"validationCustom1_96"} placeholder={"sendi_rest_name"} value={sendiRestName}
                                        onChange={(e) => { rest["sendiRestName"] = e.target.value; setSendiRestName(e.target.value) }} />

                                </div>
                            </div>
                        }

                    </>
                }


            </div>
        </>

    );
}

export default TabOrdersOptions;