import myFunctions from "../../myFunctions";
import BuisUsers from "../_ApiConn/apiBuisUsers";



const getAllBuisiUsers = async () => {

    const token = localStorage.getItem('token');
    let dFromF = await BuisUsers.getAllBuisiUsers({token});
    return (dFromF);
}

const buisUserLogin = async (obj) => {

    let dFromF = await BuisUsers.buisUserLogin(obj);
    return (dFromF);
}

const updatePermiss = async (newVal,usrId) => {
    const token = localStorage.getItem('token');
    let dFromF = await BuisUsers.updatePermiss({newVal,usrId,token});
    return (dFromF);
}

const updateUsersList = async (tempList) => {
    const token = localStorage.getItem('token');
    let dFromF = await BuisUsers.updateUsersList({tempList,token});
    return (dFromF);
}


//מקבל רשימת עסקים שמיועדים למחיקה ומוחק אותם
const removeBuisList = async (tempList) => {

    const token = localStorage.getItem('token');
    let dFromF = await BuisUsers.removeBuisList({tempList,token});
    return (dFromF);
}


const checkToken = async (token) => {

    let dFromF = await BuisUsers.checkToken(token);
    return (dFromF);
}

const buisUserChangePassword = async (email) => {

    let token = localStorage.getItem("token");
    let dFromF = await BuisUsers.buisUserChangePassword(email, token);
    return (dFromF);
}


const buisUserChangePasswordApprove = async (passwordToSet, token) => {

    let dFromF = await BuisUsers.buisUserChangePasswordApprove(passwordToSet, token);
    return (dFromF);
}


const updateBuisUser = async (nUser) => {
    let token = localStorage.getItem("token");
    let dFromF = await BuisUsers.updateBuisUser(nUser, token);
    return (dFromF);
}


const createNewBuisUsr = async (nUser) => {
    let token = localStorage.getItem("token");
    let dFromF = await BuisUsers.createNewBuisUsr(nUser, token);
    return (dFromF);
}


export default {
    buisUserLogin,
    checkToken,
    buisUserChangePassword,
    buisUserChangePasswordApprove,
    updateBuisUser,
    getAllBuisiUsers,
    updatePermiss,
    updateUsersList,
    createNewBuisUsr,
    removeBuisList
};