import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const createSinglePage = async () => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "singleP/sp/cr");
    return (dFromF);
}

const deleteSinglePage = async (id, obj) => {
    let dFromF = await axios.delete(ConnUrls.ApiUrl + "singleP/sp/" + id, obj);
    return (dFromF);
}
const allPgsNames = async (token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "singleP/sp/allPgsNames", { token });

    return (dFromF);
}
const allConpagsNames = async (token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "singleP/sp/allConpagsNames", { token });

    return (dFromF);
}



const getSinglePageById = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "singleP/" + id);
    return (dFromF);
}

const updateSingle = async (id, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "singleP/" + id, obj);
    return (dFromF);
}


const getPageByName = async (name, obj) => {

    let dFromF = await axios.get(ConnUrls.ApiUrl + "singleP/bn/" + name, obj);
    return (dFromF);
}

export default {
    getSinglePageById,
    updateSingle,
    getPageByName,
    createSinglePage,
    allPgsNames,
    deleteSinglePage,
    allConpagsNames

}; 