import React, { useContext, useEffect, useState } from 'react';
import { MobiOrNotContext } from '../../../../MyContexts/MobiOrNotContext';
import { DeliveryDining, LocalMallOutlined } from '@mui/icons-material';
import OrdersHistoryItem from './OrdersHistoryItem';
import utilsOrders from '../../../../../MyConns/_Utils/utilsOrders';

const OrdersHistoryList = (props) => {
    // const [order, setOrder] = useContext(OrderContext);

    const [histOrdrs, setHistOrdrs] = useState([])
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext)

    const [intervalID, setIntervalID] = useState();
    const [shown, setShown] = useState(false);

    const maxCLosedToSHow = props.maxCLosedToSHow || 10;
    let countit = 0;
    useEffect(() => {
        getList();

    }, [])


    const getList = async () => {
        let ordslst = await utilsOrders.getOrdersHistoryList(props.clentId);
        setHistOrdrs(ordslst);

    }

    const showThisOrder = (orderDivId) => {
        var ordDisp = window.document.getElementById(orderDivId);

        if (!ordDisp.classList.contains("showMe")) {
            ordDisp.classList.add("showMe");
            ordDisp.classList.remove("hideMe");

        } else {
            ordDisp.classList.add("hideMe");
            ordDisp.classList.remove("showMe");
        }
    }




    const orderConfirmd = (ord) => {
        // props.orderConfirmd(ord);

    }

    return (
        <div className={"MyOrdersLst historys"}>

            <div className={"insideFrame"}>

                {
                    histOrdrs.reverse().map((ord, indx) => {

                        countit++;
                        if (indx < maxCLosedToSHow) {
                            return (

                                <OrdersHistoryItem theOrder={ord}
                                    key={indx}
                                    ordrIndex={indx}
                                    orderConfirmd={(ordBack) => orderConfirmd(ordBack)}
                                    showThisOrder={(orderDivId) => showThisOrder(orderDivId)}
                                />


                            )
                        }
                        else {
                        }
                    })
                }
            </div>

        </div>





    )

}

export default OrdersHistoryList