import blackLst from "../_ApiConn/apiBlackList";


const getBlackList = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await blackLst.getBlackList({ token });
    return (dFromF);
}


const updateBlackL = async (tempList) => {
    const token = localStorage.getItem('token');
    let dFromF = await blackLst.updateBlackL({tempList,token});
    return (dFromF);
}


const removeFromBlackL = async (id) => {
    const token = localStorage.getItem('token');
    let dFromF = await blackLst.removeFromBlackL({ id,token });
    return (dFromF);
}



export default {
    getBlackList,
    removeFromBlackL,
    updateBlackL

};