import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const addCategury = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "categurys", obj);
    return (dFromF);
}

const getAllCategurys = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "categurys");
    return (dFromF);
}

const getCateguryById = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "categurys/" + id);
    return (dFromF);
}
const getRestsByCatId = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "RestsByCat/" + id);
    return (dFromF);
}

const updateCategury = async (id, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "categurys/" + id, obj);
    return (dFromF);
}

const removeCategury = async (id, token) => {
    let dFromF = await axios.delete(ConnUrls.ApiUrl + "categurys/" + id, { data: { token } });
    return (dFromF.data);
}


export default {
    getAllCategurys,
    getCateguryById,
    getRestsByCatId,
    updateCategury,
    addCategury,
    removeCategury
};