import { isFunction } from "jquery";
import React, { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Breadcrumb from './Breadcrumb';
import utilsCategurys from "../../../../MyConns/_Utils/utilsCategurys";
import EraseCheck from "../../../MySections/PopUps/EraseCheck";
import InputSet from "../../../MySections/PopUps/InputSet";
import { AddBoxOutlined, HighlightOffOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Dialog, Stack } from "@mui/material";
import SucessToast from '../../../MySections/Toasts/SucessToast';
import ConnUrls from "../../../../MyConns/Connections/ConnectURL";
import PopSelectImage from "../../../MySections/ImagesSetter/PopSelectImage";
import myFunctions from "../../../../myFunctions";
import RichTextComp from "../../../MySections/TextEditor/RichText";
import { Accordion, Card } from "react-bootstrap";
import TabPageDetails from "../../compsForAllPages/TabPageDetails";
import SpecialPaegDetails from "../../compsForAllPages/SpecialPaegDetails";

const CategurysList = (props) => {

    const [sourceCats, setSourceCats] = useState([]);
    const [allCats, setAllCats] = useState([]);
    const [catsToUpdate, setCatsToUpdate] = useState([]);
    const [catsToRemove, setCatsToRemove] = useState([]);
    const [thePopUp, setThePopUp] = useState("");
    const [diag, setDiag] = useState("");


    useEffect(() => {
        getCats();
    }, [])





    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };


    const setUp = (index) => {

        let temp = array_move([...allCats], index, index - 1);
        temp.forEach((element, indx) => {
            element.catPosition = indx;
        });
        setAllCats(temp);
    }

    const setDown = (index) => {
        let temp = array_move([...allCats], index, index + 1);
        temp.forEach((element, indx) => {
            element.catPosition = indx;
        });
        setAllCats(temp);
    }


    const UpdateTheList = () => {


        let tempMnu = [...allCats];

        let tmLst = tempMnu.sort((a, b) => (a.catPosition > b.catPosition)).forEach(async (elm, indx) => {

            let temp = { ...elm, catPosition: indx }
            await utilsCategurys.updateCategury(temp._id, temp);

        });


    }


    const getCats = async () => {
        let rstLst = await utilsCategurys.getAllCategurys();
        let rstLstbk = rstLst;
        rstLstbk = myFunctions.sortAsc(rstLstbk, "catPosition");

        setAllCats(rstLstbk);
        setSourceCats(rstLstbk);

    }



    const createNewCategury = () => {
        setThePopUp(
            <InputSet Title={"יצירת קטגוריה"}
                ContentText={"שם הקטגוריה"} inLabel={""}
                okClick={(textBack) => createCateguryOK(textBack)}
                cancelClick={() => setThePopUp()}
                errorMsg={"על מנת ליצור קטגוריה חדשה, חובה לתת לה שם."}
            />)
    }
    const createCateguryOK = async (textBack) => {
        let newCat = { catHead: textBack, catImage: "", catText: "" };
        let tempMnu = [...allCats];

        tempMnu.push(newCat);
        let rstLst = await utilsCategurys.addCategury(newCat);
        setAllCats(rstLst);
        setSourceCats(rstLst);

    }


    const removeCat = (index) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את הקטגוריה?'} sucecssFunc={() => removeCatConfirmd(index)} />)
    }

    const removeCatConfirmd = (index) => {
        let tempMnu = [...allCats];

        if (index !== -1) {
            let tmp = [...catsToRemove];
            tmp.push(tempMnu[index]);
            setCatsToRemove(tmp)
        }


        tempMnu.splice(index, 1);
        setAllCats(tempMnu);
    }


    const changeCatHead = (index, e) => {
        let tempMnu = [...allCats];
        tempMnu[index].catHead = e.target.value

        let getIt = catsToUpdate.findIndex(ct => ct._id === tempMnu[index]._id)

        let tmp = [...catsToUpdate];

        if (getIt === -1) {
            tmp.push(tempMnu[index]);
            setCatsToUpdate(tmp)
        }
        else {
            tmp[getIt] = tempMnu[index];
            setCatsToUpdate(tmp)
        }


        setAllCats(tempMnu);
    }

    const changeCatTxt = (index, e) => {
        let tempMnu = [...allCats];
        tempMnu[index].catText = e

        let getIt = catsToUpdate.findIndex(ct => ct._id === tempMnu[index]._id)

        let tmp = [...catsToUpdate];

        if (getIt === -1) {
            tmp.push(tempMnu[index]);
            setCatsToUpdate(tmp)
        }
        else {
            tmp[getIt] = tempMnu[index];
            setCatsToUpdate(tmp)
        }

        setAllCats(tempMnu);
    }

    const updateTheCats = async () => {

        UpdateTheList();
        catsToUpdate.forEach(async (item, index) => {
            let tempo = sourceCats.findIndex(el => el._id === item._id)
            let rstLst = await utilsCategurys.updateCategury(sourceCats[tempo]._id, item);

        })
        catsToRemove.forEach(async (item, index) => {
            let tempo = sourceCats.findIndex(el => el._id === item._id)
          
            let rstLst = await utilsCategurys.removeCategury(sourceCats[tempo]._id);
        })


        SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
    }

    const selectImgClickOpen = (cat, index) => {
        setDiag(
            <Dialog onClose={() => setDiag()} open={true} className={"popUpWrapMedia"}>
                <div className={"inDiag"}>
                    <HighlightOffOutlined sx={{ fontSize: 40 }}
                        className={"pointing closeDiagBtn"} onClick={() => setDiag()} />
                    <PopSelectImage firstImg={cat.catImage} handleClose={() => setDiag()}
                        selectedImg=
                        {(fUrl) => {

                            let tempMnu = [...allCats];
                            tempMnu[index].catImage = fUrl

                            let getIt = catsToUpdate.findIndex(ct => ct._id === tempMnu[index]._id)

                            let tmp = [...catsToUpdate];

                            if (getIt === -1) {
                                tmp.push(tempMnu[index]);
                                setCatsToUpdate(tmp)
                            }
                            else {
                                tmp[getIt] = tempMnu[index];
                                setCatsToUpdate(tmp)
                            }


                            setAllCats(tempMnu);
                            setDiag();
                        }} />

                </div>
            </Dialog>
        )

    };


    const updatePDetails = (thepage, key, val) => {

        let tempo = { ...thepage, [key]: val }

        let tempMnu = [...allCats];

        let indx = tempMnu.findIndex(ct => ct._id === tempo._id)

        tempMnu[indx] = tempo;

        let getIt = catsToUpdate.findIndex(ct => ct._id === tempo._id)

        let tmp = [...catsToUpdate];

        if (getIt === -1) {
            tmp.push(tempMnu[indx]);
            setCatsToUpdate(tmp)
        }
        else {
            tmp[getIt] = tempMnu[indx];
            setCatsToUpdate(tmp)
        }


        setAllCats(tempMnu);


    }



    return (
        <>
            <div className="ms-content-wrapper categurysList">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                        {/* Active Orders Graph */}
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <div><h6>כל הקטגוריות</h6></div>
                                        <div className={"buisTollBar"}>
                                            <Stack direction="row" spacing={2}>
                                                <Button variant="contained" className={"theSBtn"} onClick={() => createNewCategury()}
                                                    startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                                                    חדש
                                                </Button>
                                            </Stack>

                                        </div>
                                    </div>
                                    <div className="ms-panel-body">
                                        <div className="table-responsive">

                                            <Accordion key={"pagma"} defaultActiveKey="-1" className="has-gap ms-accordion-chevron catsAcord">
                                                <Card>
                                                    <Accordion.Collapse className="collapseparent" eventKey={"pagma" + 1}>
                                                        <Card.Body>
                                                            <table className="table table-hover thead-primary">

                                                                <tbody>
                                                                    <tr >
                                                                        <td colSpan={4}>
                                                                            <SpecialPaegDetails pgToSet="pgPageDetails" thePageName={"קטגוריות"} />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                    <Accordion.Toggle as={Card.Header} eventKey={"pagma" + 1}>
                                                        <span>{"הגדרות עמוד"}</span>

                                                    </Accordion.Toggle>

                                                </Card>
                                            </Accordion>
                                            {allCats.map((item, index) => (

                                                <Accordion key={index} defaultActiveKey="-1" className="has-gap ms-accordion-chevron catsAcord">
                                                    <Card>
                                                        <Accordion.Collapse className="collapseparent" eventKey={index + 1}>
                                                            <Card.Body>
                                                                <table className="table table-hover thead-primary">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">שם הקטגוריה</th>
                                                                            <th scope="col">תמונה</th>
                                                                            <th scope="col">טקסט קצר</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr key={item._id} itmid={item._id} className={"innnerRow"}>
                                                                            <td scope="row" className={"innnerTD"}>
                                                                                <input type={"text"} value={item.catHead} onChange={(e) => changeCatHead(index, e)} />
                                                                            </td>
                                                                            <td scope="row" className={"innnerTD"}>
                                                                                <div className={"col-md-12 mb-2"}>
                                                                                    <div className={"input-group theCteguryImg"} onClick={() => selectImgClickOpen(item, index)} >
                                                                                        <img className={"medImgDisp pointing"}
                                                                                            src={item.catImage ? ConnUrls.servMediasUrl + item.catImage :
                                                                                                ConnUrls.mediasLocalUrl + "/PermanentImages/AddImageBlk.png"} />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td scope="row" className={"innnerTD"}>
                                                                                <div className={"txtAreaCats"}>
                                                                                    <RichTextComp firstText={item.catText}
                                                                                        saveEditorTxt={(content) => changeCatTxt(index, content)} />
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                        <tr >
                                                                            <td colSpan={4}>
                                                                                <TabPageDetails key={`${index}_tabpDetails`} pgToSet="pgPageDetails" thePage={item} thePageUpdate={(key, val) => updatePDetails(item, key, val)} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                        <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                                                            <span>{item.catHead}</span>

                                                        </Accordion.Toggle>
                                                        <div className={"acoordBtns"}>
                                                            <div className={"acoordIcon acord1 pointing"} onClick={() => removeCat(index)}><i className="far fa-trash-alt theSecIconsColor onLeft" /></div>
                                                            {index != 0 && allCats.length > 1 ? <div className={"acoordIcon acord2 pointing"} onClick={() => setUp(index)} title={"הקפץ למעלה"}><i className="far fa-caret-square-up theSecIconsColor onLeft" /></div> : ""}
                                                            {index < allCats.length - 1 ? <div className={"acoordIcon acord3 pointing"} onClick={() => setDown(index)} title={"הקפץ למטה"}><i className="far fa-caret-square-down theSecIconsColor onLeft" /></div> : ""}

                                                        </div>
                                                    </Card>
                                                </Accordion>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ms-panel-header approveLine">
                    <button type="button" className="onLeft btn btn-primary" onClick={() => updateTheCats()}>שמור</button>
                </div>
            </div>

            {thePopUp}
            {diag}
        </>

    );
}

export default CategurysList;