import React, { useState, createContext, useEffect } from 'react';
import utilsRests from '../../MyConns/_Utils/utilsRests';
import myFunctions from '../../myFunctions';

export const BuisListForTablesContext = createContext();

export const BuisListForTablesContextProvider = props => {
    const [buisLstTables, setBuisLstTables] = useState([]);

    useEffect(() => {
        
        const getBuisLst = async () => {

            if (buisLstTables.length === 0) {
                let tempolst = await utilsRests.getRestsForTablesListSmall();
                tempolst = myFunctions.sortAsc(tempolst, "buissName");
                tempolst = myFunctions.addIdtag(tempolst);
                setBuisLstTables(tempolst)
            }
        }

        getBuisLst();
    }, [])



    return (
        <BuisListForTablesContext.Provider value={[buisLstTables, setBuisLstTables]}>
            {props.children}
        </BuisListForTablesContext.Provider>
    )
}



