import { Autocomplete, MenuItem, Select, TextField } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import myFunctions from '../../../../myFunctions';

const BuisSelect_ForTable = (props) => {

    const [busissList, setBusissList] = useState((props.busissList != null) ? props.busissList : []);
    const [userSelection, setUserSelection] = useState(myFunctions.getBuisNameByIdInList(busissList, props.userSelection))

    const [buisOpts, setBuisOpts] = useState([]);

    useEffect(() => {
        setTheOpts()

    }, [])

    useEffect(() => {
        setUserSelection(myFunctions.getBuisNameByIdInList(busissList, props.userSelection))
    }, [props.userSelection])

    const selChanged = (e) => {
        if (e) {
            setUserSelection(e.label)
            props.setUserBuis(e.inVal)
        }
        else {
            setUserSelection("כל העסקים")
            props.setUserBuis("-1")

        }

    }


    const setTheOpts = () => setBuisOpts(busissList.map((item, index) => {
        return (
            { key: index, inVal: item._id, value: item.buissName, label: item.buissName }
        )
    }))


    return (

        <Autocomplete
            className={`selectInBlock ${props.clss ? props.clss : ""}`}
            disablePortal
            value={userSelection}
            options={buisOpts}
            sx={{ maxWidth: "300px" }}
            onChange={(event, e) => {
                selChanged(e);
            }}
            renderInput={(params) => <TextField {...params} className={"specialTxt"} placeholder='כל העסקים' />}
        />
    )

}

export default BuisSelect_ForTable;