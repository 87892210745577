import rests from "../_ApiConn/apiSilo";


const getAllFromSilo = async () => {
    let dFromF = await rests.getAllFromSilo();
    return (dFromF.data);
}

const createSilo = async (obj) => {
    let dFromF = await rests.createSilo(obj);
    return (dFromF);
}



const clearSilo = async () => {
    let dFromF = await rests.clearSilo();
    return (dFromF.data);
}
const removeFromSilo = async (id) => {
    let dFromF = await rests.removeFromSilo(id);
    return (dFromF.data);
}

const clearItmsFromSilo = async (lst) => {
    let dFromF = await rests.clearItmsFromSilo(lst);
    return (dFromF.data);
}



export default {
    getAllFromSilo,
    createSilo,
    removeFromSilo,
    clearSilo,
    clearItmsFromSilo
    
};