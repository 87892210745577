import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, DeleteForever, Visibility } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import myFunctions from '../../../myFunctions';
import BuisSelect from '../OrdersP/OrdersList-list/BuisSelect';
import UserPermission from '../OrdersP/OrdersList-list/UserPermission';
import Pagination from '@mui/material/Pagination';

const ListClientUsersBlocks = (props) => {
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    useEffect(() => { }, []);

    const setFirstName = (e) => { };

    const setLastName = (e) => { };

    const setPhone = (e) => { };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <div className="table-responsive theBigTbl ms-panel-body">
            {props.usersList.slice(startIndex, endIndex).map((item, index) => {
                let itmBlock =
                    item.blocked === undefined || item.blocked === false ? false : true;

                return (

                    <div className={"grayFrame innnerRow smallPadding"} key={item._id}>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>שם פרטי</span>
                            </div>
                            <div className={"fullRow"}>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder={"שם פרטי"}
                                    value={item.firstName}
                                    onChange={(e) => {
                                        setFirstName(e);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>שם משפחה</span>
                            </div>
                            <div className={"fullRow"}>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder={"שם משפחה"}
                                    value={item.lastName}
                                    onChange={(e) => {
                                        setLastName(e);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>טלפון</span>
                            </div>
                            <div className={"fullRow"}>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder={"טלפון"}
                                    value={item.phone}
                                    onChange={(e) => {
                                        setPhone(e);
                                    }}
                                />
                            </div>
                        </div>

                        <div className={"fullRow"}>


                            <div className={"col-stretch onRight spaceMargeTopbig"} style={{ backgroundColor: 'white' }}>

                                <FormControlLabel className={"noMargin"} label="חסום משתמש"
                                    control={<Checkbox
                                        icon={<CheckBoxOutlineBlankOutlined sx={{ color: 'var(--mainColor)' }} />}
                                        checkedIcon={<CheckBoxOutlined sx={{ color: 'var(--mainColor)' }} />}
                                        sx={{}}
                                        className={"spaceMargeLeftbig pointing"}
                                        checked={itmBlock}
                                        onChange={(e) => {
                                            props.blockUser(item, index, !itmBlock)
                                        }}
                                    />
                                    }
                                />

                            </div>
                            <div className="col-stretch onLeft spaceMargeRight">
                                <Button className={"btnMediumMnu"} variant="contained"
                                    onClick={() => props.showClient(item._id)}
                                    startIcon={
                                        <Visibility className={"visibleHover"} sx={{ color: 'white' }} />
                                    }
                                >
                                    הצג לקוח
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            })}
            <Pagination
                count={Math.ceil(props.usersList.length / itemsPerPage)}
                sx={{ direction: 'ltr' }}
                page={page}
                onChange={handlePageChange}
                color="primary"
            />
        </div >
    );
};

export default ListClientUsersBlocks;

