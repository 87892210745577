import singleP from "../_ApiConn/apiSinglePage";


const getSinglePageById = async (idToGet) => {
    let dFromF = await singleP.getSinglePageById(idToGet);
    return (dFromF.data);
}

const allPgsNames = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await singleP.allPgsNames(token);
    return (dFromF.data);
}




const deleteSinglePage = async (id, obj) => {
    let dFromF = await singleP.deleteSinglePage(id, obj);
    return (dFromF.data);
}


const updateSingleP = async (currSingle) => {

    if (currSingle._id) {
        let dFromF = await singleP.updateSingle(currSingle._id, currSingle);
        return (dFromF.data);
    }

}

const createSinglePage = async () => {
    let dFromF = await singleP.createSinglePage();
    return (dFromF.data);
}

const getPageByName = async (name) => {
    let dFromF = await singleP.getPageByName(name, { obj: "" });
    return (dFromF.data[0]);
}

export default {
    getSinglePageById,
    updateSingleP,
    getPageByName,
    createSinglePage,
    allPgsNames,
    deleteSinglePage
    
};