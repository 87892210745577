import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import he from 'date-fns/locale/he';

const DatePick = forwardRef((props, ref) => {
  const [selectedDate, setSelectedDate] = useState(props.selectedDate);

  useEffect(() => {
    setSelectedDate(props.selectedDate);
  }, [props.selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.settingFunc(date);
  };

  // Expose a function to update selectedDate from parent component
  useImperativeHandle(ref, () => ({
    setSelectedDate,
  }));

  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        locale={he}
        months={he.months}
        weekdaysShort={he.weekdaysShort}
        customInput={<CustomInput label={props.lbl || ""} Css={props.Css || ""} selectedDate={selectedDate} />}
        className={`custom-datepicker ${props.Css}`}
      />
    </div>
  );
});

const CustomInput = ({ label, Css, selectedDate, onClick }) => {
  const formattedDate = selectedDate ? selectedDate.toLocaleDateString('he-IL') : '';

  return (
    <div>
      <input className={Css}
        value={label ? `${label} ${formattedDate}` : formattedDate}
        onClick={onClick}
        readOnly
      />
    </div>
  );
};

export default DatePick;
