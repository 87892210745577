import { Dialog, Button, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExitToApp, FileUpload, CreateNewFolder, HighlightOffOutlined, VisibilityOutlined, CheckCircle } from '@mui/icons-material';
import utilsMediaList from '../../../MyConns/_Utils/utilsMediaList';
import { map } from "jquery";
import ConnUrls from "../../../MyConns/Connections/ConnectURL";
import Swal from "sweetalert2";
import EraseCheck from "../PopUps/EraseCheck";
import InputSet from "../PopUps/InputSet";
import ImagePopUp from "../PopUps/ImagePopUp";


const PopSelectMultiImages = (props) => {

    let [mediaList, setMediaList] = useState([]);
    const [currentDisplay, setCurrentDisplay] = useState([]);
    const [currentPosition, setCurrentPosition] = useState({ fileUrl: "images/Uploads/" });
    const [thePopUp, setThePopUp] = useState("");
    const [selImg, setSelImg] = useState({ item: { fileUrl: props.firstImg } });
    const [thePosi, setThePosi] = useState();
    const [imageslst, setImageslst] = useState(props.imgsList);


    useEffect(() => {
        if (props.imgsList?.length > 0) {
            let fSet = props.imgsList[0].img.replace(ConnUrls.servMediasUrl, "").split('/');
            fSet.pop();
            let jon = fSet.join('/');

            currentPosition.fileUrl = jon + "/";
        }
    }, [])

    useEffect(() => {
        setTheList()
    }, [currentPosition, selImg, imageslst])



    const addNewFolder = () => {
        setThePopUp(
            <InputSet Title={"יצירת תיקייה"}
                ContentText={"שם התיקייה"} inLabel={""}
                okClick={(textBack) => createFolderOK(textBack)}
                cancelClick={() => setThePopUp()}
                errorMsg={"על מנת ליצור תיקייה חדשה, חובה לתת לה שם."}
            />)
    }

    const removeFolder = (fileUrl) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את התיקייה?'} sucecssFunc={() => removeFolderConfirmd(fileUrl)} />)
    }

    const removeFile = (fileUrl) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את הקובץ?'} sucecssFunc={() => removeFileConfirmd(fileUrl)} />)
    }

    const uploadNewFile = async (filesToUp) => {
        for (let i = 0; i < filesToUp.length; i++) {
            let mdlist = await utilsMediaList.addNewFile({ fileToUp: filesToUp[i], Path: currentPosition.fileUrl });
        }
        setTheList();
        setThePopUp();

    }

    const removeFileConfirmd = async (fileUrl) => {
        let mdlist = await utilsMediaList.removeFile({ StartingPath: fileUrl });
        setTheList();

    }

    const removeFolderConfirmd = async (fileUrl) => {
        let mdlist = await utilsMediaList.removeFolder({ StartingPath: fileUrl });
        setTheList();

    }


    const setTheList = async () => {

        let theList = await utilsMediaList.getMediaList
            ({ StartingPath: currentPosition.fileUrl });

        mediaList = theList;
        setMediaList(theList)

        getListCurrnetF();
        setThePosi(getThePosi());

    }
    const updateFile = async (newFileName, imgItm) => {

        let mdlist = await utilsMediaList.updateFile
            ({ fName: imgItm.name, StartingPath: imgItm.fileUrl, newFileName: newFileName });

        setTheList();
        setThePopUp();

    }

    const removeFromLst = (itemUrl) => {

        let temp = [...imageslst];
        let index = temp.indexOf(itemUrl);
        temp.splice(index, 1);

        setImageslst(temp);
    }

    const createFolderOK = async (textBack) => {

        let mdlist = await utilsMediaList.createFolder
            ({ FolderName: textBack, Path: currentPosition.fileUrl });

        setTheList();
        setThePopUp();
    }
    let imgsCount = 0;

    const getListCurrnetF = () => {
        imgsCount++;

        let fldsList =
            mediaList.folderList.map((item, index) => {
                // if (item.extension === "") {

                return (
                    <div key={index} className={"popImagesItemWrap"}>
                        <HighlightOffOutlined sx={{ fontSize: 20 }}
                            className={"pointing removeOnImages"} onClick={() => removeFolder(item.folderUrl)} />
                        <div className={"popImagesItem pointing"} title={item.folderName}
                            onClick={() => setCurrentPosition({ ...item, fileUrl: item.fixPath })}>
                            <img src={ConnUrls.mediasLocalUrl + "/PermanentImages/FolderIcon.png"} alt={item.folderName} />
                            <p className={""}>{item.folderName}</p>
                        </div>
                    </div >
                )

            })

        let filsLst =
            mediaList.fileList.map((item, index) => {
                return (
                    <div key={index} className={"popImagesItemWrap"}>
                        <HighlightOffOutlined sx={{ fontSize: 20 }}
                            className={"pointing removeOnImages"} onClick={() => removeFile(item.fileUrl)} />
                        <VisibilityOutlined sx={{ fontSize: 20 }}
                            className={"pointing ViewOnImages"} onClick={() => openImage(item)} />
                        <div className={"popImagesItem pointing"} title={item.name}>
                            <CheckCircle sx={{ fontSize: 40 }}
                                className={`pointing selectedImgIcon ${(imageslst.findIndex(el => el.img === item.fileUrl) > -1) ? "" : "hideMe"}`}
                                onClick={() => removeFromLst(item.fileUrl)} />
                            <img src={item.fileUrl} alt={item.fileName} onClick={() => setImageslst([...imageslst, { img: item.fileUrl, txt: "" }])} />
                            <p className={""}>{item.name}</p>
                        </div>
                    </div>
                )
            })

        let mergedList = fldsList.concat(filsLst);


        setCurrentDisplay(mergedList);

    }



    const goBkOfFolder = () => {
        let tempo = currentPosition.fileUrl.split('/');
        tempo.pop();
        let jon = tempo.join('/');

        setCurrentPosition({ fileUrl: jon + "/" });
    }

    const goToSplt = (index) => {

        let tempo = currentPosition.fileUrl.split('/');

        while (index < tempo.length - 1)
            tempo.pop();

        let jon = tempo.join('/');

        setCurrentPosition({ fileUrl: jon + "/" });
    }



    const getThePosi = () => {
        let headLinks = [];

        headLinks.push({ Id: 0, Name: "Media" });

        let spl = currentPosition.fileUrl.split('/');

        let theLnk = spl.map((item, index) => {
            return (<span key={index} className="brd pointing" onClick={() => goToSplt(index)}>{index != 0 ? " > " : ""} {item}</span>)
        })
        return (
            <div className="thePosi bold huge">
                {currentPosition.fileUrl.split('/').length > 1 ?
                    <ExitToApp sx={{ fontSize: 30 }} className={"pointing"} onClick={() => goBkOfFolder()} /> :
                    <ExitToApp sx={{ fontSize: 30, color: "gray" }} className={"pointing"} />}
                {theLnk}

            </div>
        )
    }


    const openImage = (imgItm) => {

        setThePopUp(
            <ImagePopUp Title={imgItm.name}
                okClick={(fName) => updateFile(fName, imgItm)}
                cancelClick={() => setThePopUp()}
                imgSrc={ConnUrls.servMediasUrl + imgItm.fileUrl}
                imageName={imgItm.name}
                cancelText={"סגור"}
                saveText={"עדכן וסגור"}
            />)
    }

    const saveTheGal = () => {
        props.imagesArray(imageslst)

    }

    return (
        <>
            <div className="PopTopLine">
                <div className="IconsLine">
                    <Button variant="contained" onClick={() => addNewFolder()}
                        startIcon={<CreateNewFolder style={{ color: 'white' }} className={"pointing"} />}>
                        יצירת תיקיה
                    </Button>

                    <label className={"uploadBtn css-sghohy-MuiButtonBase-root-MuiButton-root pointing"} htmlFor="raised-button-file">
                        <FileUpload sx={{ fontSize: 20 }} className={"pointing"} title={"העלאת קובץ"} />העלאת קובץ
                    </label>
                    <input
                        accept="image/*"
                        className={""}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={(event) => uploadNewFile(event.target.files)}
                    />
                </div>
                {thePosi}
            </div>
            <div className={"popSelectImgBox"}>

                {currentDisplay}
                {thePopUp}
            </div>
            <div className="bottomBtnsline">
                <button type="button" className="onLeft btn btn-success btn-primary" onClick={() => saveTheGal()}>שמור</button>
                <button type="button" className="onLeft btn btn-primary" onClick={() => props.handleClose()}>סגור</button>
            </div>

        </>
    )
}

export default PopSelectMultiImages;