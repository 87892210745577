import moment from "moment";
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ConnUrls from "../../../../MyConns/Connections/ConnectURL";
import utilsOrders from '../../../../MyConns/_Utils/utilsOrders';
import OrderFunctions from '../../../../MyFunctions/OrderFunctions';
import myFunctions from '../../../../myFunctions';
import { MobiOrNotContext } from "../../../MyContexts/MobiOrNotContext";
import MySpinner_Wait from '../../../MySections/DTechComps/MySpinner_Wait';
import SelectImgComp from "../../../MySections/ImagesSetter/SelectImgComp";
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';
import Breadcrumb from './Breadcrumb';
import BuisBarReports from './BuisBarReports';
import ListOrdersBlocks from './ListOrdersBlocks';
import ListOrdersTable from './ListOrdersTable';
import { Button, LinearProgress } from "@mui/material";
import PopUpTheComp from "../../../MySections/DTechComps/PopUpTheComp";
import ReportCalcs from "./ReportCalcs";
import MyInvoice from "./Invoice/Invoice";
import { TheMasterContext } from "../../../MyContexts/Master_Context";
import utilsMaster from "../../../../MyConns/_Utils/utilsMaster";
import TotalsBarObjects from "./TotalsBarObjects";
import { Checkbox } from '@mui/material';
import ListBuisViewTable from "./TotalBuissReports/ListBuisViewTable";
import LinearDeterminate from "../../../MySections/DTechComps/LinearDeterminate";
import CircularProgressWithLabel from "../../../MySections/DTechComps/CircularProgressWithLabel";



const ReportsLst = (props) => {

    const history = useHistory();

    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [theMaster, setTheMaster] = useContext(TheMasterContext);
    const [userBuis, setUserBuis] = useState("-1");
    const [orderlisttable, setOrderlisttable] = useState([]);

    const [thePopUp, setThePopUp] = useState("");
    const [srchText, setSrchText] = useState("");
    const [startDTS, setStartDTS] = useState();
    const [endDTS, setEndDTS] = useState();

    const [totOfAll, setTotOfAll] = useState({ totClear: 0, totCancels: 0 });
    const [totOfDelivery, setTotOfDelivery] = useState({ totClear: 0, totCancels: 0 });
    const [totOfTakaway, setTotOfTakaway] = useState({ totClear: 0, totCancels: 0 });
    const [totOfCash, setTotOfCash] = useState({ totClear: 0, totCancels: 0 });
    const [totOfCreditcard, setTotOfCreditcard] = useState({ totClear: 0, totCancels: 0 });
    const [listBuisView, setListBuisView] = useState(true);


    const [showBetweenDts, setShowBetweenDts] = useState(false);
    const [reportigm, setReportigm] = useState(theMaster.reportigm || "");

    const [lstCounter, setLstCounter] = useState(null);
    const [selectedBtn, setSelectedBtn] = useState(myFunctions.setGetCurrnetTab("Get", "reportsLstBtn") || 1);
    const [listSordted, setListSordted] = useState({ sort: false, sortBy: "Asc" });
    const [intervalID, setIntervalID] = useState(null);
    const [loading, setLoading] = useState(false);


    const [blingOn, setBlingOn] = useState(true);
    const [allOrdersCount, setAllOrdersCount] = useState(0);

    const [reportHeadTimes, setReportHeadTimes] = useState("חודש שעבר");
    const [reportHeadTyp, setReportHeadTyp] = useState("");

    let dispPayType = "הכל";


    useEffect(() => {
        myFunctions.connCheck();
    })

    useEffect(() => {
        checkForMaster();
    }, [])



    const checkForMaster = async () => {
        if (theMaster.length === 0) {
            let mast = await utilsMaster.getMaster();
            setReportigm(mast.reportigm)
            setTheMaster(mast)
        }
    }



    const showAllOrders = async (btnInt) => {

        let OrdrsList = await utilsOrders.getAllOrders(startDTS, endDTS);
        setOrderlisttable(OrdrsList);
        getTheTotal(OrdrsList)
        getTheTotOfDelivery(OrdrsList)
        getTheTotOfTakaway(OrdrsList)
        getTheTotOfCash(OrdrsList)
        getTheTotOfCreditcard(OrdrsList)
        setSrchText("");
        // getTimes(OrdrsList)
        setSelectedBtn(btnInt)

    }


    const showByType = async (typ, btnInt) => {

        let OrdrsList = await utilsOrders.getOrdByType(typ);

        setOrderlisttable(OrdrsList);
        getTheTotal(OrdrsList)
        getTheTotOfDelivery(OrdrsList)
        getTheTotOfTakaway(OrdrsList)
        getTheTotOfCash(OrdrsList)
        getTheTotOfCreditcard(OrdrsList)
        setSrchText("")

        // getTimes(OrdrsList)

        setSelectedBtn(btnInt)

    }

    const setAllLists = (btnInt, OrdrsList) => {
        setLoading(true)
        let tempoList = [...OrdrsList];


        if (dispPayType != "הכל") {
            tempoList = (dispPayType.toString() === "2") ?
                OrdrsList.filter(or => or.ord.payt === dispPayType) :
                OrdrsList.filter(or => or.ord.payt.toString() != "2")
        }
        // console.log(tempoList)

        setLstCounter(tempoList.length)
        setOrderlisttable(tempoList);
        getTheTotal(tempoList)
        getTheTotOfDelivery(tempoList)
        getTheTotOfTakaway(tempoList)
        getTheTotOfCash(tempoList)
        getTheTotOfCreditcard(tempoList)
        setSrchText("");
        setSelectedBtn(btnInt)
        myFunctions.setGetCurrnetTab("Set", "reportsLstBtn", btnInt)

        setLoading(false)
    }

    const refreshTheList = (btnInt, payTypeDisp) => {
        dispPayType = payTypeDisp;

        const buttonFunctions = {
            "6": () => { showTodaysOpen(); setShowBetweenDts(false) },
            "7": () => { showTodaysAll(); setShowBetweenDts(false) },
            "8": () => { showYesterdayAll(); setShowBetweenDts(false) },
            "9": () => { showMounth(); setShowBetweenDts(false) },
            "10": () => {
                setShowBetweenDts(true);
                // showByDates(10);
                // setSelectedBtn(10);
            },
            "11": showByDates(10),
            "12": () => { showAllOpen(); setShowBetweenDts(false) },
            "13": () => { lastMounth(); setShowBetweenDts(false) }
        };


        const selectedFunction = buttonFunctions[btnInt.toString()] || showTodaysOpen;
        selectedFunction(btnInt);


    }


    //פתוחות היום
    const showTodaysOpen = async (btnInt) => {
        setLoading(true)
        var today = MyDateTime.getSpecificDate(new Date(), 0);
        let fixToday = parseInt(moment(today).hours()) > 5 ? today : MyDateTime.getSpecificDate(today, -1);

        var yesterday = parseInt(moment(today).hours()) > 5 ?
            MyDateTime.setTimeByStr(today, { hh: "05", mm: "00", ss: "00" }) :
            MyDateTime.setTimeByStr(MyDateTime.getSpecificDate(today, -1), { hh: "05", mm: "00", ss: "00" });

        setStartDTS(fixToday);
        setEndDTS(yesterday);

        let allordrscnt = await utilsOrders.getAllOrdersCount(yesterday, today, userBuis);
        setAllOrdersCount(allordrscnt);

        let OrdrsList = await utilsOrders.getOpenOrders(yesterday, today, userBuis);

        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);



        setAllLists(btnInt, OrdrsList);
        setLoading(false)

    }

    //כל הפתוחות
    const showAllOpen = async (btnInt) => {
        setLoading(true)
        var today = MyDateTime.getSpecificDate(new Date(), 0);
        var yesterday = MyDateTime.getSpecificDate(today, -730);

        setStartDTS(today);
        setEndDTS(yesterday);

        let allordrscnt = await utilsOrders.getAllOrdersCount(yesterday, today, userBuis);
        setAllOrdersCount(allordrscnt);

        let OrdrsList = await utilsOrders.getOpenOrders(yesterday, today, userBuis);

        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);
        setLoading(false)
    }


    //הכל מהיום
    const showTodaysAll = async (btnInt) => {
        setLoading(true)
        var today = MyDateTime.getSpecificDate(new Date(), 0);
        var yesterday = MyDateTime.setTimeByStr(today, { hh: "05", mm: "00", ss: "00" })

        setStartDTS(today);
        setEndDTS(yesterday);


        let allordrscnt = await utilsOrders.getAllOrdersCount(yesterday, today, userBuis);
        setAllOrdersCount(allordrscnt);

        let OrdrsList = await utilsOrders.getAllOrders(yesterday, today, userBuis);
        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);
        setLoading(false)
    }



    //הכל מאתמול
    const showYesterdayAll = async (btnInt) => {
        setLoading(true)
        var day1 = MyDateTime.getSpecificDate(new Date(), -1);
        day1 = MyDateTime.setTimeByStr(day1, { hh: "05", mm: "00", ss: "00" })

        var day2 = MyDateTime.getSpecificDate(day1, 1);
        var day2 = MyDateTime.setTimeByStr(day2, { hh: "05", mm: "00", ss: "00" })

        setStartDTS(day1);
        setEndDTS(day2);

        let allordrscnt = await utilsOrders.getAllOrdersCount(day1, day2, userBuis);
        setAllOrdersCount(allordrscnt);

        let OrdrsList = await utilsOrders.getAllOrders(day1, day2, userBuis);

        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);
        setLoading(false)
    }

    //הכל חודש
    const showMounth = async (btnInt) => {
        setLoading(true)
        var day1 = MyDateTime.getSpecificDate(new Date(), 0);
        var day2 = MyDateTime.getFirstDayinMounth(day1);

        setStartDTS(day1);
        setEndDTS(day2);

        let allordrscnt = await utilsOrders.getAllOrdersCount(day2, day1, userBuis);
        setAllOrdersCount(allordrscnt);

        let OrdrsList = await utilsOrders.getAllOrders(day2, day1, userBuis);
        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);
        setLoading(false);
    }

    //לפי תאריכים
    const showByDates = async (btnInt) => {
        setLoading(true)
        var day1 = MyDateTime.getSpecificDate(startDTS);
        var day2 = MyDateTime.getSpecificDate(endDTS);

        setStartDTS(day1);
        setEndDTS(day2);
        setShowBetweenDts(true)

        let allordrscnt = await utilsOrders.getAllOrdersCount(day1, day2, userBuis);
        setAllOrdersCount(allordrscnt);

        let OrdrsList = await utilsOrders.getAllOrders(day1, day2, userBuis);
        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);
        // let OrdrsList = await utilsOrders.getAllOrders(endDTS, startDTS);
        setAllLists(btnInt, OrdrsList);
        setLoading(false)
    }

    //סיכום חודש שעבר
    const lastMounth = async (btnInt) => {
        setLoading(true)
        var day1 = new Date();
        day1 = moment(day1).subtract(1, 'months').startOf('month');

        day1 = MyDateTime.setTimeByStr(day1, { hh: "05", mm: "00", ss: "00" })

        var day2 = new Date();
        day2 = moment(day2).subtract(1, 'months').endOf('month');
        var day2 = MyDateTime.setTimeByStr(day2, { hh: "23", mm: "59", ss: "59" })


        setStartDTS(day1);
        setEndDTS(day2);

        let allordrscnt = await utilsOrders.getAllOrdersCount(day1, day2, userBuis);
        setAllOrdersCount(allordrscnt);

        let OrdrsList = await utilsOrders.getAllOrders(day1, day2, userBuis);

        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);
        setLoading(false)
    }

    const setNewStatus = async (orderId, statBack, buisId) => {

        let updteme = await utilsOrders.updateStatus(orderId, statBack);

        let temp = orderlisttable.findIndex(it => it.ord._id === orderId);
        orderlisttable[temp].ord.orderStatus = statBack;

        setAllLists(selectedBtn, orderlisttable);

        // socket.emit('orderUpdated', { buisId });

    }




    const rowCellClick = (ordId) => {
        // stopHandler();
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"fullRow"}
                popDiagStyle={""}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["פרטי הזמנה"]}
                theInsideComp={
                    <MyInvoice ordId={ordId} />}
            />
        )
        // history.push('/Order/' + ordId);
    }

    const checkIfEnter = (e, byWhat) => {
        if (e.key === 'Enter') {
            searchOrder(byWhat);
        }
    }

    const searchOrder = async (byWhat) => {
        setLoading(true)
        let OrdrsBk = await utilsOrders.getOrderBySearch(srchText, startDTS, endDTS, byWhat);
        if (OrdrsBk != null) {
            setOrderlisttable(OrdrsBk);
            getTheTotal(OrdrsBk)
            getTheTotOfDelivery(OrdrsBk)
            getTheTotOfTakaway(OrdrsBk)
            getTheTotOfCash(OrdrsBk)
            getTheTotOfCreditcard(OrdrsBk)
            // getTimes(OrdrsBk)
        }
        setLoading(false)

    }

    //סכום הכנסה ללא ביטולים
    const getTheTotal = (OrdrsBk) => {
        let totClear = 0;
        let totCancels = 0;
        OrdrsBk.forEach(element => {
            if (element.ord.orderStatus !== "מבוטל") {
                totClear += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
            }
            else {
                totCancels += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
            }
        });

        setTotOfAll({ totClear, totCancels })

    }

    const getTheTotOfDelivery = (OrdrsBk) => {
        let totClear = 0;
        let totCancels = 0;
        OrdrsBk.forEach(element => {
            if (element.ord.OrderType === "Deliver") {

                if (element.ord.orderStatus !== "מבוטל") {
                    totClear += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
                }
                else {
                    totCancels += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
                }
            }
        });

        setTotOfDelivery({ totClear, totCancels })
    }

    const getTheTotOfTakaway = (OrdrsBk) => {
        let totClear = 0;
        let totCancels = 0;
        OrdrsBk.forEach(element => {
            if (element.ord.OrderType != "Deliver") {

                if (element.ord.orderStatus !== "מבוטל") {
                    totClear += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
                }
                else {
                    totCancels += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
                }

            }
        });

        setTotOfTakaway({ totClear, totCancels })

    }

    const getTheTotOfCash = (OrdrsBk) => {
        let totClear = 0;
        let totCancels = 0;
        OrdrsBk.forEach(element => {
            if (element.ord.payt === "2") {
                if (element.ord.orderStatus !== "מבוטל") {
                    totClear += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
                }
                else {
                    totCancels += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
                }
            }
        });

        setTotOfCash({ totClear, totCancels })
    }
    const getTheTotOfCreditcard = (OrdrsBk) => {
        let totClear = 0;
        let totCancels = 0;
        OrdrsBk.forEach(element => {
            if (element.ord.payt != "2") {
                if (element.ord.orderStatus !== "מבוטל") {
                    totClear += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
                }
                else {
                    totCancels += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
                }
            }
        });

        setTotOfCreditcard({ totClear, totCancels })
    }



    const orderBythis = (byWhat, ordUserOrBuiss) => {
        if (listSordted.sortBy === "Asc") {
            setOrderlisttable(myFunctions.sortByItemAsc(orderlisttable, byWhat, ordUserOrBuiss))
            setAllLists(selectedBtn, myFunctions.sortByItemAsc(orderlisttable, byWhat, ordUserOrBuiss));
            setListSordted({ sort: true, sortBy: "Dsc" })
        }
        else {
            setOrderlisttable(myFunctions.sortByItemDsc(orderlisttable, byWhat, ordUserOrBuiss))
            setAllLists(selectedBtn, myFunctions.sortByItemDsc(orderlisttable, byWhat, ordUserOrBuiss));
            setListSordted({ sort: true, sortBy: "Asc" })
        }


    }

    //מעדכן את התמונה שתוצג בדוחות
    const setReportsIMg = async (fUrl) => {
        theMaster.reportigm = fUrl;
        let mst = await utilsMaster.updateTheMaster(theMaster);
        setTheMaster({ ...theMaster })
        setReportigm(fUrl)

    }



    const setListBuisView_Changed = (chkOrNOt) => {

        setListBuisView(chkOrNOt)

    }



    const rowBuissViewCellClick = (BuisId) => {
    }

    // let theList = listSordted
    let theList = (!listSordted.sort && orderlisttable) ?
        (myFunctions.sortByItemDsc(orderlisttable, "dateCreated", "ord")) : orderlisttable;




    return (
        <>

            <div className="ms-content-wrapper">
                <div className="ordrspanel reportsPnl">
                    <Breadcrumb />
                    {/* Active Orders Graph */}

                    <div className="ordrspanel posRelative">
                        <div className="ms-panel">
                            {!mobiOrNot ?
                                <>
                                    <div className={"posAbsolute reportsIMgSelect no_print"}>
                                        <label htmlFor={"reportsIMg"} className={"input-group onRight"}>תמונה להדפסה</label>
                                        <SelectImgComp key={"reportigm_Selc"} imgCss={"medImgDisp pointing"} firstImgToDisp={reportigm ? reportigm : ""}
                                            theFunction={(fUrl) => { setReportsIMg(fUrl) }} />
                                    </div>
                                    <div className={"onlyOnPrint fullRow overflowHid spacePadBig"}>
                                        <img className="reportsIMgItem"
                                            src={`${ConnUrls.servMediasUrl + reportigm}`} />
                                    </div>
                                </> : ""}

                            <div className="col-md-12 onRight no_print">

                                <Checkbox
                                    checked={listBuisView}
                                    onChange={(e) => setListBuisView_Changed(e.target.checked)}
                                    className={"chkBx"}

                                /><label className={"pointing"} onClick={() => setListBuisView_Changed(!listBuisView)}>טבלת ריכוז</label>

                            </div>

                            <BuisBarReports
                                selectedBtn={selectedBtn}
                                refreshTheList={(selectedBtn, payTypeDisp) =>
                                    refreshTheList(selectedBtn, payTypeDisp)}
                                srchText={srchText}
                                // setSrchText={(txt) => setSrchText(txt)}
                                // searchOrder={(txt) => searchOrder(txt)}
                                showBetweenDts={showBetweenDts}
                                setShowBetweenDts={(btdats) => setShowBetweenDts(btdats)}
                                showAllOpen={(indx) => showAllOpen(indx)}
                                startDTS={startDTS}
                                setStartDTS={(dt) => setStartDTS(dt)}
                                endDTS={endDTS}
                                setEndDTS={(dt) => setEndDTS(dt)}
                                reportHeadTimes={reportHeadTimes}
                                reportHeadTyp={reportHeadTyp}
                                setReportHeadTimes={(hed) => setReportHeadTimes(hed)}
                                setReportHeadTyp={(hed) => setReportHeadTyp(hed)}
                                // checkIfEnter={(e,byWhat) => checkIfEnter(e,byWhat)}
                                setUserBuis={(newVal) => { setUserBuis(newVal) }}
                                soundOn={blingOn}
                                setSoundOn={(tr) => setBlingOn(tr)}



                            />
                          
                            {/* 
                                <div className={"centerText"}>
                                    <CircularProgressWithLabel counter={allOrdersCount} updateCounter={() => setAllOrdersCount(0)} />
                                </div> */}

                            {/* <LinearDeterminate counter={allOrdersCount} /> */}

                            {/* <LinearProgress variant="determinate" value={progress} />
<div>כול: {allOrdersCount}</div> */}

                            {loading ?
                                <>
                                    <MySpinner_Wait />
                                    <br />
                                    <br />
                                </>
                                :
                                <>
                                    <div className="fullRow">
                                        <TotalsBarObjects prms={{
                                            "orderlisttable": orderlisttable,
                                            "totOfDelivery": totOfDelivery,
                                            "totOfTakaway": totOfTakaway,
                                            "totOfCash": totOfCash,
                                            "totOfCreditcard": totOfCreditcard,
                                            "totOfAll": totOfAll,
                                            "userBuis": userBuis
                                        }} />
                                        <div className="col-md-12 onRight">
                                            {/* {selectedBtn && */}
                                            <div className="col-md-12 onRight">

                                                {!listBuisView ?
                                                    <>
                                                        {!mobiOrNot ?
                                                            <ListOrdersTable
                                                                theList={

                                                                    (!listSordted.sort && orderlisttable) ?
                                                                        (myFunctions.sortByItemDsc(orderlisttable, "dateCreated", "ord")) : orderlisttable
                                                                }
                                                                orderBythis={(byWhat, ordUserOrBuiss) => orderBythis(byWhat, ordUserOrBuiss)}
                                                                rowCellClick={(ordId) => rowCellClick(ordId)}
                                                                setNewStatus={(orderId, statBack, buisId) => setNewStatus(orderId, statBack, buisId)}

                                                            />
                                                            :
                                                            <ListOrdersBlocks
                                                                theList={
                                                                    (!listSordted.sort && orderlisttable) ?
                                                                        (myFunctions.sortByItemDsc(orderlisttable, "dateCreated", "ord")) : orderlisttable
                                                                }
                                                                orderBythis={(byWhat, ordUserOrBuiss) => orderBythis(byWhat, ordUserOrBuiss)}
                                                                rowCellClick={(ordId) => rowCellClick(ordId)}
                                                                setNewStatus={(orderId, statBack, buisId) => setNewStatus(orderId, statBack, buisId)}
                                                            />

                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {!mobiOrNot ?
                                                            <ListBuisViewTable
                                                                theList={

                                                                    (!listSordted.sort && orderlisttable) ?
                                                                        (myFunctions.sortByItemDsc(orderlisttable, "dateCreated", "ord")) : orderlisttable
                                                                }


                                                                orderBythis={(byWhat, ordUserOrBuiss) => orderBythis(byWhat, ordUserOrBuiss)}
                                                                rowCellClick={(buisId) => rowBuissViewCellClick(buisId)}
                                                                setNewStatus={(orderId, statBack, buisId) => setNewStatus(orderId, statBack, buisId)}
                                                                reportigm={theMaster.reportigm}
                                                                headsToPrint={{ reportHeadTimes, reportHeadTyp, startDTS, endDTS }}
                                                            />
                                                            :
                                                            // <ListOrdersBlocks
                                                            //     theList={
                                                            //         (!listSordted.sort && orderlisttable) ?
                                                            //             (myFunctions.sortByItemDsc(orderlisttable, "dateCreated", "ord")) : orderlisttable
                                                            //     }
                                                            //     orderBythis={(byWhat, ordUserOrBuiss) => orderBythis(byWhat, ordUserOrBuiss)}
                                                            //     rowCellClick={(ordId) => rowCellClick(ordId)}
                                                            //     setNewStatus={(orderId, statBack, buisId) => setNewStatus(orderId, statBack, buisId)}
                                                            // />
                                                            ""

                                                        }
                                                    </>
                                                }
                                            </div>

                                            {/* } */}

                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>


                </div >
                {thePopUp}

            </div >

        </>
    );


}

export default ReportsLst;

