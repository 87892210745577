import { DeleteForever } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import myFunctions from '../../../myFunctions';
import BuisSelect from '../OrdersP/OrdersList-list/BuisSelect';
import UserPermission from '../OrdersP/OrdersList-list/UserPermission';
import EraseCheck from '../../MySections/PopUps/EraseCheck';
import MultiBuisSelect from '../OrdersP/OrdersList-list/MultiBuisSelect';

const ListUsersBlocks = (props) => {
    const [tempoUsersList, setTempoUsersList] = useState(props.usersList);
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {

    }, [props.buisLst])

    const updatePermiss = async (newVal, usrId) => {
        let temp = [...tempoUsersList]
        let itmIndx = temp.findIndex(it => it._id === usrId);
        temp[itmIndx].permiss = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)
        //setTempList([...tempList, itm])

    }


    const setUserBuis = async (newVal, usrId) => {
        let temp = [...tempoUsersList]
        let itmIndx = temp.findIndex(it => it._id === usrId);
        temp[itmIndx].buisID = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)


    }

    const blockUser = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].blocked = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)

    }

    const setFirstName = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].firstName = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)

    }
    const setLastName = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].lastName = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)
    }


    const setPassword = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].password = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)
    }


    const setPhone = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].phone = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)
    }

    const setEmail = (index, newVal) => {
        let temp = [...tempoUsersList]
        temp[index].email = newVal;
        setTempoUsersList(temp)

        props.updateList(temp)

    }

    const removeUser = (indx) => {

        setThePopUp(<EraseCheck title={'האם בטוח למחוק את המשתמש?'}
            sucecssFunc={() => removeOptConfirmd(indx)} />)
    }

    const removeOptConfirmd = async (indx) => {

        let temp = [...tempoUsersList];
        let itmeToErease = temp[indx]
        props.addToDelete(itmeToErease)

        temp.splice(indx, 1)

        setTempoUsersList(temp)

        props.updateList(temp)



    }


    return (
        <div className="table-responsive  theBigTbl ms-panel-body">

            {props.usersList.map((item, index) => {
                let itmBlock = (item.blocked === undefined || item.blocked === false) ? false : true;

                return (
                    <div className={"innnerRow smallPadding"} key={item._id}>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>שם פרטי</span>
                            </div>
                            <div className={"fullRow"}>
                                <input type="text" className={"form-control"} placeholder={"שם פרטי"} value={item.firstName}
                                    onChange={(e) => { setFirstName(index, e.target.value) }} />
                            </div>
                        </div>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>שם משפחה</span>
                            </div>
                            <div className={"fullRow"}>
                                <input type="text" className={"form-control"} placeholder={"שם משפחה"} value={item.lastName}
                                    onChange={(e) => { setLastName(index, e.target.value) }}
                                />
                            </div>


                        </div>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>טלפון</span>
                            </div>
                            <div className={"fullRow"}>
                                <input type="text" className={"form-control"} placeholder={"טלפון"} value={item.phone}
                                    onChange={(e) => { setPhone(index, e.target.value) }} />
                            </div>

                        </div>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>אימייל</span>
                            </div>
                            <div className={"fullRow"}>
                                <input type="text" className={"form-control"} placeholder={"אימייל"} value={item.email}
                                    onChange={(e) => { setEmail(index, e.target.value) }} />
                            </div>

                        </div>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>סיסמא</span>
                            </div>
                            <div className={"fullRow"}>
                                <input type="text" className={"form-control"} placeholder={"סיסמא"} value={item.password}
                                    onChange={(e) => { setPassword(index, e.target.value) }}
                                />
                            </div>

                        </div>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>הרשאות</span>
                            </div>
                            <div className={"fullRow"}>
                                <UserPermission clss={"buisSelectOnBlock onRight"}
                                    key={`${index}_perm`}
                                    UserPermiss={item.permiss}
                                    setUserPermiss={(newVal) => { updatePermiss(newVal, item._id) }}
                                />
                            </div>

                        </div>
                        <div className={"fullRow"}>
                            <div className={"fullRow"}>
                                <span className={"orderItmTxt regular bold"}>עסק</span>
                            </div>
                            <div className={"fullRow"}>
                                <MultiBuisSelect key={index}
                                    firstHead={"כל העסקים"}
                                    clss={"buisSelectOnTable onLeft"}
                                    defaultValue={myFunctions.getBuisNameByIdInList(props.buisLst, item.buisID)}
                                    userSelection={item.buisID ? item.buisID.split(',') : []}
                                    busissList={props.buisLst}
                                    setUserBuis={(newVal) => { setUserBuis(newVal, item._id) }}
                                />
                            </div>

                        </div>
                        <div className={"fullRow"}>
                            <div className={"halfRow onRight"}>
                                <span className={"orderItmTxt regular bold"}>מחק</span>
                                <Checkbox
                                    icon={<DeleteForever sx={{ color: 'white' }} />}
                                    sx={{}}
                                    className={"ChkMyButton pointing"}
                                    onClick={() => {
                                        removeUser(index);
                                    }}
                                />
                            </div>

                            <div className={"halfRow onRight"}>

                                <div className={"fullRow"}>
                                    <span className={"orderItmTxt regular bold"}>חסום</span>
                                </div>
                                <div className={"fullRow"}>
                                    <input type="checkbox" checked={itmBlock} onChange={(e) => blockUser(index, !itmBlock)} /> <i className="ms-checkbox-check" />

                                </div>
                            </div>

                        </div>


                    </div>

                )
            })}
            {thePopUp}
        </div>
    )
}

export default ListUsersBlocks;