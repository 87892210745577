import React, { Component, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal, Accordion, Card } from "react-bootstrap";
import ConnUrls from '../../../MyConns/Connections/ConnectURL';
import utilsBuisUser from '../../../MyConns/_Utils/utilsBuisUser';
import { UserContext } from '../../MyContexts/Users_Context';
import ValidatorsCheck from '../../../MyFunctions/ValidatorsCheck';
import { TheMasterContext } from '../../MyContexts/Master_Context';




const Login = (props) => {

    const history = useHistory();
    const [user, setUser] = useContext(UserContext)

    const [showForgotPass, setShowForgotPass] = useState(false);
    const [forgotPass, setForgotPass] = useState("");
    const [forgotErr, setForgotErr] = useState("");
    const [closeBtn, setCloseBtn] = useState(false);

    const [theUserName, setTheUserName] = useState("");
    const [thePassword, setThePassword] = useState("");

    const [validateErr, setValidateErr] = useState("");

    const [validatMe, setValidatMe] = useState({
        Usr: true,
        Pas: true
    });

    useEffect(() => {
    }, [])

    const TheFrmLoad = async (e) => {
        let valiMe = {
            Usr: (theUserName != ""),
            Pas: (thePassword != "")
        }

        setValidatMe(valiMe);

        if (valiMe.Usr && valiMe.Pas) {
            let chkUsr = await utilsBuisUser.buisUserLogin({ userName: theUserName, password: thePassword });

            if (chkUsr.token) {
                localStorage.setItem('token', chkUsr.token)

                setUser(chkUsr.user)

                if (chkUsr.user.permiss === "admin") {
                    history.push("/")
                }
                else {
                    if (chkUsr.user.doOrds.ordersOn) { history.push("/Orders") }
                    else if (chkUsr.user.doOrds.tblsRsv) { history.push("/TblOrders") }
                }

            }
            else {
                setValidateErr(chkUsr.errMsg ? chkUsr.errMsg : "יש להזין שם משתמש וסיסמא")
            }
        }
    }



    const closeMe = () => {
        setCloseBtn(false);
        setShowForgotPass(false);
        setForgotErr("");
        setForgotPass("");
    }

    const changePassword = async () => {

        if (ValidatorsCheck.validEmail(forgotPass)) {

            let setPass = await utilsBuisUser.buisUserChangePassword(forgotPass);
            setForgotErr(setPass.errMsg);
            setCloseBtn(true);
        }
        else {
            setForgotErr("יש להזין כתובת מייל חוקית");
        }
    }
    return (
        <div className="ms-content-wrapper ms-auth">
            <div className="ms-auth-container">

                <div className="ms-auth-col">
                    <div className="ms-auth-form">

                        <div className="needs-validation validBLock" noValidate >
                            <div className={"firstlogo"}>
                                <img src={`${ConnUrls.mediasLocalUrl}/PermanentImages/mainLogo55.png`} alt="logo" />
                            </div>
                            <br />
                            <h3 style={{ fontFamily: "var(--SecondFont)" }}>התחברות למערכת</h3>
                            <p>יש להקליד שם משתמש וסיסמא</p>
                            <div className="mb-3">
                                <div className="input-group">
                                    <input type="text" className="form-control" name={"UserName"}
                                        onChange={(e) => setTheUserName(e.target.value)} onKeyUp={e => (e.key === 'Enter' || e.keyCode === 13) ? TheFrmLoad(e) : ""} value={theUserName} placeholder="שם משתמש" required />
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="input-group">
                                    <input type="password" className="form-control" name={"Password"}
                                        onChange={(e) => setThePassword(e.target.value)} onKeyUp={e => (e.key === 'Enter' || e.keyCode === 13) ? TheFrmLoad(e) : ""}
                                        value={thePassword} placeholder="סיסמא" required />
                                </div>
                            </div>
                            <div className="mb-2 theValBlock mediumFont">
                                <div className={`centerText ${(validatMe.Usr) ? "" : "showMe"} invalid-feedback`}>יש לספק שם משתמש.</div>
                                <div className={`centerText ${(validatMe.Pas) ? "" : "showMe"} invalid-feedback`}>יש להזין סיסמא.</div>
                                <div className={`centerText ${(validateErr === "") ? "" : "showMe"} invalid-feedback`}>{validateErr}</div>

                            </div>
                            <div className="form-group">
                                {/* <label className="ms-checkbox-wrap">
                                    <input className="form-check-input" type="checkbox" defaultValue /> <i className="ms-checkbox-check" />
                                </label> <span> זכור סיסמא </span> */}
                                <label className="d-block mt-3"><Link to="/Login" className="btn-link" onClick={() => setShowForgotPass(true)}>שכחתי סיסמא?</Link>
                                </label>
                            </div>
                            <button className="btn btn-primary mt-4 d-block w-100" onClick={(e) => TheFrmLoad(e)} type="submit" name={"SubmitBtn"}>התחברות</button>
                            {/* <p className="mb-0 mt-3 text-center">אין לך חשבון? <Link className="btn-link" to="/Register">צור חשבון</Link>
                            </p> */}
                        </div>
                    </div>
                </div>
                <div className="ms-auth-col">
                    <div className={`ms-auth-bg ms-SideLoginImg`} style={{ backgroundImage: `url("${ConnUrls.mediasLocalUrl + "/PermanentImages/53.jpg"}")` }} />
                </div>
            </div>
            <Modal className="modal-min" show={showForgotPass} onHide={() => setShowForgotPass(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body className="text-center">
                    <button type="button" className="close" onClick={() => setShowForgotPass(false)}><span aria-hidden="true">×</span></button>
                    <i className="flaticon-secure-shield d-block" />
                    <h1>?שכחת סיסמא</h1>
                    <p>יש להזין את כתובת המייל שלך לשחזור סיסמא</p>
                    <div className="ms-form-group has-icon">
                        <input type="email" placeholder="כתובת מייל לשחזור" value={forgotPass} onChange={(e) => setForgotPass(e.target.value)} className="form-control" name="buisUserChangePassword-password" required /> <i className={"material-icons"}>email</i>
                    </div>
                    <div className={""}>
                        <div className="mb-2 theValBlock">
                            <div className={`centerMargin centerText ${(forgotErr === "") ? "" : "showMe"} invalid-feedback`}>{forgotErr}</div>
                        </div>
                        <button className={`btn btn-primary centerMargin shadow-none ${closeBtn ? "hideMe" : "showMe"}`} onClick={() => changePassword()}>החלפת סיסמא</button>
                        <button className={`btn btn-primary centerMargin shadow-none ${closeBtn ? "showMe" : "hideMe"}`} onClick={() => closeMe()}>סגור</button>

                    </div>
                </Modal.Body>
            </Modal>
        </div>

    );
}

export default Login;