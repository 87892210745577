import React, { useEffect, useState } from 'react';
import utilsRests from '../../../../MyConns/_Utils/utilsRests';



const ReportCalcs = (props) => {

    const [payPrecent, setPpayPrecent] = useState("7")

    useEffect(() => {
        const getPayments = async () => {
            if (props.totals.userBuis) {
                let py = await utilsRests.getRestPayPrecent(props.totals.userBuis)
                setPpayPrecent( py.payPrecent || "7")
            }
        }
        getPayments()
    }, [props.totals.userBuis])


    const sumToPass = () => {
        return (parseInt(
            props.totals.totOfCreditcard.totClear -
            (
                (props.totals.totOfAll.totClear * payPrecent / 100) +
                ((props.totals.totOfAll.totClear * payPrecent / 100) * 0.17)
            )
        ))
    }

    return (
        <>
            <div className={"totalReportsLine textRight"}>
                <div className="col-md-8 onRight bold">
                    עסקאות במזומן:
                </div>
                <div className="col-md-4 onRight bold">{props.totals.totOfCash.totClear} ₪</div>
            </div>
            <div className={"totalReportsLine textRight"}>
                <div className="col-md-8 onRight bold">
                    עסקאות באשראי:
                </div>
                <div className="col-md-4 onRight bold">{props.totals.totOfCreditcard.totClear} ₪</div>
            </div>
            <div className={"totalReportsLine textRight"}>
                <div className="col-md-8 onRight bold">
                    סה"כ:
                </div>
                <div className="col-md-4 onRight bold">{props.totals.totOfAll.totClear} ₪</div>
            </div>

            <div className={"totalReportsLine textRight"}>
                <div className="col-md-8 onRight bold">
                    עמלה (כולל מע"מ): ({payPrecent} %)
                </div>
                <div className="col-md-4 onRight bold">{parseInt((props.totals.totOfAll.totClear * payPrecent / 100) + ((props.totals.totOfAll.totClear * payPrecent / 100) * 0.17))}</div>
            </div>

            <div className={"totalReportsLine textRight"}>
                <div className="col-md-8 onRight bold">
                    העברה ללקוח לאחר קיזוז:
                </div>
                <div className="col-md-4 onRight bold">{sumToPass()} ₪</div>
            </div>
        </>

    );
}

export default ReportCalcs;
