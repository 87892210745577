import { MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';

const TableOrderTypeSelect = (props) => {

    const [reportType, setReportType] = useState((props.reportType != null) ? props.reportType : 5);


    const handleChange = (e) => {

        setReportType(e.target.value);
        props.selectedRepo(e.target.value)
    }

    return (
        <select
            className={`${props.onLeft} selectInBlockMobi`}
            value={reportType}
            onChange={handleChange}>

            <option value={5}>ממתינים לאישור</option>
            <option value={6}>להיום</option>

            <option value={7}>למחר</option>

            <option value={14}>מהיום קדימה</option>
            <option value={11}>לפי תאריכים</option>
            <option value={9}>החודש הנוכחי</option>
            <option value={13}>כל ההזמנות</option>

        </select>
    );
}

export default TableOrderTypeSelect;