import React, { useContext, useEffect, useState } from 'react'
import utilsMaster from '../../../../MyConns/_Utils/utilsMaster';
import { TheMasterContext } from '../../../MyContexts/Master_Context';
import SelectImgComp from '../../../MySections/ImagesSetter/SelectImgComp';

const TabSiteDetails = (props) => {
    const [theMaster, setTheMaster] = useContext(TheMasterContext);

    const [mainTitle, setMainTitle] = useState(theMaster.mainTitle);
    const [manageSiteLogo, setManageSiteLogo] = useState(theMaster.manageSiteLogo);


    useEffect(() => {
        getMaster();
    }, [])



    const getMaster = async () => {
        let mst = await utilsMaster.getMaster();
        setTheMaster(mst)
        setManageSiteLogo(mst.manageSiteLogo )
    }



    const setTheLogo = (furl) => {
        theMaster.manageSiteLogo = furl;
        setManageSiteLogo(furl);
        // console.log(theMaster)
        // console.log(theMaster.manageSiteLogo)
    }


    return (
        <>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>לוגו אזור ניהול</label>
                <span className={"explanation mediumst fullROw"}>מוצג על כפתור התפריט</span>
                <SelectImgComp firstImgToDisp={manageSiteLogo ? manageSiteLogo : ""} theFunction={(fUrl) => setTheLogo(fUrl)} />
            </div>


        </>

    );
}

export default TabSiteDetails;