import React, { useContext, useEffect, useState } from 'react';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import utilsBuissTableSettings from '../../../MyConns/_Utils/utilsBuissTableSettings';
import { RestContext } from '../../MyContexts/Rest_Context';
import { UserContext } from '../../MyContexts/Users_Context';
import { Button } from '@mui/material';
import { Add, Edit, RemoveCircleOutline } from '@mui/icons-material';
import DropHouresSelection from '../../MySections/DTechComps/DropHouresSelection';
import EraseCheck from '../../MySections/PopUps/EraseCheck';
import TableItemSet from './TableItemSet';
import { TablesSetterContext } from '../../MyContexts/TablesSetter_Context';



//יוצר רשימת שעות לשימוש בהמשך
const getTimesList = () => {
    let timeSlots = [];
    let strttm = 0;
    let endttm = 5;
    for (let hour = strttm; hour <= endttm; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const formattedHour = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            timeSlots.push(`${formattedHour}:${formattedMinute}`);
        }
    }

    return (timeSlots);
};



const TablesItemsList = (props) => {

    const [tbls, setTbls] = useContext(TablesSetterContext);
    const [numberOptions] = useState(getTimesList());

    useEffect(() => {
    }, [])


    return (

        <div className="fullRow">
            {
                tbls.tables.map((tbitm, index) => {
                    return (
                        <TableItemSet key={index}
                            itmIndx={index}
                            numberOptions={numberOptions}
                        />
                    )
                })
            }
        </div>
    )
}

export default TablesItemsList;

