import React, { useContext, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RichTextComp from '../TextEditor/RichText';
import SunEditor, { buttonList } from "suneditor-react";
import utilsOrders from '../../../MyConns/_Utils/utilsOrders';
import MySpinner_Wait from '../DTechComps/MySpinner_Wait';
import { CancelRounded } from '@mui/icons-material';
import MyDateTime from '../TimeSections/MyDateTime';
import myFunctions from '../../../myFunctions';
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';

const LogsOrder = (props) => {

    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);

    const [open, setOpen] = useState(true);
    const [ordrsLog, setOrdrsLog] = useState([]);
    const [loading, setLoading] = useState(true);

    let theCount = null;

    useEffect(() => {

        loadLog();
    }, [])

    const loadLog = async () => {
        let lgs = await utilsOrders.getOrderLog(props.ordNum);
        setOrdrsLog(lgs)

        setLoading(false)
    }




    return (
        <>
            {!loading ?
                myFunctions.sortDsc(ordrsLog, "dtLog").map((item, index) => {
                    return (
                        <div key={index} className={"col-12 overflowHid grayFrame"}>
                            <div className={`${!mobiOrNot ? "col-3 onRight bold" : "col-12 bold"}`}>
                                <span>{MyDateTime.getDateAndTimeShort(item.dtLog)}</span>
                            </div>
                            <div className={`${!mobiOrNot ? "col-3 onRight" : "col-12"}`}>
                                <span>{item.updater}</span>
                            </div>
                            <div className={`${!mobiOrNot ? "col-6 onRight" : "col-12"}`}>
                                <span>{item.updateTxt}</span>
                            </div>
                        </div>
                    )
                })
                :
                <MySpinner_Wait />
            }
        </>

    );
}

export default LogsOrder;