import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";


///בחירת זמן השהיית תמונה
const SelectImgDelay = (props) => {

    const handleChange = (event) => {
        props.theFunction(event.target.value);
    };

    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{ direction: "rtl" }}>
            <Select
                labelId="imgDelay"
                id="imgDelay"
                value={(props.currentDelay != "" && props.currentDelay != null) ? props.currentDelay : "animate__delay-05s"}
                onChange={handleChange}
            >
                <MenuItem value={"animate__delay-05s"}>0.5 שניות</MenuItem>
                <MenuItem value={"animate__delay-1s"}>1 שניות</MenuItem>
                <MenuItem value={"animate__delay-2s"}>2 שניות</MenuItem>
                <MenuItem value={"animate__delay-3s"}>3 שניות</MenuItem>

            </Select >
        </FormControl >


    )
}

export default SelectImgDelay;