import React, { useState, createContext, useEffect } from 'react';

export const LinksContext = createContext();

export const LinksContextProvider = props => {
    const [Links, setLinks] = useState([]);

    useEffect(() => {
          
    }, [Links])


    return (
        <LinksContext.Provider value={[Links, setLinks]}>
            {props.children}
        </LinksContext.Provider>
    )
}

