import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card, Nav, Tab } from "react-bootstrap";
import { useParams } from 'react-router';
import Breadcrumb from './Breadcrumb'

import { useHistory } from "react-router-dom";
import SucessToast from '../../../MySections/Toasts/SucessToast';
import ErrorToast from '../../../MySections/Toasts/ErrorToast';
import { UserContext } from '../../../MyContexts/Users_Context';
import TabBuisUsrProfile from '../../compsForAllPages/TabBuisUsrProfile';
import MySpinner_Wait from '../../../MySections/DTechComps/MySpinner_Wait';
import utilsBuisUser from '../../../../MyConns/_Utils/utilsBuisUser';


const BuisUserprofileContent = (props) => {
    const history = useHistory();

    const [user, setUser] = useContext(UserContext)

    const [loading, setLoading] = useState(true);
    let idParam = useParams();
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {
        setLoading(false)
    }, [])

    const updateProfile = (contType, toUpDate) => {

        user[contType] = toUpDate;
    }

    const updateTheProfile = async () => {
        setLoading(true)
        let bkUser = await utilsBuisUser.updateBuisUser({ ...user });

        if (bkUser.errMsg === "OK") {
            setUser(bkUser.user)
            SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
        } else {
            // console.log(bkUser.errMsg)
            ErrorToast({ position: "toast-bottom-right", text: "התגלתה בעייה בשמירה" });
        }
        setLoading(false)
    }
    return (
        <div className="ms-content-wrapper SetBlock">
            {/* <Breadcrumb /> */}
            {/* Active Orders Graph */}
            <div className="row">
                <div className="col-md-12">
                    {loading ? <MySpinner_Wait /> :
                        <>
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <div><h6>פרופיל משתמש</h6></div>
                                    <div className={"buisTollBar"}>
                                    </div>
                                </div>

                                <div className="col-md-12">

                                    <div className="ms-panel-body clearfix">
                                        <div className="form-row tabsInputs ">
                                            <TabBuisUsrProfile updateProfile={(toUpDate, conType) => updateProfile(toUpDate, conType)} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="ms-panel-header approveLine">
                <button type="button" className="onLeft btn btn-primary" onClick={() => updateTheProfile()}>שמור</button>
            </div>
            {thePopUp}
        </div>
    );
}

export default BuisUserprofileContent;