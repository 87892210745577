import { MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';

const MultiLIstEditSelect = (props) => {

    const [multiSelVal, setMultiSelVal] = useState((props.multiSelVal != null) ?
        props.multiSelVal : "-1");

    const [colorforStat, setColorforStat] = useState("");

    useEffect(() => {
        setMultiSelVal(props.multiSelVal)

    }, [props.multiSelVal])

    const handleChange = (e) => {
        props.changeToStatus(e.target.value)
        setMultiSelVal(e.target.value)
    }


    return (
        <span className={`${colorforStat} 'pointing' `}>
            <select
                className={`selectInBlock`}
                value={multiSelVal}
                onChange={handleChange}>
                <option value={"-1"}>עריכה</option>
                <option value={"בהכנה"}>שנה לבהכנה</option>
                <option value={"מוכן"}>שנה למוכן</option>
                <option value={"ממתין לאיסוף"} >שנה לממתין לאיסוף</option>
                <option value={"ממתין למשלוח"} >שנה לממתין למשלוח</option>
                <option value={"נשלח"} >שנה לנשלח</option>
                <option value={"נמסר ללקוח"} >שנה לנמסר ללקוח</option>
                <option value={"סגור"} >סגור נבחרים</option>
                <option value={"מבוטל"} >בטל נבחרים</option>
            </select>
        </span>
    );
}

export default MultiLIstEditSelect;