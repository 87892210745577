import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const goGama = async (theOrder) => {

    let dFromF = await axios.post(ConnUrls.ApiUrl + "goGama", theOrder);
    return (dFromF.data);
}

const ApproveJ = async (theOrder) => {

    let dFromF = await axios.post(ConnUrls.ApiUrl + "goGama/ApproveJ", theOrder);
    return (dFromF.data);
}


export default {
    goGama,
    ApproveJ
};