import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card } from "react-bootstrap";
import SelectImgComp from '../ImagesSetter/SelectImgComp';
import { AddBoxOutlined, ArrowCircleUp } from "@mui/icons-material";
import Button from '@mui/material/Button';
import EraseCheck from '../PopUps/EraseCheck';
import Scroll from 'react-scroll';
import utilsRests from '../../../MyConns/_Utils/utilsRests';
import utilsHomePage from '../../../MyConns/_Utils/utilsHomePage';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BuisListContext } from '../../MyContexts/BuisList_Context';
import myFunctions from '../../../myFunctions';

var scroller = Scroll.scroller;

const BuisSelectFromList = (props) => {
    const [buisLst, setBuisLst] = useContext(BuisListContext);
    const [contentList, setContentList] = useState([]);
    const [tempCont, setTempCont] = useState([]);
    const [loading, setLoading] = useState(true);
    const [theCheks, setTheCheks] = useState([]);
    const [displyList, setDisplyList] = useState();
    const [thePopUp, setThePopUp] = useState("");
    const [searchTxt, setSearchTxt] = useState("");



    useEffect(() => {

        getBuisList();

        setTheCheks(props.chsksList || []);

    }, [])

    const getBuisList = async () => {

        // let tempolst = await utilsRests.getRestsListSmall();

        // tempolst = myFunctions.sortAsc(tempolst, "buissName");


        setContentList(buisLst);
        setTempCont(buisLst);
        setLoading(false);


    }

    useEffect(() => {
    }, [tempCont, theCheks])




    const setChkCat = (item, checkOrNot) => {


        let tempCats = [...theCheks];

        if (!checkOrNot) {
            let bk = tempCats.findIndex(el => el._id === item._id);
            if (bk != -1) {
                tempCats.splice(bk, 1);
            }
        }
        else {
            tempCats.push({ ...item, random: false });
        }

        setTheCheks(tempCats)

    }



    const setNewList = () => {

        props.setTheList(theCheks)

    }

    const serachTheBuis = (e) => {
        let tempo = [...contentList];
        setTempCont(tempo.filter(itm => itm.buissName.startsWith(e.target.value)));
        setSearchTxt(e.target.value);
       
    }

    return (
        <>
            {loading ? "Loading" :
                <>
                    <div className={"PopTopLine"}>
                        <input type={"text"} placeholder={"חפש שם עסק"} value={searchTxt} onChange={(e) => serachTheBuis(e)} className={"buisSearchTxt"} />
                    </div>
                    <div className="popSelectImgBox">
                        {tempCont.map((item, index) => {

                            let chck = (theCheks.findIndex(el => el._id === item._id) != -1) ? true : false;

                            return (
                                <div key={index} className={"col-md-6"}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={chck}
                                                onChange={() => setChkCat(item, !chck)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                className={"blackCheck"}
                                            />
                                        }
                                        label={item.buissName}
                                    />

                                </div>

                            )
                        })}
                    </div>
                    <div className="bottomBtnsline">
                        <button type="button" className="onLeft btn btn-success btn-primary" onClick={() => setNewList(theCheks)}>שמור</button>
                        <button type="button" className="onLeft btn btn-primary" onClick={() => props.handleClose()}>סגור</button>
                    </div>

                </>
            }
            {thePopUp}
        </>
    );
}

export default BuisSelectFromList;