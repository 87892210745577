import homeP from "../_ApiConn/apiHomePage";

const getHomePage = async () => {

    const token = localStorage.getItem('token');
    let dFromF = await homeP.getHomePage(token);

    return (dFromF.data[0]);
}

const updateHomePage = async (obj) => {
    let dFromF = await homeP.updateHomePage(obj);
    return (dFromF.data[0]);
}

const getMainRests = async () => {
    let dFromF = await homeP.getMainRests();

    return (dFromF.data);
}

const getOnlineRests = async () => {
    let dFromF = await homeP.getOnlineRests();

    return (dFromF.data);
}

const getFavoRests = async () => {
    let dFromF = await homeP.getFavoRests();
    return (dFromF.data);
}


const getPopularRests = async () => {
    let dFromF = await homeP.getPopularRests();
    return (dFromF.data);
}

const getSectionRests = async (SectionName) => {
    const token = localStorage.getItem('token');

    let dFromF = await homeP.getSectionRests({ token, SectionName });
    return (dFromF.data);
}



export default {
    getHomePage,
    updateHomePage,
    getMainRests,
    getFavoRests,
    getPopularRests,
    getOnlineRests,
    getSectionRests

};