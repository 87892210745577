import React, { useContext, useEffect, useState } from 'react';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import utilsBuissTableSettings from '../../../MyConns/_Utils/utilsBuissTableSettings';
import { RestContext } from '../../MyContexts/Rest_Context';
import { UserContext } from '../../MyContexts/Users_Context';
import { Button } from '@mui/material';
import { Add, Edit, RemoveCircleOutline } from '@mui/icons-material';
import DropHouresSelection from '../../MySections/DTechComps/DropHouresSelection';
import EraseCheck from '../../MySections/PopUps/EraseCheck';
import { TablesSetterContext } from '../../MyContexts/TablesSetter_Context';


const TableItemSet = (props) => {
    const [tbls, setTbls] = useContext(TablesSetterContext);
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {
    }, [])


    const setLocations = (item) => {

        const updatedTables = [...tbls.tables];

        let tempoSelecteds = [];

        if (updatedTables[props.itmIndx].selectedLocations.includes(item)) {
            tempoSelecteds = updatedTables[props.itmIndx].selectedLocations.filter(it => it != item)
        }
        else {
            tempoSelecteds = [...updatedTables[props.itmIndx].selectedLocations]
            tempoSelecteds.push(item)
        }

        updatedTables[props.itmIndx] = { ...updatedTables[props.itmIndx], selectedLocations: tempoSelecteds };
        setTbls({ ...tbls, tables: updatedTables });

    }

    const setSpecials = (item) => {

        const updatedTables = [...tbls.tables];

        let tempoSelecteds = [];

        if (updatedTables[props.itmIndx].specials.includes(item)) {
            tempoSelecteds = updatedTables[props.itmIndx].specials.filter(it => it != item)
        }
        else {
            tempoSelecteds = [...updatedTables[props.itmIndx].specials]
            tempoSelecteds.push(item)
        }

        updatedTables[props.itmIndx] = { ...updatedTables[props.itmIndx], specials: tempoSelecteds };
        setTbls({ ...tbls, tables: updatedTables });

    }


    const setSTime = (tmName, val) => {

        const updatedTables = [...tbls.tables];

        switch (tmName) {
            case "fromOpening": {
                updatedTables[props.itmIndx] = { ...updatedTables[props.itmIndx], fromOpening: val };
                break;
            }
            case "toClosing": {
                updatedTables[props.itmIndx] = { ...updatedTables[props.itmIndx], toClosing: val };
                break;
            }
            case "catchingTime": {
                updatedTables[props.itmIndx] = { ...updatedTables[props.itmIndx], catchingTime: val };
                break;
            }
        }

        setTbls({ ...tbls, tables: updatedTables });
    }


    const tableEraseCheck = async () => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את השולחן מהרשימה?'}
            sucecssFunc={() => removeTblFromList()} />)

    }

    const removeTblFromList = () => {

        const updatedTables = [...tbls.tables];

        updatedTables.splice(props.itmIndx, 1)

        setTbls({ ...tbls, tables: updatedTables });

    }


    return (

        <div className="fullRow">
            <div className="row">

                <div className={"col mb-2 onRight"}>
                    <label htmlFor={"setGroupkoponName_1Cs"}>שם / מס.שולחן</label>
                    <div className={"input-group"}>
                        <input type="text" className={"koponInput"}
                            placeholder={"שם שולחן"} value={tbls.tables[props.itmIndx].tableName}
                            onChange={(e) => {
                                const updatedTables = [...tbls.tables];
                                updatedTables[props.itmIndx] = { ...updatedTables[props.itmIndx], tableName: e.target.value };
                                setTbls({ ...tbls, tables: updatedTables });
                            }} />

                    </div>
                </div>

                <div className={"col mb-2 onRight"}>
                    <label htmlFor={"setKoponDiscount_1Cs"}>מספר מקומות</label>
                    <div className={"input-group"}>
                        <input type="number" className={"koponInput"}
                            value={tbls.tables[props.itmIndx].tableDinMax}
                            min={0}
                            onChange={(e) => {
                                const updatedTables = [...tbls.tables];
                                updatedTables[props.itmIndx] = { ...updatedTables[props.itmIndx], tableDinMax: e.target.value };
                                setTbls({ ...tbls, tables: updatedTables });
                            }}
                        />
                    </div>
                </div>

                <div className={"col mb-2 onRight"}>
                    <label htmlFor={"setMinmumForOrder_1Cs"}>מיקום השולחן</label>
                    <div className={"input-group"}>
                        <div className="checkbox-list-tbls">
                            {tbls.tabelsLocations?.split(",").map((item, indx) => {
                                return (
                                    <div key={indx}>
                                        <input
                                            className={"spaceMargeLeftbig"}
                                            type="checkbox"
                                            value={item}
                                            checked={tbls.tables[props.itmIndx].selectedLocations.includes(item)}
                                            onChange={() => { setLocations(item) }}
                                        />
                                        <span className={"pointing"} onClick={() => { setLocations(item) }}>
                                            {item}
                                        </span>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
                <div className={"col mb-2 onRight"}>
                    <label htmlFor={"setMinmumForOrder_1ss"}>מיוחדים</label>
                    <div className={"input-group"}>
                        <div className="checkbox-list-tbls">
                            {tbls.specialsList?.split(",").map((item, indx) => {
                                return (
                                    <div key={indx}>
                                        <input
                                            className={"spaceMargeLeftbig"}
                                            type="checkbox"
                                            value={item}
                                            checked={tbls.tables[props.itmIndx].specials.includes(item)}
                                            onChange={() => { setSpecials(item) }}
                                        />
                                        <span className={"pointing"} onClick={() => { setSpecials(item) }}>
                                            {item}
                                        </span>
                                    </div>

                                )
                            })
                            }
                        </div>
                    </div>
                </div>


                <div className={"col mb-2 onRight"}>
                    <label htmlFor={"setGroupkoponName_1Cs"}>זמן תפיסת שולחן</label>
                    <div className={"input-group"}>
                        <select
                            className={"timsFromToTables"}
                            value={tbls.tables[props.itmIndx].catchingTime}
                            onChange={(e) => {
                                setSTime("catchingTime", e.target.value)
                            }}
                        >
                            {props.numberOptions?.map((time) => (
                                <option key={time} value={time}>
                                    {time}
                                </option>
                            ))}
                        </select>

                    </div>
                </div>
                <div className={"col onRight"}>
                    <br />
                    <i className="fa fa-minus-circle ms-text-danger pointing" onClick={() => tableEraseCheck()} />
                </div>

            </div>
            {thePopUp}
        </div >
    )
}

export default TableItemSet;

