import React, { useContext, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { RestContext } from '../../../../MyContexts/Rest_Context';

const TabMaps = (props) => {

    const [rest] = useContext(RestContext);

    const [buisAvgPrice, setBuisAvgPrice] = useState(rest.buisAvgPrice);
    const [wazeLL, setWazeLL] = useState(rest.wazeLL);
    const [wazeAddress, setWazeAddress] = useState(rest.wazeAddress);
    const [googleLink, setGoogleLink] = useState(rest.GoogleLink);
    const [buissAddress, setBuissAddress] = useState(rest.buissAddress);

    useEffect(() => {
    }, [])

    const setStreet = (e) => {
        rest["buissAddress"] = { street: e.target.value, num: buissAddress.num, ent: buissAddress.ent, city: buissAddress.city };
        setBuissAddress({ street: e.target.value, num: buissAddress.num, ent: buissAddress.ent, city: buissAddress.city });
    }

    const setnum = (e) => {
        rest["buissAddress"] = { street: buissAddress.street, num: e.target.value, ent: buissAddress.ent, city: buissAddress.city };
        setBuissAddress({ street: buissAddress.street, num: e.target.value, ent: buissAddress.ent, city: buissAddress.city });
    }

    const setent = (e) => {
        rest["buissAddress"] = { street: buissAddress.street, num: buissAddress.num, ent: e.target.value, city: buissAddress.city };
        setBuissAddress({ street: buissAddress.street, num: buissAddress.num, ent: e.target.value, city: buissAddress.city });
    }

    const setcity = (e) => {
        rest["buissAddress"] = { street: buissAddress.street, num: buissAddress.num, ent: buissAddress.ent, city: e.target.value };
        setBuissAddress({ street: buissAddress.street, num: buissAddress.num, ent: buissAddress.ent, city: e.target.value });
    }


    return (
        <>

            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom01"}>מחיר ממוצע</label>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom04"} placeholder={"ממוצע מחירים"} value={buisAvgPrice}
                        onChange={(e) => { rest["buisAvgPrice"] = e.target.value; setBuisAvgPrice(e.target.value) }} />
                </div>
            </div>
            <div className={"col-md-6 mb-2"}>
                <label htmlFor={"validationCustom01"}>כתובת העסק</label>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom04"} placeholder={"רחוב"} value={buissAddress.street}
                        onChange={(e) => setStreet(e)} />

                </div>
            </div>
            <div className={"col-md-6 mb-2"}>
                <div className={"input-group"}>
                    <label className={"spacLable"}></label>
                    <input type="text" className={"form-control"} id={"validationCustom04"} placeholder={"מספר"} value={buissAddress.num}
                        onChange={(e) => setnum(e)} />

                </div>
            </div>
            <div className={"col-md-6 mb-2"}>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom04"} placeholder={"כניסה"} value={buissAddress.ent}
                        onChange={(e) => setent(e)} />
                </div>
            </div>
            <div className={"col-md-6 mb-2"}>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom04"} placeholder={"עיר"} value={buissAddress.city}
                        onChange={(e) => setcity(e)} />
                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom11"}>Waze טקסט הטמעה</label>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom04"} placeholder={"שורת ההטמעה מwaze מפות"} value={wazeLL}
                        onChange={(e) => { rest["wazeLL"] = e.target.value; setWazeLL(e.target.value) }} />
                </div>
            </div>

            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom12"}>כתובת העסק לפי הwaze</label>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom04"} placeholder={"רחוב מספר עיר"} value={wazeAddress}
                        onChange={(e) => { rest["wazeAddress"] = e.target.value; setWazeAddress(e.target.value) }} />
                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom13"}>GoogleLink</label>

                <span className={"explanation mediumst fullROw"}>הקישור של המפה של גוגל להטמעה (מקבלים את זה מגוגל מפות)</span>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom04"} placeholder={"http://www.google.com"} value={googleLink}
                        onChange={(e) => { rest["GoogleLink"] = e.target.value; setGoogleLink(e.target.value) }} />
                </div>
            </div>
        </>

    );
}

export default TabMaps;