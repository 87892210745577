import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Card } from 'react-bootstrap';
import ReactDOM from 'react-dom'
import utilsCategurys from '../../../../../MyConns/_Utils/utilsCategurys';
import { RestContext } from '../../../../MyContexts/Rest_Context';
import CatsCheckslist from '../../../CategurysP/Categurys-list/CatsCheckslist';
import RichTextComp from '../../../../MySections/TextEditor/RichText';
import CRTypeSelect from '../../../../MySections/DTechComps/CRTypeSelect';

const TabBuisDetails = (props) => {
    const [rest, setRest] = useContext(RestContext);

    const [buissName, setBuissName] = useState(rest.buissName);
    const [buissPhone, setBuissPhone] = useState(rest.buissPhone);
    const [smsPhone, setSmsPhone] = useState(rest.smsPhone);
    const [txtBuisKosher, setTxtBuisKosher] = useState(rest.txtBuisKosher);
    const [buissKitchType, setBuissKitchType] = useState(rest.buissKitchType);
    const [buissRedirecting, setBuissRedirecting] = useState(rest.buissRedirecting);

    

    const [buissAbout, setBuissAbout] = useState(rest.buissAbout);
    const [buissMoreText, setBuissMoreText] = useState(rest.buissMoreText);
    const [emailForDelv, setEmailForDelv] = useState(rest.emailForDelv);
    
    




    useEffect(() => {
    }, [])

    const aboutTxtChanged = (content) => {
        rest.buissAbout = content;
        setBuissAbout(content);
        //חובה על ריץ' טקסט
        setRest({ ...rest });
    }
    const moreTxtChanged = (content) => {

        rest.buissMoreText = content;
        setBuissMoreText(content);
        //חובה על ריץ' טקסט
        setRest({ ...rest });
    }
    return (
        <>

            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_1"}>שם העסק</label>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_1"} placeholder={"שם העסק"} value={buissName}
                        onChange={(e) => { rest["buissName"] = e.target.value; setBuissName(e.target.value) }} />
                </div>
            </div>
            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_4"}>טלפון ראשי</label>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_4"} placeholder={"טלפון"} value={buissPhone}
                        onChange={(e) => { rest["buissPhone"] = e.target.value; setBuissPhone(e.target.value) }} />

                </div>
            </div>
            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_12"}>טלפון SMS</label>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_12"} placeholder={"טלפון SMS"} value={smsPhone}
                        onChange={(e) => { rest["smsPhone"] = e.target.value; setSmsPhone(e.target.value) }} />

                </div>
            </div>
            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_87"}>מייל למשלוח הזמנות</label>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_87"} placeholder={"מייל למשלוח הזמנות"} value={emailForDelv}
                        onChange={(e) => { rest["emailForDelv"] = e.target.value; setEmailForDelv(e.target.value) }} />

                </div>
            </div>
            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>כשרות</label>
                <span className={"explanation mediumst fullROw"}>טקסט חופשי</span>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_6"} placeholder={"כשרות"} value={txtBuisKosher}
                        onChange={(e) => { rest["txtBuisKosher"] = e.target.value; setTxtBuisKosher(e.target.value) }} />

                </div>
            </div>
            <div className={"col-md-4 mb-2"}>
                <label htmlFor={"validationCustom1_7"}>סוג מטבח</label>
                <span className={"explanation mediumst fullROw"}>טקסט חופשי</span>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_7"} placeholder={"סוג המטבח"} value={buissKitchType}
                        onChange={(e) => { rest["buissKitchType"] = e.target.value; setBuissKitchType(e.target.value) }} />
                </div>
            </div>
            <div className={"col-md-5 mb-2"}>
                <label htmlFor={"validationCustom1_7"}>קישור רידיירקט</label>
                <span className={"explanation mediumst fullROw"}>ניתן להזין קישור מלא או רק את שם העסק</span>
                <div className={"input-group"}>
                    <input type="text" className={"form-control"} id={"validationCustom1_7"} placeholder={"סוג המטבח"} value={buissRedirecting}
                        onChange={(e) => { rest["buissRedirecting"] = e.target.value; setBuissRedirecting(e.target.value) }} />
                </div>
            </div>
            <CatsCheckslist />



            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_2"}>טקסט אודות</label>
                <div className={"input-group"}>
                    <RichTextComp firstText={buissAbout}
                        saveEditorTxt={(content) => aboutTxtChanged(content)} />
                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_3"}>עוד טקסט</label>
                <div className={"input-group"}>

                    <RichTextComp firstText={buissMoreText}
                        saveEditorTxt={(content) => moreTxtChanged(content)} />
                </div>
            </div>
         
        </>

    );
}

export default TabBuisDetails;