import React, { useEffect, useState } from 'react';
import InputSet from '../../../MySections/PopUps/InputSet';
import CancelOrderTableReason from './ForTableReservation/CancelOrderTableReason';

const TblOrderStatuselect = (props) => {

    const [orderStatus, setOrderStatus] = useState((props.orderStatus != null) ? props.orderStatus : "ממתין לאישור");
    const [colorforStat, setColorforStat] = useState("");
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {
        setOrderStatus(props.orderStatus)
        setColorforStat(getOrdColorByStat(props.orderStatus))

    }, [props.orderStatus])

    const handleChange = (e) => {
        if (e.target.value !== "מבוטל") {
            setOrderStatus(e.target.value)
            setColorforStat(getOrdColorByStat(e.target.value))
            props.setOrderStatus(e.target.value,"")

        }
        else {

            setThePopUp(<CancelOrderTableReason 
                ContentText={``}
                Title={`מהי סיבת הביטול? (ישלח ללקוח בהודעת SMS)`}
                okClick={(inputTxt) => okCancel(inputTxt)}
                cancelClick={() => { setThePopUp() }}
                diagCss={"CancelOrderPop"}
                errorMsg={"חובה להזין סיבה לביטול"} />)

            const okCancel = (cancelReason) => {
                setOrderStatus("מבוטל")
                setColorforStat(getOrdColorByStat("מבוטל"))
                props.setOrderStatus("מבוטל",cancelReason)
            }


        }
    }


    const getOrdColorByStat = (status) => {
        const statusClassMap = {
            "ממתין לאישור": "badge badge-primary",
            // "מוזמן": "badge badge-finished",
            "מאושר": "badge badge-finished",
            "סגור": "badge badge-dark",
            "מושהה": "badge badge-Delayed",
            "מבוטל": "badge badge-dark",

        };

        return statusClassMap[status] || "badge badge-primary";
    }

    return (
        <>
            <span style={props.inStyle} className={`${colorforStat} 'pointing' `}>
                <select
                    className={`onLeft selectInBlock`}
                    value={orderStatus}
                    onChange={handleChange}>
                    <option value={"ממתין לאישור"}>ממתין לאישור</option>
                    {/* <option value={"מוזמן"}>מוזמן</option> */}
                    <option value={"מאושר"}>מאושר</option>
                    <option value={"סגור"} >סגור</option>
                    <option value={"מושהה"} >מושהה</option>
                    <option value={"מבוטל"} >מבוטל</option>
                </select>
            </span>

            {thePopUp}
        </>
    );
}

export default TblOrderStatuselect;