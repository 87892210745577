import React, { useContext, useEffect } from 'react';
import Topnavigation from '../../../../layouts/Topnavigation';
import Sidenavigation1 from '../../../../layouts/Sidenavigation1';

import Content from './Content';
import { TheMasterContextProvider } from '../../../../MyContexts/Master_Context';
import myFunctions from '../../../../../myFunctions';
import { MobiOrNotContext } from '../../../../MyContexts/MobiOrNotContext';
import MobiContent from './MobiContent';

const MyInvoice = (props) => {
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    useEffect(() => {

        myFunctions.setGetCurrnetTab("Set", "pg", "pgBuisDetails")
    }, [])

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>
                <Sidenavigation1 />
                <main className="body-content">
                    <Topnavigation />
                    {!mobiOrNot?<Content />:<MobiContent />}  
                </main>
                {/* <Quickbar /> */}
            </TheMasterContextProvider>
        </div>
    );
}

export default MyInvoice;