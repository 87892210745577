import { Autocomplete, MenuItem, Select, TextField } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';



const permissOpts = [
    {
        value: "צופה", label: "צופה", inVal: "watch"
    },
    {
        value: "מנהל מסעדה", label: "מנהל מסעדה", inVal: "restAdmin"
    },
    {
        value: "עובד מסעדה", label: "עובד מסעדה", inVal: "restUser"
    },
    {
        value: "אדמין", label: "אדמין", inVal: "admin"
    },
    {
        value: "שליח", label: "שליח", inVal: "deliver"
    },
    {
        value: "חסום", label: "חסום", inVal: "blocked"
    }
]

const UserPermission = (props) => {

    const [userPermiss, setUserPermiss] =
        useState((props.UserPermiss != null) ? props.UserPermiss : "watch");

    useEffect(() => {
        setUserPermiss(permissOpts.find(per => per.inVal === props.UserPermiss).label || "watch");

    }, [])




    const handleChange = (e) => {
        if (e) {
            setUserPermiss(permissOpts.find(per => per.value === e.value).label);

            props.setUserPermiss(e.inVal)

        }
    }



    return (
        <Autocomplete
            className={`selectInBlock ${props.clss ? props.clss : ""}`}
            disablePortal
            value={userPermiss}
            options={permissOpts}
            sx={{ maxWidth: "300px" }}
            onChange={(event, e) => {
                handleChange(e);
            }}
            renderInput={(params) => <TextField {...params} className={"specialTxt"} placeholder='' />}
        />

    );
}

export default UserPermission;