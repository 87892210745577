import React, { useContext, useEffect, useState } from 'react'
import BuisSelectFromList from '../../../MySections/DTechComps/BuisSelectFromList';
import utilsHomePage from '../../../../MyConns/_Utils/utilsHomePage';
import EraseCheck from '../../../MySections/PopUps/EraseCheck';
import { HomePContext } from '../../../MyContexts/HomeP_Context';
import Button from '@mui/material/Button';
import { AddBoxOutlined, HighlightOffOutlined, ArrowCircleUp } from "@mui/icons-material";
import { Dialog } from '@mui/material';



const TabFavoRests = (props) => {
    const [homeP] = useContext(HomePContext);
    const [contentList, setContentList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [thePopUp, setThePopUp] = useState("");
    const [displyList, setDisplyList] = useState();
    const [diag, setDiag] = useState("");
    const [TheFavRestsHeader, setTheFavRestsHeader] = useState(homeP.TheFavRestsHeader)

    useEffect(() => {
        getAllRests();
    }, [])

    useEffect(() => {
        setTheDisplay();
    }, [contentList])

    const getAllRests = async () => {
        let HomePLst = await utilsHomePage.getFavoRests();
        setContentList(HomePLst.map(itm => { return ({ "_id": itm.id, "buissName": itm.buissName, "random": itm.random || false }) }));
        setLoading(false);
    }

    const setTheMainRests = (urlsList) => {

        let itm = urlsList.filter(itm => itm._id);

        homeP["TheFavRests"] = urlsList.filter(itm => itm._id);

        setContentList(urlsList);

    }



    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };


    const setUp = (index) => {
        let temp = array_move([...contentList], index, index - 1);

        setTheMainRests(temp);
    }

    const setDown = (index) => {
        let temp = array_move([...contentList], index, index + 1);

        setTheMainRests(temp);

    }

    const removeItm = (index) => {
        setThePopUp(<EraseCheck title={'האם בטוח להסיר מהרשימה?'} sucecssFunc={() => removeItmConfirmd(index)} />)
    }
    const removeItmConfirmd = async (index) => {
        let temp = [...contentList]
        temp.splice(index, 1);

        setTheMainRests(temp);

    }

    const addBuisToLst = () => {

        setDiag(
            <Dialog onClose={() => setDiag()} open={true} className={"popUpWrapMedia"}>
                <div className={"inDiag"}>
                    <HighlightOffOutlined sx={{ fontSize: 40 }}
                        className={"pointing closeDiagBtn"} onClick={() => setDiag()} />
                    <BuisSelectFromList chsksList={contentList}
                        setTheList={(theLst) => { setTheMainRests(theLst); setDiag() }}
                        handleClose={() => setDiag()} />
                </div>
            </Dialog>
        )

    }



    const setTheDisplay = () => {
        let counter = 0;
        let bk = contentList.map((item, index) => {
            counter++;

            return (

                <tr key={index}>
                    <td className="l2row">
                        {index != 0 && contentList.length > 1 ? <div className={"iconsOnLeft pointing"} onClick={() => setUp(index)} title={"הקפץ למעלה"}><i className="far fa-caret-square-up theIconsColor" /></div> : ""}
                        {index < contentList.length - 1 ? <div className={"iconsOnLeft pointing"} onClick={() => setDown(index)} title={"הקפץ למטה"}><i className="far fa-caret-square-down theIconsColor" /></div> : ""}
                    </td>
                    <td className="mrow">
                        {item ? <label>{item.buissName}</label> : ""}
                    </td>
                    <td className="lrow iconCel">
                        <div className={"iconsOnLeft pointing"} onClick={() => removeItm(index)}><i className="far fa-trash-alt theIconsColor" /></div>
                    </td>
                </tr>

            )
        })
        setDisplyList(bk);
    }


    return (
        <>
            {loading ? "Loading" :
                <div className="ms-content-wrapper fullRow">
                    <div className="row">
                        <div className={"col-md-12 mb-2"}>
                            <label htmlFor={"validationCustom1_6"}>כותרת מקטעים שניה</label>
                            <div className={"input-group"}>
                                <input type="text" className={"form-control"} id={"validationCustom1_17"} placeholder={"שלישית"} value={TheFavRestsHeader}
                                    onChange={(e) => { setTheFavRestsHeader(e.target.value); homeP["TheFavRestsHeader"] = e.target.value; }}
                                />

                            </div>
                        </div>
                        <div className="col-md-12">
                            {/* Active Orders Graph */}

                            <div className="col-xl-12">
                                <div className="ms-panel">
                                    <div className={"smallBtnsLine"}>
                                        <Button className={"btnSPc"} variant="contained" onClick={() => addBuisToLst()}
                                            startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                                            בחר מרשימת העסקים
                                        </Button>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-hover thead-primary">
                                            <thead>
                                                <tr>
                                                    <th scope="col">מיקום</th>
                                                    <th scope="col">שם העסק</th>
                                                    <th scope="col" className={"centerText iconCel"}>הסר מהרשימה</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displyList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {diag}
            {thePopUp}
        </>
    );
}

export default TabFavoRests;