import React, { Component, useContext, useEffect, useState } from 'react';
import Sidenavigation from '../../layouts/Sidenavigation';
import Sidenavigation1 from '../../layouts/Sidenavigation1';
import Topnavigation from '../../layouts/Topnavigation';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';

import { MenuContextProvider } from '../../MyContexts/Rest_Context';
import myFunctions from '../../../myFunctions';
import SingleP from './SingleP';
import { UserContext } from '../../MyContexts/Users_Context';



const SinglePage = (props) => {
    const [user, setUser] = useContext(UserContext)

    const [thePage, setThePage] = useState([]);

    useEffect(() => {
  
        if (props.newp) {
            setThePage(<SingleP newp={true} upSimplsList={(newitem) => props.upSimplsList(newitem)} />);
        }
        else {
            setThePage(<SingleP key={props.pageItem._id} pageItem={props.pageItem} pageId={props.pageItem._id}
                upSimplsList={(newitem) => props.upSimplsList(newitem)} />);
        }

    }, [props.pageItem])
    
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">

            <TheMasterContextProvider>
                <Sidenavigation1 />
                <main className="body-content">
                    <MenuContextProvider>
                        <Topnavigation />
                        {user.permiss === "admin" &&
                            thePage 
                        }

                    </MenuContextProvider>
                </main>
                {/* <Quickbar /> */}
            </TheMasterContextProvider>

        </div>
    );
}

export default SinglePage;