import SpecialPage from "../_ApiConn/apiSpecialPage";


const getSpecialPageByName = async (pageName) => {
    let dFromF = await SpecialPage.getSpecialPageByName(pageName);
    return (dFromF.data);
}

const updateSpecialPage = async (pageName,obj) => {

    
        let dFromF = await SpecialPage.updateSpecialPage(pageName, obj);
        
        return (dFromF.data);
    

}

export default {
    getSpecialPageByName,
    updateSpecialPage,
    
    
};