import React, { useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PopUpRegular = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState((props.errorMsg === "") ? false : true);

    const saveMe = () => {

        if (inputTxt != "") {
            props.okClick(inputTxt);
        }
        else {
            setErr(true);
        }
    };


    const handleClose = () => {
        props.cancelClick();
    };

    return (
        <Dialog open={open} onClose={handleClose} className={"textSizeDiag popDiagBlock"}>
            <DialogTitle className={"theTitlePop"}>
                {props.Title.map((txtItm, index) => {
                    return (
                        <span key={index} >{txtItm}</span>
                    )
                })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={"theTxtPop"}>
                    {props.ContentText.map((txtItm, index) => {
                        return (
                            <span key={index} >{txtItm}<br /></span>
                        )
                    })}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={props.inLabel}
                    type="text"
                    fullWidth
                    variant="standard"
                    className="centerText"
                    onChange={(e) => { setInputTxt(e.target.value); setErr(false) }}
                />

                <p onClick={() => props.recreateCode()} className={"spaceTopBig centerText"}>שלח לי שוב</p>
                {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}

            </DialogContent>
            <DialogActions >
                <button type="button" className="centerMe btn btn-success btnPopRegular" onClick={() => saveMe()}>אימות</button>
            </DialogActions>
        </Dialog>

    );
}

export default PopUpRegular;