import DeliveryDetails from "../_ApiConn/apiDeliveryDetails";


const getDeliveryDetails = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await DeliveryDetails.getDeliveryDetails({ token });
    return (dFromF);
}


const getDeliveryDetailsByOrdNum = async (orderNum) => {
    const token = localStorage.getItem('token');
    let dFromF = await DeliveryDetails.getDeliveryDetailsByOrdNum({ token,orderNum });
    return (dFromF);
}

const updateDeliveryDetails = async (obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await DeliveryDetails.updateDeliveryDetails({ token,obj });
    return (dFromF);
}



export default {
    getDeliveryDetails,
    updateDeliveryDetails,
    getDeliveryDetailsByOrdNum
    
};