import React, { useContext, useEffect, useState } from 'react';
import ReportCalcs from '../ReportCalcs';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';
import utilsRests from '../../../../../MyConns/_Utils/utilsRests';
import { Button } from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import { MobiOrNotContext } from '../../../../MyContexts/MobiOrNotContext';
import TotalsBarObjects from '../TotalsBarObjects';
import ListOrdersTable from '../ListOrdersTable';
import ListOrdersBlocks from '../ListOrdersBlocks';
import myFunctions from '../../../../../myFunctions';
import MyDateTime from '../../../../MySections/TimeSections/MyDateTime';
import PrintingStyle from '../../../../../MyFunctions/PrintingStyle';
import getTotalsFunctss from './getTotalsFunctss';

const RowTotalsBuissReport = (props) => {
    const [thePrms, setThPrms] = useState(props.prms);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);


    const [smToPass, setSmToPass] = useState({ percantToPass: "", totWithCrdit: "" });
    const [payPrecent, setPayPrecent] = useState("7");

    const [printComponent, setPrintComponent] = useState(null);

    useEffect(() => {

        const getSumss = async () => {
            if (thePrms.item.orders && thePrms.item.orders.length > 0) {
                let tempo = await getTotalsFunctss.sumToPass(thePrms.item.orders, thePrms.item.buissId)

                setSmToPass({ percantToPass: tempo.percantToPass, totWithCrdit: tempo.totWithCrdit })
            }

        }
        getSumss();

    }, [thePrms.item])



    const rowCellClick = (buissId) => {
        props.rowCellClick(buissId);
    }

    return (
        <>

            <td onClick={() => rowCellClick(thePrms.item.buissId)}>{thePrms.item.buissName}</td>
            <td onClick={() => rowCellClick(thePrms.item.buissId)}>{getTotalsFunctss.getTheTotOfCash(thePrms.item.orders).totClear}</td>
            <td onClick={() => rowCellClick(thePrms.item.buissId)}>{getTotalsFunctss.getTheTotOfCreditcard(thePrms.item.orders).totClear}</td>
            <td onClick={() => rowCellClick(thePrms.item.buissId)}>{getTotalsFunctss.getTheTotal(thePrms.item.orders).totClear}</td>
            <td onClick={() => rowCellClick(thePrms.item.buissId)}>{smToPass.percantToPass}</td>
            <td onClick={() => rowCellClick(thePrms.item.buissId)}>{smToPass.totWithCrdit} ₪</td>

        </>
    );
}

export default RowTotalsBuissReport;
