import React, { useContext, useEffect, useState } from 'react';
import ReportCalcs from '../ReportCalcs';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';
import utilsRests from '../../../../../MyConns/_Utils/utilsRests';
import { Button } from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import { MobiOrNotContext } from '../../../../MyContexts/MobiOrNotContext';
import TotalsBarObjects from '../TotalsBarObjects';
import ListOrdersTable from '../ListOrdersTable';
import ListOrdersBlocks from '../ListOrdersBlocks';
import myFunctions from '../../../../../myFunctions';
import MyDateTime from '../../../../MySections/TimeSections/MyDateTime';
import PrintingStyle from '../../../../../MyFunctions/PrintingStyle';
import PopUpTheComp from '../../../../MySections/DTechComps/PopUpTheComp';
import getTotalsFunctss from './getTotalsFunctss';
import ConnUrls from '../../../../../MyConns/Connections/ConnectURL';

const BuissReportPrintComp = (props) => {
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);


    useEffect(() => {
    }, [])


    const insideRowCellClick = (ordId) => {
        // props.rowCellClick(buissId);
    }

    const orderBythis = (byWhat, ordUserOrBuiss) => {
        // props.rowCellClick(buissId);
    }

    const setNewStatus = (orderId, statBack, buisId) => {
        props.setNewStatus(orderId, statBack, buisId)
    }



    const printIt = () => {
        const printWindow = window.open('', '', 'width=800,height=600');
        const printContent = document.getElementById("print-content").innerHTML;

        // Create the HTML structure for the print window
        printWindow.document.write('<html><head><title>Print</title>');

        // Get all stylesheets from the current document and add them to the print window
        const stylesheets = Array.from(document.styleSheets).map(styleSheet => {
            if (styleSheet.href) {
                return `<link rel="stylesheet" type="text/css" href="${styleSheet.href}">`;
            } else {
                // Handle inline styles (if any)
                const cssRules = Array.from(styleSheet.cssRules).map(rule => rule.cssText).join('\n');
                return `<style>${cssRules}</style>`;
            }
        }).join('\n');

        printWindow.document.write(stylesheets);

        printWindow.document.write('</head><body style="text-align: right; direction: rtl;">');
        printWindow.document.write(printContent);
        printWindow.document.write('</body></html>');
        printWindow.document.close();

        // Ensure the content is loaded before printing
        printWindow.onload = () => {
            printWindow.print();
            printWindow.close();
        }
    }


    return (
        <div className={"printBuissReportWrap"} id="print-content">
            {!mobiOrNot ?
                <>
                    <div className={"fullRow no_print"}>
                        <Button className={`btnBigMnu btnBigMnu_big onLeft`} variant="contained"
                            onClick={() => printIt()}>
                            הדפס דוח
                        </Button>
                    </div>
                    <div className={"fullRow onlyOnPrint"}>
                        <div className={" overflowHid spacePadBig"}>
                            <img className="reportsIMgItem"
                                src={`${ConnUrls.servMediasUrl + props.reportigm}`} />
                        </div>
                    </div>
                </>
                : ""}
            <div className={"reportsHead fullRow"}>
                <div className={"regular bold"}>{props.item.buissName}</div>
                <span className={"smallFont bold"}> דו"ח </span>
                <span className={"smallFont bold"}>{props.headsToPrint.reportHeadTimes !== "חודש שעבר" ? props.headsToPrint.reportHeadTimes : "חודשי"} </span>
                <span className={"smallFont bold"}> {props.headsToPrint.reportHeadTyp}</span>
                <span className={"smallFont bold"}> :{MyDateTime.getDate(props.headsToPrint.endDTS)} - </span>
                <span className={"smallFont bold"}>{MyDateTime.getDate(props.headsToPrint.startDTS)}</span>
            </div>

            <div className="fullRow">
                <TotalsBarObjects key={props.item.buissId}
                    prms={{
                        "orderlisttable": props.thFIxdList,
                        "totOfDelivery": getTotalsFunctss.getTheTotOfDelivery(props.item.orders),
                        "totOfTakaway": getTotalsFunctss.getTheTotOfTakaway(props.item.orders),
                        "totOfCash": getTotalsFunctss.getTheTotOfCash(props.item.orders),
                        "totOfCreditcard": getTotalsFunctss.getTheTotOfCreditcard(props.item.orders),
                        "totOfAll": getTotalsFunctss.getTheTotal(props.item.orders),
                        "userBuis": props.item.buissId
                    }} />
            </div>

            {!mobiOrNot ?
                <ListOrdersTable key={props.item.buissId}
                    theList={
                        myFunctions.sortByItemDsc(props.thFIxdList, "dateCreated", "ord")
                    }
                    orderBythis={(byWhat, ordUserOrBuiss) => orderBythis(byWhat, ordUserOrBuiss)}
                    rowCellClick={(ordId) => insideRowCellClick(ordId)}
                    setNewStatus={(orderId, statBack, buisId) => setNewStatus(orderId, statBack, buisId)}
                />
                :
                <ListOrdersBlocks key={props.item.buissId}
                    theList={
                        myFunctions.sortByItemDsc(props.thFIxdList, "dateCreated", "ord")
                    }
                    orderBythis={(byWhat, ordUserOrBuiss) => orderBythis(byWhat, ordUserOrBuiss)}
                    rowCellClick={(ordId) => insideRowCellClick(ordId)}
                    setNewStatus={(orderId, statBack, buisId) => setNewStatus(orderId, statBack, buisId)}
                />
            }
        </div>
    )
}

export default BuissReportPrintComp;
