import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CancelRounded } from '@mui/icons-material';

const PopUpTheComp = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState((props.errorMsg === "") ? false : true);

    useEffect(() => {

    }, [])

    const saveMe = () => {
        props.okClick();
        handleClose();

    };


    const handleClose = () => {
        // props.cancelClick();
    };

    return (

        <div className={`myPopDiag ${props.cssStyl ? props.cssStyl : ""}`}>
            <div className={`${props.popDiagStyle ? props.popDiagStyle + " popDialogContent" : "popDialogContent"}`}>
                <CancelRounded className={`inBlue popUpCloseBtn pointing  ${props.CancelRounded ? props.CancelRounded : "onTopOfAll"}`}
                    style={{ fontSize: "35px" }} onClick={() => props.closePop()} />
                {props.Title && props.Title != "" ?
                    <DialogTitle className={`${props.titleCss || ""} boldTitle`}>
                        {props.Title.map((txtItm, index) => {
                            return (
                                <span key={index} >{txtItm}<br /></span>
                            )
                        })}</DialogTitle>
                    : ""}
                <DialogContent>
                    <DialogContentText component="div">
                        {props.theInsideComp}
                    </DialogContentText>

                    {err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}

                    <div className={"btnsDiag"}>
                        {props.btn1ClickShow != null ?
                            <>
                                <button type="button"
                                    className="centerMe btn btn-success btnPopRegular onRight"
                                    onClick={() => { props.okClick(); handleClose(); }}>
                                    {(props.BtnTxt != null) ? props.BtnTxt : "סגור"}
                                </button>
                            </>
                            : ""}
                        {props.btn2ClickShow != null ?
                            <>
                                <span>&nbsp;&nbsp;</span>
                                <button type="button"
                                    className="centerMe btn btn-success btnPopRegular onRight"
                                    onClick={() => { props.btnClick2(); handleClose(); }}>
                                    {(props.BtnTxt2 != null) ? props.BtnTxt2 : "ביטול"}</button>
                            </>
                            : ""}
                    </div>
                </DialogContent>

            </div>
        </div>
    );
}

export default PopUpTheComp;