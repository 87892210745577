import React, { useContext, useEffect, useState } from 'react';
import { MobiOrNotContext } from '../../../../MyContexts/MobiOrNotContext';
import { LocalDining } from '@mui/icons-material';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';
import ConnUrls from '../../../../../MyConns/Connections/ConnectURL';
import parse from 'html-react-parser';

const ShowMyOrder = (props) => {
    // const [order, setOrder] = useContext(OrderContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext)

    const [theOrder, setTheOrder] = useState(props.theOrder)

    const [intervalID, setIntervalID] = useState();



    useEffect(() => {
    }, [])


    const getTimes = () => {

    }

    const stopHandler = () => {
    }


    //הצג או הסתר הערות על המנה
    const showHideRemarks = (elemId) => {
        let Remarks = document.getElementById(`${elemId}_remarks`);
        let RemarksItm = document.getElementById(`${elemId}_remarksTxt`);
        let RemarksItmTxt = document.getElementById(`${elemId}_remarkstxLine`);

        if (Remarks.classList.contains("slideMeUp")) {
            Remarks.classList.remove("slideMeUp");
            RemarksItmTxt.classList.add("slideMeUp");
        }
        else {
            Remarks.classList.add("slideMeUp");
            RemarksItmTxt.classList.remove("slideMeUp");
        }
    }


    //הסתר או הצג כמות של מנה מסויימת
    const showHideMount = (elemId) => {

        let mountItm = document.getElementById(elemId);

        if (mountItm.classList.contains("slideMeUp")) {
            mountItm.classList.remove("slideMeUp");
        }
        else {
            mountItm.classList.add("slideMeUp");
        }
    }



    let orderOrTake = () => {
        if (theOrder.OrderType == "Take")
            return (<div className={"fullWidth huge bold spacePadBig"}>איסוף</div>)
        else {
            let addrs = theOrder.deliveryAddress
            return (
                <>
                    <div className={"fullWidth huge bold spaceTopBig"}>משלוח</div>
                    <div className={"fullWidth big bold spaceButtomVeryBig"}>
                        {addrs.street}&nbsp;
                        {addrs.num}&nbsp;
                        {(addrs.appartment != null && addrs.appartment != "") ? `דירה: ${addrs.appartment}` : ""}&nbsp;
                        {(addrs.ent != null && addrs.ent != "") ? `כניסה: ${addrs.ent}` : ""}&nbsp;
                        {(addrs.flor != null && addrs.flor != "" && addrs.num != "") ? `קומה: ${addrs.flor}` : ""}&nbsp;
                        {(addrs.city != null && addrs.city != "") ? ` ${addrs.city}` : " אשדוד"}
                    </div>


                </>
            )
        }
    }



    ///המוצרים בהזמנה
    let ordersItems = theOrder.Orders.map((item, index) => {
        return (
            <div key={index} className={"ordItem spaceButtomBig "}>

                <div className={"ordItmLine"}>

                    <div className={`${!mobiOrNot ? "col-8 onRight noPadding" : "col-12 onRight noPadding"}`}>
                        <span className={"bold rSideMiddle"}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className={"tdmin10"}>
                                            <span className={"engFont ordItemMount"}>X&nbsp;</span>
                                            <span className={"ordItemMount mainMount"}>{item.mount}&nbsp;</span>
                                        </td>
                                        <td>
                                            <span className={"ordItemMount"}> {item.productName}</span>
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </span>
                        <span className={"bold lSideSmall"}>₪ {parseFloat(item.prodPrice) * parseInt(item.mount)}</span>

                        <div className={"fullRow overflowHid"}>
                            {OrderFunctions.theItemSpecials(item)}
                        </div>

                    </div>
                    <div className="col-4 onLeft overflowHid noPadding noMargin">
                        <div className={"smallDIspImageWrap"}>
                            {item.prodImageUrl ? <img src={`${ConnUrls.servMediasUrl}${item.prodImageUrl}`}
                                className={"smallDIspImage"} />
                                : ""}
                        </div>
                    </div>
                </div>
                <div className={"dottedFullLightGray"}></div>


            </div >

        )

    })


    //רטבים מוצמדים מוזמנים
    let stickedSauces =
        theOrder.StickedSauces?.length > 0 ?
            <div className={"spaceButtomBig ordLstSpesBlk "}>
                <div className={"smallIconWrap"}>
                    <img src={"/PermanentImages/suse-icon.png"} className={"smallIcon"} alt="" />
                </div>
                <span className={"bold fullRow "}>רטבים מוצמדים</span><span className={"veryVerySmall"}>&nbsp;{(theOrder.freeSaucess > 0) ? `(${theOrder.freeSaucess} ללא עלות)` : ""} </span>
                <span className={"bold lSideSmall"}>{OrderFunctions.getStickedSauces(theOrder) > 0 ? OrderFunctions.getStickedSauces(theOrder) + "  ₪" : ""} </span>

                {
                    theOrder.StickedSauces?.map((sauce, indx) => {
                        return (
                            <div key={indx} className={"sucItem"}>

                                <span className={"rSideMiddle"}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className={"tdmin10"}>
                                                    <span className={"engFont ordItemMount"}>X&nbsp;</span><span className={"ordItemMount"}>{sauce.mount}&nbsp;</span>
                                                </td>
                                                <td>
                                                    <span className={"ordItemMount"}> {sauce.servType}</span>
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        )
                    })
                }
            </div>
            : "";




    //מספר סועדים
    let Dinners =
        (theOrder.getfANife || theOrder.getChupstiks) ?
            <div className={"sucItem spacePadSmall"} >
                <span className={"rSideMiddle"}><span className={"bold"}>מספר סועדים: </span><span>{theOrder.Dinners}</span></span>
            </div>
            : "";

    //סכו"ם
    let ANife =
        (theOrder.getfANife) ?
            <div className={"sucItem spacePadSmall"}>
                <span className={"rSideMiddle"}><span className={"bold"}>סכו"ם חד פעמי: </span><span>{theOrder.getfANife ? "כן" : "אין צורך "}</span></span>
            </div>
            : "";

    //צופסטיקס
    let chupstiks =
        (theOrder.getChupstiks) ?
            <div className={"sucItem spacePadSmall"}>
                <span className={"rSideMiddle"}><span className={"bold"}>צ'ופסטיקס: </span><span>{theOrder.getChupstiks ? "כן" : "אין צורך "}</span></span>
            </div>
            : "";



    let totalDinners =
        (Dinners != "" || ANife != "" || chupstiks != "") ?
            <div className={"ordItmLine spaceAndLine"}>
                <span className={"rSideFirst"}><LocalDining /></span>
                <span className={"rSideMiddle"}>
                    {Dinners}
                    {ANife}
                    {chupstiks}
                </span>
                <span className={"bold lSideSmall"}></span>
            </div>
            : ""



    //סיכום מחירים
    let TotPrices =
        <>
            <div className={`${!mobiOrNot ? "col-6" : "col-12"} centerMargin overflowHid`}>
                <div className={"spaceTopBig col-12 overflowHid"}>
                    <div className={`${!mobiOrNot ? "big" : "regular"} col-6 noPadding bold onRight`}>סכום ההזמנה</div>
                    <div className={`${!mobiOrNot ? "big" : "regular"} onRight col-6 noPadding bold textLeft `}>{OrderFunctions.getOrderPrice(theOrder)} ₪ </div>
                </div>

                {(theOrder.OrderType == "Deliver") ?
                    <div className={"col-12 overflowHid"}>
                        <div className={`${!mobiOrNot ? "big" : "regular"} col-6 noPadding bold onRight`}>משלוח</div>
                        <div className={`${!mobiOrNot ? "big" : "regular"} onRight col-6 noPadding bold textLeft`}>{theOrder.OrderDelPrice} ₪ </div>
                    </div> : ""}

                <div className={"col-12 overflowHid"}>
                    <div className={`${!mobiOrNot ? "big" : "regular"} col-6 noPadding bold onRight`}>סה"כ</div>
                    <div className={`${!mobiOrNot ? "big" : "regular"} onRight col-6 noPadding bold textLeft`}>{OrderFunctions.getOrderTotalPrice(theOrder)} ₪ </div>
                </div>

            </div>
            <div className={"veryBig bold spacePad startNBlock centerText"}>כל המחירים בשקלים וכוללים מע"מ</div>

        </>


    //אופן תשלום
    const payingTYpe = () => {
        switch (theOrder.payt) {
            case "1":
                return "אשראי";
            case "2":
                return "מזומן";
            case "3":
                return "ביט";
        }
    }


    return (
        <>
            {
                theOrder.Orders.length <= 0 ?
                    <div className={"noItmes huge centerText"}>אין פריטים להזמנה.</div> :

                    <div className={"TheOrderListToDisp col-stretch "}>
                        <div className={"TheOrderShow ordLstWrap"}>
                            {/* <div className={"veryBig bold spaceRightVeryLong"}>סוג ההזמנה</div> */}
                            {orderOrTake()}
                            <div className={"dottedFullLightGray spaceMargeBottombig overflowHid"}></div>

                            {ordersItems}
                            {stickedSauces}
                            {totalDinners}
                            {(theOrder.orderKopon && theOrder.orderKopon != "") ?

                                <div key={'kop'} className={"ordItem spaceButtomBig "}>

                                    <div className={"ordItmLine"}>

                                        <span className={"bold rSideMiddle"}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className={"tdmin10"}>
                                                            <span className={"engFont ordItemMount"}></span>
                                                            <span className={"ordItemMount mainMount"}></span>
                                                        </td>
                                                        <td>
                                                            <span className={"ordItemMount"}>קופון {theOrder.orderKopon.Name}</span>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </span>
                                        <span className={"bold lSideSmall"}>{theOrder.orderKopon.KoponDiscount}{theOrder.orderKopon.KoponDiscType === '1' ? "₪" : "%"}</span>
                                    </div>

                                </div>
                                : ""}

                            {(theOrder.orderZikoy && theOrder.orderZikoy != "") &&
                                theOrder.orderZikoy.map((element, index) => {
                                    return (
                                        <div key={`zikoy_${index}`} className={"ordItem spaceButtomBig "}>

                                            <div className={"ordItmLine"}>

                                                <span className={"bold rSideMiddle"}>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className={"tdmin10"}>
                                                                    <span className={"engFont ordItemMount"}></span>
                                                                    <span className={"ordItemMount mainMount"}></span>
                                                                </td>
                                                                <td>
                                                                    <span className={"ordItemMount"}>זיכוי {element.Name}</span>
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </span>
                                                <span className={"bold lSideSmall"}>₪{element.zickoyPrice}-</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            {/* {(theOrder.orderRemarks != null && theOrder.orderRemarks != "") ?
                            <div className={"bold big spacePadBig"}>{parse(theOrder.orderRemarks)}</div>
                            : ""} */}

                            <div className={"spaceTopBig fullRow overflowHid"}>
                                <div className={"big bold onRight"}>צורת תשלום:</div>
                                <div className={"onRight big bold spaceRight"}>{payingTYpe()}</div>
                            </div>
                            <div className={"fullRow overflowHid"}>
                                <div className={"big bold onRight"}>סטטוס תשלום:</div>
                                <div className={"big onRight bold spaceRight"}>{theOrder.paymentStatus}</div>
                            </div>
                            {(theOrder.ord?.orderRemarks != null) ?
                                <div className={"pointing"}>
                                    <div className="veryBigFontImportant bold">{(theOrder.ord?.orderRemarks != null) ? parse(`${theOrder.ord.orderRemarks}`) : ""} </div>
                                </div>
                                : ""}

                            <div className={"dottedLightGray spaceTopBig"}></div>

                            {TotPrices}
                        </div>
                    </div>
            }
        </>

    )

}

export default ShowMyOrder