import React, { Component } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'


const InfoToast  = (props) => {
 
    // toastr.remove();
    toastr.options.positionClass = props.position;
    toastr.warning(props.text);

    return (
        <></>
    )
}

export default InfoToast ;
