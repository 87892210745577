import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { UserContextProvider } from './components/MyContexts/Users_Context';
import { ScreenWidthContextProvider } from './components/MyContexts/ScreenWidth_Context';
import { BuisListContextProvider } from './components/MyContexts/BuisList_Context';
import { MobiOrNotContextProvider } from './components/MyContexts/MobiOrNotContext';
import { WaitingToApprovContextProvider } from './components/MyContexts/WaitingToApprov_Context';
import { BuisListForTablesContextProvider } from './components/MyContexts/BuisListForTables_Context';


// Css

import './assets/css/style.css';
import './assets/css/myStyle.css';
import './assets/css/printingPos.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/vendors/iconic-fonts/font-awesome/css/all.min.css';
import './assets/vendors/iconic-fonts/flat-icons/flaticon.css';
import './assets/vendors/iconic-fonts/cryptocoins/cryptocoins.css';
import './assets/vendors/iconic-fonts/cryptocoins/cryptocoins-colors.css';
import './assets/css/animate.min.css';
import './assets/css/MySetStyle.css';
import './assets/css/MobileMe.css';
import { BuisListForKoponContextProvider } from './components/MyContexts/BuisListForKopon_Context';



ReactDOM.render(
  <BrowserRouter>
    <ScreenWidthContextProvider>
      <UserContextProvider>
        <BuisListContextProvider>
          <BuisListForTablesContextProvider>
            <BuisListForKoponContextProvider>
              <MobiOrNotContextProvider>
                <WaitingToApprovContextProvider>
                  <App />
                </WaitingToApprovContextProvider>
              </MobiOrNotContextProvider>
            </BuisListForKoponContextProvider>
          </BuisListForTablesContextProvider>
        </BuisListContextProvider>
      </UserContextProvider>
    </ScreenWidthContextProvider>
  </BrowserRouter>,
  document.getElementById('AshManagment')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
