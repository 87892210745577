import { MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import myFunctions from '../../../myFunctions';
import BuisSelect from '../OrdersP/OrdersList-list/BuisSelect';
import UserPermission from '../OrdersP/OrdersList-list/UserPermission';
import { Visibility } from '@mui/icons-material';

const ListClientUsersTable = (props) => {


    useEffect(() => {

    }, [])

    return (
        <div className="ms-panel-body">
            <div className="table-responsive">
                <table className="table table-hover thead-primary">
                    <thead>
                        <tr>
                            <th scope="col">שם פרטי</th>
                            <th scope="col">שם משפחה</th>
                            <th scope="col">טלפון</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">מחק</th>
                            <th scope="col">חסום</th>
                            <th scope="col">הצג</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.usersList.map((item, index) => {
                            let itmBlock = (item.blocked === undefined || item.blocked === false) ? false : true;

                            return (
                                <tr key={item._id} itmid={item._id}>
                                    <td scope="row">{item.firstName} </td>
                                    <td scope="row">{item.lastName}</td>
                                    <td scope="row">{item.phone}</td>
                                    <td className={"textRight pointing"} >
                                     
                                    </td>
                                    <td className={"textRight pointing"} >
                                    
                                    </td>
                                    <td>
                                        <div className={"divAsIcon pointing"} onClick={() => props.removeUser(item._id)}><i className="far fa-trash-alt ms-text-danger" /></div>
                                    </td>
                                    <td>
                                        <input type="checkbox" checked={itmBlock} onChange={(e) => props.blockUser(item, index, !itmBlock)} /> <i className="ms-checkbox-check" />
                                    </td>
                                    <td>
                                    <Visibility className={"visibleHover"} sx={{ color: 'lightGray' }} onClick={() => props.showClient(item._id)}/>
                                    </td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ListClientUsersTable;