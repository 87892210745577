import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'

const getAllFromSilo = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "silo");
    return (dFromF);
}

const createSilo = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "silo", obj);
    return (dFromF.data);
}


const removeFromSilo = async (id) => {
    let dFromF = await axios.delete(ConnUrls.ApiUrl + "silo/" + id);
    return (dFromF.data);
}


const clearSilo = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "silo/clr");
    return (dFromF.data);
}


const clearItmsFromSilo = async (lst) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "silo/clr", lst);
    return (dFromF.data);
}



export default {
    getAllFromSilo,
    createSilo,
    removeFromSilo,
    clearSilo,
    clearItmsFromSilo

};