import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RichTextComp from '../TextEditor/RichText';
import SunEditor, { buttonList } from "suneditor-react";
import OrderStatuselect from '../../MyPages/OrdersP/OrdersList-list/OrderStatuselect';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import GetDeliveryAddress from '../DTechComps/GetDeliveryAddress';
import utilsGamma from "../../../MyConns/_Utils/utilsGamma"
import Crdit_Problem from "./Pop_Fixed/Crdit_Problem"
import PopUpIframe from "./PopUpIframe"
import { Helmet } from 'react-helmet';
import MySpinner_Wait from '../DTechComps/MySpinner_Wait';

import ErrorToast from '../../MySections/Toasts/ErrorToast';
import utilsUsers from '../../../MyConns/_Utils/utilsUsers';
import { LocalConvenienceStoreOutlined } from '@mui/icons-material';

const UpdateOrderDetails = (props) => {


    const [open, setOpen] = useState(true);
    const [theOrder, setTheOrder] = useState("");
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState(false);
    const [canConti, setCanConti] = useState(false);
    const [orderType, setOrderType] = useState();
    const [payt, setPayt] = useState("");
    const [thePopUp, setThePopUp] = useState("");
    const [theGamma, setTheGamma] = useState();
    const [loading, setLoading] = useState(true);
    const [errorsTxts1, setErrorsTxts1] = useState("");
    const [errorsTxts2, setErrorsTxts2] = useState("");

    const [inUser, setInUser] = useState("");

    let theCount = null;

    useEffect(() => {
        setTheOrder(props.theOrder);
        setOrderType(props.theOrder.ord.OrderType)
        setPayt(props.theOrder.ord.payt)
        setInUser(props.theOrder.usr)
        setLoading(false)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setCanConti(true)

        }, 150);
    }, [])


    const saveMe = async () => {

        // if (orderType === "Deliver" && theOrder.ord.deliveryAddress === "") {
        //     ErrorToast({ position: "toast-bottom-right", text: "יש לבחור כתובת למשלוח" });
        //     setErrorsTxts1("יש לבחור כתובת למשלוח")
        // }
        // else if (inUser != theOrder.usr) {
        //     if (inUser.firstName != "" &&
        //         inUser.lastName != "" &&
        //         inUser.phone != "") {
        //         theOrder.usr = inUser;

        //         // console.log(theOrder.usr._id)
        //         await utilsUsers.updateUser(theOrder.usr._id, theOrder.usr);


        //         theOrder.ord.OrderType = orderType;
        //         props.okClick(theOrder);
        //         handleClose();

        //     } else {
        //         ErrorToast({ position: "toast-bottom-right", text: "פרטי הלקוח לא תקינים" });
        //         setErrorsTxts2("פרטי הלקוח לא תקינים")
        //     }
        // }
        // else {
        //     theOrder.ord.OrderType = orderType;
        //     props.okClick(theOrder);
        //     handleClose();
        // }




    };


    const handleClose = () => {
        props.cancelClick();
    };



    const setTheInput = (content) => {

        setInputTxt(content);

        // setErr(false);
    }



    const goGamma = async () => {
        let order = theOrder.ord;
        let user = theOrder.usr;
        let gammaBk = await utilsGamma.goGama({ order, user });
        if (gammaBk.data != 'ERROR') {
            if (gammaBk.success) {
                // let ordrIT = await creatTheorder();
                { window.gamapayInit(gammaBk.data) }


            }
            else {
                setThePopUp(crditProblem())
            }
        }
        else {
            setThePopUp(crditProblem())
        }

    }

    let crditProblem = () => {
        return (<Crdit_Problem
            okClick={() => {
                theOrder.ord.payt = "2";
                setThePopUp()
            }}
            closePop={() => setThePopUp()} />
        )
    }



    const setNewStatus = (statBack) => {
        theOrder.ord.orderStatus = statBack;
    }


    const payTypeChange = async (e) => {
        setPayt(e.target.value);
        if (e.target.value != "2") {
            setTheGamma(await goGamma());
        }

    }


    const setOrdTypeChanged = (e) => {
        setOrderType(e.target.value);
        // theOrder.ord.OrderType = e.target.value;


    }

    return (
        loading ?
            <Dialog open={open} onClose={handleClose} className={"UpdateOrderDetailsWrap"}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <MySpinner_Wait />
                </DialogContent >
            </Dialog>
            :
            <Dialog open={open} onClose={handleClose} className={"UpdateOrderDetailsWrap"}>
                <DialogTitle>
                    <div className="bold fullRow spaceTop dottedLine">
                        <div className={"spaceTop overflowHid col-md-6 bold huge"}>{props.Title}
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>

                    {/* משלוח או איסוף */}
                    <div className={"spaceButtomVeryBig dottedLine"}>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="order Status"
                                defaultValue={orderType}
                                name="radioTakDelv"
                                className={"rdbStatSelect"}
                                onChange={(e) => setOrderType(e.target.value)}
                            >
                                <FormControlLabel value="Take" control={<Radio />} label="איסוף" />
                                <FormControlLabel value="Deliver" control={<Radio />} label="משלוח" />
                            </RadioGroup>
                        </FormControl>

                        {orderType !== "Take" ?
                            <GetDeliveryAddress class={"upordrAddrs "}
                                userId={theOrder.ord.userId}
                                delAddress={theOrder.ord.deliveryAddress}
                                updateAddress={(addrss) => theOrder.ord.deliveryAddress = addrss} />
                            : theOrder.ord.deliveryAddress = ""}

                        <div className={"errorLine"}>
                            <p>
                                {errorsTxts1}
                            </p>
                        </div>

                    </div>

                    {/* מזומן או אשראי */}
                    <div className={"fullRow spaceButtomVeryBig dottedLine"}>
                        <div className="bold fullRow spaceTop">
                            <div className={"spaceTop overflowHid col-md-6 bold huge"}>אופן תשלום:
                            </div>

                        </div>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="order Status"
                                defaultValue={payt || "2"}
                                name="radioPaytp"
                                className={"rdbStatSelect"}
                                onChange={(e) => payTypeChange(e)}
                            >
                                <FormControlLabel value="2" control={<Radio />} label="מזומן" />
                                <FormControlLabel value="1" control={<Radio />} label="אשראי" />
                            </RadioGroup>
                        </FormControl>



                        {(payt != "2") ?
                            (theOrder.ord.creditCrdApprov != null && theOrder.ord.creditCrdApprov != "") ?
                                <>
                                    <div className="bold onRight spaceTop fullRow ">
                                        שולם, מספר אישור עסק: {theOrder.ord.creditCrdApprov}
                                    </div>
                                </>
                                : theGamma
                            : ""
                        }

                    </div>


                    {/* פרטי הלקוח */}
                    <div className={"row spaceButtomVeryBig dottedLine "}>
                        <div className="bold fullRow spaceTop">
                            <div className={"spaceTop overflowHid col-md-6 bold huge"}>פרטי הלקוח:
                            </div>

                        </div>
                        <div className={"errorLine fullRow"}>
                            <p>
                                {errorsTxts2}
                            </p>
                        </div>
                        <div className="bold spaceTop overflowHid col-md-6">
                            <div className={"onRight col-md-6"} >שם פרטי:
                            </div>

                            <input type={"text"} className={"usrDet connInput onRight col-md-6"}
                                value={inUser.firstName} placeholder={"שם פרטי"} onChange={(e) => setInUser({ ...inUser, firstName: e.target.value })} />
                        </div>
                        <br />
                        <div className="bold spaceTop overflowHid col-md-6">

                            <div className={"onRight col-md-6"} >שם משפחה:
                            </div>

                            <input type={"text"} className={"usrDet connInput onRight col-md-6"}
                                value={inUser.lastName} placeholder={"שם משפחה"} onChange={(e) => setInUser({ ...inUser, lastName: e.target.value })} />
                        </div>

                        <br />
                        <div className="bold spaceTop overflowHid col-md-6">

                            <div className={"onRight col-md-6"} >טלפון:
                            </div>

                            <input type={"text"} className={"usrDet connInput onRight col-md-6"}
                                value={inUser.phone} placeholder={"טלפון נייד (לקבלת SMS)"} onChange={(e) => setInUser({ ...inUser, phone: e.target.value })} />
                        </div>

                        <br />
                        <div className="bold spaceTop overflowHid col-md-6">

                            <div className={"onRight col-md-6"} >אימייל:</div>
                            <input type={"text"} className={"usrDet connInput onRight col-md-6"}
                                value={inUser.email} placeholder={"אימייל"} onChange={(e) => setInUser({ ...inUser, email: e.target.value })} autoComplete="off" />
                        </div>
                    </div>

                    {thePopUp}
                </DialogContent >
                <DialogActions>
                    <button type="button" className="onLeft btn btn-primary" onClick={() => handleClose()}>ביטול</button>
                    {canConti ?
                        <button type="button" className={`onLeft btn btn-success`} onClick={() => saveMe()}>שמור</button>
                        : ""
                    }

                </DialogActions>
            </Dialog>


    )
}

export default UpdateOrderDetails;