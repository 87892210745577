import React, { useContext, useEffect, useState } from 'react';
// import Breadcrumb from './Breadcrumb'

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import myFunctions from '../../../myFunctions';
import { BuisListContext } from '../../MyContexts/BuisList_Context';
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import { UserContext } from '../../MyContexts/Users_Context';
import BuisSelect from '../OrdersP/OrdersList-list/BuisSelect';
import UserPermission from '../OrdersP/OrdersList-list/UserPermission';
import utilsRests from '../../../MyConns/_Utils/utilsRests';
import utilsBuisUser from '../../../MyConns/_Utils/utilsBuisUser';
import { AddBoxOutlined, ArrowBack } from '@mui/icons-material';



const CreateBuisUser = (props) => {


    const [user, setUser] = useContext(UserContext)
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [buisLst, setBuisLst] = useContext(BuisListContext);
    const [loading, setLoading] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [permiss, setPermiss] = useState("watch");
    const [buisID, setBuisID] = useState("");
    const [errorTxt, setErrorTxt] = useState("");

    useEffect(() => {
        const getBuisList = async () => {
            if (buisLst.length === 0) {
                let busiLst = await utilsRests.getRestsListSmall();
                let busiLstt = busiLst;
                setBuisLst(busiLstt)
            }
        }
        getBuisList();
        setLoading(false)

    }, [])


    const goCreate = async () => {
        let errTxt = "";
        if (firstName.trim().length <= 1) {
            errTxt = "חובה להזין שם פרטי"
        }
        else if (lastName.trim().length <= 1) {
            errTxt = "חובה להזין שם משפחה"
        }
        else if (!myFunctions.validPhone(phone)) {
            errTxt = "חובה להזין מספר טלפון תקין"
        }
        else if (!myFunctions.validEmail(email)) {
            errTxt = "יש להזין אימייל חוקי"
        }
        else if (!myFunctions.validPassword(password)) {
            errTxt = "חובה להזין סיסמא לפחות ספרה 1 ואות אחת, מינ' 6 תווים"
        }

        if (errTxt === "") {

            let nUserBack = await utilsBuisUser.createNewBuisUsr({
                firstName,
                lastName,
                phone,
                email,
                password,
                permiss,
                buisID
            });

            if (nUserBack.errMsg === "Ok") {
                setErrorTxt("נוצר בהצלחה!")
                props.setTheLists();
            }
            else {
                setErrorTxt(nUserBack.errMsg)
            }
        }
        else { setErrorTxt(errTxt) }
    }


    return (
        <div>
            {loading ?
                ""
                :
                <div className={"col-md-12 mb-2"}>
                    {(errorTxt !== "") ?
                        <label className={'inRed bold errorTxt'}>{errorTxt}</label> : ""
                    }
                    <div className={"col-md-12 mb-2 fullRow textRight pointing specialBlk"}>
                        <div className={"col-md-5 mb-2 onRight"}>
                            <span className={"smallPadding"}>הרשאה:</span>
                        </div>
                        <div className={"col-md-3 mb-2 onRight"}>
                            <div className={"autoCOmpItem"}>
                                <UserPermission clss={"buisSelectAddUser"}
                                    UserPermiss={permiss}
                                    setUserPermiss={(newVal) => { setPermiss(newVal) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-12 mb-2 fullRow textRight pointing specialBlk"}>
                        <div className={"col-md-5 mb-2 onRight"}>
                            <span className={"smallPadding"}>עסק:</span>
                        </div>
                        <div className={"col-md-3 mb-2 onRight"}>
                            <div className={"autoCOmpItem"}>
                                <BuisSelect
                                    firstHead={"כל העסקים"}
                                    clss={"buisSelectAddUser"}
                                    defaultValue={myFunctions.getBuisNameByIdInList(buisLst, buisID)}
                                    userSelection={buisID ? buisID : -1}
                                    busissList={buisLst}
                                    setUserBuis={(newVal) => { setBuisID(newVal) }}
                                />
                            </div>
                        </div>

                    </div>
                    <div className={"col-md-12 mb-2 fullRow"}>
                        <div className={"col-md-5 mb-2 onRight"}>
                            <span className={"smallPadding"}>שם פרטי:</span>
                        </div>
                        <div className={"col-md-3 mb-2 onRight"}>
                            <input type="text" className={""}
                                placeholder={"שם פרטי"} value={firstName}
                                onChange={(e) => { setFirstName(e.target.value) }} />
                        </div>
                    </div>
                    <div className={"col-md-12 mb-2 fullRow"}>
                        <div className={"col-md-5 mb-2 onRight"}>
                            <span className={"smallPadding"}>שם משפחה:</span>
                        </div>
                        <div className={"col-md-3 mb-2 onRight"}>
                            <input type="text" className={""}
                                placeholder={"שם משפחה"} value={lastName}
                                onChange={(e) => { setLastName(e.target.value) }} />
                        </div>

                    </div>
                    <div className={"col-md-12 mb-2 fullRow"}>
                        <div className={"col-md-5 mb-2 onRight"}>
                            <span className={"smallPadding"}>טלפון:</span>
                        </div>
                        <div className={"col-md-3 mb-2 onRight"}>
                            <input type="text" className={""}
                                placeholder={"טלפון"} value={phone}
                                onChange={(e) => { setPhone(e.target.value) }} />
                        </div>
                    </div>

                    <div className={"col-md-12 mb-2 fullRow"}>
                        <div className={"col-md-5 mb-2 onRight"}>

                            <span className={"smallPadding"}>אימייל:</span>
                        </div>
                        <div className={"col-md-3 mb-2 onRight"}>
                            <input type="text" className={""}
                                placeholder={"אימייל"} value={email}
                                onChange={(e) => { setEmail(e.target.value) }} />

                        </div>
                    </div>
                    <div className={"col-md-12 mb-2 fullRow"}>
                        <div className={"col-md-5 mb-2 onRight"}>
                            <span className={"smallPadding"}>סיסמא:</span>
                        </div>
                        <div className={"col-md-3 mb-2 onRight"}>
                            <input type="text" className={""}
                                placeholder={"סיסמא"} value={password}
                                onChange={(e) => { setPassword(e.target.value) }} />
                        </div>
                    </div>


                    <div className={"col-md-12 mb-2 fullRow"}>
                        <div className={"col-md-5 mb-2 onRight"}>
                            <Button variant="contained" className={"theSBtn"} onClick={() => goCreate()}
                                startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                                צור
                            </Button>
                        </div>
                        <div className={"col-md-5 mb-2 onRight"}>
                            <Button variant="contained" className={"theSBtn"} onClick={() => props.okClick()}
                                endIcon={<ArrowBack style={{ color: 'white' }} />}>
                                סגור
                            </Button>
                        </div>
                    </div>
                </div>

            }
        </div>
    )
}
export default CreateBuisUser;

