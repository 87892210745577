import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const createLandingPage = async () => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "landingP/landP/cr");
    return (dFromF);
}

const deleteLandingPage = async (id, obj) => {
    let dFromF = await axios.delete(ConnUrls.ApiUrl + "landingP/landP/" + id, obj);
    return (dFromF);
}
const allPgsNames = async (token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "landingP/landP/allPgsNames",{token});

    return (dFromF);
}

const getLandingPageById = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "landingP/" + id);
    return (dFromF);
}

const updateSingle = async (id, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "landingP/" + id, obj);
    return (dFromF);
}


const getPageByName = async (name, obj) => {

    let dFromF = await axios.get(ConnUrls.ApiUrl + "landingP/bn/" + name, obj);
    return (dFromF);
}

export default {
    getLandingPageById,
    updateSingle,
    getPageByName,
    createLandingPage,
    allPgsNames,
    deleteLandingPage,

}; 