import { MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';

const CRTypeSelect = (props) => {

    const [restCRT, setCRT] = useState((props.restCRT != null) ? props.restCRT : "tablet");


    const handleChange = (e) => {
        setCRT(e.target.value)
        props.CRTUpdate(e.target.value)
    }

    return (
        <select
            className={`${props.onLeft} selectInBlock`}
            value={restCRT}
            onChange={handleChange}>
            <option value={"tablet"} >tablet</option>
            <option value={"beeCom"}>beeCom</option>
            <option value={"beeComCloud"}>beeComCloud</option>
            <option value={"presto"} >presto</option>
            <option value={"Aviv"} >Aviv</option>
            <option value={"Caspit"} >Caspit</option>
            <option value={"TabIt"} >TabIt</option>
            <option value={"ווריפון"} >ווריפון</option>
            <option value={"Dorix"} >Dorix</option>
            <option value={"אחר"} >אחר</option>
        </select>
    );
}

export default CRTypeSelect;