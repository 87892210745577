import { MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';

const RestStatusSelect = (props) => {

    const [restStatus, setStatus] = useState((props.restStatus != null) ? props.restStatus : "פעיל");


    const handleChange = (e) => {
        setStatus(e.target.value)
        props.statusUpdate(e.target.value)
    }

    return (
        <select
            className={`onLeft selectInBlock ${(restStatus === "לא פעיל") ? "noActive" : (restStatus === "לא מפורסם") ? "noPublish" : ""}`}
            value={restStatus}
            onChange={handleChange}>
            <option value={"פעיל"}>פעיל</option>
            <option value={"לא פעיל"} >לא פעיל</option>
            <option value={"לא מפורסם"} >לא מפורסם</option>
        </select>
    );
}

export default RestStatusSelect;