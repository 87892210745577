import categurys from "../_ApiConn/apiCategurys";


const addCategury = async (obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await categurys.addCategury({token,obj});
    return (dFromF.data);
}


const removeCategury = async (id) => {
    const token = localStorage.getItem('token');
    let dFromF = await categurys.removeCategury(id,token);
    return (dFromF.data);
}

const getAllCategurys = async () => {
    let dFromF = await categurys.getAllCategurys();
    return (dFromF.data);
}

const updateCategury = async (id, obj) => {
    const token = localStorage.getItem('token');
    let dFromF = await categurys.updateCategury(id, { token, obj });

    return (dFromF.data);
}

const getCateguryById = async (id) => {
    let dFromF = await categurys.getCateguryById(id);
    return (dFromF.data);
}


const getRestsByCatId = async (id) => {
    let dFromF = await categurys.getRestsByCatId(id);
    return (dFromF.data);
}


export default {
    getAllCategurys,
    getCateguryById,
    getRestsByCatId,
    updateCategury,
    addCategury, removeCategury

};