import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const getKopons = async (token) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "kopons/all", { params: token });
    return (dFromF.data);
}


const createKopon = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "kopons/createKopon", obj);
    return (dFromF.data);
}

const getKoponsBySearch = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "kopons/getKoponsBySearch", { params: obj });
    return (dFromF.data);
}

const updateKopon = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "kopons/updateKopons", obj);
    return (dFromF.data);
}

const deleteKoponsByIds = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "kopons/deleteKoponsByIds", obj);
    return (dFromF.data);
}


const getKoponById = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "kopons/getKoponById", { params: obj });
    return (dFromF.data);
}


const updateFullKopon = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "kopons/updateFullKopon", obj);
    return (dFromF.data);
}

const getActivatorsList = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "kopons/getActivatorsList", { params: obj });
    return (dFromF.data);
}


const sendKoponToClient = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "kopons/sendKoponToClient", obj);
    return (dFromF.data);
}

export default {
    deleteKoponsByIds,
    sendKoponToClient,
    getActivatorsList,
    getKopons,
    createKopon,
    getKoponsBySearch,
    updateKopon,
    getKoponById,
    updateFullKopon
};