
import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card, Nav, Tab } from "react-bootstrap";
import { useParams } from 'react-router';
import Breadcrumb from './Breadcrumb'
// import TabBuisDetails from './parts/TabBuisDetails';
// import TabImages from './parts/TabImages';
// import TabMaps from './parts/TabMaps';
// import TabNetwork from './parts/TabNetwork';
// import TabSales from './parts/TabSales';
// import TabPageDetails from './parts/TabPageDetails';
// import TabOpeningHours from './parts/TabOpeningHours';

import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import { HomePContext } from '../../MyContexts/HomeP_Context';

import { useHistory } from "react-router-dom";
import TabPageDetails from '../compsForAllPages/TabPageDetails';
import RichTextComp from '../../MySections/TextEditor/RichText';
import SelectImgComp from '../../MySections/ImagesSetter/SelectImgComp';
import SucessToast from '../../MySections/Toasts/SucessToast';
import EraseCheck from '../../MySections/PopUps/EraseCheck';
import utilsContactPage from '../../../MyConns/_Utils/utilsContactPage';
// import BasicPopUp from '../../MySections/PopUps/BasicPopUp';
// import SucessToast from '../../MySections/Toasts/SucessToast';
// import TabPageDetails from '../compsForAllPages/TabPageDetails';
// import TabHPDetails from './parts/TabHPDetails';
// import TabMainRests from './parts/TabMainRests';
// import TabFavoRests from './parts/TabFavoRests';


const ContactP = (props) => {
    const history = useHistory();

    const [currSingle, setCurrSingle] = useState();
    const [loading, setLoading] = useState(true);

    let params = useParams();
    const [thePopUp, setThePopUp] = useState("");
    const [mainTitle, setMainTitle] = useState("");
    const [addressLine, setAddressLine] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [wahtsUpNum, setWahtsUpNum] = useState("");

    const [fax, setFax] = useState("");
    const [subj, setSubj] = useState("");

    const [mainImage, setMainImage] = useState("");
    const [mainContent, setMainContent] = useState("");
    const [pageName, setPageName] = useState("");
    const [inLinkToPage, setInLinkToPage] = useState("");

    const [linkChanged, setLinkChanged] = useState(false);



    useEffect(() => {
        if (props.newp) {
            createNewP();
        }
        else {
            getSinglePage();
        }
    }, [])

    const createNewP = async () => {
        //יוצר עמוד חדש
        let theSingle = await utilsContactPage.createContactPage();
        if (theSingle != null) {
            props.upContactList(theSingle)
            history.push(theSingle.inLinkToPage)
        }
    }
    const tempUpdate = (upRest) => {
        // setHomeP(upRest);

    }

    const getSinglePage = async () => {

        let theSingle = await utilsContactPage.getContactPageById(props.pageId);

        setCurrSingle(theSingle);
        setLoading(false)

    }


    const updateTheContactP = async () => {

        if (currSingle._id != null) {

            let theSingle = await utilsContactPage.updatecontactP(currSingle);

            if (linkChanged) {
                document.location.href = currSingle.inLinkToPage;
            }
            else {
                SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
            }
        }

    }

    const removeSinglePage = () => {
        if (currSingle._id != null) {
            setThePopUp(<EraseCheck title={'האם בטוח למחוק את העמוד?'} sucecssFunc={() => removePageConfirmd(currSingle._id)} />)
        }
    }

    const removePageConfirmd = async () => {
        let theSingle = await utilsContactPage.deleteContactPage(currSingle._id);
        history.push('/');

    }

    const updatePDetails = (key, val) => {
        currSingle[key] = val;
    }

    return (
        <div className="ms-content-wrapper SetBlock">
            <Breadcrumb />
            {/* Active Orders Graph */}
            <div className="row">
                <div className="col-md-12">
                    {loading ? "loading" :

                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <div className="col-md-6 mb-6 onLeft">
                                    <button type="button" className="onLeft btn btn-primary" onClick={() => removeSinglePage()}>מחק</button>
                                </div>
                                <div className={"col-md-12 mb-6 onRight"}>
                                    <label className={"bold"}>שם העמוד</label>
                                    <div className={"input-group"}>
                                        <input type="text" className={"Big-form-control inputSPMain"}
                                            placeholder={"שם העמוד"} value={(currSingle.pageName) ? currSingle.pageName : ""}
                                            onChange={(e) => { currSingle["pageName"] = e.target.value; setPageName(e.target.value); setLinkChanged(true) }} />
                                    </div>
                                </div>

                                <div className={"col-md-12 mb-6 onRight"}>
                                    <label className={"bold"}>קישור</label>
                                    <div className={"input-group"}>
                                        <input type="text" className={"Big-form-control inputSPMain"}
                                            placeholder={"קישור"} value={(currSingle.inLinkToPage) ? currSingle.inLinkToPage : ""}
                                            onChange={(e) => { currSingle["inLinkToPage"] = e.target.value; setInLinkToPage(e.target.value); setLinkChanged(true) }} />
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel-body clearfix">
                                    <Tab.Container defaultActiveKey="tab2">
                                        <Nav variant="tabs" className="nav nav-tabs d-flex nav-justified mb-2">
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab2">תוכן</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tab1">פרטי העמוד</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>


                                            {/*תוכן*/}
                                            <Tab.Pane eventKey="tab2">
                                                <div className="form-row tabsInputs ">
                                                    <div className={"col-md-12 mb-6"}>
                                                        <label htmlFor={"validationCustom1_1"}>כותרת ראשית</label>
                                                        <div className={"input-group"}>
                                                            <input type="text" className={"form-control"} id={"validationCustom1_1"} placeholder={"כותרת ראשית"} value={currSingle.mainTitle}
                                                                onChange={(e) => { currSingle["mainTitle"] = e.target.value; setMainTitle(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-3 mb-6"}>
                                                        <label htmlFor={"validationCustom1_6"}>תמונה ראשונה</label>
                                                        <span className={"explanation mediumst fullROw"}></span>
                                                        <SelectImgComp firstImgToDisp={currSingle.mainImage ? currSingle.mainImage : ""}
                                                            theFunction={(fUrl) => { currSingle["mainImage"] = fUrl; setMainImage(fUrl) }} />

                                                    </div>
                                                    <div className={"col-md-9 mb-2"}>
                                                        <label htmlFor={"validationCustom1_2"}>תוכן</label>
                                                        <div className={"input-group"}>
                                                            <RichTextComp firstText={currSingle.mainContent}
                                                                saveEditorTxt={(content) => { currSingle["mainContent"] = content; setMainContent(content) }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-row tabsInputs ">
                                                    <div className={"col-md-4 mb-12"}>
                                                        <label htmlFor={"validationCustom1_1"}>כתובת</label>
                                                        <div className={"input-group"}>
                                                            <input type="text" className={"form-control"} id={"validationCustom1_addr"} placeholder={"כתובת"} value={currSingle.addressLine}
                                                                onChange={(e) => { currSingle["addressLine"] = e.target.value; setAddressLine(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-4 mb-12"}>
                                                        <label htmlFor={"validationCustom1_Ph"}>טלפון</label>
                                                        <div className={"input-group"}>
                                                            <input type="text" className={"form-control"} id={"validationCustom1_Ph"} placeholder={"טלפון"} value={currSingle.phoneNum}
                                                                onChange={(e) => { currSingle["phoneNum"] = e.target.value; setPhoneNum(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-4 mb-12"}>
                                                        <label htmlFor={"validationCustom1_Ph"}>וואטסאפ</label>
                                                        <div className={"input-group"}>
                                                            <input type="text" className={"form-control"} id={"validationCustom1_Ph"} placeholder={"וואטסאפ"} value={currSingle.wahtsUpNum}
                                                                onChange={(e) => { currSingle["wahtsUpNum"] = e.target.value; setWahtsUpNum(e.target.value) }} />

                                                        </div>
                                                    </div>
                                                    <div className={"col-md-4 mb-12"}>
                                                        <label htmlFor={"validationCustom1_Fax"}>פקס</label>
                                                        <div className={"input-group"}>
                                                            <input type="text" className={"form-control"} id={"validationCustom1_Fax"} placeholder={"פקס"} value={currSingle.fax}
                                                                onChange={(e) => { currSingle["fax"] = e.target.value; setFax(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-4 mb-12"}>
                                                        <label htmlFor={"validationCustom1_Subj"}>נושא העמוד</label>
                                                        <div className={"input-group"}>
                                                            <input type="text" className={"form-control"} id={"validationCustom1_Subj"} placeholder={"נושא"} value={currSingle.subj}
                                                                onChange={(e) => { currSingle["subj"] = e.target.value; setSubj(e.target.value) }} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </Tab.Pane>

                                            {/* פרטי העמוד */}
                                            <Tab.Pane eventKey="tab1">
                                                <div className="form-row tabsInputs ">
                                                    <TabPageDetails pgToSet="pgPageDetails" thePage={currSingle} thePageUpdate={(key, val) => updatePDetails(key, val)} />
                                                </div>
                                            </Tab.Pane>

                                        </Tab.Content>
                                    </Tab.Container>
                                </div>



                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="ms-panel-header approveLine">
                <button type="button" className="onLeft btn btn-primary" onClick={() => updateTheContactP()}>שמור</button>
            </div>
            {thePopUp}
        </div>
    );
}



export default ContactP;