import React, { Component, useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import OrderFunctions from '../../../../MyFunctions/OrderFunctions';
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';
import OrderStatuselect from './OrderStatuselect';
import DeliveryDetails from '../DeliveryDetails/DeliveryDetails';
import PopUpTheComp from '../../../MySections/DTechComps/PopUpTheComp';

const ListOrdersBlocks = (props) => {

    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {

    }, [])

    const orderBythis = (byWhat, ordUserOrBuiss) => {
        props.orderBythis(byWhat, ordUserOrBuiss);
    }
    const rowCellClick = (ordId) => {
        props.rowCellClick(ordId);
    }

    const setNewStatus = (orderId, statBack, buisId) => {
        props.setNewStatus(orderId, statBack, buisId);
    }




    const PopDeliveryDetails = (orderNUM, buisName, AddrsTo) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"OrderDispPop"}
                popDiagStyle={"OrderDispValPop"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["פרטי השליחות"]}
                theInsideComp={
                    <DeliveryDetails cssStyl={"OrderDisplayPop"}
                        cancelClick={() => setThePopUp()}
                        okClick={() => { setThePopUp() }}
                        Title={[""]}
                        ordNum={orderNUM}
                        buisName={buisName}
                        AddrsTo={AddrsTo}
                    />}
            />

        )
    }
    return (
        <div className="table-responsive theBigTbl ms-panel-body">

            {
                props.theList.reverse().map((item, i) => (
                    <div className={`orderItminList big pointing  ${item.ord.paymentStatus === "ממתין לחיוב" ? "tempoOrdRow" : ""}`} key={item.ord._id}
                        itmid={item.ord._id}>
                        <div className={"timerTd"} onClick={() => rowCellClick(item.ord._id)} id={i + "_Count"}>
                            <div id={item.ord._id + "_Cnt"}>
                                <br />
                            </div>
                        </div>
                        <div className={'orderItmFlo onLeft'}>
                            <div className={"textLeft spaceTop spaceButtom bold"}>#{item.ord.orderNUM}</div>
                            <br />
                            <div className={"textLeft regular"}>הוזמן ב: {MyDateTime.getShortTime(item.ord.dateCreated)}</div>
                            <Button className={`btnMediumMnuSmall theSBtn`} variant="contained"
                                onClick={() => rowCellClick(item.ord._id)}>
                                הצג הזמנה
                            </Button>
                        </div>

                        <div className={'orderItmblkIN'}>

                            <div className={"fullRow spaceButtom"}>
                                <span className={"orderItmTxt veryBig bold"}>{(item.ord.OrderType === "Take") ? "איסוף" : "משלוח"}</span>
                            </div>
                            <br />
                            <div className={"fullRow spaceButtom"}>
                                <span className={"orderItmTxt smallFont"}>העסק:</span>&nbsp;
                                <span className={"orderItmTxt"}>{item.buiss.buissName}</span>
                            </div>
                            <div className={"fullRow spaceButtom"}>
                                <span className={"orderItmTxt smallFont"}>הלקוח:</span>&nbsp;
                                <span className={"orderItmTxt"}>{item.theUser.firstName} {item.theUser.lastName}</span>
                            </div>
                            <div className={"fullRow spaceButtom"}>
                                <span className={"orderItmTxt smallFont"}>טלפון:</span>&nbsp;
                                <span className={"orderItmTxt"}><a href={`tel:${item.theUser.phone}`}>{item.theUser.phone}</a></span>
                            </div>
                            <div className={"fullRow spaceButtom"}>
                                {(item.ord.OrderType === "Deliver") ?
                                    <>
                                        <br />
                                        {(!item.ord?.deliveryAddress.street.trim().endsWith(item.ord?.deliveryAddress.num)) ?
                                            item.ord.deliveryAddress.street + " " + item.ord.deliveryAddress.num + " " + item.ord.deliveryAddress.city
                                            : item.ord.deliveryAddress.street + " " + item.ord.deliveryAddress.city
                                        }
                                    </>

                                    : ""}
                            </div>
                            <div className={"fullRow spaceButtom"}>

                                <div className={"statusCell pointing"} >
                                    <OrderStatuselect orderStatus={item.ord.orderStatus}
                                        setOrderStatus={(statBack) => setNewStatus(item.ord._id, statBack, item.buiss._id)}
                                    />

                                </div>

                            </div>
                            {(item.ord.OrderType !== "Take" && item.ord?.sendiStatus && item.ord?.sendiStatus.trim() !== "") ?
                                <>
                                    <div className={"fullRow sendiStatus"}>
                                        <span className={"orderItmTxt smallFont"}>שליחות:</span>&nbsp;
                                        <Button className={"btnDeliveryDetail dvStat"} variant="contained"
                                            onClick={() => PopDeliveryDetails(item.ord.orderNUM, item.buiss.buissName,
                                                item.ord.deliveryAddress.street + " " + item.ord.deliveryAddress.num + " " + item.ord.deliveryAddress.city)}>
                                            {item.ord.sendiStatus}
                                        </Button>
                                    </div>
                                    <br />
                                </>
                                : ""}
                            <div className={"fullRow"}>
                                <div className={"orderItmTxt textLeft big bold"}>לתשלום: ₪{OrderFunctions.getOrderTotalPrice(item.ord)}</div>
                            </div>

                        </div>
                    </div>
                ))
            }
            {thePopUp}
        </div>
    )
}

export default ListOrdersBlocks;