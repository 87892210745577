import React, { useState, createContext, useEffect } from 'react';
import myFunctions from '../../myFunctions';
import utilsTblOrders from '../../MyConns/_Utils/utilsTblOrders';

export const WaitingToApprovContext = createContext();

export const WaitingToApprovContextProvider = props => {
    const [wToImpto, setWToImpto] = useState([]);

    useEffect(() => {
        getItms();
    }, []);

    const getItms = async () => {
        let lst = await utilsTblOrders.getWaitingForApprov();
        const reducedList = lst.map(item => ({ _id: item._id, status: item.status }));
        setWToImpto(reducedList);
    };

    const checkIfItemExistsAndStatus = (theItmToCheck) => {

        const foundItem = wToImpto.find(item => item._id === theItmToCheck._id);

        if (foundItem) {
            if (theItmToCheck.status !== 'ממתין לאישור') {
                setWToImpto(prevList => prevList.filter(item => item._id !== theItmToCheck._id))
            }
        } 
        else if (theItmToCheck.status === 'ממתין לאישור') 
        { 
            setWToImpto(prevList => prevList.concat(theItmToCheck));
        }
    };

    return (
        <WaitingToApprovContext.Provider value={[wToImpto, setWToImpto, checkIfItemExistsAndStatus]}>
            {props.children}
        </WaitingToApprovContext.Provider>
    );
};
