import React, { useContext, useEffect, useState } from 'react'

const AccordProdNumInput = (props) => {

    const [prodName, setProdName] = useState((props.prodTxtVal != null) ? props.prodTxtVal : "");

    useEffect(() => {
        let proVal = (props.prodTxtVal != null) ? props.prodTxtVal : "";
        setProdName(proVal);
    }, [props.prodTxtVal])
    return (
        <div className={`${props.clss ? props.clss : "prodFirst"}`}>
            <label>{props.headI}</label>
            <input type={"number"} min={props.minimum} value={prodName} placeholder={props.headI}
                onChange={(e) => setProdName(e.target.value)}
                onBlur={(e) => props.setProdItm(props.index, props.proIndex, e.target.value)} />
        </div>

    )
}

export default AccordProdNumInput;