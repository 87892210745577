import React, { useContext, useEffect, useState } from 'react'

const DivSmalltext = (props) => {

    const [divSmalltextName, setDivSmalltextName] = useState(props.prodTxtVal);

    useEffect(() => {
        setDivSmalltextName(props.prodTxtVal);
    }, [props.prodTxtVal])
    return (
        <div className={`${props.clss ? props.clss : "prodFirst"}`}>
            <label className={"txtARLbl"}>{props.headI}</label>
            <textarea className={"textAr"} ros={"4"} placeholder={props.headI}
                onChange={(e) => setDivSmalltextName(e.target.value)}
                onBlur={(e) => props.setDivDescription(e.target.value)} 
                value={divSmalltextName}
                ></textarea>
        </div>

    )
}

export default DivSmalltext;



