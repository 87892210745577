import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const updateUser = async (userId, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "user/" + userId, obj);
    return (dFromF.data);
}


const updateClientsList = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "user/upClntsLst", obj);
    return (dFromF.data);
}

const clear24Stuck = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "user/clear24Stuck", obj);
    console.log(dFromF);
    return (dFromF.data);
}


const createUser = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "user", obj);
    return (dFromF.data);
}


const removeUser = async (obj) => {
    let dFromF = await axios.delete(ConnUrls.ApiUrl + "user", { data: obj });
    return (dFromF.data);
}

const getUsers = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "user/getUsrs", {
        params: {
            token: obj.token,
        },
    });
    return (dFromF.data);
}
const searchUser = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "user/searchUser", {
        params: {
            token: obj.token,
            txtSrch: obj.txtSrch,
        },
    });
    return (dFromF.data);
}



const getUserById = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "user/getUsr", {
        params: obj,
    });
    return (dFromF.data);
}



const getAdresses = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "user/Address/" + id);
    return (dFromF.data);
}

const addNewAddress = async (id, address) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "user/Address/" + id, address);
    return (dFromF.data);
}


const removeAddress = async (id, address) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "user/removeAdd/" + id, address);
    return (dFromF.data);
}


export default {
    removeAddress,
    addNewAddress,
    getAdresses,
    updateUser,
    createUser,
    getUsers,
    updateClientsList,
    removeUser,
    getUserById,
    clear24Stuck,
    searchUser
};