import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import Breadcrumb from './Breadcrumb'

import { Checkbox, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import { UserContext } from '../../MyContexts/Users_Context';
import { AddBoxOutlined, Visibility } from '@mui/icons-material';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { DatePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/he';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import utilsKopons from '../../../MyConns/_Utils/utilsKopons';
import myFunctions from '../../../myFunctions';
import MyDateTime from '../../MySections/TimeSections/MyDateTime';
import MultiLIstKoponSelect from './MultiLIstKoponSelect';
import MultiUpdateCheck from '../../MySections/PopUps/MultiUpdateCheck';
import PopUpTheComp from '../../MySections/DTechComps/PopUpTheComp';
import KoponActivatorsList from './KoponActivatorsList';
import InputSet from '../../MySections/PopUps/InputSet';
import SucessToast from '../../MySections/Toasts/SucessToast';



const KoponDetails = (props) => {
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [theKopon, setTheKopon] = useState();
    const [thePopUp, setThePopUp] = useState("");
    const [name, setName] = useState('');
    const [buissName, setBuissName] = useState('');
    const [type, setType] = useState('');
    const [koponsCount, setKoponsCount] = useState('');

    const [koponCreationDate, setKoponCreationDate] = useState('');
    const [koponExperationDate, setKoponExperationDate] = useState('');
    const [used, setUsed] = useState('');
    const [kCode, setKCode] = useState('');
    const [koponDiscount, setKoponDiscount] = useState('');
    const [koponDiscType, setKoponDiscType] = useState('1');
    const [minmumForOrder, setMinmumForOrder] = useState('');
    const [kStatus, setKStatus] = useState('');
    const [usersUsedIt, setUsersUsedIt] = useState([]);


    useEffect(() => {
        getTheKopon();
    }, [])

    const getTheKopon = async () => {
        let kopi = await utilsKopons.getKoponById(props.kopoinId);
        setTheKopon(kopi)

        setName(kopi.Name)
        setBuissName(kopi.buissName)
        setType(kopi.Type)
        setKoponsCount(kopi.KoponsCount)
        setKoponDiscType(kopi.KoponDiscType || '1')
        setKoponCreationDate(kopi.KoponCreationDate)
        setKoponExperationDate(kopi.KoponExperationDate)
        setUsed(kopi.Used)
        setKCode(kopi.KCode)
        setKoponDiscount(kopi.KoponDiscount)
        setMinmumForOrder(kopi.MinmumForOrder)
        setKStatus(kopi.KStatus)
        setUsersUsedIt(kopi.usersUsedIt)

    }

    const updateTheKopon = () => {

        theKopon.Name = name;
        theKopon.Type = type;
        theKopon.KoponsCount = koponsCount;
        theKopon.KoponCreationDate = koponCreationDate;
        theKopon.KoponExperationDate = koponExperationDate;
        theKopon.Used = used;
        theKopon.KCode = kCode;
        theKopon.KoponDiscount = koponDiscount;
        theKopon.KoponDiscType = koponDiscType;
        theKopon.MinmumForOrder = minmumForOrder;
        theKopon.KStatus = kStatus;

        let theUpdatedKop = utilsKopons.updateFullKopon(theKopon);

        props.okClick();
    }



    const handleDiscountTypeChange = (event) => {
        setKoponDiscType(event.target.value);
    };


    const showActivators = () => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"KoponDispPop"}
                popDiagStyle={"kopActivatorsPop"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={[`מפעילי הקופון (${usersUsedIt.length})`]}
                theInsideComp={<KoponActivatorsList usersUsedIt={usersUsedIt} />}
            />
        )
    }

    const sendToClients = (koponId) => {

        setThePopUp(<InputSet
            okCLickTxt={"שלח"}
            ContentText={`טלפונים לשליחה? הפרד מספרים עם פסיק...`}
            diagCss={"z9005"}
            okClick={(inputTxt) => okSend(inputTxt, koponId)}
            cancelClick={() => { setThePopUp() }}
            errorMsg={"יש להזין לפחות מספר טלפון אחד"} />)

        const okSend = async (inputTxt, koponId) => {
            let phonesList = inputTxt.split(',').map(number => number.trim());
            let sendme = await utilsKopons.sendKoponToClient(koponId, phonesList);
            SucessToast({ position: "toast-bottom-right", text: "נשלח בהצלחה!" });
            setThePopUp()
        }
    }

    return (
        <div className={`${props.cssStyl ? props.cssStyl : ""}`}>

            <div className="">
                <div className="table-responsive ms-panel-body">
                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>שם: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <span>{name}</span>
                        </div>
                    </div>
                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>לעסק/ים: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <span>{buissName}</span>
                        </div>
                    </div>

                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>סוג: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <span>{type}</span>
                        </div>
                    </div>
                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>קוד הקופון: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <span>{kCode}</span>
                        </div>
                    </div>
                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>כמות קופונים: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <input type="number" className={"koponInput"}
                                id={"setGroupkoponNumb_11Cs"}
                                min={1}
                                value={koponsCount || 50}
                                onChange={(e) => {
                                    // Ensure the entered value is within the specified range
                                    const newValue = Math.min(props.max || Infinity, Math.max(e.target.value, 1));
                                    setKoponsCount(newValue);
                                }} />
                        </div>
                    </div>

                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>הופעלו: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <span>{used}  <Button variant="contained" className={"theSBtn"}
                                onClick={() => showActivators()}
                                startIcon={<Visibility style={{ color: 'white' }} />}>
                                הצג
                            </Button></span>
                        </div>
                    </div>
                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>סכום הקופון: </span>

                            <div className={"onRight spaceMargeRightbig"}>
                                <label>
                                    <input
                                        type="radio"
                                        value="1"
                                        checked={koponDiscType === '1'}
                                        onChange={handleDiscountTypeChange}
                                    />
                                    &nbsp;בשקלים
                                </label>
                                &nbsp; &nbsp;
                                <label>
                                    <input
                                        type="radio"
                                        value="2"
                                        checked={koponDiscType === '2'}
                                        onChange={handleDiscountTypeChange}
                                    />
                                    &nbsp;באחוזים
                                </label>
                            </div></div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <input type="number" className={"koponInput"}
                                id={"setKoponDiscount_1Cs"}
                                value={koponDiscount}
                                min={0}
                                onChange={(e) => { setKoponDiscount(e.target.value) }} />
                        </div>
                    </div>


                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>מחיר מינימום להפעלה: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <input type="number" className={"koponInput"}
                                id={"setKoponDiscount_1Cs"}
                                value={minmumForOrder}
                                min={0}
                                onChange={(e) => { setMinmumForOrder(e.target.value) }} />
                        </div>
                    </div>


                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>סטטוס: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <select className={"koponInput"}
                                value={kStatus ? 'פעיל' : 'לא פעיל'}
                                onChange={(e) => setKStatus(e.target.value === 'פעיל')}>
                                <option value="פעיל">פעיל</option>
                                <option value="לא פעיל">לא פעיל</option>
                            </select>

                        </div>
                    </div>
                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>תאריך סיום תוקף: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale} timeZone="Asia/Jerusalem">
                                <DatePicker
                                    ampm={false}
                                    label="תפוגה"
                                    value={koponExperationDate}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(e) => setKoponExperationDate(e)}
                                    renderInput={(params) => <TextField {...params} />}
                                    className={"tmPick"}
                                    style={{ width: '100%' }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>



                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>תאריך יצירה: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <span>{MyDateTime.getDate(koponCreationDate)}</span>
                        </div>
                    </div>

                    <div className={"col-md-12 mb-6 onRight spaceMargeTopbig spaceBottomBig dottedLine"}>
                        <div className={` ${mobiOrNot ? "onRight textRight" : "col-md-6 mb-6 onRight textLeft"}`}>
                            <span className={'bold'}>שיתוף בסמס: </span>
                        </div>
                        <div className={` ${mobiOrNot ? "onRight textRight spaceMargeRightbig" : "col-md-6 mb-6 onRight"}`}>
                            <Button variant="contained" className={"theSBtn"}
                                onClick={() => sendToClients(theKopon._id)}
                                startIcon={<Visibility style={{ color: 'white' }} />}>
                                SMS
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={"btnsDiag"}>
                    <button type="button"
                        className="centerMe btn btn-success btnPopRegular onRight"
                        onClick={() => { updateTheKopon() }}>
                        עדכן
                    </button>
                    <span>&nbsp;&nbsp;</span>
                    <button type="button"
                        className="centerMe btn btn-success btnPopRegular onRight"
                        onClick={() => { props.cancelClick() }}>
                        סגור
                    </button>
                </div>
            </div >
            {thePopUp}
        </div >
    )
}
export default KoponDetails;

