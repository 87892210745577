import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { RestContext } from '../../MyContexts/Rest_Context';

const DTSearchBar = (props) => {

    const [txtToSearch, setTxtToSearch] = useState();


    useEffect(() => {
    }, [])


    const searchMe = (txtSearch) => {
        if (txtToSearch) {
            txtSearch = (txtSearch === null) ? txtToSearch.trim() : txtSearch.trim();
            let lstBack = [];

            if (txtSearch != "") {
                let theLstBack = props.searchList.map((itm, index) => {

                    if (itm.buissName.includes(txtSearch)) {
                        lstBack = [...lstBack, itm];
                    }
                    else {

                        let fMe = props.CatList.map(item =>

                            itm.buisCats.forEach(cat => {
                                if (cat === item._id && lstBack.findIndex(ls => ls._id === itm._id) === -1) {
                                    if (item.catHead.includes(txtSearch)) {
                                        lstBack = [...lstBack, itm];
                                    }
                                }
                            }
                            ))

                    }
                })

                props.serachRslt(lstBack);
            }
            else {

                props.serachRslt(props.searchList);
            }
        }
    }

    const checkIfEnter = (e) => {
        searchMe(txtToSearch);
    }

    const catSearch = (sen) => {
        if (sen != "cat_0") {
            setTxtToSearch(sen)
            searchMe(sen)
        }
        else {
            setTxtToSearch("")
            searchMe("")
        }
    }

    const txtChanged = (e) => {
        setTxtToSearch(e.target.value)
        searchMe(e.target.value)
    }

    let catSelect =
        <div className="catgeDrp pointing">

            <select
                value={txtToSearch}
                onChange={(e) => catSearch(e.target.value)}
            >

                <option key={"cat_0"} value={"cat_0"}>כל הקטגוריות</option >

                {
                    props.CatList.map((cat, index) => {

                        return (
                            <option key={cat._id} value={cat.catHead}>{cat.catHead}</option >
                        )

                    })
                }
            </select>

        </div>


    return (
        <>
            <div className="srchBox">
                <div className="searchSe">

                    <div className="ms-form-group my-0 mb-0 has-icon fs-14">
                        <input type="search" className="dtSearchInput ms-form-input" id="dtSearchInput" name="dtSearchInput" placeholder="שם העסק או קטגוריה" onChange={(e) => txtChanged(e)}
                            onKeyUp={(e) => checkIfEnter(e)}
                        />
                        <Button className="dtSearchIcon flaticon-search text-disabled" onClick={() => searchMe()} />
                    </div>
                </div>
                <div className="catSe">
                    {catSelect}

                </div>
            </div>

        </>


    );
}

export default DTSearchBar;