import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card, Nav, Tab } from "react-bootstrap";
import { useParams } from 'react-router';
// import Breadcrumb from './Breadcrumb'

import { useHistory } from "react-router-dom";
import SucessToast from '../../MySections/Toasts/SucessToast';
import { UserContext } from '../../MyContexts/Users_Context';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import { Dialog } from '@mui/material';
import { HighlightOffOutlined } from '@mui/icons-material';
import PageSelectImage from '../../MySections/ImagesSetter/PageSelectImage';



const ImageGallContent = (props) => {
    const history = useHistory();

    const [user, setUser] = useContext(UserContext)
    const [diag, setDiag] = useState("");

    const [loading, setLoading] = useState(true);
    let idParam = useParams();
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {
        setLoading(false)
    }, [])


    const updateTheProfile = async () => {
        // let HomePLst = await utilsHomePage.updateHomePage(homeP);
        // // setRest(rstLst);
        // setHomeP({ ...homeP })
        SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
    }




    return (
        <div className="ms-content-wrapper SetBlock">
            {/* <Breadcrumb /> */}
            {/* Active Orders Graph */}
            <div className="row">
                <div className="col-md-12">
                    {loading ? <MySpinner_Wait /> :
                        <>
                            <div className={"inDiag"}>
                                <PageSelectImage firstImg={""} handleClose={() => setDiag()}
                                    selectedImg=
                                    {(fUrl) => {
                                        setDiag();
                                    }} />

                            </div>
                        </>
                    }
                </div>
            </div>
            {thePopUp}
        </div>
    );
}


export default ImageGallContent;