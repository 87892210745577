import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { RestContext } from '../../MyContexts/Rest_Context';
import SelectImgComp from '../../MySections/ImagesSetter/SelectImgComp';
import SelectImgDelay from '../../MySections/ImagesSetter/SelectImgDelay';

const TabPageDetails = (props) => {

    let thePage = props.thePage;

    const [pageName, setPageName] = useState(thePage.pageName);
    const [sEOdescription, setSEOdescription] = useState(thePage.sEOdescription);
    const [sEOkeywords, setSEOkeywords] = useState(thePage.sEOkeywords);
    const [searchTxtBox, setSearchTxtBox] = useState(thePage.searchTxtBox);
    const [altTags, setAltTags] = useState(thePage.altTags);
    const [waitingImg, setWaitingImg] = useState(thePage.waitingImg);
    const [pageUrlFix, setPageUrlFix] = useState(thePage.pageUrlFix);
    const [imgDelay, setImgDelay] = useState(thePage.imgDelay ? thePage.imgDelay : "");
    const [imgPosRndom, setImgPosRndom] = useState(thePage.imgPosRndom ? thePage.imgPosRndom : false);

    useEffect(() => {
        thePage = props.thePage;
        setPageName(thePage.pageName);
        setSEOdescription(thePage.sEOdescription);
        setSEOkeywords(thePage.sEOkeywords);
        setSearchTxtBox(thePage.searchTxtBox);
        setAltTags(thePage.altTags);
        setWaitingImg(thePage.waitingImg);
        setPageUrlFix(thePage.pageUrlFix);
        setImgDelay(thePage.imgDelay ? thePage.imgDelay : "");
        setImgPosRndom(thePage.imgPosRndom ? thePage.imgPosRndom : false);
    }, [props.thePage])
    return (
        <>

            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom01"}>שם העמוד</label>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom01"} placeholder={"שם העמוד"} value={pageName}
                        onChange={(e) => { props.thePageUpdate("pageName", e.target.value); setPageName(e.target.value) }} required />

                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom01"}>URL</label>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom58"} placeholder={"Url חדש"} value={pageUrlFix}
                        onChange={(e) => { props.thePageUpdate("pageUrlFix", e.target.value.replace(' ', '-')); setPageUrlFix(e.target.value) }} required />
                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom02"}>SEO תיאור</label>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom02"} placeholder={"SEO"} value={sEOdescription}
                        onChange={(e) => { props.thePageUpdate("sEOdescription", e.target.value); setSEOdescription(e.target.value) }} />
                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom03"}>SEO KEYS</label>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom03"} placeholder={"SEO KEYS"} value={sEOkeywords}
                        onChange={(e) => { props.thePageUpdate("sEOkeywords", e.target.value); setSEOkeywords(e.target.value) }} />
                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom04"}>מילות חיפוש</label>
                <span className={"explanation mediumst fullROw"}>יש להפריד עם פסיק (,)</span>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom04"} placeholder={"מילים מופרדות בפסיק"} value={searchTxtBox}
                        onChange={(e) => { props.thePageUpdate("searchTxtBox", e.target.value); setSearchTxtBox(e.target.value) }} />
                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom05"}>Tags</label>
                <div className={"input-group"}>
                    <input type={"text"} className={"form-control"} id={"validationCustom05"} placeholder={"TAGS"} value={altTags}
                        onChange={(e) => { props.thePageUpdate("altTags", e.target.value); setAltTags(e.target.value) }} />
                </div>
            </div>

            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>תמונת המתנה</label>

                <span className={"explanation mediumst fullROw"}>מוצג בזמן המתנת עליית כל הנתונים</span>
                <SelectImgComp firstImgToDisp={waitingImg ? waitingImg : ""} theFunction={(fUrl) => { props.thePageUpdate("waitingImg", fUrl); setWaitingImg(fUrl) }} />

            </div>
            <div className={"col-md-3 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>השהיית תמונה</label>
                <span className={"explanation mediumst fullROw"}>זמן השהייה לפני העלמות התמונה</span>

                <SelectImgDelay currentDelay={imgDelay ? imgDelay : ""} theFunction={(fUrl) => { props.thePageUpdate("imgDelay", fUrl); setImgDelay(fUrl) }} />
            </div>
            <div className={"col-md-3 mb-2"}>

                <label className="ms-switch">
                    <input type="checkbox" id={"validationCustom5_3"} checked={imgPosRndom}
                        onChange={() => {
                            props.thePageUpdate("imgPosRndom", !imgPosRndom); setImgPosRndom(!imgPosRndom)
                        }} />
                    <span className="ms-switch-slider ms-switch-primary round" />
                </label> <span className={"spHead"}>מיקום רנדומלי</span>
            </div>

        </>

    );
}

export default TabPageDetails;