import React, { useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { RestContext } from '../../../../MyContexts/Rest_Context';

const TabNetwork = (props) => {

    const [rest] = useContext(RestContext);

    const [buisWebLInk, setBuisWebLInk] = useState(rest.buisWebLInk);
    const [buissFacbook, setBuissFacbook] = useState(rest.buissFacbook);
    const [buissInstegram, setBuissInstegram] = useState(rest.buissInstegram);
    const [buissYoutube, setBuissYoutube] = useState(rest.buissYoutube);


    return (
        <>
            <div className={"roEvenOdd "}>
                <div className={"col-md-6 mb-2"}>
                    <label htmlFor={"validationCustom1_5"}>אתר העסק</label>
                    <span className={"explanation mediumst fullROw"}></span>
                    <div className={"input-group"}>
                        <input type="text" className={"form-control"} id={"validationCustom1_5"} placeholder={"http://mysite.com"} value={buisWebLInk.lnk}
                            onChange={(e) => { rest["buisWebLInk"] = { lnk: e.target.value, txt: buisWebLInk.txt }; setBuisWebLInk({ lnk: e.target.value, txt: buisWebLInk.txt }) }} />
                    </div>
                </div>
                <div className={"col-md-6 mb-2"}>
                    <label htmlFor={"validationCustom1_5"}>טקסט הקישור</label>
                    <span className={"explanation mediumst fullROw"}>לא חובה</span>
                    <div className={"input-group"}>
                        <input type="text" className={"form-control"} id={"validationCustom1_5"} placeholder={"http://mysite.com"} value={buisWebLInk.txt}
                            onChange={(e) => { rest["buisWebLInk"] = { lnk: buisWebLInk.lnk, txt: e.target.value }; setBuisWebLInk({ lnk: buisWebLInk.lnk, txt: e.target.value }) }} />
                    </div>
                </div>
            </div>
            <div className={"roEvenOdd "}>
                <div className="col-md-6 mb-2">
                    <label htmlFor="validationCustom09">פייסבוק</label>
                    <span className={"explanation mediumst fullROw"}></span>
                    <div className="input-group">
                        <input type="text" className="form-control" id="validationCustom04" placeholder={"http://www.facebook.com"} value={buissFacbook.lnk}
                            onChange={(e) => { rest["buissFacbook"] = { lnk: e.target.value, txt: buissFacbook.txt }; setBuissFacbook({lnk:e.target.value, txt:buissFacbook.txt}) }} />
                    </div>
                </div>
                <div className={"col-md-6 mb-2"}>
                    <label htmlFor={"validationCustom1_5"}>טקסט הקישור</label>
                    <span className={"explanation mediumst fullROw"}>לא חובה</span>
                    <div className={"input-group"}>
                        <input type="text" className={"form-control"} id={"validationCustom1_5"} placeholder={"http://mysite.com"} value={buissFacbook.txt}
                            onChange={(e) => { rest["buissFacbook"] = { lnk: buissFacbook.lnk, txt: e.target.value }; setBuissFacbook({ lnk: buissFacbook.lnk, txt: e.target.value }) }} />
                    </div>
                </div>
            </div>
            <div className={"roEvenOdd "}>
                <div className="col-md-6 mb-2">
                    <label htmlFor="validationCustom09">אינסטגרם</label>
                    <span className={"explanation mediumst fullROw"}></span>
                    <div className="input-group">
                        <input type="text" className="form-control" id="validationCustom04" placeholder={"http://www.instegram.com"} value={buissInstegram.lnk}
                            onChange={(e) => { rest["buissInstegram"] = { lnk: e.target.value, txt: buissInstegram.txt }; setBuissInstegram({lnk:e.target.value, txt:buissInstegram.txt}) }} />
                    </div>
                </div>
                <div className={"col-md-6 mb-2"}>
                    <label htmlFor={"validationCustom1_5"}>טקסט הקישור</label>
                    <span className={"explanation mediumst fullROw"}>לא חובה</span>
                    <div className={"input-group"}>
                        <input type="text" className={"form-control"} id={"validationCustom1_5"} placeholder={"http://mysite.com"} value={buissInstegram.txt}
                            onChange={(e) => { rest["buissInstegram"] = { lnk: buissInstegram.lnk, txt: e.target.value }; setBuissInstegram({ lnk: buissInstegram.lnk, txt: e.target.value }) }} />
                    </div>
                </div>
            </div>
            <div className={"roEvenOdd "}>
                <div className="col-md-6 mb-2">
                    <label htmlFor="validationCustom09">יוטיוב</label>
                    <span className={"explanation mediumst fullROw"}></span>
                    <div className="input-group">
                        <input type="text" className="form-control" id="validationCustom04" placeholder={"http://www.youtube.com"} value={buissYoutube.lnk}
                            onChange={(e) => { rest["buissYoutube"] ={ lnk: e.target.value, txt: buissYoutube.txt }; setBuissYoutube({lnk:e.target.value, txt:buissYoutube.txt}) }} />
                    </div>
                </div>
                <div className={"col-md-6 mb-2"}>
                    <label htmlFor={"validationCustom1_5"}>טקסט הקישור</label>
                    <span className={"explanation mediumst fullROw"}>לא חובה</span>
                    <div className={"input-group"}>
                        <input type="text" className={"form-control"} id={"validationCustom1_5"} placeholder={"http://mysite.com"} value={buissYoutube.txt}
                            onChange={(e) => { rest["buissYoutube"] = { lnk: buissYoutube.lnk, txt: e.target.value }; setBuissYoutube({ lnk: buissYoutube.lnk, txt: e.target.value }) }} />
                    </div>
                </div>
            </div>
        </>

    );
}

export default TabNetwork;