import React, { useState, createContext, useEffect } from 'react';

export const TheMasterContext = createContext();

export const TheMasterContextProvider = props => {
    const [theMaster, setTheMaster] = useState([]);

    useEffect(() => {
   
       
    }, [theMaster])


    return (
        <TheMasterContext.Provider value={[theMaster, setTheMaster]}>
            {props.children}
        </TheMasterContext.Provider>
    )
}

