import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useState } from 'react';
import utilsRests from '../../../../MyConns/_Utils/utilsRests';
import myFunctions from '../../../../myFunctions';
import { BuisListContext } from '../../../MyContexts/BuisList_Context';
import { UserContext } from '../../../MyContexts/Users_Context';
import BetweenDates from '../../../MySections/TimeSections/BetweenDates';
import BuisSelect from './BuisSelect';
import { MobiOrNotContext } from '../../../MyContexts/MobiOrNotContext';
import { Checkbox } from '@mui/material';
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';



const BuisBarReports = (props) => {
    const [user] = useContext(UserContext);
    const [buisLst, setBuisLst] = useContext(BuisListContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [payTypeDisp, setPayTypeDisp] = useState("הכל");
    const [selectedBtn, setSelectedBtn] = useState("13");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const getBuisList = async () => {
            setLoading(true)
            if (buisLst.length === 0) {
                let tempolst = await utilsRests.getRestsListSmall();
                tempolst = myFunctions.sortAsc(tempolst, "buissName");
                tempolst = myFunctions.addIdtag(tempolst);
                setBuisLst(tempolst)
            }
            setLoading(false)
        }
        getBuisList();
    }, [])



    const goSearch = () => {
        props.refreshTheList(selectedBtn, payTypeDisp)
    }

    const printIt = () => {
        window.print();

    }




    return (
        <>
            <div id={"printingReporter"}>
                <div className={"buisTollBar"}>
                    {loading ? "" :
                        <BuisSelect busissList={buisLst}
                            defaultValue={myFunctions.getBuisNameByIdInList(buisLst, props.userBuis)}
                            firstHead={"כל העסקים"}
                            clss={`buisSelectOnReports ${!mobiOrNot ? "onRight" : ""}`}
                            userSelection={props.userBuis ? props.userBuis : -1}
                            setUserBuis={(newVal) => { props.setUserBuis(newVal) }}
                        />}

                    <div className={"reportsHead onlyOnPrint fullRow"}>
                        <span className={"smallFont bold"}> דו"ח </span>
                        <span className={"smallFont bold"}>{props.reportHeadTimes !== "חודש שעבר" ? props.reportHeadTimes : "חודשי"} .
                        </span>
                        <span className={"smallFont bold"}> {props.reportHeadTyp}</span>
                        <span className={"smallFont bold"}> :{MyDateTime.getDate(props.endDTS)} - </span>
                        <span className={"smallFont bold"}>{MyDateTime.getDate(props.startDTS)}</span>
                    </div>




                    <Stack direction="row" spacing={2}>
                        <div className={"reportsBtnMenu onRight"}>

                            {mobiOrNot ?
                                <div className={"spaceRow"}></div>
                                : ""
                            }

                            <div className={"fullRow no_print"}>
                                <RadioGroup
                                    onChange={(e) => setSelectedBtn(e.target.value)}
                                    row
                                    defaultValue="13"
                                    name="זמני חיפוש"
                                >
                                    <FormControlLabel value="13" control={<Radio onClick={() => { props.setReportHeadTimes("חודש שעבר"); props.setShowBetweenDts(false) }} />} label="חודש שעבר" />
                                    <FormControlLabel value="8" control={<Radio onClick={() => { props.setReportHeadTimes("אתמול"); props.setShowBetweenDts(false) }} />} label="אתמול" />
                                    <FormControlLabel value="9" control={<Radio onClick={() => { props.setReportHeadTimes("מתחילת החודש"); props.setShowBetweenDts(false) }} />} label="מתחילת החודש" />
                                    <FormControlLabel value="10" control={<Radio onClick={() => { props.setReportHeadTimes("לפי תאריכים"); props.setShowBetweenDts(true) }} />} label="לפי תאריכים" />

                                </RadioGroup>


                            </div>

                            {mobiOrNot ?
                                <div className={"spaceRow"}></div>
                                : ""
                            }

                            <div className={`spaceTop  ${(props.showBetweenDts) ? "" : "hideMe"}`}>
                                <div className={"onRight"}>
                                    <BetweenDates
                                        startDTS={props.startDTS} setStartDTS={(dt) => props.setStartDTS(dt)}
                                        endDTS={props.endDTS} setEndDTS={(dt) => props.setEndDTS(dt)} />
                                </div>
                            </div>
                            <div className={"fullRow no_print"}>

                                <RadioGroup
                                    onChange={(e) => {
                                        setPayTypeDisp(e.target.value);
                                        props.setReportHeadTimes((e.target.value === "הכל") ? "מזומן ואשראי" :
                                            (e.target.value === "2") ? "מזומן" : "אשראי");
                                    }}
                                    row
                                    aria-labelledby="מזומן-או-אשראי"
                                    defaultValue="הכל"
                                    name="מזומן-או-אשראי"
                                >
                                    <FormControlLabel value="הכל" lbl={"מזומן+אשראי"} control={<Radio />} label="מזומן+אשראי" />
                                    <FormControlLabel value="2" lbl={"מזומן"} control={<Radio />} label="מזומן" />
                                    <FormControlLabel value="1" lbl={"אשראי"} control={<Radio />} label="אשראי" />
                                </RadioGroup>


                            </div>

                            {mobiOrNot ?
                                <div className={"spaceRow"}></div>
                                : ""
                            }
                            <Button className={`no_print btnBigMnu fullRow no_print`} variant="contained" onClick={() => goSearch()}>
                                חפש
                            </Button>
                            {!mobiOrNot ?
                                <Button className={`no_print btnBigMnu fullRow no_print`} variant="contained" onClick={() => printIt()}>
                                    printIt
                                </Button>
                                : ""}



                        </div>
                        {/* <div className={"btnMnuTop onLeft"}>
            <Link to={`/Order`}>
                <Button className={"btnAddItm"} variant="contained"
                    startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                    חדש
                </Button>
            </Link>
        </div> */}


                    </Stack>

                </div >
            </div >
            {/* <iframe id={"ifmReporttoprint"}></iframe> */}
        </>
    )
}

export default BuisBarReports;

