
import React, { useState, createContext, useEffect } from 'react';
import myFunctions from '../../myFunctions';


export const ScreenWidthContext = createContext();

export const ScreenWidthContextProvider = props => {
    const [screenWidth, setScreenWidth] = useState();

    useEffect(() => {
        handleResize()
    }, [])


    const handleResize = () => {
        //  console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
        setScreenWidth(window.screen.width != null ? window.screen.width : "")
    }


    window.addEventListener('resize', handleResize)



    return (
        <ScreenWidthContext.Provider value={[screenWidth, setScreenWidth]}>
            {props.children}
        </ScreenWidthContext.Provider>
    )
}





