
const validPhone = (phone) => {
    if (phone !== "") {
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (!pattern.test(phone)) {
            return false
        } else if (phone.length != 10 && phone.length != 9) {
            return false
        }
        else {
            return true;
        }
    }
}
const validEmail = (email) => {
    if (email !== "") {
        var pattern = new RegExp(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i);
        if (!pattern.test(email)) {
            return false
        }
        else {
            return true;
        }
    }
}

const validPassword = (password) => {
    if (password !== "") {
        let pattern = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{6,}))');
        if (!pattern.test(password)) {
            return false
        }
        else {
            return true;
        }
    }
}

export default { validPhone, validEmail, validPassword };