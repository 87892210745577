import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'

const getMediaList = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "media/folderLst", obj);
    return (dFromF);
}

const updateFile = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "media", obj);
    return (dFromF.data);
}

const createFolder = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "media", obj);
    return (dFromF.data);
}

const removeFolder = async (obj) => {

    let dFromF = await axios.delete(ConnUrls.ApiUrl + "media", { data: { StartingPath: obj.StartingPath }});

    return (dFromF.data);
}

const removeFile = async (obj) => {

    let dFromF = await axios.delete(ConnUrls.ApiUrl + "media", { data: {type:"removeFile", StartingPath: obj.StartingPath }});

    return (dFromF.data);
}



const addNewFile = async (data) => {

    let dFromF =  await axios.post(ConnUrls.ApiUrl + "media", data,{
        headers:{
            'content-type': 'multipart/form-data'
        }
    } );
    return (dFromF.data);
}

export default {
    getMediaList,
    updateFile,
    createFolder,
    removeFolder,
    addNewFile,
    removeFile
};