
import React, { useEffect } from 'react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import SunEditor, { buttonList } from "suneditor-react";
import parse from 'html-react-parser';


const MyTextArea = (props) => {
    return (
        <>
            <textarea className={props.cssStyle} lang="he" onChange={(e) => props.saveEditorTxt(e.target.value)}
                // defaultValue={props.firstText}
                value={props.firstText}>{props.firstText}</textarea>

        </>

    )
}

export default MyTextArea