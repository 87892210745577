import React, { useEffect, useState } from 'react';


const NumberSelection = (props) => {
    const [startNumber, setStartNumber] = useState(50);


    useEffect(() => {


    }, [])


    const updateNumber = (e) => {
        setStartNumber(e)
        props.setTheNumber(e)

    }

    return (
        <table className={"tabTable tableBordSpace"}>
            <tbody>
                {

                    <tr style={{ marginTop: "10px" }}>
                        {/* <td style={{ width: "20%" }}>{MyDateTime.getDayOfWeekToHeb(item.Day)}</td> */}
                        <td>
                            <table className={"tblOpHours"}>
                                <tbody>
                                    <tr>
                                        <td>

                                            <input type="number" className={"koponInput"}
                                                id={"setGroupkoponNumb_11Cs"}
                                                min={1}
                                                max={props.max || null}
                                                value={startNumber}
                                                onChange={(e) => {
                                                    // Ensure the entered value is within the specified range
                                                    const newValue = Math.min(props.max || Infinity, Math.max(e.target.value, 1));
                                                    updateNumber(newValue);
                                                }} />

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </td>

                    </tr>

                }
            </tbody>
        </table>

    );
}

export default NumberSelection;
