import { MenuItem, Select } from '@mui/material';
import React, { Component, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../MyContexts/Users_Context';

const OrderStatuselect = (props) => {

    const [user] = useContext(UserContext);
    const [orderStatus, setOrderStatus] = useState((props.orderStatus != null) ? props.orderStatus : "ממתין");
    const [colorforStat, setColorforStat] = useState("");

    useEffect(() => {
        setOrderStatus(props.orderStatus)
        setColorforStat(getOrdColorByStat(props.orderStatus))

    }, [props.orderStatus])

    const handleChange = (e) => {
        if (orderStatus != "סגור" || user.permiss === "admin") {
            setOrderStatus(e.target.value)
            setColorforStat(getOrdColorByStat(e.target.value))
            props.setOrderStatus(e.target.value)
        }
    }

    const getOrdColorByStat = (status) => {

        const colorsSelect = {
            "ממתין לתגובה": "badge badge-primary",
            "בהכנה": "badge badge-primary",
            "מוכן": "badge badge-finished",
            "ממתין למשלוח": "badge badge-danger",
            "ממתין לאיסוף": "badge badge-warning",
            "נשלח": "badge badge-secondary",
            "נמסר ללקוח": "badge badge-finished",
            "סגור": "badge badge-dark",
            "מושהה": "badge badge-Delayed",
            "מבוטל": "badge badge-dark"
        };

        return colorsSelect[status] || "badge badge-primary";
    }

    return (
        <span className={`${colorforStat} 'pointing' `}>
            <select
                className={`onLeft selectInBlock ${(orderStatus === "לא פעיל") ? "noActive" : (orderStatus === "לא מפורסם") ? "noPublish" : ""}`}
                value={orderStatus}
                onChange={handleChange}>
                <option value={"ממתין לתגובה"}>ממתין לתגובה</option>
                <option value={"בהכנה"}>בהכנה</option>
                <option value={"מוכן"}>מוכן</option>
                <option value={"ממתין לאיסוף"} >ממתין לאיסוף</option>
                <option value={"ממתין למשלוח"} >ממתין למשלוח</option>
                <option value={"נשלח"} >נשלח</option>
                <option value={"נמסר ללקוח"} >נמסר ללקוח</option>
                <option value={"סגור"} >סגור</option>
                <option value={"מושהה"} >מושהה</option>
                <option value={"מבוטל"} >מבוטל</option>
            </select>
        </span>
    );
}

export default OrderStatuselect;