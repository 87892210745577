import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const createContactPage = async () => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "contactP/conp/cr");
    return (dFromF);
}

const deleteContactPage = async (id, obj) => {
    let dFromF = await axios.delete(ConnUrls.ApiUrl + "contactP/conp/" + id, obj);
    return (dFromF);
}
const allPgsNames = async (token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "contactP/conp/allPgsNames",{token});

    return (dFromF);
}

const getContactPageById = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "contactP/" + id);
    return (dFromF);
}

const updateSingle = async (id, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "contactP/" + id, obj);
    return (dFromF);
}


const getPageByName = async (name, obj) => {

    let dFromF = await axios.get(ConnUrls.ApiUrl + "contactP/bn/" + name, obj);
    return (dFromF);
}

export default {
    getContactPageById,
    updateSingle,
    getPageByName,
    createContactPage,
    allPgsNames,
    deleteContactPage,

}; 