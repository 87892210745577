import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ImagePopUp = (props) => {


    const [open, setOpen] = useState(true);
    const [extan, setExtan] = useState("");
    const [imgName, setImgName] = useState("");


    useEffect(() => {
        let temp = props.Title.split('.');

        setExtan(temp[temp.length - 1])

        temp.pop();
        setImgName(temp.join('.'));
    }, [])

    const saveMe = () => {

        props.okClick((imgName != "") ? imgName + "." + extan : props.Title);

        handleClose();

    };


    const handleClose = () => {
        props.cancelClick();
    };

    return (
        <Dialog open={open} onClose={handleClose} className={"imgPopUp"}>
            <div className="imgPopUpTitle big">
                <input type="text" value={imgName} onChange={(e) => setImgName(e.target.value)} />.{extan}
            </div>
            <DialogContent>
                <img src={props.imgSrc} />

            </DialogContent>
            <DialogActions>
                <button type="button" className="onLeft btn btn-primary" onClick={handleClose}>{props.cancelText}</button>
                <button type="button" className="onLeft btn btn-primary" onClick={() => saveMe()}>{props.saveText}</button>

            </DialogActions>
        </Dialog>
    );
}

export default ImagePopUp;