import jwt from "jsonwebtoken";
import { useHistory } from 'react-router-dom';
import ConnUrls from "./MyConns/Connections/ConnectURL";
import utilsBuisUser from "./MyConns/_Utils/utilsBuisUser";
import utilsRests from "./MyConns/_Utils/utilsRests";






const getBuisNameByIdInList = (lst, Id) => {

    let tempo = lst.find(itm => itm._id === Id);
    if (tempo) {
        return tempo.buissName;
    }
    else { return "" }

}

const getBuisnessByIds = (lst, busness) => {
    console.log(busness)
    // let alls = busness.split(',');

    // Use map to transform each element in alls
    const results = busness.map(Id => {
        let tempo = lst.find(itm => itm._id === Id);
        return tempo ? tempo.buissName : "";
    });

    return results;
};

const addIdtag = (arr) => {

    let tempo = [...arr];

    tempo.forEach((item, index) => {
        if (item.id) {
        } else {
            item.id = item._id;
        }
    })
    return tempo;
}

const sortAsc = (arr, field) => {
    try {
        return arr.sort((a, b) => {
            if (a[field] > b[field]) { return 1; }
            if (b[field] > a[field]) { return -1; }
            return 0;
        })
    }
    catch { }
}

const sortDsc = (arr, field) => {
    return arr.sort((a, b) => {
        if (a[field] < b[field]) { return 1; }
        if (b[field] < a[field]) { return -1; }
        return 0;
    })
}


const sortByItemAsc = (arr, field, ordUserOrBuiss) => {

    if (ordUserOrBuiss === "ord") {
        return arr.sort((a, b) => {
            if (a.ord[field] > b.ord[field]) { return 1; }
            if (b.ord[field] > a.ord[field]) { return -1; }
            return 0;
        })
    }
    else if (ordUserOrBuiss === "buiss") {
        return arr.sort((a, b) => {
            if (a.buiss[field] > b.buiss[field]) { return 1; }
            if (b.buiss[field] > a.buiss[field]) { return -1; }
            return 0;
        })
    }

    else if (ordUserOrBuiss === "theUser") {
        return arr.sort((a, b) => {
            if (a.theUser[field] > b.theUser[field]) { return 1; }
            if (b.theUser[field] > a.theUser[field]) { return -1; }
            return 0;
        })
    }
}


const sortTheBuisLst = (arr, field, rev, nullReplace) => {
    let tempo = [...arr];
    let theRtrn =
        tempo.sort((a, b) => {
            let theTester = "";
            let theTester2 = "";

            if (field === "Delivery" || field === "TakeAway") {
                theTester = a[field].Doing || false;
                theTester2 = b[field].Doing || false;
            }
            else if (field === "buissIsOnline") {
                theTester = a[field] || false;
                theTester2 = b[field] || false;
            } else {
                theTester = (a[field] === null) ? nullReplace : a[field];
                theTester2 = (b[field] === null) ? nullReplace : b[field];
            }

            if (theTester > theTester2) { return 1; }
            if (theTester2 > theTester) { return -1; }

            return 0;
        })
    return ((rev) ? theRtrn : theRtrn.reverse());

}


const sortByItemDsc = (arr, field, ordUserOrBuiss) => {

    if (ordUserOrBuiss === "ord") {
        try {
            return arr.sort((a, b) => {
                if (a.ord[field] < b.ord[field]) { return 1; }
                if (b.ord[field] < a.ord[field]) { return -1; }
                return 0;
            })
        }
        catch { }
    }
    else if (ordUserOrBuiss === "buiss") {
        return arr.sort((a, b) => {
            if (a.buiss[field] < b.buiss[field]) { return 1; }
            if (b.buiss[field] < a.buiss[field]) { return -1; }
            return 0;
        })
    }
    else if (ordUserOrBuiss === "theUser") {
        return arr.sort((a, b) => {
            if (a.theUser[field] < b.theUser[field]) { return 1; }
            if (b.theUser[field] < a.theUser[field]) { return -1; }
            return 0;
        })
    }
}






const checkToken = async (toeknToCheck) => {

    let theUsr = await utilsBuisUser.checkToken(toeknToCheck);
    return theUsr;

}



const setGetCurrnetTab = (setOrGet, itemName, tabName) => {
    if (setOrGet === "Set") {
        sessionStorage.setItem(itemName, tabName);
        return ("done")
    }
    else if (setOrGet = "Get") {
        return (sessionStorage.getItem(itemName) || "")
    }
}


const connCheck = async () => {

    const token = localStorage.getItem('token');
    if (token) {
        const user = jwt.decode(token);
        if (!user) {
            localStorage.removeItem('token');
            goLogin();
        }
        else {
            let usr = await checkToken(token);

            if (usr.user != null) {
                return usr;
            }
            else {
                localStorage.removeItem('token');
                goLogin();
            }
        }
    }
    else {
        goLogin();
    }
}

const goLogin = () => {
    if (!window.location.href.includes('/Login') && !window.location.href.includes('/resPass')) {
        return window.location.href = '/Login'
    }

}





const getOrderTotalPrice = (theOrder) => {
    let tot = 0;
    if (theOrder.OrderType === "Deliver") {
        tot = parseInt(theOrder.OrderDelPrice);
    }
    theOrder.Orders.map((item, index) => {

        if (item.specials.length > 0) {
            item.specials.map((special, specialIndex) => {
                special.spec.map((spec, specIndex) => {
                    if (parseFloat(spec.addToPrice) > -1) {
                        tot += parseFloat(spec.addToPrice) * parseInt(item.mount);
                    }
                })
            })
        }
        tot += parseFloat(item.prodPrice) * parseInt(item.mount);

    })

    return tot;
}
const getOrderPrice = (theOrder) => {
    let tot = 0;

    theOrder.Orders.map((item, index) => {

        if (item.specials.length > 0) {
            item.specials.map((special, specialIndex) => {
                special.spec.map((spec, specIndex) => {
                    if (parseFloat(spec.addToPrice) > -1) {
                        tot += parseFloat(spec.addToPrice) * parseInt(item.mount);
                    }
                })
            })
        }
        tot += parseFloat(item.prodPrice) * parseInt(item.mount);

    })

    return tot;
}

const hearBling = () => {

    let ad = new Audio(ConnUrls.servMediasUrl + "Sounds/bell1.wav");
    ad.play();
}

const hearUpdatedBling = () => {

    let ad = new Audio(ConnUrls.servMediasUrl + "Sounds/bell2.wav");
    ad.play();
}

const hearSendiUpBling = () => {

    let ad = new Audio(ConnUrls.servMediasUrl + "Sounds/bell3.wav");
    ad.play();
}

const hearNewNoPayBling = () => {

    let ad = new Audio(ConnUrls.servMediasUrl + "Sounds/bell4.wav");
    ad.play();
}

const getListByBuisId = (OrdrsList, userBuis) => {
    if (userBuis != null && userBuis != -1) {
        let ordsNew = OrdrsList.filter(itm => itm.buiss._id === userBuis)
        OrdrsList = ordsNew;
    }

    return (OrdrsList || [])
}

const validPhone = (phone) => {

    if (phone !== "") {
        var pattern = new RegExp(/^[0-9]+$/);
        if (!pattern.test(phone)) {
            return false
        } else if (phone.length != 10 && phone.length != 9) {
            return false
        }
        else {
            return true;
        }
    }
}
const validEmail = (email) => {
    if (email !== "") {
        var pattern = new RegExp(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i);
        if (!pattern.test(email)) {
            return false
        }
        else {
            return true;
        }
    }
}

const valid6DigsPassword = (password) => {
    if (password !== "") {
        //לפחות ספרה 1 ואות אחת, מינימום 6 תווים
        let pattern = new RegExp('(?=.*[a-zA-Z])(?=.*\\d).{6,}');
        if (!pattern.test(password)) {
            return false
        }
        else {
            return true;
        }
    }
}
const validPassword = (password) => {
    if (password !== "") {
        let pattern = new RegExp('^(?=.*[a-zA-Z])(?=.*\\d).{6,}$');
        if (!pattern.test(password)) {
            return false;
        } else {
            return true;
        }
    }
}

const sendiStatus = (delivery_status) => {
    const theStatus = {
        0: "ממתין לציוות",
        1: "בדרך לאיסוף",
        2: "בדרך ללקוח",
        3: "נמסר ללקוח",
        4: "השליחות בוטלה"
    };

    return theStatus[delivery_status] || "ממתין לציוות";

};

const getOrdColorByStat = (status) => {

    const colorsSelect = {
        "ממתין לתגובה": "badge badge-primary",
        "בהכנה": "badge badge-primary",
        "מוכן": "badge badge-finished",
        "ממתין למשלוח": "badge badge-danger",
        "ממתין לאיסוף": "badge badge-warning",
        "נשלח": "badge badge-secondary",
        "נמסר ללקוח": "badge badge-finished",
        "סגור": "badge badge-dark",
        "מושהה": "badge badge-Delayed",
        "מבוטל": "badge badge-dark"

    }


    return colorsSelect[status] || "badge badge-primary";

}

const getSendiColors = (status) => {


    let newStat = status.split("-")[0].trim();

    const sendiColors = {

        "בדרך לאיסוף": "badge badge-primary",
        "בדרך ללקוח": "inBGPurpple",
        "נמסר ללקוח": "inBGGreen",

    };

    return sendiColors[newStat] || "badge badge-primary";
    // return "badge badge-primary";

}

const getStatusIndxByVal = (status) => {

    const statusSelected = {
        "ממתין לתגובה": 0,
        "בהכנה": 1,
        "מוכן": 2,
        "ממתין למשלוח": 3,
        "ממתין לאיסוף": 4,
        "נשלח": 5,
        "נמסר ללקוח": 6,
        "סגור": 7,
        "מושהה": 8,
        "מבוטל": 9,
    };

    return statusSelected[status] || 0;

}

//יוצר רשימת שעות לשימוש 
const getTimesList = () => {
    let timeSlots = [];
    let strttm = 0;
    let endttm = 5;
    for (let hour = strttm; hour <= endttm; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const formattedHour = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            timeSlots.push(`${formattedHour}:${formattedMinute}`);
        }
    }

    return (timeSlots);
};
export default {
    sortAsc,
    setGetCurrnetTab,
    addIdtag,
    sortDsc,
    sortByItemAsc,
    sortByItemDsc,
    connCheck,
    checkToken,
    getOrderTotalPrice,
    getOrderPrice,
    goLogin,
    hearUpdatedBling,
    hearBling,
    getListByBuisId,
    getBuisNameByIdInList,
    sortTheBuisLst,
    validPhone,
    validEmail,
    validPassword,
    valid6DigsPassword,
    getOrdColorByStat,
    getStatusIndxByVal,
    sendiStatus,
    getTimesList,
    getBuisnessByIds,
    hearSendiUpBling,
    hearNewNoPayBling,
    getSendiColors

};

