import React, { useContext, useEffect, useState } from 'react'

const AccordProdTextInput = (props) => {

    const [prodName, setProdName] = useState(props.prodTxtVal);

    useEffect(() => {
        setProdName(props.prodTxtVal);
    }, [props.prodTxtVal])
    return (
        <div className={`${props.clss ? props.clss : "prodFirst"}`}>
            <label>{props.headI}</label>
            <input type="text" value={prodName} placeholder={props.headI}
                onChange={(e) => setProdName(e.target.value)}
                onBlur={(e) => props.setProdItm(props.index, props.proIndex, e.target.value)} />
        </div>

    )
}

export default AccordProdTextInput;

