import React, { useEffect } from 'react';
import Topnavigation from '../../../../layouts/Topnavigation';
import Sidenavigation1 from '../../../../layouts/Sidenavigation1';

import Content from './Content';
import { TheMasterContextProvider } from '../../../../MyContexts/Master_Context';
import myFunctions from '../../../../../myFunctions';

const MyInvoice = (props) => {
    useEffect(() => {

      
    }, [])

        return (
            // <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            //      <TheMasterContextProvider>
            //      <Sidenavigation1 />
            //     <main className="body-content">
            //         <Topnavigation />
                    <Content ordId={props.ordId}/>
            //     </main>
            //     {/* <Quickbar /> */}
            //     </TheMasterContextProvider>
            // </div>
        );
}

export default MyInvoice;