import { isFunction } from "jquery";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import utilsCategurys from "../../../../MyConns/_Utils/utilsCategurys";
import { RestContext } from "../../../MyContexts/Rest_Context";

const CatsCheckslist = (props) => {
    const [rest] = useContext(RestContext);
    const [buisCats,setBuisCats] = useState(rest.buisCats)
    const [catsNames, setCatsNames] = useState([]);
    const [allCats, setAllCats] = useState([]);



    useEffect(() => {
        getAllCats();
    }, [])



    useEffect(() => {
        getCatsNames(allCats);
    }, [rest.buisCats])


    const getAllCats = async () => {
        let CatList = await utilsCategurys.getAllCategurys();
        setAllCats(CatList);

        getCatsNames(CatList);

    }


    const getCatsNames = (catsLst) => {

        let catStr = "";

        rest.buisCats.forEach(element => {
            catsLst.map((cat, index) => {
                if (cat._id === element) {
                    (catStr.length > 0) ? catStr += "," + cat.catHead : catStr = cat.catHead;
                }
            })
        });

        setCatsNames(catStr);;

    }


    const setChkCat = (index, checkOrNot) => {

        let tempCats = [...buisCats];


        if (!checkOrNot) {
            let bk = tempCats.findIndex(el => allCats[index]._id === el);

            if (bk != -1) {
                tempCats.splice(bk, 1);
            }
        }
        else {
            tempCats.push(allCats[index]._id);
        }

        setBuisCats(tempCats)
        rest["buisCats"] = tempCats;

    }

    return (
        <>
            <Accordion defaultActiveKey="-1" className="has-gap ms-accordion-chevron catsAcord">
                <Card>
                    <Accordion.Collapse className="collapseparent" eventKey="0">
                        <Card.Body>
                            <ul>
                                {allCats.map((cat, index) => {
                                    let bk = buisCats.findIndex(el => cat._id === el);
                                    let chck = (bk != -1) ? true : false;

                                    return (

                                        <li key={index} className="ms-list-item">
                                            <label className="ms-checkbox-wrap">
                                                <input type="checkbox" checked={chck} onChange={() => setChkCat(index, !chck)} /> <i className="ms-checkbox-check" />
                                            </label> <span> {cat.catHead} </span>
                                        </li>

                                    )
                                })}
                            </ul>
                        </Card.Body>
                    </Accordion.Collapse>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <span>קטגוריות ({catsNames})</span>
                    </Accordion.Toggle>
                </Card>
            </Accordion>

        </>

    );
}

export default CatsCheckslist;