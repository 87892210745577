import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const getBlackList = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "blackl", {params: obj});
    return (dFromF.data);
}


const updateBlackL = async (obj) => {
        let dFromF = await axios.put(ConnUrls.ApiUrl + "blackl/upDt", obj);
        return (dFromF.data);
    }


const removeFromBlackL = async (obj) => {
    let dFromF = await axios.delete(ConnUrls.ApiUrl + "blackl", {data: obj});
    return (dFromF.data);
}

export default {
    getBlackList,
    updateBlackL,
    removeFromBlackL
};