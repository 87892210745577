import React, { useContext, useEffect, useState } from 'react';
import utilsTblOrders from '../../../../MyConns/_Utils/utilsTblOrders';
import { WaitingToApprovContext } from '../../../MyContexts/WaitingToApprov_Context';
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';
import TblOrderStatuselect from './TblOrderStatuselect';
import ShowTableOrder from './showTableOrder';
import SucessToast from '../../../MySections/Toasts/SucessToast';

const ListTblOrdersTable = (props) => {
    const [wToImpto, setWToImpto, checkIfItemExistsAndStatus] = useContext(WaitingToApprovContext);

    const [thePopUp, setThePopUp] = useState("");


    useEffect(() => {

    }, [])

    const orderBythis = (byWhat) => {
        props.orderBythis(byWhat);
    }

    const rowCellClick = (ordId) => {
        setThePopUp(<ShowTableOrder
            ordId={ordId}
            cancelClick={() => setThePopUp()}
            ordrApproved={() => { props.refreshTheList(); setThePopUp() }}
        />)

    }

    const setNewStatus = async (orderId, statBack, cancelReason) => {
        let updteme = await utilsTblOrders.updateTableStatus(orderId, statBack, cancelReason);

        checkIfItemExistsAndStatus({ _id: orderId, status: statBack });

        if (updteme.sendedSMS) {
            if (statBack === "מאושר")
                SucessToast({ position: "toast-bottom-right", text: "עודכן, הלקוח קיבל סמס אישור הזמנה." });
            else if (statBack === "מבוטל")
                SucessToast({ position: "toast-bottom-right", text: "עודכן, הלקוח קיבל סמס על ביטול הזמנה." });
        }

        props.refreshTheList();
    }


    const getRemrksAnrequests = (itm) => {
        let bk = "";
        bk = itm.remarks !== "" ? itm.remarks + ". " : "";
        if (itm.tabelsLocationsReq.length > 0) { bk += itm.tabelsLocationsReq.join(',') + ". " }
        if (itm.specialsListReq.length > 0) { bk += itm.specialsListReq.join(',') + ". " }

        return bk;
    }

    return (
        <div className="row lstOrderTbls">
            <div className="col-xl-12">
                <div className="table-responsive ms-panel-body">
                    <table className="table table-hover thead-primary">
                        <thead>
                            <tr>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("orderNUM")}>הזמנה</th>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("dateCreated")}>הוזמן ב</th>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("buisName")}>שם העסק</th>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("clientName")}>שם הלקוח</th>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("clientPhone")}>טלפון</th>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("dinners")}>סועדים</th>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("timeToCome")}>שעת הגעה</th>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("dateToCome")}>תאריך הגעה</th>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("DayToCome")}>יום</th>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("status")}>סטטוס</th>
                                <th scope="col" className={"pointing"} onClick={() => orderBythis("remarks")}>הערות</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.theList.map((item, i) => {
                                    let theremarks = getRemrksAnrequests(item);
                                    return (
                                        <tr key={item._id} itmid={item._id}
                                            className={`pointing ${item.status === "ממתין לאישור" ? "tableOrdWaiting" : ""}`}
                                        >
                                            <td onClick={() => rowCellClick(item._id)}><p>{item.tblOrderNum}</p></td>
                                            <td onClick={() => rowCellClick(item._id)}><p>{MyDateTime.getDateAndTime(item.dateCreated)}</p></td>
                                            <td onClick={() => rowCellClick(item._id)}><p className='bold'>{item.buisName}</p></td>
                                            <td onClick={() => rowCellClick(item._id)}><p className='bold'>{item.clientName}</p></td>
                                            <td onClick={() => rowCellClick(item._id)}><p className='bold'>{item.clientPhone}</p></td>
                                            <td onClick={() => rowCellClick(item._id)}><p className='bold'>{item.dinners}</p></td>
                                            <td onClick={() => rowCellClick(item._id)}><p className='bold'> {item.timeToCome}</p></td>
                                            <td onClick={() => rowCellClick(item._id)}><p className='bold'> {MyDateTime.getDate(item.dateToCome)}</p></td>
                                            <td onClick={() => rowCellClick(item._id)}><p className='bold'> {MyDateTime.getDayOfWeekHeb(item.dateToCome)}</p></td>
                                            <td className={"statusCell pointing"} >
                                                <TblOrderStatuselect orderStatus={item.status}
                                                    setOrderStatus={(statBack, cancelReason) => setNewStatus(item._id, statBack, cancelReason)}
                                                />
                                            </td>
                                            <td onClick={() => rowCellClick(item._id)}><p>{theremarks.trim() !== "" ? "יש" : "אין"}</p></td>
                                        </tr>
                                    )
                                })

                            }

                        </tbody>
                    </table>
                </div>
            </div>
            {thePopUp}
        </div >
    )
}

export default ListTblOrdersTable;