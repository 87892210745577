import React, { useContext, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RichTextComp from '../TextEditor/RichText';
import SunEditor, { buttonList } from "suneditor-react";
import utilsOrders from '../../../MyConns/_Utils/utilsOrders';
import MySpinner_Wait from '../DTechComps/MySpinner_Wait';
import { CancelRounded, FiberManualRecord, RotateRight } from '@mui/icons-material';
import MyDateTime from '../TimeSections/MyDateTime';
import myFunctions from '../../../myFunctions';
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import SucessToast from '../Toasts/SucessToast';
import utilsUsers from '../../../MyConns/_Utils/utilsUsers';

const LogsConnectionsTry = (props) => {

    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);

    const [open, setOpen] = useState(true);
    const [clntLogs, setClntLog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showDots, setShowDots] = useState(true);


    let theCount = null;

    useEffect(() => {

        loadLog();
    }, [])

    const loadLog = async () => {
        let lgs = props.theClientLogs
        setClntLog(lgs)

        setLoading(false)
    }

    const clear24Stuck = async () => {
        let clr = await utilsUsers.clear24Stuck(props.clientId);
        setShowDots(false)
        if (clr) {
            SucessToast({ timeOut: 5000, position: `${!mobiOrNot ? "toast-inPop" : "toast-inPop-Mobi"}`, text: "שוחרר בהצלחה!" });
        }
    }

    const btnsLine = () => {
        return (
            <div className={"col-12 noPadding overflowHid"}>
                <div className="col-stretch onLeft spaceMargeLeft">
                    <Button className={"btnMediumMnu"} variant="contained"
                        onClick={() => clear24Stuck()}
                        startIcon={<RotateRight sx={{ color: 'white' }} />}
                    >
                        שחרר 24
                    </Button>
                </div>
            </div>
        )
    }

    const totalsLine = () => {
        return (
            <div className={"col-12 noPadding overflowHid"}>
                <div className="col-6 onLeft noPadding spaceMargeLeft">
                    <div className={'col-12 textLeft noPadding bold'}>
                        סה"כ: {clntLogs.allLgs.length}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {!loading ?
                <>
                    <div className="popToastPosition">
                    </div>
                    <div className={"col-12 noPadding overflowHid"}>
                        <div className={"col-4 noPadding overflowHid onLeft"}>
                            {btnsLine()}
                        </div>

                        <div className={"col-8 noPadding overflowHid onLeft"}>
                            {totalsLine()}
                        </div>
                    </div>

                    {
                        myFunctions.sortDsc(clntLogs.allLgs, "dateCreated").map((item, index) => {
                            return (
                                <div key={index} className={"col-12 overflowHid grayFrame"}>
                                    {clntLogs.filteredLgs.findIndex(it => it._id === item._id) !== -1 &&

                                        <div className={"onLeft"}>
                                            {showDots && <FiberManualRecord sx={{ color: 'red' }} />}
                                        </div>

                                    }
                                    <div className={`${!mobiOrNot ? "col-3 onRight bold" : "col-12 bold"}`}>
                                        <span>{MyDateTime.getDateAndTimeShort(item.dateCreated)}</span>
                                    </div>
                                    <div className={`${!mobiOrNot ? "col-3 onRight" : "col-12"}`}>
                                        <span>{item.updater}</span>
                                    </div>
                                    <div className={`${!mobiOrNot ? "col-6 onRight" : "col-12"}`}>
                                        <span>{item.updateTxt}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
                :
                <MySpinner_Wait />
            }
        </>

    );
}

export default LogsConnectionsTry;