import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'

const getErrMsgs = async (token) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "ErrMsgs/GetMe", token);
    
    return (dFromF.data);
}

const updateErrMsgs = async (msgWithToken) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "ErrMsgs/SetMe", msgWithToken);

    return (dFromF.data);
}


export default {
    getErrMsgs,
    updateErrMsgs
};