import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { io } from 'socket.io-client';
import ConnUrls from '../../../../MyConns/Connections/ConnectURL';
import myFunctions from '../../../../myFunctions';
import MySpinner_Wait from '../../../MySections/DTechComps/MySpinner_Wait';
import Breadcrumb from './Breadcrumb';
import TblBuisTollBar from './TblBuisTollBar';
// import BuisTollBarMobi from './BuisTollBarMobi';
// import ListOrdersBlocks from './ListOrdersBlocks';
import { useLocation } from 'react-router-dom';
import utilsTblOrders from '../../../../MyConns/_Utils/utilsTblOrders';
import { MobiOrNotContext } from '../../../MyContexts/MobiOrNotContext';
import { WaitingToApprovContext } from '../../../MyContexts/WaitingToApprov_Context';
import ListTblOrdersTable from './ListTblOrdersTable';
import { refreshTheTblList } from './refreshTheTblList';
import { BuisListContext } from '../../../MyContexts/BuisList_Context';
import utilsRests from '../../../../MyConns/_Utils/utilsRests';
import { UserContext } from '../../../MyContexts/Users_Context';
import { BuisListForTablesContext } from '../../../MyContexts/BuisListForTables_Context';
import ListTblOrdersTableMobile from './ListTblOrdersTableMobile';
import TblBuisTollBarMobi from './TblBuisTollBarMobi';


const TblOrderLst = (props) => {


    const history = useHistory();
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [buisLstTables, setBuisLstTables] = useContext(BuisListForTablesContext);

    const [wToImpto, setWToImpto, checkIfItemExistsAndStatus] = useContext(WaitingToApprovContext);
    const [user] = useContext(UserContext);
    const [userBuis, setUserBuis] = useState();

    const [orderlisttable, setOrderlisttable] = useState([]);
    const [srchList, setSrchList] = useState([]);
    const [allCats, setAllCats] = useState([]);
    const [sortByWhat, setSortByWhat] = useState("");

    const [thePopUp, setThePopUp] = useState("");
    // const [srchText, setSrchText] = useState("");
    const [startDTS, setStartDTS] = useState();
    const [endDTS, setEndDTS] = useState();
    const [totOfAll, setTotOfAll] = useState(0);
    const [showBetweenDts, setShowBetweenDts] = useState(false);
    const [beepMe, setBeepMe] = useState();
    const [lstCounter, setLstCounter] = useState(null);
    const [selectedBtn, setSelectedBtn] = useState(sessionStorage.getItem("ordTblLstBtn") || 5);
    const [listSordted, setListSordted] = useState({ sort: false, sortBy: "Asc" });
    const [intervalID, setIntervalID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [busissList, setBusissList] = useState([]);

    const location = useLocation();



    let allIntervs = [];

    useEffect(() => {

    }, [sortByWhat])

    useEffect(() => {
        myFunctions.connCheck();
    })
    useEffect(() => {

        const socket = io.connect(ConnUrls.socketClient, {
            auth: {
                token: localStorage.getItem("token")
            },
            query: {
                userType: "back"
            }
        }
        );

        socket.on("newTableOrder", (data) => {

            setTimeout(() => {
                let selBtn = sessionStorage.getItem("ordTblLstBtn");
                if (selBtn != null) {
                    refreshMe(selBtn)
                }
                else {

                    refreshMe(selectedBtn)
                }

                checkIfItemExistsAndStatus({ _id: data._id, status: data.status })

                myFunctions.hearBling();

            }, 5000);

        })

    }, [ConnUrls.socket])


    useEffect(() => {

        refreshMe(location.state ? location.state.ordTblLstBtn : 5);
        if (busissList.length < 1) {
            getBuisList();
        }
        // showAllOrders(13)
    }, [])

    useEffect(() => {

        refreshMe(location.state ? location.state.ordTblLstBtn : 5);
      
        
    }, [userBuis])



    const refreshMe = (btnSel) => {
        const functionName = refreshTheTblList(btnSel);

        if (functionName) {
            const selectedFunction = eval(functionName);
            if (typeof selectedFunction === 'function') {
                selectedFunction(btnSel); // Call the function with an argument
            }
        }
    }



    const getBuisList = async () => {

        let busiLst = await utilsRests.getRestsForTablesListSmall();
        setBuisLstTables(myFunctions.sortAsc(busiLst, "buissName"))

    }


    //refreshMe עובד דרך 
    //ממתינים לאישור
    const waitingForApprov = async (btnInt) => {
        setLoading(true);
        
        let OrdrsList = await utilsTblOrders.getWaitingForApprov(userBuis);
        OrdrsList.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
        setOrderlisttable(OrdrsList);
        setSelectedBtn(btnInt)
        setLoading(false)
    }
    //refreshMe עובד דרך 
    const showAllOrders = async (btnInt) => {
        setLoading(true);
        let OrdrsList = await utilsTblOrders.getAllTableOrders(userBuis);
        setOrderlisttable(OrdrsList);
        setSelectedBtn(btnInt)
        setLoading(false)
    }

    //refreshMe עובד דרך 
    //הכל להיום
    const showTodaysAll = async (btnInt) => {
        setLoading(true);
        var today = new Date();
        today.setHours(0, 0, 0);

        var tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1)
        tomorrow.setHours(5, 0, 0);

        setStartDTS(today);
        setEndDTS(tomorrow);

        let OrdrsList = await utilsTblOrders.getAllOrdersByDates(today, tomorrow,userBuis);

        setOrderlisttable(OrdrsList);
        setSelectedBtn(btnInt)
        setLoading(false)
    }

    //refreshMe עובד דרך 
    //הכל למחר
    const showTomorowAll = async (btnInt) => {
        setLoading(true);
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0);

        var afterTommorow = new Date(tomorrow);
        afterTommorow.setDate(tomorrow.getDate() + 1)
        afterTommorow.setHours(5, 0, 0);

        setStartDTS(tomorrow);
        setEndDTS(afterTommorow);

        let OrdrsList = await utilsTblOrders.getAllOrdersByDates(tomorrow, afterTommorow,userBuis);

        setOrderlisttable(OrdrsList);
        setSelectedBtn(btnInt)
        setLoading(false)
    }


    //refreshMe עובד דרך 
    //הכל מתחילת החודש
    const showMounth = async (btnInt) => {
        setLoading(true);
        var currentDate = new Date();
        currentDate.setDate(1);
        currentDate.setHours(0, 0, 0);

        var startMounth = new Date(currentDate); // Copy the currentDate object
        var endMounth = new Date(currentDate); // Copy the currentDate object

        // Set the last day of the current month
        endMounth.setMonth(currentDate.getMonth() + 1);
        endMounth.setDate(1);
        endMounth.setHours(0, 0, 0);
        endMounth.setTime(endMounth.getTime() - 1); // Subtract one millisecond to get the last second of the last day

        setStartDTS(startMounth);
        setEndDTS(endMounth);

        let OrdrsList = await utilsTblOrders.getAllOrdersByDates(startMounth, endMounth,userBuis);

        setOrderlisttable(OrdrsList);
        setSelectedBtn(btnInt);

        setLoading(false)
    }

    //refreshMe עובד דרך 
    //מהיום קדימה
    const fromTodayToEnd = async (btnInt) => {
        setLoading(true);
        var strtdt = new Date();
        var enddt = new Date();
        //במידה והתאריך התחלה ותאריך הסוף זהה, מוסיף לתאריך 
        //הסוף יום כדי שיראו את ההזמנות של אותו יום שנבחר

        enddt.setDate(enddt.getDate() + 5000)

        let OrdrsList = await utilsTblOrders.getAllOrdersByDates(strtdt, enddt,userBuis);

        setOrderlisttable(OrdrsList);
        setSelectedBtn(btnInt);

        setLoading(false)
    }

    //refreshMe עובד דרך 
    //מציג לפי תאריכים שנבחרו בהזנת תאריכים
    const showByDates = async (btnInt) => {
        if (startDTS && endDTS) {
            setLoading(true)
            var strtdt = new Date(startDTS);
            var enddt = new Date(endDTS);
            //במידה והתאריך התחלה ותאריך הסוף זהה, מוסיף לתאריך 
            //הסוף יום כדי שיראו את ההזמנות של אותו יום שנבחר
            if (strtdt.getDate() === enddt.getDate()) {
                enddt.setDate(enddt.getDate() + 1);
            }
            else {
                enddt.setDate(enddt.getDate() + 2)
            }
            let OrdrsList = await utilsTblOrders.getAllOrdersByDates(strtdt, enddt,userBuis);

            setOrderlisttable(OrdrsList);
            setSelectedBtn(btnInt);
            setLoading(false)
        }
    }

    const searchTblOrder = async (txt) => {
        setLoading(true)
        let OrdrsList = await utilsTblOrders.getTableOrderBySearch(txt,userBuis);
        if (OrdrsList != null) {
            setOrderlisttable(OrdrsList);
        }
        setLoading(false)
    }


    const orderBythis = (byWhat) => {
        if (sortByWhat === "Dsc") {
            setOrderlisttable(myFunctions.sortDsc(orderlisttable, byWhat))
            setSortByWhat("Asc")
        }
        else {
            setOrderlisttable(myFunctions.sortAsc(orderlisttable, byWhat))
            setSortByWhat("Dsc")
        }


    }


    return (
        <>

            <div className="ms-content-wrapper">
                <div className="ordrspanel">
                    <Breadcrumb />
                    {/* Active Orders Graph */}
                    <div className="ordrspanel">
                        <div className="ms-panel">
                            {!mobiOrNot ?
                                <TblBuisTollBar
                                    selectedBtn={selectedBtn}
                                    refreshTheList={(selectedBtn) => refreshMe(selectedBtn)}
                                    searchTblOrder={(txt) => searchTblOrder(txt)}
                                    showBetweenDts={showBetweenDts}
                                    setShowBetweenDts={(btdats) => setShowBetweenDts(btdats)}
                                    setStartDTS={(dt) => setStartDTS(dt)}
                                    setEndDTS={(dt) => setEndDTS(dt)}
                                    userBuis={userBuis}
                                    setUserBuis={(newVal) => { setUserBuis(newVal) }}
                                />
                                :
                                <TblBuisTollBarMobi
                                    selectedBtn={selectedBtn}
                                    refreshTheList={(selectedBtn) => refreshMe(selectedBtn)}
                                    searchTblOrder={(txt) => searchTblOrder(txt)}
                                    showBetweenDts={showBetweenDts}
                                    setShowBetweenDts={(btdats) => setShowBetweenDts(btdats)}
                                    setStartDTS={(dt) => setStartDTS(dt)}
                                    setEndDTS={(dt) => setEndDTS(dt)}
                                    userBuis={userBuis}
                                    setUserBuis={(newVal) => { setUserBuis(newVal) }}
                                />
                            }


                            {loading ?
                                <MySpinner_Wait />
                                :
                                <div className="fullRow">
                                    <div className="col-md-12">
                                        {!mobiOrNot ?
                                            <ListTblOrdersTable
                                                theList={orderlisttable}
                                                refreshTheList={() => refreshMe(selectedBtn)}
                                                orderBythis={(byWhat) => orderBythis(byWhat)}
                                                userBuis={userBuis}
                                            // orderBythis={(byWhat, ordUserOrBuiss) => orderBythis(byWhat, ordUserOrBuiss)}
                                            // rowCellClick={(ordId) => rowCellClick(ordId)}
                                            // setNewStatus={(orderId, statBack, buisId) => setNewStatus(orderId, statBack, buisId)}
                                            />
                                            :
                                            <ListTblOrdersTableMobile
                                                theList={orderlisttable}
                                                refreshTheList={() => refreshMe(selectedBtn)}
                                                orderBythis={(byWhat) => orderBythis(byWhat)}
                                                userBuis={userBuis} />
                                        }
                                        {/* {screenWidth > 500 ?
                                            <>
                                                <div className={"totalsLine"}>
                                                    <div className={"onLeft spaceMargeRightbig"}>סה"כ: {totOfAll} ₪</div>
                                                    <div className={"onLeft "}>משלוחים: {orderlisttable.length}</div>
                                                </div>
                                               
                                            </>
                                            :
                                            <>
                                                <div className={"totalsLine"}>
                                                    <div className={"onRight spaceMargeLeftbig"}>משלוחים: {orderlisttable.length}</div>
                                                    <div className={"onRight "}>סה"כ: {totOfAll} ₪</div>
                                                </div>
                                                <ListOrdersBlocks
                                                    theList={theList}
                                                    orderBythis={(byWhat, ordUserOrBuiss) => orderBythis(byWhat, ordUserOrBuiss)}
                                                    rowCellClick={(ordId) => rowCellClick(ordId)}
                                                    setNewStatus={(orderId, statBack, buisId) => setNewStatus(orderId, statBack, buisId)}
                                                />
                                            </>

                                        } */}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {thePopUp}

            </div >

        </>
    );


}

export default TblOrderLst;

