import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const orderMyFood = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "order/nOrder", obj);
    return (dFromF.data);
}

const getOrder = async (id, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/" + id, obj);
    return (dFromF.data);
}


const getOrdersHistoryList = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "order/getOrdersHistoryList", { params: obj });
    return (dFromF.data);
}
const getOrderLog = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "order/getOrderLog", { params: obj });
    return (dFromF.data);
}
const updateOrder = async (id, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/upOrd/" + id, obj);
    return (dFromF.data);
}

const getOrderBySearch = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "order/bySearch",  { params: obj });
    return (dFromF.data);
}


const getAllOrders = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/all", obj);
    return (dFromF.data);
}

const getAllOrdersCount = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/getAllOrdersCount", obj);
    return (dFromF.data);
}



const getOrdersBy = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/ordsBy", obj);
    return (dFromF.data);
}

// const getOpenOrders = async (obj) => {
//     let dFromF = await axios.put(ConnUrls.ApiUrl + "order/ordsBy",obj);
//     return (dFromF.data);
// }

// const getClosedOrders = async (obj) => {
//     let dFromF = await axios.put(ConnUrls.ApiUrl + "order/closed",obj);
//     return (dFromF.data);
// }


const updateStatus = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/upstat", obj);
    return (dFromF.data);
}


const updateStatusToCancel = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/upstatToCancel", obj);
    return (dFromF.data);
}


const updateStatusToList = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/upstatToOrdsList", obj);
    return (dFromF.data);
}

const updateStatusToListToCancel = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/upstatToOrdsListToCancel", obj);
    return (dFromF.data);
}

const refreshList = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "order/refOrds", obj);
    return (dFromF.data);
}





export default {
    // getClosedOrders,
    getAllOrders,
    orderMyFood,
    getOrder,
    getAllOrdersCount,
    // getOpenOrders,
    getOrderBySearch,
    updateStatus,
    updateStatusToCancel,
    updateStatusToList,
    updateStatusToListToCancel,
    getOrdersBy,
    refreshList,
    updateOrder,
    getOrderLog,
    getOrdersHistoryList
};