import landingP from "../_ApiConn/apiLandingPage";


const getLandingPageById = async (idToGet) => {
    let dFromF = await landingP.getLandingPageById(idToGet);
    return (dFromF.data);
}

const allPgsNames = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await landingP.allPgsNames(token);
    return (dFromF.data);
}


const deleteLandingPage = async (id, obj) => {
    let dFromF = await landingP.deleteLandingPage(id, obj);
    return (dFromF.data);
}


const updateLandingP = async (currSingle) => {

    if (currSingle._id) {
        let dFromF = await landingP.updateSingle(currSingle._id, currSingle);
        return (dFromF.data);
    }

}

const createLandingPage = async () => {
    let dFromF = await landingP.createLandingPage();
    return (dFromF.data);
}

const getPageByName = async (name) => {
    let dFromF = await landingP.getPageByName(name, { obj: "" });
    return (dFromF.data[0]);
}

export default {
    getLandingPageById,
    updateLandingP,
    getPageByName,
    createLandingPage,
    allPgsNames,
    deleteLandingPage
    
};