import React, { useState, createContext, useEffect } from 'react';

export const HomePContext = createContext();

export const HomePContextProvider = props => {
    const [homeP, setHomeP] = useState([]);

    useEffect(() => {
       
    }, [homeP])


    return (
        <HomePContext.Provider value={[homeP, setHomeP]}>
            {props.children}
        </HomePContext.Provider>
    )
}

