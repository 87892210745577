import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card, Nav, Tab } from "react-bootstrap";
import { useParams } from 'react-router';
import utilsCategurys from '../../../../MyConns/_Utils/utilsCategurys';
import utilsRests from '../../../../MyConns/_Utils/utilsRests';

import TabBuisDetails from './parts/TabBuisDetails';
import TabImages from './parts/TabImages';
import TabMaps from './parts/TabMaps';
import TabNetwork from './parts/TabNetwork';
import TabSales from './parts/TabSales';
import TabPageDetails from '../../compsForAllPages/TabPageDetails';
import TabOpeningHours from './parts/TabOpeningHours';

import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import TabOrdersOptions from './parts/TabOrdersOptions';
import { TheMenuContext, RestContext } from '../../../MyContexts/Rest_Context';
import { useHistory } from "react-router-dom";
import BasicPopUp from '../../../MySections/PopUps/BasicPopUp';
import SucessToast from '../../../MySections/Toasts/SucessToast';
import TabMenu from './parts/TabMenu';
import utilsRestMenus from '../../../../MyConns/_Utils/utilsRestMenus';
import MySpinner_Wait from '../../../MySections/DTechComps/MySpinner_Wait';
import RestStatusSelect from '../../../MySections/DTechComps/RestStatusSelect';
import myFunctions from '../../../../myFunctions';



const RestSetSmall = (props) => {
    const history = useHistory();

    const [rest, setRest] = useContext(RestContext);
    const [theMenu, setTheMenu] = useContext(TheMenuContext);

    const [restPre, setRestPre] = useState();

    const [loading, setLoading] = useState(true);
    let idParam = useParams();
    const [thePopUp, setThePopUp] = useState("");
    const [pg, setPg] = useState("");

    useEffect(() => {
        getRest();
        setPg(myFunctions.setGetCurrnetTab("Get","pg"));
    }, [])

    useEffect(() => {

    }, [restPre])


    const tempUpdate = (upRest) => {
        setRest(upRest);
    }

    const getRest = async () => {
        let rstLst = [];
        let mnu = [];
        if (idParam.id) {
            rstLst = await utilsRests.getRestById(idParam.id);
            setRest(rstLst);
            // let mnu = await utilsRestMenus.getMnuByRestId(idParam.id);
            // if (mnu === null) {
            //     mnu = await utilsRestMenus.createRestMnu({ restId: rstLst._id, buisMenu: rstLst.buisMenu });
            // }
            // setTheMenu(mnu);

            setLoading(false);
        }
        else {
            rstLst = await utilsRests.getClearRest();
            setRest({ ...rstLst, newrest: true });
            setLoading(false);
        }

        setRestPre(rstLst);

    }


    const updateTheRest = async () => {

        let rstLst = [];


        if (rest.buissName === "") {
            setThePopUp(<BasicPopUp title={"לא ניתן לשמור עסק ללא שם לעסק"} text={""}
                icon={"error"} />);
        }
        else {
            rstLst = await utilsRests.updateRest(rest.id, rest);
            SucessToast({ position: "toast-bottom-right", text: rstLst.resp });

            setRest(rstLst.item)
        }

        setPg(myFunctions.setGetCurrnetTab("Get","pg"));
        setLoading(false)
    }


    return (
        <div className="ms-content-wrapper SetBlock">
            {loading ?
                <MySpinner_Wait />
                :
                <div className="col-md-12">

                    <div className="ms-panel ms-panel-fh">

                        <div className="ms-panel-body clearfix">
                            <Tab.Container defaultActiveKey={pg}>
                                <h3>{rest.buissName}</h3>
                                <Nav variant="tabs" className="nav nav-tabs d-flex nav-justified mb-2">

                                    <Nav.Item>
                                        <Nav.Link eventKey="pgActionTimes" onClick={() => myFunctions.setGetCurrnetTab("Set","pg", "pgActionTimes")}>אופני פעילות</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pgRestHours" onClick={() => myFunctions.setGetCurrnetTab("Set","pg", "pgRestHours")}>שעות פתיחה</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Mnu" onClick={() => myFunctions.setGetCurrnetTab("Set","pg", "Mnu")}>תפריט</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                                <Tab.Content>


                                    {/*אופני פעילות*/}
                                    <Tab.Pane eventKey="pgActionTimes">
                                        <div className={"form-row tabsInputs"}>
                                            <TabOrdersOptions pgToSet="pgActionTimes" />
                                        </div>

                                    </Tab.Pane>

                                    {/* שעות פתיחה */}
                                    <Tab.Pane eventKey="pgRestHours">
                                        <div className={"form-row tabsInputs"}>
                                        <TabOpeningHours pgToSet="pgRestHours"/>

                                        </div>

                                    </Tab.Pane>


                                    {/* תפריט */}
                                    <Tab.Pane eventKey="Mnu">
                                        <div className={"form-row tabsInputs"}>                                            
                                            <TabMenu pgToSet="Mnu" Rest={rest} updateTheRest={() => updateTheRest()} />
                                        </div>

                                    </Tab.Pane>

                                    {/* 
                                  "buissAddress" : [ 
                                  {
                                    "street" : "",
                                    "num" : "",
                                    "ent" : "",
                                    "city" : ""
                                  }
                                ], 
                                */}

                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>}

            <div className="ms-panel-header approveLine">
                <button type="button" className="onLeft btn btn-primary" onClick={() => { updateTheRest(); setLoading(true) }}>שמור</button>
            </div>
            {thePopUp}
        </div>
    );
}


export default RestSetSmall;