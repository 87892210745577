import myFunctions from "../../myFunctions";
import apiLinks from "../_ApiConn/apiLinks";



const getLinks = async () => {
    let token = localStorage.getItem("token");
    let dFromF = await apiLinks.getLinks({token});
    return (dFromF);
}


const getLink = async (lnkName) => {
    let token = localStorage.getItem("token");
    let dFromF = await apiLinks.getLink(lnkName,{token});
    return (dFromF);
}

const updateLinkById = async (id) => {
    let token = localStorage.getItem("token");
    let dFromF = await apiLinks.updateLinkById(id,token);

  return (dFromF);
}


const updateLinks = async (links) => {
    let token = localStorage.getItem("token");
    let dFromF = await apiLinks.updateLinks({links,token});

  return (dFromF);
}




export default {
    getLink,
    getLinks,
    updateLinkById,
    updateLinks
};