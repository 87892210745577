import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import Breadcrumb from './Breadcrumb'

import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import { UserContext } from '../../MyContexts/Users_Context';
import { AddBoxOutlined } from '@mui/icons-material';
import myFunctions from '../../../myFunctions';
import KoponCreate from './KoponCreate';
import AllKopons from './AllKopons';



const KoponsToolBar = (props) => {
    const history = useHistory();

    const [user, setUser] = useContext(UserContext)
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [srchText, setSrchText] = useState("");
    const [selectedBtn, setSelectedBtn] = useState(myFunctions.setGetCurrnetTab("Get", "koponsLstBtn") || "AllKopons");

    const [loading, setLoading] = useState(true);
    let idParam = useParams();

    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {
        refreshLst(selectedBtn)
        setLoading(false)
    }, [])

    const checkIfEnter = (e) => {
        if (e.key === 'Enter') {
            props.goSearch(srchText);
        }
    }

    const refreshLst = (selBtn) => {
        const sectionTypeComponents = {
            "AllKopons": <AllKopons refreshDisplay={() => refreshLst("AllKopons")} key={Date.now()} />,
            "koponPackCreate": <KoponCreate key={Date.now()} koponType={"חבילה"} header={"יצירת חבילת קופונים"} />,
            "oneKopon": <KoponCreate key={Date.now()} koponType={"זיכוי"} max={1} header={"יצירת קופון זיכוי"} />,
            "discountKopon": <KoponCreate key={Date.now()} koponType={"הנחה"} max={1} header={"יצירת קופון הנחה"} />,
        };

        setSelectedBtn(selBtn)
        myFunctions.setGetCurrnetTab("Set", "koponsLstBtn", selBtn)

        props.setTheFrameDisp(sectionTypeComponents[selBtn])
    }

    return (
        <div className={"buisTollBar"}>

            <Stack direction="row" spacing={2}>
                <div className={"btnKoponMnuTop onRight"}>
                    <div className="spaceButtomBig ms-form-group my-0 mb-0 has-icon fs-14">
                        <input type="search" className="dtSearchInput ms-form-input" id="dtSearchInput" name="dtSearchInput"
                            placeholder="מספר קופון, או שם חבילה" value={srchText} onChange={(e) => setSrchText(e.target.value)}
                            onKeyUp={(e) => checkIfEnter(e)}
                        />
                        <Button className="dtSearchIcon flaticon-search text-disabled" onClick={() => props.goSearch(srchText)} />
                    </div>

                    <Button className={`btnBigMnu ${selectedBtn === "AllKopons" ? "selectedMnuBtn" : ""}`}
                        variant="contained" onClick={() => refreshLst("AllKopons")} >
                        כל הקופונים
                    </Button>

                    <Button className={`btnBigMnu ${selectedBtn === "koponPackCreate" ? "selectedMnuBtn" : ""}`} startIcon={<AddBoxOutlined style={{ color: 'white' }} />}
                        variant="contained" onClick={() => refreshLst("koponPackCreate")} >
                        חבילת קופונים
                    </Button>
                    <Button className={`btnBigMnu ${selectedBtn === "oneKopon" ? "selectedMnuBtn" : ""}`} startIcon={<AddBoxOutlined style={{ color: 'white' }} />}
                        variant="contained" onClick={() => refreshLst("oneKopon")} >
                        קופון זיכוי
                    </Button>

                    <Button className={`btnBigMnu ${selectedBtn === "discountKopon" ? "selectedMnuBtn" : ""}`} startIcon={<AddBoxOutlined style={{ color: 'white' }} />}
                        variant="contained" onClick={() => refreshLst("discountKopon")} >
                        קופון הנחה
                    </Button>

                </div>

            </Stack>

        </div>


    );
}
export default KoponsToolBar;