import React, { useContext, useEffect, useState } from 'react'
import { RestContext } from "../../../../../MyContexts/Rest_Context";
import Button from '@mui/material/Button';
import { Accordion, Card } from 'react-bootstrap';
import EraseCheck from '../../../../../MySections/PopUps/EraseCheck';
import { AddBoxOutlined, HighlightOffOutlined } from '@mui/icons-material';
import InputDubleSet from '../../../../../MySections/PopUps/InputDubleSet';
import AccordProdOptions from './AccordProdOptions';
import InputSet from '../../../../../MySections/PopUps/InputSet';
import SelectImgComp from '../../../../../MySections/ImagesSetter/SelectImgComp';
import { Dialog } from '@mui/material';
import SelectFromSilo from '../../../../../MySections/DTechComps/SelectFromSilo';
import AccordProdTextInput from './AccordProdTextInput'
import AccordProdNumInput from './AccordProdNumInput'
import AccordProdTextArea from './AccordProdTextArea';
import utilsRests from '../../../../../../MyConns/_Utils/utilsRests';
import AccordProdChkSaucess from './AccordProdChkSaucess';
import { UserContext } from '../../../../../MyContexts/Users_Context';

const AcoordBtns = (props) => {



    useEffect(() => {


    }, [])


    return (
        <div className={props.cs}>
            {props.user.permiss === "admin" ?
                <div className={"acoordIcon acord1 pointing"}
                    onClick={() => props.removeProd(props.index, props.proIndex)}>
                    <i className="far fa-trash-alt theSecIconsColor onLeft" /></div>
                : ""}

            {props.user.permiss === "admin" ?
                props.proIndex != 0 && props.productsLength > 1 ?
                    <div className={"acoordIcon acord2 pointing"}
                        onClick={() => props.setUpProd(props.index, props.proIndex)} title={"הקפץ למעלה"}>
                        <i className="far fa-caret-square-up theSecIconsColor onLeft" /></div> : "" : ""}

            {props.user.permiss === "admin" ?
                props.proIndex < props.productsLength - 1 ?
                    <div className={"acoordIcon acord3 pointing"}
                        onClick={() => props.setDownProd(props.index, props.proIndex)} title={"הקפץ למטה"}>
                        <i className="far fa-caret-square-down theSecIconsColor onLeft" /></div> : "" : ""}

            <div className={"btnIteminStock"}>
                <input type="button"
                    onClick={() => props.setInStock(props.index, props.proIndex)}
                    value={(props.produc.inStock) ? "קיים במלאי" : "אין במלאי"}
                    className={(props.produc.inStock) ? "InStock" : "notInStock"} />

                <input type="button"
                    onClick={() => props.setOkToDeliver(props.index, props.proIndex)}
                    value={(props.produc.okToDeliver) ? "ניתן לשלוח" : "לא למשלוח"}
                    className={(props.produc.okToDeliver) ? "InStock" : "notInStock"} />
            </div>
        </div>
    )
}

export default AcoordBtns;