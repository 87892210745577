import React, { useEffect, useState } from 'react';

import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
import frLocale from 'date-fns/locale/he';

const DateSelection = (props) => {
    const [startDate, setStartDate] = useState(new Date());


    useEffect(() => {
        
        
    }, [])




    const updateSEDate = (e) => {
        setStartDate(e)
        props.setTheDate(e)

    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale} timeZone="Asia/Jerusalem">
            <table className={"tabTable tableBordSpace"}>
                <tbody>
                    {

                        <tr style={{ marginTop: "10px" }}>
                            {/* <td style={{ width: "20%" }}>{MyDateTime.getDayOfWeekToHeb(item.Day)}</td> */}
                            <td>
                                <table className={"tblOpHours"}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <DatePicker
                                                    ampm={false}
                                                    label="תפוגה"
                                                    value={startDate}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(e) => updateSEDate(e)}
                                                    renderInput={(params) => <TextField  {...params} />}
                                                    className={"tmPick"}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </td>

                        </tr>

                    }
                </tbody>
            </table>

        </LocalizationProvider>
    );
}

export default DateSelection;
