import React, { useContext, useEffect, useState } from 'react'

import { TheMasterContext } from '../../../../MyContexts/Master_Context';
import InputSet from '../../../../MySections/PopUps/InputSet';
import { Button } from '@mui/material';
import { AddBoxOutlined, RemoveCircleOutline } from '@mui/icons-material';


const DeliverMaster = (props) => {
    const [theMaster] = useContext(TheMasterContext);
    const [thePopUp, setThePopUp] = useState("");
    const [citysLis, setCitysList] = useState(theMaster.cityesToDeliver || [])
    useEffect(() => {


    }, [])



    const addCityToList = () => {
        setThePopUp(
            <InputSet Title={"הוספת עיר"}
                ContentText={"שם העיר"} inLabel={""}
                okClick={(textBack) => addMeTocity(textBack)}
                cancelClick={() => setThePopUp()}
                errorMsg={"על מנת ליצור עיר למשלוח, חובה לתת לה שם."}
            />)


    }

    const addMeTocity = (cityName) => {
        let ThecitysLst = theMaster["cityesToDeliver"];
        ThecitysLst.push(cityName);

        setCitysList(ThecitysLst);
        theMaster["cityesToDeliver"] = ThecitysLst;

    }


    const removeCityFromList = (cityName) => {
        let ThecitysLst = theMaster["cityesToDeliver"];
        let Filterd = ThecitysLst.filter(it => it != cityName);

        setCitysList(Filterd);
        theMaster["cityesToDeliver"] = Filterd;

    }

    return (
        <>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_6"}>מינימום מחיר הזמנה למשלוח</label>
                <span className={"explanation mediumst fullROw"}>במידה ולא מוגדר מחיר מינימום למשלוח, זה יהיה המינימום מחיר למשלוח </span>
                <div className={"input-group"}>
                    <input type="number" className={"form-control"} id={"minimomForDel"} value={theMaster.MiniDelPrice}
                        onChange={(e) => theMaster["MiniDelPrice"] = e.target.value}
                    />

                </div>
            </div>
            <div className={"col-md-12 mb-2"}>
                <label htmlFor={"validationCustom1_64"}>ערים אפשריות למשלוח</label>
                <Button className={"btnSPc"} variant="contained" onClick={() => addCityToList()}
                    startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                    הוסף עיר
                </Button>
                <div className={"input-group"}>
                    {citysLis.map((itm, indx) => {
                        return (
                            <div key={indx} className='fullRow'>
                                <RemoveCircleOutline style={{ color: '#21ccd4', float: "right" }} onClick={() => removeCityFromList(itm)} />
                                <span className='bold onRight'>{itm}</span>

                            </div>
                        )
                    })}
                </div>

            </div>


            <div className={"col-md-12 mb-2"}>
                <label>סנדימן</label>
                <div className={"col-md-6 mb-2"}>
                    <label>username</label>
                    <div className={"input-group"}>
                        <input type="text" className={"form-control"} id={"sendiManUsr"} value={theMaster.sendiManUsr}
                            onChange={(e) => theMaster["sendiManUsr"] = e.target.value}
                        />

                    </div>
                </div>
                <div className={"col-md-6 mb-2"}>
                    <label>Password</label>
                    <div className={"input-group"}>
                        <input type="text" className={"form-control"} id={"sendiManPass"} value={theMaster.sendiManPass}
                            onChange={(e) => theMaster["sendiManPass"] = e.target.value}
                        />

                    </div>
                </div>
            </div>
            {thePopUp}

        </>

    );
}

export default DeliverMaster;