import { MenuItem, Select, TextField, Autocomplete } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import myFunctions from '../../../../myFunctions';

const BuisSelect = (props) => {

    const [busissList, setBusissList] = useState((props.busissList != null) ? props.busissList : []);
    const [userSelection, setUserSelection] = useState((props.userSelection != null) ?
        myFunctions.getBuisNameByIdInList(props.busissList, props.userSelection) : "-1")

    const [buisOpts, setBuisOpts] = useState([]);

    useEffect(() => {
        setTheOpts()

    }, [])

    useEffect(() => {
        // setBusissList(props.busissList)
        // setUserSelection(props.defaultValue != "" ? props.defaultValue : "כל העסקים")
        setTheOpts()
        // console.log("changed")
    }, [props.busissList])

    const selChanged = (e) => {
        if (e) {
            setUserSelection(e.label)
            props.setUserBuis(e.inVal)
        }
        else {
            setUserSelection("כל העסקים")
            props.setUserBuis("-1")

        }

    }


    const setTheOpts = () => setBuisOpts(busissList.map((item, index) => {
        return (
            { key: index, inVal: item._id, value: item.buissName, label: item.buissName }
        )
    }))


    return (
        <Autocomplete
            className={`selectInBlock ${props.clss ? props.clss : ""}`}
            disablePortal
            value={userSelection}
            options={buisOpts}
            sx={{ maxWidth: "300px" }}
            onChange={(event, e) => {
                selChanged(e);
            }}
            renderInput={(params) => <TextField {...params} className={"specialTxt"} placeholder='כל העסקים' />}
        />

    )
}

export default BuisSelect;