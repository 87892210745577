
import React, { useState, createContext, useEffect } from 'react';

export const MobiOrNotContext = createContext();

export const MobiOrNotContextProvider = props => {
    const [mobiOrNot, setMobiOrNot] = useState(false);

    useEffect(() => {
        handleResizeMobi()
    }, [])


    const handleResizeMobi = () => {
        //  console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)

        if (window.innerWidth != null) {
            return (window.innerWidth <= 800) ? setMobiOrNot(true) :
                setMobiOrNot(false)
        }
        else { setMobiOrNot(false) }

    }

    window.addEventListener('resize', handleResizeMobi)

    return (
        <MobiOrNotContext.Provider value={[mobiOrNot, setMobiOrNot]}>
            {props.children}
        </MobiOrNotContext.Provider>
    )
}