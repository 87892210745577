import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const getAllBuisiUsers = async (obj) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "auth/all", obj)

    //  await axios.get(ConnUrls.ApiUrl + "auth/signin", { "body": obj }, {

    return (dFromF.data);
}

const buisUserLogin = async (obj) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "auth/signin", obj)

    return (dFromF.data);
}


const updatePermiss = async (obj) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "auth/upPermis", obj)
 
    return (dFromF.data);
}
const updateUsersList = async (obj) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "auth/upUsrlst", obj)

    return (dFromF.data);
}

//מקבל רשימת עסקים שמיועדים למחיקה ומוחק אותם
const removeBuisList = async (obj) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "auth/removeBuisList", obj)

    return (dFromF.data);
}



const checkToken = async (token) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "auth/checkToken", { token })
    return (dFromF.data);
}


const buisUserChangePassword = async (email, token) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "auth/buisUserChangePassword", { email, token })

    return (dFromF.data);
}


const buisUserChangePasswordApprove = async (newPassword, token) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "auth/ChangePasswordApprove", { newPassword, token })

    return (dFromF.data);
}


const updateBuisUser = async (nUser, token) => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "auth/nUser", { nUser, token })

    return (dFromF.data);
}


const createNewBuisUsr = async (nUser, token) => {

    let dFromF = await axios.post(ConnUrls.ApiUrl + "auth/signup", { nUser, token })

    return (dFromF.data);
}

export default {
    buisUserLogin,
    checkToken,
    buisUserChangePassword,
    buisUserChangePasswordApprove,
    updateBuisUser,
    getAllBuisiUsers,
    updatePermiss,
    updateUsersList,
    createNewBuisUsr,
    removeBuisList

};