import ErrMsgs from "../_ApiConn/apiErrMsgs";

const getErrMsgs = async () => {
    
    let token = localStorage.getItem("token");
    let dFromF = await ErrMsgs.getErrMsgs({ token });
  
    return (dFromF);
}


const updateErrMsgs = async (msg) => {

    let token = localStorage.getItem("token");
    let dFromF = await ErrMsgs.updateErrMsgs({ msg, token });

    return (dFromF);
}


export default {
    getErrMsgs,
    updateErrMsgs

};