import tblOrder from "../_ApiConn/apiTableOrder";


const getWaitingForApprov = async (userBuis) => {
    let token = localStorage.getItem("token");
    let dFromF = await tblOrder.getWaitingForApprov({ token ,userBuis});

    return (dFromF);
}
const getAllTableOrders = async (userBuis) => {
    let token = localStorage.getItem("token");
    let dFromF = await tblOrder.getAllTableOrders({ token ,userBuis});

    return (dFromF);
}

const getAllOrdersByDates = async (startDTS, endDTS,userBuis) => {
    let token = localStorage.getItem("token");
    let dFromF = await tblOrder.getAllOrdersByDates({ startDTS, endDTS, token ,userBuis });

    return (dFromF);
}


const getTableOrderBySearch = async (txtToSearch,userBuis) => {
    let token = localStorage.getItem("token");
    let dFromF = await tblOrder.getTableOrderBySearch({ txtToSearch, token ,userBuis });
    return (dFromF);
}



const getTableOrder = async (orderID,userBuis) => {
    let token = localStorage.getItem("token");
    let dFromF = await tblOrder.getTableOrder({ orderID, token, userBuis });

    return (dFromF);
}


const orderMyTable = async (obj) => {

    let token = localStorage.getItem("token");

    let dFromF = await tblOrder.orderMyTable({ obj, token });
    return (dFromF);
}


const updateTableOrder = async (orderId, objToUpdate) => {

    let token = localStorage.getItem("token");
    let dFromF = await tblOrder.updateTableOrder({ orderId, objToUpdate, token });
    return (dFromF);
}

const updateTableStatus = async (orderId, newStatus,cancelReason) => {

    let token = localStorage.getItem("token");
    let dFromF = await tblOrder.updateTableStatus({ orderId, newStatus,cancelReason, token });
    return (dFromF);
}

export default {
    getWaitingForApprov,
    orderMyTable,
    getTableOrderBySearch,
    getAllTableOrders,
    updateTableStatus,
    getAllOrdersByDates,
    getTableOrder,
    updateTableOrder
};