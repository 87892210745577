import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import Breadcrumb from './Breadcrumb'

import { Checkbox, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import { UserContext } from '../../MyContexts/Users_Context';
import { AddBoxOutlined, LocalConvenienceStoreOutlined } from '@mui/icons-material';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { DatePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/he';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import utilsKopons from '../../../MyConns/_Utils/utilsKopons';
import myFunctions from '../../../myFunctions';
import MyDateTime from '../../MySections/TimeSections/MyDateTime';
import MultiLIstKoponSelect from './MultiLIstKoponSelect';
import MultiUpdateCheck from '../../MySections/PopUps/MultiUpdateCheck';
import PopUpTheComp from '../../MySections/DTechComps/PopUpTheComp';
import KoponDetails from './KoponDetails';
import DateSelection from '../../MySections/TimeSections/DateSelection';
import NumberSelection from './NumberSelection';
import utilsUsers from '../../../MyConns/_Utils/utilsUsers';



const KoponActivatorsList = (props) => {
    const [loading, setLoading] = useState(true);
    const [activatorsList, setActivatorsList] = useState([]);
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {

        setTheList();
        setLoading(false)
    }, [])

    const setTheList = async () => {
        let lst = await utilsKopons.getActivatorsList(props.usersUsedIt)
        setActivatorsList(lst)
    }

    const rowCellClick = () => {

    }

    return (
        <div className="lstOrderTbls">
            <div className="col-xl-12">
                <div className="table-responsive ms-panel-body">

                    {loading ?
                        <MySpinner_Wait />
                        :
                        <table className="table table-hover thead-primary">
                            <thead>
                                <tr>
                                    {/* <th scope="col" className={"pointing centerText"} onClick={() => { }}>
                                        <Checkbox checked={selectAll} onChange={() => { updateSelAll() }} className="chkBx" />
                                    </th> */}
                                    <th scope="col" className={"pointing centerText"} >שם הלקוח</th>
                                    <th scope="col" className={"pointing centerText"} >מספר הזמנה</th>
                                    <th scope="col" className={"pointing centerText"} >שם העסק</th>
                                    <th scope="col" className={"pointing centerText"} >מתי הופעל</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    activatorsList?.map((item, i) => (
                                        <tr key={item._id} className={`${new Date(item.KoponExperationDate) < new Date() ?
                                            "redRow" : ""} pointing centerText`} itmid={item._id}>

                                            <td onClick={() => rowCellClick(item._id)}>{item.firstName} {item.lastName}</td>
                                            <td onClick={() => rowCellClick(item._id)}>{item.orderNUM}</td>
                                            <td onClick={() => rowCellClick(item._id)}>{item.buissName}</td>
                                            <td onClick={() => rowCellClick(item._id)}>{MyDateTime.getDateAndTime(item.timeUsed)}</td>

                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    }
                </div>
            </div>
            {thePopUp}
        </div>
    )
}
export default KoponActivatorsList;

