
import React, { useEffect, useState } from 'react';
import ConnUrls from '../../../../MyConns/Connections/ConnectURL';
import PopUpMsg from '../PopUpMsg';



const Crdit_Problem = (props) => {

    return (
        <PopUpMsg Title={["בעיית חיוב"]}
            ContentText={["התגלתה בעייה במערכת החיוב"
                , "ולכן זמנית לא ניתן לבצע חיוב להזמנה."
                , "בתור תחלופה, ניתן להמשיך עם ההזמנה"
                , "ולבצע תשלום במזומן"]}
            inLabel={""}

            BtnTxt={"אשלם במזומן"}
            okClick={() => {
                props.okClick()
            }}

            btnClick2={() => { props.closePop() }}
            cssStyl={"orderOneClose"}
            BtnTxt2={"סגור"}
            errorMsg={""} />
    )
}


export default Crdit_Problem