import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const getWaitingForApprov = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "ordTbl/WaitingForApprov", { params: obj });
    return (dFromF.data);
}

const getAllTableOrders = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "ordTbl/all", { params: obj });
    return (dFromF.data);
}

const orderMyTable = async (obj) => {
    let dFromF = await axios.post(ConnUrls.ApiUrl + "ordTbl/nTblOrder", obj);
    return (dFromF.data);
}

const getAllOrdersByDates = async (obj) => {
    
    let dFromF = await axios.get(ConnUrls.ApiUrl + "ordTbl/byDates", { params: obj });
    return (dFromF.data);
}

const getTableOrderBySearch = async (obj) => {
    
    let dFromF = await axios.get(ConnUrls.ApiUrl + "ordTbl/bySearch", { params: obj });
    return (dFromF.data);
}

const updateTableStatus = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "ordTbl/upTablestat", obj);
    return (dFromF.data);
}

const getTableOrder = async (obj) => {
    
    let dFromF = await axios.get(ConnUrls.ApiUrl + "ordTbl/getTableOrder", { params: obj });
    return (dFromF.data);
}

const updateTableOrder = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "ordTbl/updateTableOrder", obj);
    return (dFromF.data);
}

export default {
    getWaitingForApprov,
    orderMyTable,
    getAllTableOrders,
    getAllOrdersByDates,
    getTableOrderBySearch,
    updateTableStatus,
    getTableOrder,
    updateTableOrder
};