import React, { useContext, useEffect, useState } from 'react'

const AccordProdChkSaucess = (props) => {

    const [prodName, setProdName] = useState(props.prodTxtVal);
    const [chkProdSauces, setChkProdSauces] = useState(props.prod_chkProdSauces);
    const [chkProdValSauces, setChkProdValSauces] = useState(props.prod_chkProdValSaucess ? props.prod_chkProdValSaucess : "0");

    useEffect(() => {
    }, [])


    useEffect(() => {
        setProdName(props.prodTxtVal);
    }, [props.prodTxtVal])

    const blorin = () => {
        props.setProdItm(chkProdSauces, chkProdValSauces)
        // props.setProdItm2(chkProdValSauces)

    }

    let theChkBox =
        <input type="checkbox"
            checked={chkProdSauces}
            value={chkProdSauces}
            onChange={(e) => setChkProdSauces(!chkProdSauces)}
        />

    let theValBox =
        <input type="number"
            value={chkProdValSauces ? chkProdValSauces : 0}
            className={"saussInput"}
            onChange={(e) => setChkProdValSauces(e.target.value)}
        />

    return (

        <div onBlur={blorin} className={`${props.clss ? props.clss : "input-group prodSwitch switchMe onRight fullRow"}`}>
            <label className={"spHead"}>{props.headI}</label>
            <label className="ms-switch">
                {theChkBox}
                <span className="ms-switch-slider ms-switch-primary round" />
            </label>

            <div className={`saccsValInput ${chkProdSauces ? "showMe" : "hideMe"}`}>
                {theValBox}
            </div>

        </div>



    )
}

export default AccordProdChkSaucess;

