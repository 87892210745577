import React, { Component, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal, Accordion, Card } from "react-bootstrap";
import ConnUrls from '../../../../MyConns/Connections/ConnectURL';
import ValidatorsCheck from '../../../../MyFunctions/ValidatorsCheck';
import utilsBuisUser from '../../../../MyConns/_Utils/utilsBuisUser';



const ResetPasswordPage = (props) => {

    const queryParams = new URLSearchParams(window.location.search);

    const [thePass, setThePass] = useState("");
    const [thePass2, setThePass2] = useState("");

    const [validateErr, setValidateErr] = useState("");
    const [showConnect, setShowConnect] = useState(false);


    const [validatMe, setValidatMe] = useState({
        pass: true,
        pass2: true
    });

    useEffect(() => {
    }, [])

    const updatePass = async (e) => {
        let valiMe = {
            pass: (thePass != ""),
            pass2: (thePass2 != "")
        }

        setValidatMe(valiMe);

        if (valiMe.pass && valiMe.pass2) {
            if (ValidatorsCheck.validPassword(thePass)) {
                if (thePass != thePass2) {
                    setValidateErr("הסיסמא והסיסמא בשנית לא זהים")
                }
                else {
                    let resp = await utilsBuisUser.buisUserChangePasswordApprove(thePass, queryParams.get('co'))

                    if (resp.success) {
                        setShowConnect(true)
                    }
                    setValidateErr(resp.errMsg)

                }
            }
            else {
                setValidateErr(
                    <>
                        <p className={"mediumFont"}>על הסיסמא להכיל מינימום 6 תווים, אות גדולה באנגלית</p>
                        <p className={"mediumFont"}> אות קטנה באנגלית ותו מיוחד ( לדוגמא: ! או $) </p>
                    </>
                )
            }
        }
    }




    return (
        <div className="ms-content-wrapper ms-auth">
            <div className="ms-auth-container">

                <div className="ms-auth-col">
                    <div className="ms-auth-form">
                        <div className="needs-validation validBLock" noValidate >
                            <h3>החלפת סיסמא</h3>
                            <p>יש להקליד סיסמא חדשה</p>
                            <p className={"mediumFont"}>על הסיסמא להכיל מינימום 6 תווים, אות גדולה באנגלית</p>

                            <p className={"mediumFont"}> אות קטנה באנגלית ותו מיוחד ( לדוגמא: ! או $) </p>
                            <div className="mb-3">
                                <div className="input-group">
                                    <input type="password" className="form-control" name={"password"}
                                        onChange={(e) => setThePass(e.target.value)} value={thePass} placeholder="סיסמה" required />
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="input-group">
                                    <input type="password" className="form-control" name={"passwordAgain"}
                                        onChange={(e) => setThePass2(e.target.value)} value={thePass2} placeholder="סיסמה בשנית" required />
                                </div>
                            </div>
                            <div className="mb-2 theValBlock mediumFont">
                                <div className={`centerText ${(validatMe.pass) ? "hideMe" : "showMe"} invalid-feedback`}>יש להזין סיסמא.</div>
                                <div className={`centerText ${(!validatMe.pass || validatMe.pass2) ? "hideMe" : "showMe"} invalid-feedback`}>יש להזין סיסמא בשנית.</div>
                                <div className={`centerText ${(validateErr === "") ? "hideMe" : "showMe"} invalid-feedback`}>{validateErr}</div>

                            </div>
                            
                            {showConnect ?
                                <Link to="/Login"> <button className="btn btn-primary mt-4 d-block w-100" name={"SubmitBtn"}>התחבר</button></Link> :
                                <button className="btn btn-primary mt-4 d-block w-100" onClick={(e) => updatePass(e)} type="submit" name={"SubmitBtn"}>עדכן סיסמא</button>}
                        </div>
                    </div>
                </div>
                <div className="ms-auth-col">
                    <div className={`ms-auth-bg ms-SideLoginImg`} style={{ backgroundImage: `url("${ConnUrls.mediasLocalUrl + "/PermanentImages/53.jpg"}")` }} />
                </div>
            </div>
        </div>

    );
}

export default ResetPasswordPage;