import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'

const getHomePage = async (token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "homeP", { token });
    return (dFromF);
}

const updateHomePage = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "homeP/" + obj._id, obj);
    return (dFromF);
}

const getMainRests = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "homePBack/mainRests");
    return (dFromF);
}

const getOnlineRests = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "homeP/onlineRests");
    return (dFromF);
}


const getFavoRests = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "homeP/favoRests");
    return (dFromF);
}

const getPopularRests = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "homeP/popularRests");
    return (dFromF);
}

const getSectionRests = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "homeP/getSectionRests", { params: obj });
    return (dFromF);
}


export default {
    getHomePage,
    updateHomePage,
    getMainRests,
    getFavoRests,
    getPopularRests,
    getOnlineRests,
    getSectionRests
};