import React, { useContext, useEffect, useState } from 'react';

import { Button } from '@mui/material';
import utilsBuisUser from '../../../MyConns/_Utils/utilsBuisUser';
import utilsRests from '../../../MyConns/_Utils/utilsRests';
import { BuisListContext } from '../../MyContexts/BuisList_Context';
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import SucessToast from '../../MySections/Toasts/SucessToast';
import Breadcrumb from './Breadcrumb';
import ListUsersBlocks from './ListUsersBlocks';
import ListUsersTable from './ListUsersTable';
import { AddBoxOutlined } from '@mui/icons-material';
import PopUpTheComp from '../../MySections/DTechComps/PopUpTheComp';
import CreateBuisUser from './CreateBuisUser';

const UsersPage = (props) => {
    const [buisLst, setBuisLst] = useContext(BuisListContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [thePage, setThePage] = useState();
    const [usersList, setUsersList] = useState([]);
    const [fullList, setFullList] = useState([]);
    const [txtSrch, setTxtSrch] = useState("");
    const [loading, setLoading] = useState(true);
    const [thePopUp, setThePopUp] = useState("");
    const [deletionList, setDeletionList] = useState([]);

    useEffect(() => {

        setTheLists();

    }, [])


    //עדכון כללי, לחיצה על כפתור שמור
    const updateTheBuisList = async () => {

        let usrsList = await utilsBuisUser.updateUsersList(usersList);

        if (deletionList.length > 0) {
            usrsList = await utilsBuisUser.removeBuisList(deletionList);

        }

        if (usrsList) {
            SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
        }


    }

    //יוצר רשימות פתיחה
    const setTheLists = async () => {
        let usrsList = await utilsBuisUser.getAllBuisiUsers();
        setUsersList(usrsList)
        setFullList(usrsList)


        if (buisLst.length === 0) {
            let busiLst = await utilsRests.getRestsListSmall();
            let busiLstt = busiLst;
            setBuisLst(busiLstt)
        }
        setLoading(false)

    }



    const checkIfEnter = (e) => {
        // if (e.key === 'Enter') {
        searchUser();
        // }
    }



    const searchUser = () => {
        let usrsBack = fullList.filter(usr => usr.firstName?.includes(txtSrch) ||
            usr.lastName?.includes(txtSrch) ||
            usr.phone?.includes(txtSrch) ||
            usr.buisName?.includes(txtSrch));

        if (usrsBack === -1 || txtSrch === "") {

            let tBk = ([...fullList])

            usersList.forEach((itm) => {
                let itIndx = tBk.findIndex(ful => ful._id === itm._id)
                if (itIndx != -1) {
                    tBk[itIndx] = { ...itm };
                }
            })

            setUsersList(tBk)
        }
        else {
            setUsersList(usrsBack)

        }


    }

    const createUser = () => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"KoponDispPop"}
                popDiagStyle={"diagSmallWidth"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["יצירת משתמש מערכת"]}
                theInsideComp={
                    <CreateBuisUser cssStyl={"KoponDetValPop"}
                        setTheLists={() => setTheLists()}
                        okClick={() => { setThePopUp() }}
                        Title={[""]}
                    />}
            />

        )
    }


const addToDeletionList=(item)=>
{
    let tempDel = [...deletionList];
    tempDel.push(item);

    setDeletionList(tempDel);

}


    return (
        <>
            <div className="ms-content-wrapper categurysList">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <div className={mobiOrNot ? "bigPadding" : "smallPadding"}>
                                            <h6>כל המשתמשים</h6>
                                        </div>
                                        <Button variant="contained" className={"theSBtn"} onClick={() => createUser()}
                                            startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                                            צור
                                        </Button>
                                        <div className={"fullRow"}>
                                            <div className={"spaceButtomBig bigPadding ms-form-group my-0 mb-0 has-icon fs-14"}>
                                                <input type="search" className="dtSearchInput ms-form-input" id="dtSearchInput" name="dtSearchInput"
                                                    placeholder="שם פרטי, שם משפחה, שם העסק או טלפון" value={txtSrch}
                                                    onChange={(e) => setTxtSrch(e.target.value)}
                                                    onKeyUp={(e) => checkIfEnter(e)}
                                                />
                                                <Button className="dtSearchIcon flaticon-search text-disabled" onClick={() => searchUser()} />
                                            </div>
                                        </div>
                                    </div>
                                    {loading ? <MySpinner_Wait />
                                        :
                                        (mobiOrNot) ?
                                            <ListUsersBlocks
                                                usersList={usersList}
                                                updateList={(upList) => setUsersList(upList)}
                                                buisLst={buisLst}
                                                addToDelete={(item) => addToDeletionList(item)}

                                            />
                                            :
                                            <ListUsersTable
                                                usersList={usersList}
                                                updateList={(upList) => setUsersList(upList)}
                                                buisLst={buisLst}
                                                addToDelete={(item) => addToDeletionList(item)}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ms-panel-header approveLine">
                    <button type="button" className="onLeft btn btn-primary" onClick={() => updateTheBuisList()}>שמור</button>
                </div>
                {thePopUp}
            </div>

        </>
    );
}

export default UsersPage;