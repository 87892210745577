import React, { Component } from 'react';
import Topnavigation from '../../../layouts/Topnavigation';
import Sidenavigation from '../../../layouts/Sidenavigation';
import Sidenavigation1 from '../../../layouts/Sidenavigation1';

import BuisUserprofileContent from './BuisUserprofileContent';
import { TheMasterContextProvider } from '../../../MyContexts/Master_Context';

const BuisUserprofile = (props) => {
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>
               
                    <Sidenavigation1 />
                    <main className="body-content">
                        <Topnavigation />
                        <BuisUserprofileContent />
                    </main>
               
            </TheMasterContextProvider>
        </div>
    );
}

export default BuisUserprofile;