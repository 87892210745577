import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card, Nav, Tab } from "react-bootstrap";
import { useParams } from 'react-router';
// import Breadcrumb from './Breadcrumb'

import { useHistory } from "react-router-dom";
import SucessToast from '../../MySections/Toasts/SucessToast';
import { UserContext } from '../../MyContexts/Users_Context';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import { Dialog } from '@mui/material';
import { HighlightOffOutlined } from '@mui/icons-material';
import PageSelectImage from '../../MySections/ImagesSetter/PageSelectImage';
import utilsRests from '../../../MyConns/_Utils/utilsRests';
import utilsErrMsgs from '../../../MyConns/_Utils/utilsErrMsgs';
import RichTextComp from '../../MySections/TextEditor/RichText';



const ProgramrsMsgsContent = (props) => {
    const history = useHistory();

    const [user, setUser] = useContext(UserContext)
    const [theErrtxt, setTheErrtxt] = useState("");

    const [loading, setLoading] = useState(true);
    let idParam = useParams();
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {
        loadErrsMsgs();
        setLoading(false)
    }, [])

    const loadErrsMsgs = async () => {

        let theErrsmsg = await utilsErrMsgs.getErrMsgs();
        try {
            setTheErrtxt(theErrsmsg.data.theMsg);
        }
        catch { }
    }

    const updateTheErrs = async () => {

        let theErrsmsg = await utilsErrMsgs.updateErrMsgs(theErrtxt);

        setLoading(false)

        SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });

    }



    return (
        <div className="ms-content-wrapper SetBlock">
            {/* <Breadcrumb /> */}
            {/* Active Orders Graph */}
            <div className="row">
                <div className="col-md-12">
                    {loading ? <MySpinner_Wait /> :
                        <>
                            <div className={"col-md-12 mb-2 mainErrBlk"}>
                                <label htmlFor={"validationCustom1_2"}>מטלות לביצוע</label>
                                <div className={"input-group"}>
                                    <RichTextComp firstText={theErrtxt}
                                        height={500}
                                        saveEditorTxt={(content) => setTheErrtxt(content)} />
                                </div>
                            </div>

                        </>
                    }
                </div>
            </div>

            <div className="ms-panel-header approveLine">
                <button type="button" className="onLeft btn btn-primary" onClick={() => { updateTheErrs(); setLoading(true) }}>שמור</button>
            </div>
            {thePopUp}
        </div>
    );
}


export default ProgramrsMsgsContent;