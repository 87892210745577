import { Button, Checkbox, MenuItem, Select } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';
import MyDateTime from '../../../../MySections/TimeSections/MyDateTime';
import OrderStatuselect from '../OrderStatuselect';
import RowTotalsBuissReport from './RowTotalsBuissReport';
import BuissReportPrintComp from './BuissReportPrintComp';
import PopUpTheComp from '../../../../MySections/DTechComps/PopUpTheComp';
import { AddBoxOutlined } from '@mui/icons-material';

const ListBuisViewTable = (props) => {




    const [theReport, setTheReport] = useState([]);
    const [loading, setLoading] = useState(true);
    const [thePopUp, setThePopUp] = useState("");


    useEffect(() => {

        if (props.theList.length > 0) {
            let tempo = groupOrdersByBuiss(props.theList)

            fixTheReport(tempo)
            setLoading(false)
        }


    }, [props.theList])

    useEffect(() => {

    }, [props.headsToPrint])





    const fixTheReport = (tempo) => {
        let theRep = tempo.map((item, i) => {

            return (
                <tr key={item.buissId} className={"pointing"} itmid={item.buissId} >
                    <RowTotalsBuissReport
                        rowCellClick={() => rowCellClick(item.buissId)}
                        prms={{
                            item
                        }} />
                    <td>
                        <Button className={"btnSPc"} variant="contained"
                            onClick={() => createRportPrint(item, props.headsToPrint)}
                            startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                            הפק דוח
                        </Button>
                    </td>

                </tr>
            )
        }
        )

        setTheReport(theRep)

    }

    const groupOrdersByBuiss = (orders) => {
        const groupedOrders = [];

        orders.forEach(order => {
            const buissId = order.buiss._id;
            const buissName = order.buiss.buissName;
            let grpF = groupedOrders.findIndex(bus => bus.buissId === buissId)
            if (grpF === -1) {
                let nItem = {
                    buissId: buissId,
                    buissName: buissName,
                    orders: [order.ord]
                };
                groupedOrders.push(nItem)
            }
            else {
                groupedOrders[grpF].orders.push(order.ord)
            }

        });

        return groupedOrders;
    };


    const orderBythis = (byWhat, ordUserOrBuiss) => {


        // let tempo = groupOrdersByBuiss(props.theList)
        // fixTheReport(tempo)

        // if (listSordted.sortBy === "Asc") {
        //     setOrderlisttable(myFunctions.sortByItemAsc(orderlisttable, byWhat, ordUserOrBuiss))
        //     setAllLists(selectedBtn, myFunctions.sortByItemAsc(orderlisttable, byWhat, ordUserOrBuiss));
        //     setListSordted({ sort: true, sortBy: "Dsc" })
        // }
        // else {
        //     setOrderlisttable(myFunctions.sortByItemDsc(orderlisttable, byWhat, ordUserOrBuiss))
        //     setAllLists(selectedBtn, myFunctions.sortByItemDsc(orderlisttable, byWhat, ordUserOrBuiss));
        //     setListSordted({ sort: true, sortBy: "Asc" })
        // }


    }

    const rowCellClick = (buisID) => {
        props.rowCellClick(buisID);
    }

    const setNewStatus = (orderId, statBack, buisId) => {
        props.setNewStatus(orderId, statBack, buisId);
    }


    const createRportPrint = (item, headsToPrint) => {
        let thFIxdList = props.theList.filter(orderItem => orderItem.buiss._id === item.buissId)

        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp(); }}
                cssStyl={"fullRow"}
                popDiagStyle={""}
                errorMsg={""}
                theInsideComp={<BuissReportPrintComp
                    reportigm={props.reportigm || ""}
                    headsToPrint={headsToPrint}
                    item={item}
                    thFIxdList={thFIxdList} />}

            />
        )

    }




    return (
        <div className="row lstOrderTbls">
            {!loading &&
                <div className="col-xl-12">
                    <div className="table-responsive ms-panel-body">
                        <table className="table table-hover thead-primary">
                            <thead>
                                <tr>
                                    {/* <th scope="col" className={"pointing"} onClick={() => { }}>_</th> */}
                                    {/* <th scope="col" className={"pointing"} onClick={() => orderBythis("orderNUM", "ord")}></th> */}
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("buissName", "buiss")}>שם העסק</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("payt", "ord")}>מזומן</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("firstName", "theUser")}>אשראי</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("deliveryAddress.street", "ord")}>סה"כ</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("dateCreated", "ord")}>עמלה כולל</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("orderStatus", "ord")}>העברה לאחר קיזוז</th>
                                    {/* <th scope="col" className={"pointing no_print"}>תזמון</th> */}
                                    <th scope="col" className={"pointing"}>דוח</th>
                                </tr>
                            </thead>
                            <tbody>
                                {theReport}

                            </tbody>
                        </table>
                    </div>
                    {thePopUp}
                </div >
            }
        </div >
    )
}

export default ListBuisViewTable;