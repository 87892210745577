import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Card } from 'react-bootstrap';
import ConnUrls from '../../../../MyConns/Connections/ConnectURL';
import { HomePContext } from '../../../MyContexts/HomeP_Context';
import ImgAndTxtAndLnkCARD from '../../../MySections/DTechComps/ImgAndTxtAndLnkCARD';

const TabHPDetails = (props) => {
    const [homeP, setHomeP] = useContext(HomePContext);

    const [mainTitle, setMainTitle] = useState(homeP.mainTitle);
    const [webSiteLogo, setWebSiteLogo] = useState(homeP.webSiteLogo);
    const [topImages, setTopImages] = useState(homeP.topImages);
    const [loginTopImages, setLoginTopImages] = useState(homeP.loginTopImages);
    const [bootomBaners, setBootomBaners] = useState(homeP.bootomBaners);

    const [mainGallDisp, setMainGallDisp] = useState();

    useEffect(() => {


    }, [])
    const setTheTopImages = (urlsList) => {
        homeP["topImages"] = urlsList;
        setTopImages(urlsList);
    }



    return (
        <>
            <div className={"col-md-12 mb-2"}>

                <Accordion defaultActiveKey="main" className="has-gap ms-accordion-chevron menuSectionsAcord accordion">

                    <div className={"col-md-12 mb-2"}>
                        <ImgAndTxtAndLnkCARD Head={"(עליון) גלריית תמונות ראשית"} tblHeads={[ "מיקום","תמונה", "טקסט על התמונה","קישור", "מחק"]}
                            TheList={topImages} theFunction={(lst) => setTheTopImages(lst)} eventKey={"elme_0"} />

                    </div>


                </Accordion>
            </div>

        </>

    );
}

export default TabHPDetails;