import React, { Component, useContext, useEffect } from 'react';
import jwt from 'jsonwebtoken';

import Sidenavigation1 from '../../layouts/Sidenavigation1';
// import Sidenavigation from '../../layouts/Sidenavigation';
import Topnavigation from '../../layouts/Topnavigation';
import Quickbar from '../../layouts/Quickbar';
import MasterSet from './MasterSect/MasterSet';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';
import TopnavigationSpecial from '../../MySections/DTechComps/TopnavigationSpecial';
import utilsBuisUser from '../../../MyConns/_Utils/utilsBuisUser';
import { useHistory } from 'react-router-dom';
import myFunctions from '../../../myFunctions';
import { UserContext } from '../../MyContexts/Users_Context';


const MasterSettings = (props) => {
    const [user, setUser] = useContext(UserContext)

    useEffect(() => {

    }, [])


    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>

                {/* <Sidenavigation /> */}
                <Sidenavigation1 user={user} />
                <main className="body-content">
                    <Topnavigation />
                    {user.permiss === "admin" &&
                        <MasterSet />
                    }
                </main>
                {/* <Quickbar /> */}

            </TheMasterContextProvider>

        </div>
    );
}

export default MasterSettings;