import React, { Component, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'

import Sidenavigation from '../../layouts/Sidenavigation';
import Sidenavigation1 from '../../layouts/Sidenavigation1';
import Topnavigation from '../../layouts/Topnavigation';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';

import { MenuContextProvider } from '../../MyContexts/Rest_Context';
import myFunctions from '../../../myFunctions';
import utilsUsers from '../../../MyConns/_Utils/utilsUsers';
import Breadcrumb from './Breadcrumb';
import utilsBuisUser from '../../../MyConns/_Utils/utilsBuisUser';
import UserPermission from '../OrdersP/OrdersList-list/UserPermission';
import SucessToast from '../../MySections/Toasts/SucessToast';
import utilsRests from '../../../MyConns/_Utils/utilsRests';
import BuisSelect from '../OrdersP/OrdersList-list/BuisSelect';
import { BuisListContext } from '../../MyContexts/BuisList_Context';
import { Button } from '@mui/material';
import ListClientUsersTable from './ListClientUsersTable';
import ListClientUsersBlocks from './ListClientUsersBlocks';
import EraseCheck from '../../MySections/PopUps/EraseCheck';
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import ClientUserItem from './ClientUserItem';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';

const ClientUsersPagePage = (props) => {
    const [buisLst, setBuisLst] = useContext(BuisListContext);
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [thePage, setThePage] = useState();
    const [usersList, setUsersList] = useState([]);
    const [busissList, setBusissList] = useState([]);
    const [tempList, setTempList] = useState([]);
    const [fullList, setFullList] = useState([]);
    const [txtSrch, setTxtSrch] = useState("");
    const [thePopUp, setThePopUp] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {


    }, [props.pageItem])


    //עדכון כללי, לחיצה על כפתור שמור
    const updateTheCutumersList = async () => {

        let usrsList = await utilsUsers.updateClientsList(tempList);
        if (usrsList) {

            SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
        }

    }



    const blockUser = (item, index, newVal) => {

        let itm = { ...usersList[index] }
        itm.blocked = newVal;

        let tempUsrs = [...usersList];
        tempUsrs[index] = itm;

        setUsersList(tempUsrs)

        let ind = (tempList.findIndex(it => it._id === itm._id));
        if (ind != -1) {
            tempList[ind] = itm;

            setTempList([...tempList])
        }
        else {
            setTempList([...tempList, itm])
        }

    }

    const showClient = (id) => {
        history.push("/ClientUsers/" + id)
    }



    const removeUser = (id) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את הלקוח?'}
            sucecssFunc={() => removeOptConfirmd(id)} />)
    }

    const removeOptConfirmd = async (id) => {
        let reply = await utilsUsers.removeUser(id);

        let tempUsrs = [...usersList];
        let ind = (tempUsrs.findIndex(it => it._id === id));

        if (ind !== -1) {
            tempUsrs.splice(ind, 1); // Remove the item at the found index 'ind'
        }

        setUsersList(tempUsrs);
    }

    const checkIfEnter = (e) => {
        if (e.key === 'Enter') {
            searchUser();
        }
    }



    const searchUser = async () => {
        setLoading(true)
        let fullListGO = await utilsUsers.searchUser(txtSrch.trim());

        setUsersList(fullListGO)
        setLoading(false)
    }

    const setFirstName = (item, index, newVal) => {
        let itm = { ...usersList[index] }
        itm.blocked = newVal;

        let tempUsrs = [...usersList];
        tempUsrs[index] = itm;

        setUsersList(tempUsrs)

        setTempList([...tempList, itm])

    }

    const setLastName = (e) => {

    }
    const setPhone = (e) => {
    }


    return (
        <>
            <div className="ms-content-wrapper categurysList">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <div className={!mobiOrNot ? "bigPadding" : "smallPadding"}>
                                            <h6>לקוחות</h6>
                                        </div>
                                        <div className={"fullRow"}>
                                            <div className={"spaceButtomBig bigPadding ms-form-group my-0 mb-0 has-icon fs-14"}>
                                                <input type="search" className="dtSearchInput ms-form-input" id="dtSearchInput" name="dtSearchInput"
                                                    placeholder="שם פרטי, שם משפחה, אימייל או טלפון" value={txtSrch}
                                                    onChange={(e) => setTxtSrch(e.target.value)}
                                                    onKeyUp={(e) => checkIfEnter(e)}
                                                />
                                                <Button className="dtSearchIcon flaticon-search text-disabled" onClick={() => searchUser()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"spaceButtomVeryBig"}>
                                        {

                                            loading ? <MySpinner_Wait /> :
                                                <>
                                                    {
                                                        mobiOrNot ?
                                                            <div className={"grayFrame spaceMargeBottombig spaceMargeTopbig spaceMargeRightbig spaceMargeLeftbig"}>
                                                                < ListClientUsersBlocks
                                                                    usersList={usersList}
                                                                    // updatePermiss={(newVal, itemid) => { updatePermiss(newVal, itemid) }}
                                                                    // setUserBuis={(newVal, itemid) => { setUserBuis(newVal, itemid) }}
                                                                    removeUser={(itemid) => removeUser(itemid)}
                                                                    blockUser={(item, index, val) => blockUser(item, index, val)}
                                                                    buisLst={buisLst}
                                                                    showClient={(itemid) => showClient(itemid)}
                                                                />
                                                            </div>
                                                            :

                                                            <div className={"grayFrame spaceMargeSmall spaceMargeRightbig spaceMargeLeftbig"}>

                                                                <ListClientUsersTable
                                                                    usersList={usersList}
                                                                    // updatePermiss={(newVal, itemid) => { updatePermiss(newVal, itemid) }}
                                                                    // setUserBuis={(newVal, itemid) => { setUserBuis(newVal, itemid) }}
                                                                    removeUser={(itemid) => removeUser(itemid)}
                                                                    blockUser={(item, index, val) => blockUser(item, index, val)}
                                                                    showClient={(itemid) => showClient(itemid)}
                                                                    buisLst={buisLst}
                                                                />
                                                            </div>
                                                    }
                                                </>

                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="ms-panel-header approveLine">
                    <button type="button" className="onLeft btn btn-primary" onClick={() => updateTheCutumersList()}>שמור</button>
                </div>
            </div >
            {thePopUp}
        </>
    );
}

export default ClientUsersPagePage;