import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Breadcrumb from './Breadcrumb';
// import Breadcrumb from './Breadcrumb'

import { useHistory } from "react-router-dom";
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import { UserContext } from '../../MyContexts/Users_Context';
import KoponsToolBar from './KoponsToolBar';
import AllKopons from './AllKopons';




const KoponsContent = (props) => {
    const history = useHistory();

    const [user, setUser] = useContext(UserContext)
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [theDispFrame, setTheDispFrame] = useState();


    const [loading, setLoading] = useState(true);
    let idParam = useParams();

    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {
        setLoading(false)
    }, [])


    const searchIt = (srchTxt) => {
        setTheDispFrame(<AllKopons txtSearch={srchTxt} refreshDisplay={() => searchIt(srchTxt)} key={Date.now()} />)

    }


    return (
        <div className="ms-content-wrapper categurysList">
            <div className="row">
                <div className="col-md-12">
                    <Breadcrumb />
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <div className={!mobiOrNot ? "bigPadding" : "smallPadding"}>
                                        <h6>מערכת קופונים</h6>
                                    </div>

                                    <KoponsToolBar setTheFrameDisp={(dispItem) =>setTheDispFrame(dispItem)}
                                        goSearch={(srchTxt) => searchIt(srchTxt)} />
                                    <div className="col-xl-12">
                                        <div className="table-responsive ms-panel-body">
                                            {theDispFrame}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ms-panel-header approveLine">
                <button type="button" className="onLeft btn btn-primary" onClick={() => { }}>שמור</button>
            </div>
            {thePopUp}

        </div >
    );
}


export default KoponsContent;