import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useState } from 'react';
import BetweenDatesNoHours from '../../../MySections/TimeSections/BetweenDatesNoHours';
import CreateTblOrder from './createTblOrder';
import BuisSelect from '../../OrdersP/OrdersList-list/BuisSelect';
import { BuisListContext } from '../../../MyContexts/BuisList_Context';
import myFunctions from '../../../../myFunctions';
import { UserContext } from '../../../MyContexts/Users_Context';
import { BuisListForTablesContext } from '../../../MyContexts/BuisListForTables_Context';
import BuisSelect_ForTable from './BuisSelect_ForTable';




const TblBuisTollBar = (props) => {

    const [busissList, setBusissList] = useState([]);
    const [user] = useContext(UserContext);

    const [buisLstTables, setBuisLstTables] = useContext(BuisListForTablesContext);
    const [thePopUp, setThePopUp] = useState("");
    const [showBetweenDts, setShowBetweenDts] = useState(false);
    const [selectedBtn, setSelectedBtn] = useState(props.selectedBtn);
    const [srchText, setSrchText] = useState("");

    useEffect(() => {
        setSelectedBtn(showBetweenDts ? 11 : props.selectedBtn)
    }, [showBetweenDts, props.selectedBtn])

    const refreshTheList = (selectedBtn) => {
        props.refreshTheList(selectedBtn)
    }

    const cretateTableOrder = () => {
        setThePopUp(<CreateTblOrder
            cancelClick={() => setThePopUp()}
            ordrApproved={() => { props.refreshTheList(selectedBtn); setThePopUp() }}
        />)
    }

    const checkIfEnter = (e) => {
        if (e.key === 'Enter') {
            props.searchTblOrder(srchText);
        }
    }

    return (
        <div className={"buisTollBar"}>
            <Button className={`btnBigMnu onLeft theSBtn bigSbtn`} variant="contained" onClick={() => {
                refreshTheList(selectedBtn);
            }}>
                רענן
            </Button>
            <Button className={`btnBigMnu onLeft theSBtn bigSbtn`} variant="contained" onClick={() => {
                cretateTableOrder();
            }}>
                צור הזמנה
            </Button>
            {
                (user.permiss === "admin") ?
                    <BuisSelect_ForTable busissList={buisLstTables}
                        defaultValue={myFunctions.getBuisNameByIdInList(buisLstTables, props.userBuis)}
                        firstHead={"כל העסקים"}
                        clss={"buisSelectOnTBAR onLeft"}
                        userSelection={props.userBuis ? props.userBuis : -1}
                        setUserBuis={(newVal) => { props.setUserBuis(newVal) }}
                    />
                    : ""
            }
            <Stack direction="row" spacing={2}>
                <div className={"btnMnuTop onRight"}>
                    <div className="spaceButtomBig ms-form-group my-0 mb-0 has-icon fs-14">
                        <input type="search" className="dtSearchInput ms-form-input" id="dtSearchInput" name="dtSearchInput"
                            placeholder="מספר הזמנה, טלפון או שם לקוח" value={srchText} onChange={(e) => setSrchText(e.target.value)}
                            onKeyUp={(e) => checkIfEnter(e)}
                        />
                        <Button className="dtSearchIcon flaticon-search text-disabled" onClick={() => props.searchTblOrder(srchText)} />
                    </div>
                    <Button className={`btnBigMnu ${selectedBtn === 5 ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => { refreshTheList(5); setShowBetweenDts(false) }}>
                        ממתינים לאישור
                    </Button>
                    <Button className={`btnBigMnu ${selectedBtn === 6 ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => { refreshTheList(6); setShowBetweenDts(false) }}>
                        להיום
                    </Button>
                    <Button className={`btnBigMnu ${selectedBtn === 7 ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => { refreshTheList(7); setShowBetweenDts(false) }}>
                        למחר
                    </Button>
                    <Button className={`btnBigMnu ${selectedBtn === 14 ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => { refreshTheList(14); setShowBetweenDts(false) }}>
                        מהיום קדימה
                    </Button>
                    <Button className={`btnBigMnu ${selectedBtn === 11 ? "selectedMnuBtn" : ""}`}
                        variant="contained" onClick={() => setShowBetweenDts(true)}>
                        לפי תאריכים
                    </Button>
                    <Button className={`btnBigMnu ${selectedBtn === 9 ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => { refreshTheList(9); setShowBetweenDts(false) }}>
                        החודש הנוכחי
                    </Button>
                    <Button className={`btnBigMnu ${selectedBtn === 13 ? "selectedMnuBtn" : ""}`} variant="contained" onClick={() => { refreshTheList(13); setShowBetweenDts(false) }}>
                        כל ההזמנות
                    </Button>

                    <div className={`spaceTop ${(showBetweenDts) ? "" : "hideMe"}`}>
                        <div className={"onRight"}>
                            <BetweenDatesNoHours
                                setStartDTS={(dt) => props.setStartDTS(dt)}
                                setEndDTS={(dt) => props.setEndDTS(dt)} />
                        </div>
                        <div className={"onRight spaceTopBig"}>
                            <Button className={`btnBigMnu onRight`} variant="contained" onClick={() => refreshTheList(11)}>
                                חפש
                            </Button></div>
                    </div>


                </div>
            </Stack>
            {thePopUp}
        </div>
    )
}

export default TblBuisTollBar;

