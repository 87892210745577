import React, { Component } from 'react';
import Swal from 'sweetalert2'


const BasicPopUp = (props) => {     
    Swal.fire(props.title, props.text, props.icon);
    return (
        <></>

    )
}

export default BasicPopUp;
