import React, { Component, useContext, useEffect, useState } from 'react';
import { Accordion, Card, Nav, Tab } from "react-bootstrap";
import { useParams } from 'react-router';
import utilsCategurys from '../../../../MyConns/_Utils/utilsCategurys';
import utilsRests from '../../../../MyConns/_Utils/utilsRests';

import TabBuisDetails from './parts/TabBuisDetails';
import TabImages from './parts/TabImages';
import TabMaps from './parts/TabMaps';
import TabNetwork from './parts/TabNetwork';
import TabSales from './parts/TabSales';
import TabPageDetails from '../../compsForAllPages/TabPageDetails';
import TabOpeningHours from './parts/TabOpeningHours';

import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import TabOrdersOptions from './parts/TabOrdersOptions';
import { TheMenuContext, RestContext } from '../../../MyContexts/Rest_Context';
import { useHistory } from "react-router-dom";
import BasicPopUp from '../../../MySections/PopUps/BasicPopUp';
import SucessToast from '../../../MySections/Toasts/SucessToast';
import TabMenu from './parts/TabMenu';
// import utilsRestMenus from '../../../../MyConns/_Utils/utilsRestMenus';
import MySpinner_Wait from '../../../MySections/DTechComps/MySpinner_Wait';
import RestStatusSelect from '../../../MySections/DTechComps/RestStatusSelect';
import myFunctions from '../../../../myFunctions';
import TabMoney from './parts/TabMoney';
import BuissTableSettings from '../../TblOrdersP/BuissTableSettings';
import { UserContext } from '../../../MyContexts/Users_Context';
import { TablesSetterContext, TablesSetterContextProvider } from '../../../MyContexts/TablesSetter_Context';
import utilsBuissTableSettings from '../../../../MyConns/_Utils/utilsBuissTableSettings';



const RestSet = (props) => {
    const history = useHistory();

    const [rest, setRest] = useContext(RestContext);
    const [user] = useContext(UserContext);
    const [tbls, setTbls] = useContext(TablesSetterContext);

    const [theMenu, setTheMenu] = useContext(TheMenuContext);

    const [restPre, setRestPre] = useState();

    const [loading, setLoading] = useState(true);
    let idParam = useParams();
    const [thePopUp, setThePopUp] = useState("");
    const [pg, setPg] = useState("");

    useEffect(() => {
        getRest();
        setPg(myFunctions.setGetCurrnetTab("Get", "pg"));
    }, [])

    useEffect(() => {
        setPg(myFunctions.setGetCurrnetTab("Get", "pg"));
    }, [restPre])


    const tempUpdate = (upRest) => {
        setRest(upRest);
    }

    const getRest = async () => {
     
        let rstLst = [];
        let mnu = [];
        if (idParam.id) {
            rstLst = await utilsRests.getRestById(idParam.id);
            setRest(rstLst);
            // let mnu = await utilsRestMenus.getMnuByRestId(idParam.id);
            // if (mnu === null) {
            //     mnu = await utilsRestMenus.createRestMnu({ restId: rstLst._id, buisMenu: rstLst.buisMenu });
            // }
            setTheMenu(mnu);

            setLoading(false);
        }
        else {
            rstLst = await utilsRests.getClearRest();
            setRest({ ...rstLst, newrest: true });
            setLoading(false);
        }

        setRestPre(rstLst);

    }


    const updateTheRest = async () => {

        let rstLst = [];


        if (rest.newrest) {

            if (rest.buissName === "") {
                setThePopUp(<BasicPopUp title={"לא ניתן ליצור עסק ללא שם לעסק"} text={""} icon={"error"} />);
            }
            else {

                rstLst = await utilsRests.createRest(rest);
                // let mnu = await utilsRestMenus.createRestMnu({
                //     restId: rstLst.item._id,
                //     buisMenu: rstLst.item.buisMenu
                // });

                let tblsList = await utilsBuissTableSettings.upOrCreate({ buisId: rstLst.item._id });
                setTbls(tblsList)

                // setTheMenu(mnu);

                rest.newrest = false;
                setRest(rstLst.item)

                history.push(`/Rest/${rstLst.item._id}`);
                SucessToast({ position: "toast-bottom-right", text: rstLst.resp });
            }

        }
        else {
            if (rest.buissName === "") {
                setThePopUp(<BasicPopUp title={"לא ניתן לשמור עסק ללא שם לעסק"} text={""}
                    icon={"error"} />);
            }
            else {

                // let mnu = await utilsRestMenus.updateRestMnu(theMenu._id, theMenu);
                rstLst = await utilsRests.updateRest(rest._id, rest);

                //רשימת שולחנות לעסק
                let tblsList = await utilsBuissTableSettings.upOrCreate({ ...tbls, buisId: rest._id });
                setTbls(tblsList)

                SucessToast({ position: "toast-bottom-right", text: rstLst.resp });

                setRest(rstLst.item)
            }
        }
        setPg(myFunctions.setGetCurrnetTab("Get", "pg"));
        setLoading(false)
    }




    return (
        <div className="ms-content-wrapper SetBlock">
            {loading ?
                <MySpinner_Wait />
                :
                <div className="col-md-12">

                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header">
                            <h6 className={"onRight"}>{rest.buissName}</h6>
                            <RestStatusSelect restStatus={rest.restStatus} statusUpdate={(stat) => { rest["restStatus"] = stat }} />
                        </div>

                        <div className="ms-panel-body clearfix">
                            <Tab.Container defaultActiveKey={pg}>
                                <Nav variant="tabs" className="nav nav-tabs d-flex nav-justified mb-2">
                                    <Nav.Item>
                                        <Nav.Link eventKey="pgBuisDetails" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "pgBuisDetails")}>פרטי העסק</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pgGalls" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "pgGalls")}>תמונות וגלריות</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pgActionTimes" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "pgActionTimes")}>אופני פעילות</Nav.Link>
                                    </Nav.Item>
                                    {
                                        user.permiss === "admin" ?
                                            <Nav.Item>
                                                <Nav.Link eventKey="tableOrdersSettings" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "tableOrdersSettings")}
                                                >הזמנת מקום</Nav.Link>
                                            </Nav.Item>
                                            : ""
                                    }
                                    <Nav.Item>
                                        <Nav.Link eventKey="pgRestHours" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "pgRestHours")}>שעות פתיחה</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pgDirections" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "pgDirections")}>הכוונה ומפות</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pgSales" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "pgSales")}>מבצעים והודעות</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pgNetowrk" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "pgNetowrk")}>קישורי רשת</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Mnu" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "Mnu")}>תפריט</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pgPageDetails" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "pgPageDetails")}>פרטי העמוד</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pgMoney" onClick={() => myFunctions.setGetCurrnetTab("Set", "pg", "pgMoney")}>פרטי חיוב</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                                <Tab.Content>
                                    {/* פרטי העמוד */}
                                    <Tab.Pane eventKey="pgPageDetails">
                                        <div className="form-row tabsInputs ">
                                            <TabPageDetails pgToSet="pgPageDetails" thePage={rest} thePageUpdate={(key, val) => { rest[key] = val }} />
                                        </div>
                                    </Tab.Pane>

                                    {/* פרטי העסק */}
                                    <Tab.Pane eventKey="pgBuisDetails">
                                        <div className="form-row tabsInputs ">
                                            <TabBuisDetails pgToSet="" />

                                        </div>
                                    </Tab.Pane>

                                    {/* תמונות  */}
                                    <Tab.Pane eventKey="pgGalls">
                                        <div className={"form-row tabsInputs"}>
                                            <TabImages pgToSet="pgGalls" Rest={rest} />
                                        </div>

                                    </Tab.Pane>

                                    {/*אופני פעילות*/}
                                    <Tab.Pane eventKey="pgActionTimes">
                                        <div className={"form-row tabsInputs"}>
                                            <TabOrdersOptions pgToSet="pgActionTimes"/>
                                        </div>

                                    </Tab.Pane>

                                    {/*הזמנת מקום*/}
                                    {
                                        user.permiss === "admin" ?
                                            < Tab.Pane eventKey="tableOrdersSettings">
                                                <div className={"form-row tabsInputs"}>
                                                    <BuissTableSettings pgToSet="tableOrdersSettings" />
                                                </div>
                                            </Tab.Pane>
                                            : ""
                                    }

                                    {/* שעות פתיחה */}
                                    <Tab.Pane eventKey="pgRestHours">
                                        <div className={"form-row tabsInputs"}>
                                            <TabOpeningHours pgToSet="pgRestHours" />

                                        </div>

                                    </Tab.Pane>

                                    {/* הכוונה ומפות */}
                                    <Tab.Pane eventKey="pgDirections">

                                        <div className={"form-row tabsInputs"}>
                                            <TabMaps pgToSet="pgDirections" Rest={rest} />

                                        </div>

                                    </Tab.Pane>
                                    {/* תפריט */}
                                    <Tab.Pane eventKey="Mnu">
                                        <div className={"form-row tabsInputs"}>
                                            <TabMenu pgToSet="Mnu" Rest={rest} updateTheRest={() => updateTheRest()} />
                                        </div>

                                    </Tab.Pane>
                                    {/* מבצעים והודעות */}
                                    <Tab.Pane eventKey="pgSales">
                                        <div className={"form-row tabsInputs"}>
                                            <TabSales pgToSet="pgSales" Rest={rest} updateRest={(uprest) => tempUpdate(uprest)} />
                                        </div>
                                    </Tab.Pane>

                                    {/* פרטי ניהול חיוב */}
                                    <Tab.Pane eventKey="pgMoney">
                                        <div className={"form-row tabsInputs"}>
                                            <TabMoney pgToSet="pgMoney" Rest={rest} updateRest={(uprest) => tempUpdate(uprest)} />

                                        </div>



                                    </Tab.Pane>

                                    {/* קישורי רשת */}
                                    <Tab.Pane eventKey="pgNetowrk">
                                        <div className={"form-row tabsInputs"}>
                                            <TabNetwork Rest={rest} />

                                        </div>

                                    </Tab.Pane>

                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            }

            <div className="ms-panel-header approveLine">
                <button type="button" className="onLeft btn btn-primary" onClick={() => { updateTheRest(); setLoading(true) }}>שמור</button>
            </div>

            {thePopUp}
        </div >
    );
}


export default RestSet;