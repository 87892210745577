

const isDev = process.env.NODE_ENV === 'development'
class ConnUrls {
    static ApiUrl = isDev ? 'http://localhost:8000/api/' : 'https://api.ashdodmenu.co.il/api/';
    static mediasUrl = isDev ? 'http://localhost:3000' : 'https://api.ashdodmenu.co.il';
    static mediasLocalUrl = isDev ? 'http://localhost:4001' : 'https://admin.ashdodmenu.co.il';
    // static servMediasUrl = isDev ? 'http://localhost:8000/images/' : 'https://api.ashdodmenu.co.il/images/';
    static servMediasUrl = 'https://am-medias.s3.eu-central-1.amazonaws.com/images/';
    static socketClient = isDev ? 'http://localhost:9000/' : 'https://socket.ashdodmenu.co.il/';

    // static ApiUrl = isDev ? 'http://192.168.1.105:8000/api/' : 'https://api.ashdodmenu.co.il/api/';
    // static mediasUrl = isDev ? 'http://192.168.1.105:3000' : 'https://api.ashdodmenu.co.il';
    // static mediasLocalUrl = isDev ? 'http://192.168.1.105:4001' : 'https://admin.ashdodmenu.co.il';
    // // static servMediasUrl = isDev ? 'http://192.168.1.105:8000/images/' : 'https://api.ashdodmenu.co.il/images/';
    // static servMediasUrl = 'https://am-medias.s3.eu-central-1.amazonaws.com/images/';
    // static socketClient = isDev ? 'http://192.168.1.105:9000/' : 'https://socket.ashdodmenu.co.il/';
}

export default ConnUrls;

