import React, { Component } from 'react';
import Sidenavigation from '../../../layouts/Sidenavigation';
import Sidenavigation1 from '../../../layouts/Sidenavigation1';

import Topnavigation from '../../../layouts/Topnavigation';
import Register from './Register';
import Quickbar from '../../../layouts/Quickbar';
import { UserContextProvider } from '../../../MyContexts/Users_Context';
import { TheMasterContextProvider } from '../../../MyContexts/Master_Context';

const WrapRegister = (props) => {
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>
                <Sidenavigation1 />
                <main className="body-content">
                    <UserContextProvider>
                        <Register />
                    </UserContextProvider>
                </main>
                {/* <Quickbar /> */}
               
            </TheMasterContextProvider>

        </div>
    );
}

export default WrapRegister;