import React, { useContext, useEffect, useState } from 'react';

import { Button } from '@mui/material';
import utilsBlackList from '../../../MyConns/_Utils/utilsBlackList';
import { BuisListContext } from '../../MyContexts/BuisList_Context';
import { ScreenWidthContext } from '../../MyContexts/ScreenWidth_Context';
import EraseCheck from '../../MySections/PopUps/EraseCheck';
import SucessToast from '../../MySections/Toasts/SucessToast';
import BlackListInBlocks from './BlackListInBlocks';
import BlackListInTable from './BlackListInTable';
import Breadcrumb from './Breadcrumb';

const BlackListPage = (props) => {
    const [buisLst, setBuisLst] = useContext(BuisListContext);
    const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);
    const [thePage, setThePage] = useState();
    const [usersList, setUsersList] = useState([]);
    const [busissList, setBusissList] = useState([]);
    const [tempList, setTempList] = useState([]);
    const [fullList, setFullList] = useState([]);
    const [txtSrch, setTxtSrch] = useState("");
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {

    }, [props.pageItem])


    //עדכון כללי, לחיצה על כפתור שמור
    const updateTheCutumersList = async () => {

        let usrsList = await utilsBlackList.updateBlackL(tempList);
        if (usrsList) {

            SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
        }

    }

  



    const blockUser = (item, index, newVal) => {

        let itm = { ...usersList[index] }
        itm.blocked = newVal;

        let tempUsrs = [...usersList];
        tempUsrs[index] = itm;

        setUsersList(tempUsrs)

        let ind = (tempList.findIndex(it => it._id === itm._id));
        if (ind != -1) {
            tempList[ind] = itm;

            setTempList([...tempList])
        }
        else {
            setTempList([...tempList, itm])
        }

    }


    const removeUser = (id) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק מהרשימה השחורה?'}
            sucecssFunc={() => removeOptConfirmd(id)} />)
    }

    const removeOptConfirmd = async (id) => {
        let reply = await utilsBlackList.removeFromBlackL(id);

        let tempUsrs = [...usersList];
        let ind = (tempUsrs.findIndex(it => it._id === id));
       
        if (ind !== -1) {
            tempUsrs.splice(ind, 1); // Remove the item at the found index 'ind'
        }
    
        setUsersList(tempUsrs);
    }

    const checkIfEnter = (e) => {
        if (e.key === 'Enter') {
            searchUser();
        }
    }



    const searchUser = async () => {
        let fullListGO = await utilsBlackList.getBlackList();

        let usrsBack = fullListGO.filter(usr => usr.firstName?.includes(txtSrch) ||
            usr.lastName?.includes(txtSrch) ||
            usr.phone?.includes(txtSrch) ||
            usr.email?.includes(txtSrch));

        if (usrsBack === -1 || txtSrch === "") {

            let tBk = ([...fullListGO])

            tempList.forEach((itm, index) => {
                let itIndx = tBk.findIndex(ful => ful._id === itm._id)
                if (itIndx != -1) {
                    tBk[itIndx] = { ...itm };
                }
            })

            setUsersList(tBk)
        }
        else {
            setUsersList(usrsBack)

        }

    }

    const setFirstName = (item, index, newVal) => {
        let itm = { ...usersList[index] }
        itm.blocked = newVal;

        let tempUsrs = [...usersList];
        tempUsrs[index] = itm;

        setUsersList(tempUsrs)

        setTempList([...tempList, itm])

    }
    const setLastName = (e) => {

    }
    const setPhone = (e) => {
    }


    return (
        <>
            <div className="ms-content-wrapper categurysList">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <div className={screenWidth > 800 ? "bigPadding" : "smallPadding"}>
                                            <h6>Black List</h6>
                                        </div>
                                        <div className={"fullRow"}>
                                            <div className={"spaceButtomBig bigPadding ms-form-group my-0 mb-0 has-icon fs-14"}>
                                                <input type="search" className="dtSearchInput ms-form-input" id="dtSearchInput" name="dtSearchInput"
                                                    placeholder="שם פרטי, שם משפחה או טלפון" value={txtSrch}
                                                    onChange={(e) => setTxtSrch(e.target.value)}
                                                    onKeyUp={(e) => checkIfEnter(e)}
                                                />
                                                <Button className="dtSearchIcon flaticon-search text-disabled" onClick={() => searchUser()} />
                                            </div>
                                        </div>
                                    </div>
                                    {screenWidth <= 800 ?
                                        <BlackListInBlocks
                                            usersList={usersList}
                                            removeUser={(itemid) => removeUser(itemid)}
                                            blockUser={(item, index, val) => blockUser(item, index, val)}

                                        />
                                        :
                                        <BlackListInTable
                                            usersList={usersList}
                                            removeUser={(itemid) => removeUser(itemid)}
                                            blockUser={(item, index, val) => blockUser(item, index, val)}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ms-panel-header approveLine">
                    <button type="button" className="onLeft btn btn-primary" onClick={() => updateTheCutumersList()}>שמור</button>
                </div>
            </div>
            {thePopUp}
        </>
    );
}

export default BlackListPage;