import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import utilsBuisUser from '../../../MyConns/_Utils/utilsBuisUser';
import ValidatorsCheck from '../../../MyFunctions/ValidatorsCheck';
import { UserContext } from '../../MyContexts/Users_Context';

const TabBuisUsrProfile = (props) => {

    const [user, setUser] = useContext(UserContext)

    const [firstName, setFirstName] = useState((user?.firstName) ? user.firstName : "")
    const [lastName, setLastName] = useState((user?.lastName) ? user.lastName : "")
    const [email, setEmail] = useState((user?.email) ? user.email : "")
    const [password, setPassword] = useState((user?.password) ? user.password : "")
    const [showForgotPass, setShowForgotPass] = useState(false);
    const [forgotPass, setForgotPass] = useState("");
    const [forgotErr, setForgotErr] = useState("");
    const [closeBtn, setCloseBtn] = useState(false);

    useEffect(() => {
        setFirstName((user?.firstName) ? user.firstName : "")
        setLastName((user?.lastName) ? user.lastName : "")
        setEmail((user?.email) ? user.email : "")

    }, [user])

    useEffect(() => {
        setForgotErr("");
        setForgotPass("");

    }, [showForgotPass])



    const closeMe = () => {
        setCloseBtn(false);
        setShowForgotPass(false);
        setForgotErr("");
        setForgotPass("");
    }

    const changePassword = async () => {
       
        if (ValidatorsCheck.validEmail(forgotPass)) {
            
            let setPass = await utilsBuisUser.buisUserChangePassword(forgotPass);
            setForgotErr(setPass.errMsg);
            setCloseBtn(true);
        }
        else {
            setForgotErr("יש להזין כתובת מייל חוקית");
        }


    }

    return (
        <>
            {/* firstName: rep[0].firstName,
                        lastName: rep[0].lastName,
                        email: rep[0].email,
                        rest: rep[0].rest */}


            <div className={"col-md-12 mb-1"}>

                <div className={"col-md-3 mb-2 onRight"}>
                    <label>שם פרטי</label>
                    <div className={"input-group"}>
                        <input type={"text"} className={"form-control"} placeholder={"שם פרטי"} value={firstName}
                            onChange={(e) => { props.updateProfile("firstName", e.target.value); setFirstName(e.target.value) }} required />

                    </div>
                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label>שם משפחה</label>
                    <div className={"input-group"}>
                        <input type={"text"} className={"form-control"} placeholder={"שם משפחה"} value={lastName}
                            onChange={(e) => { props.updateProfile("lastName", e.target.value); setLastName(e.target.value) }} required />

                    </div>
                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label>אימייל</label>
                    <div className={"input-group"}>
                        <input type={"email"} className={"form-control"} placeholder={"אמייל"} value={email}
                            onChange={(e) => { props.updateProfile("email", e.target.value); setEmail(e.target.value) }} required />

                    </div>
                </div>
                <div className={"col-md-3 mb-2 onRight"}>
                    <label>סיסמא</label>
                    <div className={"input-group"}>
                        {/* <input type={"password"} className={"form-control"} placeholder={"סיסמא"} value={password}
                            onChange={(e) => { props.updateProfile("password", e.target.value); setPassword(e.target.value) }} required /> */}

                        <button className={"form-control"} onClick={() => setShowForgotPass(true)}>{"החלף סיסמא"}</button>
                        {/* onChange={(e) => { props.updateProfile("password", e.target.value); setPassword(e.target.value) }}  /> */}

                    </div>
                </div>



                <Modal className="modal-min" show={showForgotPass} onHide={() => setShowForgotPass(false)} aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body className="text-center">
                        <button type="button" className="close" onClick={() => setShowForgotPass(false)}><span aria-hidden="true">×</span></button>
                        <i className="flaticon-secure-shield d-block" />
                        <h1>?רוצה להחליף סיסמא</h1>
                        <p>יש להזין את כתובת המייל שלך להחלפת סיסמא</p>
                        <div className="ms-form-group has-icon">
                            <input type="email" placeholder="כתובת מייל לשחזור" value={forgotPass} onChange={(e) => setForgotPass(e.target.value)} className="form-control" name="buisUserChangePassword-password" required /> <i className={"material-icons"}>email</i>
                        </div>
                        <div className={""}>
                            <div className="mb-2 theValBlock mediumFont">
                                <div className={`centerMargin centerText ${(forgotErr === "") ? "" : "showMe"} invalid-feedback`}>{forgotErr}</div>
                            </div>
                            <button className={`btn btn-primary centerMargin shadow-none ${closeBtn ? "hideMe" : "showMe"}`} onClick={() => changePassword()}>החלפת סיסמא</button>
                            <button className={`btn btn-primary centerMargin shadow-none ${closeBtn ? "showMe" : "hideMe"}`} onClick={() => closeMe()}>סגור</button>

                        </div>

                    </Modal.Body>
                </Modal>


            </div>
        </>

    );
}

export default TabBuisUsrProfile;