import React, { Component, useContext, useEffect, useState } from 'react';

import Breadcrumb from './Breadcrumb'
import { Link, useParams, useHistory } from 'react-router-dom';


import { Button, Checkbox, FormControlLabel, Radio } from '@mui/material';


import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import PopUpTheComp from '../../MySections/DTechComps/PopUpTheComp';
import { UserContext } from '../../MyContexts/Users_Context';
import LogsOrder from '../../MySections/PopUps/LogsOrder';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, DeleteForever, DeleteOutline, Done, History, LibraryBooks, PanoramaFishEye, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import utilsUsers from '../../../MyConns/_Utils/utilsUsers';
import LogsConnectionsTry from '../../MySections/PopUps/LogsConnectionsTry';
import EraseCheck from '../../MySections/PopUps/EraseCheck';
import SucessToast from '../../MySections/Toasts/SucessToast';
import EraseCheckClear from '../../MySections/PopUps/EraseCheckClear';
import MyDateTime from '../../MySections/TimeSections/MyDateTime';
import OrdersHistoryList from '../OrdersP/Orders/OrdersHistory/OrdersHistoryList';


const ClientUserItem = (props) => {

    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [user, setUser] = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [theClient, setTheClient] = useState();
    const [theInPopUp, setTheInPopUp] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [clientBLocked, setClientBLocked] = useState(false);
    const history = useHistory();


    useEffect(() => {

        loadtheClient();
    }, [])

    const loadtheClient = async () => {
        if (props.clentId !== "") {
            let clientLoaded = await utilsUsers.getUserById(props.clentId);

            if (clientLoaded.errormsg) {
                setErrMsg(clientLoaded.errormsg);
            }
            else {
                setTheClient(clientLoaded);
                setClientBLocked(clientLoaded.blocked)
                getAddress(clientLoaded);
                clientDetails(clientLoaded);
                setLoading(false);

            }
        }
        else {
            if (props.noBack) {

            }
            else {
                history.push("/ClientUsers");
            }

        }

    }



    const getAddress = (inClient) => {
        let bk = inClient.Adresses.map((addrs, index) => {
            return (
                <div key={index}>
                    <div className={"dottedLightGray"}></div>
                    <div className={"bold big "}>
                        &nbsp;
                        {addrs.street}
                        &nbsp;

                        {(!addrs.street.endsWith(addrs.num)) ?
                            addrs.num
                            : ""}
                        &nbsp;
                        &nbsp;
                        {(addrs.appartment) ?
                            `דירה: ${addrs.appartment}`
                            : ""}
                        &nbsp;
                        &nbsp;
                        {(addrs.ent) ?
                            `כניסה: ${addrs.ent}`
                            : ""}
                        &nbsp;
                        &nbsp;
                        {(addrs.flor) ?
                            `קומה: ${addrs.flor}`
                            : ""}
                        &nbsp;
                        &nbsp;
                        {addrs.city || "אשדוד"}


                    </div>

                    {(addrs.remarks) ?
                        <>
                            <div className={"bold regular maxTxtAreaHeight"}>
                                הערות: {addrs.remarks}
                            </div>
                        </>

                        : ""}


                </div>)
        });
        return bk;
    }



    const clientDetails = (inClient) => {
        return (
            <div className={"spac spacePadBig specialRow"}>
                <div className={"bold big"}>שם הלקוח: {inClient.firstName}  {inClient.lastName}</div>
                <div className={"bold"}>טלפון: {inClient.phone}</div>
                {inClient.email != "" ? <div className={"bold"}>אימייל: {inClient.email}</div> : ""}
            </div>

        )
    };

    const setRemarks = () => {

    }
    const popOrderLog = (orderNUM) => {
        setTheInPopUp(
            <PopUpTheComp
                closePop={() => { setTheInPopUp() }}
                cssStyl={"popSetter"}
                popDiagStyle={"popMax popMax800"}
                errorMsg={""}
                okClick={() => { setTheInPopUp() }}
                btnClick1={true}
                Title={["לוג הזמנה " + orderNUM]}
                titleCss={"centerText"}
                theInsideComp={
                    <LogsOrder cssStyl={"popSetter"}
                        ordNum={orderNUM}

                        cancelClick={() => setTheInPopUp()}
                        popDiagStyle={" popMax popMax800"}
                    />}
            />



        )
    }

    const removeUser = () => {
        setTheInPopUp(<EraseCheckClear title={'האם בטוח למחוק את הלקוח?'}
            sucecssFunc={() => { removeUserCheck2(); setTheInPopUp(); }} />)
    }

    const removeUserCheck2 = () => {
        setTheInPopUp(<EraseCheckClear title={'בודק שוב, האם בטוח למחוק את הלקוח?'}
            sucecssFunc={() => removeOptConfirmd()} />)
    }
    const removeOptConfirmd = async () => {

        let reply = await utilsUsers.removeUser(theClient._id);

        SucessToast({
            timeOut: 5000, position: `${!mobiOrNot ? "toast-inPop" : "toast-inPop-Mobi"}`,
            text: "נמחק בהצלחה!"
        });

        history.push("/ClientUsers")

    }



    const userBlkANdlstLog = () => {
        return (
            <>
                <div className={"fullRow overflowHid"}>
                    <FormControlLabel className={"noMargin"} label="חסום משתמש"
                        control={<Checkbox
                            icon={<CheckBoxOutlineBlankOutlined sx={{ color: 'var(--mainColor)' }} />}
                            checkedIcon={<CheckBoxOutlined sx={{ color: 'var(--mainColor)' }} />}
                            sx={{}}
                            className={" pointing"}
                            checked={clientBLocked}
                            onChange={(e) => {
                                blockUser(e);
                            }}
                        />
                        }
                    />
                </div>
                <div className={"fullRow overflowHid"}>
                    <span className={"regular bold"}>תאריך התחברות אחרון: {MyDateTime.getDateAndTime(theClient.lastLogin)}</span>
                </div>
            </>

        )
    }


    const cardToks = () => {
        if (theClient.cardsToks?.length > 0) {

            let rads = theClient.cardsToks.map((item, index) => {
                return (
                    <div key={index} className={"selBlk grayFrame overflowHid"} style={{ minWidth: "250px" }}>
                        <div className={"onRight spaceTopBig"} style={{ width: "80%" }}>
                            <div className={"bold"}>{item.Last4CardDigits}-XXXXXXXX</div>
                        </div>

                        <div className={"onLeft"} style={{ width: "18%" }}>
                            <Button className={"btnMediumMnu"} variant="contained"
                                onClick={() => removeToken(index)}
                                startIcon={<RemoveCircleOutlineOutlined sx={{ color: 'white' }} />}
                            >
                                {`${!mobiOrNot ? "הסר" : ""}`}
                            </Button>

                            {/* <RemoveCircleOutline onClick={() => removeToken(index)} className={"InRed pointing"} /> */}

                        </div>
                    </div >
                )
            });

            return rads;
        }
        else return "לא קיימים טוקנים"

    }

    const showOrdrsHis = () => {
        setTheInPopUp(
            <PopUpTheComp
                closePop={() => { setTheInPopUp() }}
                cssStyl={"popSetter"}
                popDiagStyle={"popMax popMax800"}
                errorMsg={""}
                okClick={() => { setTheInPopUp() }}
                btnClick1={true}
                Title={["היסטוריית הזמנות"]}
                titleCss={"centerText"}
                theInsideComp={
                    <OrdersHistoryList clentId={props.clentId} maxCLosedToSHow={5000} />
                }
            />



        )

    }


    const removeToken = (indx) => {
        theClient.cardsToks.splice(indx, 1);
        updateUser();
        loadtheClient();
    }



    const topBtnsROw = () => {
        return (
            <>

                <div className={`col-md-12 overflowHid`}>

                    {(user.permiss === "admin") &&
                        <>
                            <div className="col-stretch onRight spaceMargeTopbig">
                                <Button className={"btnMediumMnu"} variant="contained"
                                    onClick={() => showOrdrsHis()}
                                    startIcon={<History sx={{ color: 'white' }} />}
                                >
                                    הזמנות
                                </Button>
                            </div>
                            <div className="col-stretch onRight spaceMargeRight spaceMargeTopbig">

                                <Button className={"btnMediumMnu"} variant="contained"
                                    onClick={() => showConnectionsLog()}
                                    startIcon={<LibraryBooks sx={{ color: 'white' }} />}
                                >
                                    לוג חיבור
                                </Button>



                            </div>

                            {!mobiOrNot ?
                                <div className="col-stretch onLeft spaceMargeRight spaceMargeTopbig">
                                    <Button className={"btnMediumMnu"} variant="contained"
                                        onClick={() => removeUser()}
                                        startIcon={<DeleteOutline sx={{ color: 'white' }} />}
                                    >
                                        מחק לקוח
                                    </Button>
                                </div>
                                :
                                <div className="col-stretch onRight spaceMargeRight spaceMargeTopbig">
                                    <Button className={"btnMediumMnu btnMediumMnuNoMini"} variant="contained"
                                        onClick={() => removeUser()}
                                        startIcon={<DeleteOutline sx={{ color: 'white' }} />}
                                    >
                                    </Button>
                                </div>
                            }
                            {/* <div className="col-stretch spaceMargeRight">
                                <Button className={"btnMediumMnu"} variant="contained" onClick={() => addOrderItem()}>+</Button>
                            </div> */}
                        </>
                    }
                    <div className="col-stretch onRight spaceMargeRight spaceMargeTopbig">
                        <Button className={"btnMediumMnu"} variant="contained" onClick={() => setRemarks()}>הערות</Button>
                    </div>


                </div >


            </>

        )

    }

    const showConnectionsLog = () => {
        setTheInPopUp(
            <PopUpTheComp
                closePop={() => { setTheInPopUp() }}
                cssStyl={"popSetter"}
                popDiagStyle={"popMax popMax800"}
                errorMsg={""}
                okClick={() => { setTheInPopUp() }}
                btnClick1={true}
                Title={["לוג יצירות קוד התחברות " + theClient.firstName + " " + theClient.lastName]}
                titleCss={"centerText"}
                theInsideComp={
                    <LogsConnectionsTry cssStyl={"popSetter"}
                        theClientLogs={theClient.codsLog}
                        clientId={theClient._id}
                        cancelClick={() => setTheInPopUp()}
                        popDiagStyle={" popMax popMax800"}
                    />}
            />
        )
    }




    const blockUser = (val) => {
        theClient.blocked = !theClient.blocked;
        setClientBLocked(!clientBLocked);
    }

    const updateUser = async () => {

        let upit = await utilsUsers.updateUser(theClient._id, theClient)

        if (upit) {

            SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
            loadtheClient();
        }
    }





    return (
        loading ? <MySpinner_Wait /> :

            (errMsg !== "") ? <p>{errMsg}</p> :
                <>
                    <div className="no_print">
                        <div className="ms-content-wrapper">
                            <div className={`row`}>
                                <div className="col-md-12 inBGWhite spaceButtomVeryBig">
                                    <Breadcrumb client={`${theClient.firstName} ${theClient.lastName}`} />
                                    <div className="col-12 overflowHid noPadding">
                                        <div className="col-12 spaceMargeTopbig overflowHid">
                                            {topBtnsROw()}
                                        </div>
                                        <div className={`col-md-12 overflowHid noPadding`}>
                                            <div className={"grayFrame spaceMargeBottombig spaceMargeTopbig spaceMargeRightbig spaceMargeLeftbig"}>
                                                <div className={`col-md-12 overflowHid noPadding`}>
                                                    <div className={"col-md-6 grayFrame onRight"}>
                                                        {clientDetails(theClient)}
                                                    </div>

                                                    <div className={"col-md-6 grayFrame onRight"}>
                                                        {userBlkANdlstLog()}
                                                    </div>
                                                    <div className={"col-md-6 grayFrame onRight"}>
                                                        {cardToks()}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={"grayFrame spaceMargeBottombig spaceMargeTopbig spaceMargeRightbig spaceMargeLeftbig"}>
                                                <div className={"bold big "}>
                                                    כתובות:
                                                </div>
                                                {getAddress(theClient)}
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="ms-panel-header approveLine">
                            <button type="button" className="onLeft btn btn-primary"
                                onClick={() => updateUser()}>שמור</button>
                        </div>
                        {theInPopUp}
                    </div>


                </>


    );

}


export default ClientUserItem;
