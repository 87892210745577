import React, { useState, createContext, useEffect } from 'react';
import utilsRests from '../../MyConns/_Utils/utilsRests';
import myFunctions from '../../myFunctions';

export const TablesSetterContext = createContext();

export const TablesSetterContextProvider = props => {
      const [tbls, setTbls] = useState({
        buisId: "",
        tabelsLocations: "",
        specialsList: "",
        fromOpening: "00:00",
        toClosing: "00:00",
        tables: [],
    });
    useEffect(() => {
     
    }, [])



    return (
        <TablesSetterContext.Provider value={[tbls, setTbls]}>
            {props.children}
        </TablesSetterContext.Provider>
    )
}



