import React, { Component, useContext, useEffect } from 'react';
import Sidenavigation from '../../layouts/Sidenavigation';
import Sidenavigation1 from '../../layouts/Sidenavigation1';

import Topnavigation from '../../layouts/Topnavigation';
import Restaurentcontent from './Restaurant-list/Restaurantcontent'
import Quickbar from '../../layouts/Quickbar';
import { TheMasterContextProvider } from '../../MyContexts/Master_Context';
import TopnavigationSpecial from '../../MySections/DTechComps/TopnavigationSpecial';
import myFunctions from '../../../myFunctions';
import { UserContext } from '../../MyContexts/Users_Context';


const Restaurantlist = (props) => {
    const [user, setUser] = useContext(UserContext)


    useEffect(() => {

        myFunctions.setGetCurrnetTab("Set", "pg", "pgBuisDetails")
    }, [])
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ms-has-quickbar">
            <TheMasterContextProvider>
                <Sidenavigation1 />
                <main className="body-content">
                    <Topnavigation />
                    {user.permiss === "admin" &&
                        <Restaurentcontent />
                    }
                </main>
                {/* <Quickbar /> */}
            </TheMasterContextProvider>
        </div>
    );
}

export default Restaurantlist;