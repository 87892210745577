import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import Breadcrumb from './Breadcrumb'

import { Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { MobiOrNotContext } from '../../MyContexts/MobiOrNotContext';
import { UserContext } from '../../MyContexts/Users_Context';
import { AddBoxOutlined } from '@mui/icons-material';
import MySpinner_Wait from '../../MySections/DTechComps/MySpinner_Wait';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { DatePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/he';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import utilsKopons from '../../../MyConns/_Utils/utilsKopons';
import { BuisListForKoponContext } from '../../MyContexts/BuisListForKopon_Context';
import BuisSelect_ForKopon from './BuisSelect_ForKopon';
import myFunctions from '../../../myFunctions';



const KoponCreate = (props) => {
    const history = useHistory();

    const [user, setUser] = useContext(UserContext)
    const [buisLstKopon, setBuisLstKopon] = useContext(BuisListForKoponContext)
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [errMsg, setErrMsg] = useState("");
    const [groupkoponNumb, setGroupkoponNumb] = useState(1);
    const [groupkoponName, setGroupkoponName] = useState();
    const [kCode, setKCode] = useState("");
    const [loading, setLoading] = useState(true);
    const [thePopUp, setThePopUp] = useState("");
    const [koponDiscount, setKoponDiscount] = useState(0);
    const [koponDiscType, setKoponDiscType] = useState("1");

    const [minmumForOrder, setMinmumForOrder] = useState(50);
    const [buissId, setBuissId] = useState("-1");


    const [koponGroupValid, setKoponGroupValid] = useState(() => {
        const nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        return nextMonth;
    });
    const [newKopon, setNewKopon] = useState(null);

    let idParam = useParams();


    useEffect(() => {
        setLoading(false)
    }, [])



    const handleDiscountTypeChange = (event) => {
        setKoponDiscType(event.target.value);
    };


    const setTheGroupkoponNumb = () => {

    }
    const setThekoponGroupValid = () => {

    }



    const createKopon = async () => {
        let theErr = "";

        if (groupkoponName === undefined || groupkoponName === "") {
            theErr = "חובה להזין שם";
        }
        else if (isDateInPast(koponGroupValid)) {
            theErr = "תאריך התוקף חייב להיות לפחות עד מחר";
        }
        else if (kCode !== "" && kCode.length < 6) {
            theErr = "קוד הקופון המבוקש חייב להיות בעל 6 תווים לפחות.";
        }
        else {
            let theNewKopn = await utilsKopons.createKopon(
                {
                    Name: groupkoponName,
                    Type: props.koponType,
                    KoponsCount: groupkoponNumb,
                    KoponExperationDate: koponGroupValid,
                    KoponDiscount: koponDiscount,
                    KoponDiscType: koponDiscType,
                    MinmumForOrder: minmumForOrder,
                    KCode: kCode,
                    buissId: buissId

                }
            );
            setNewKopon(theNewKopn.createdKopon)
        }

        setErrMsg(theErr)
    }


    const isDateInPast = (date) => {
        const today = new Date();
        return date < today;
    };



    return (
        <div>
            {loading ?
                <MySpinner_Wait />
                :
                <div className={"col-md-12 mb-2"}>

                    <div className={"col-md-6 mb-2 onRight"}>
                        <div className={"col-md-12 mb-2"}>
                            <label className='bold'>{props.header}</label>
                        </div>

                        {(user.permiss === "admin") ?
                            <div className={"col-md-12 mb-2"}>
                                <BuisSelect_ForKopon busissList={buisLstKopon}
                                    firstHead={"כל העסקים"}
                                    clss={"koponcreateBuisLIst"}
                                    userSelection={buissId ? buissId : -1}
                                    setUserBuis={(newVal) => { setBuissId(newVal) }}
                                />
                            </div>

                            : ""}
                        <div className={"col-md-6 mb-2 onRight"}>
                            <label htmlFor={"setGroupkoponName_1Cs"}>שם הקופון</label>
                            <div className={"input-group"}>
                                <input type="text" className={"koponInput"}
                                    id={"setGroupkoponName_1Cs"}
                                    placeholder={"שם הקופון"} value={groupkoponName}
                                    onChange={(e) => { setGroupkoponName(e.target.value) }} />

                            </div>
                        </div>
                        <div className={"col-md-6 mb-2 onRight"}>
                            <label htmlFor={"setGroupkoponName_1Cs"}>קוד הקופון <span style={{ fontSize: "0.8rem" }} className='bold'>(אופציונלי) יש חובה של 6 תווים</span></label>

                            <div className={"input-group"}>
                                <input type="text" className={"koponInput"}
                                    id={"setGroupkoponName_1Cs"}
                                    placeholder={"קוד הקופון"} value={kCode}
                                    onChange={(e) => { setKCode(e.target.value) }} />

                            </div>
                        </div>
                        <div className={"col-md-6 mb-2 onRight"}>
                            <label className={"onRight"} htmlFor={"setKoponDiscount_1Cs"}>סכום הקופון</label>
                            <div className={"onRight spaceMargeRightbig"}>
                                <label>
                                    <input
                                        type="radio"
                                        value="1"
                                        checked={koponDiscType === '1'}
                                        onChange={handleDiscountTypeChange}
                                    />
                                    &nbsp;בשקלים
                                </label>
                                &nbsp; &nbsp;
                                <label>
                                    <input
                                        type="radio"
                                        value="2"
                                        checked={koponDiscType === '2'}
                                        onChange={handleDiscountTypeChange}
                                    />
                                    &nbsp;באחוזים
                                </label>
                            </div>
                            <div className={"input-group"}>
                                <input type="number" className={"koponInput"}
                                    id={"setKoponDiscount_1Cs"}
                                    value={koponDiscount}
                                    min={0}
                                    onChange={(e) => { setKoponDiscount(e.target.value) }} />

                            </div>
                        </div>

                        <div className={"col-md-6 mb-2 onRight"}>
                            <label htmlFor={"setMinmumForOrder_1Cs"}>מינימום להפעלת הקופון</label>
                            <div className={"input-group"}>
                                <input type="number" className={"koponInput"}
                                    id={"setMinmumForOrder_1Cs"}
                                    min={0}
                                    value={minmumForOrder}
                                    onChange={(e) => { setMinmumForOrder(e.target.value) }} />
                            </div>
                        </div>

                        <div className={"col-md-6 mb-2 onRight"}>

                            <label htmlFor={"setGroupkoponNumb_1Cs"}>כמות</label>
                            <div className={"input-group"}>
                                <input type="number" className={"koponInput"}
                                    id={"setGroupkoponNumb_1Cs"}
                                    min={1}
                                    max={props.max || null}
                                    value={groupkoponNumb}
                                    onChange={(e) => {
                                        // Ensure the entered value is within the specified range
                                        const newValue = Math.min(props.max || Infinity, Math.max(e.target.value, 1));

                                        setGroupkoponNumb(newValue);
                                    }} />

                            </div>

                        </div>

                        <div className={"col-md-6 mb-2 onRight"}>

                            <label htmlFor={"koponGroupValid_1"}>תוקף</label>
                            <div className={"input-group"}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale} timeZone="Asia/Jerusalem">
                                    <DatePicker
                                        ampm={false}
                                        label="עד תאריך"
                                        value={koponGroupValid}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(e) => setKoponGroupValid(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                        className={"tmPick"}
                                    />
                                </LocalizationProvider>
                            </div>


                        </div>
                        <Button variant="contained" className={"theSBtn"} onClick={() => createKopon()}
                            startIcon={<ModeEditIcon style={{ color: 'white' }} />}>
                            צור
                        </Button>
                    </div>
                    <div className={"col-md-6 mb-2 onRight"}>
                        {
                            (newKopon !== null) ?
                                <>
                                    <label className={'inRed bold'}>נוצר בהצלחה</label>
                                    <br />
                                    <label>עסק: {newKopon.buissName}</label>
                                    <br />
                                    <br />
                                    <label>שם: {newKopon.Name}</label>
                                    <br />
                                    <label>סכום הקופון: <span>{newKopon.KoponDiscType === '1' ? `${newKopon.KoponDiscount} ₪` : `${newKopon.KoponDiscount} %`}</span></label>
                                    <br />
                                    <label>מינימום להפעלת הקופון: <span>{newKopon.MinmumForOrder} ₪</span></label>
                                    <br />
                                    <label>כמות הקופונים: {newKopon.KoponsCount}</label>
                                    <br />
                                    <label>קוד הקופון: <span className={'bold'}>{newKopon.KCode}</span></label>
                                </>
                                :
                                <label className={'inRed bold'}>{errMsg}</label>

                        }
                    </div>

                </div>

            }
        </div >)
}
export default KoponCreate;

