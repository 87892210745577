import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import { io } from 'socket.io-client';
import ConnUrls from '../../../../MyConns/Connections/ConnectURL';

import $ from 'jquery';
import utilsRests from '../../../../MyConns/_Utils/utilsRests';
import utilsCategurys from '../../../../MyConns/_Utils/utilsCategurys';
import EraseCheck from '../../../MySections/PopUps/EraseCheck';
import { AddBoxOutlined, ContentPasteOffOutlined } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import DTSearchBar from '../../compsForAllPages/DTSearchBar';
import { useHistory } from 'react-router';
import RestStatusSelect from '../../../MySections/DTechComps/RestStatusSelect';
import MySpinner_Wait from '../../../MySections/DTechComps/MySpinner_Wait';
import CRTypeSelect from '../../../MySections/DTechComps/CRTypeSelect';
import myFunctions from '../../../../myFunctions';

const Restaurantcontent = (props) => {


    const history = useHistory();
    const [restaurentlisttable, setRestaurentlisttable] = useState([]);
    const [reversTheList, setReversTheList] = useState(true);
    const [allCats, setAllCats] = useState([]);
    const [thePopUp, setThePopUp] = useState("");
    const [srchItm, setSrchItm] = useState("");
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        getAllRests();

    }, [])


    useEffect(() => {

        const socket = io.connect(ConnUrls.socketClient, {
            auth: {
                token: localStorage.getItem("token")
            },
            query: {
                userType: "back"
            }
        }
        );

        socket.on("delaysUpdate", (data) => {
            setTimeout(() => {
                const { restID, typeToDelay, delayObj } = data;

                const row = document.querySelector(`[itmid="${restID}"]`);
                if (row) {
                    let itmToSet;
                    if (typeToDelay === "closeDeliverDelay") {
                        itmToSet = row.querySelector('.ItemDelivery .pItm');
                    }
                    else if (typeToDelay === "closeTakeDelay") {
                        itmToSet = row.querySelector('.ItemTake .pItm');
                    }

                    if (itmToSet) {
                        if (delayObj.delaydVal !== "0") {
                            itmToSet.classList.remove("inGreen");
                            itmToSet.classList.add("inOrange");
                            itmToSet.innerText = "O";
                        }
                        else {
                            itmToSet.classList.remove("inOrange");
                            itmToSet.classList.add("inGreen");
                            itmToSet.innerText = "V";

                        }

                    }
                }

            }, 3000);
        })
    }, [ConnUrls.socket])



    const getCatsNames = (catsLst) => {
        let catStr = "";

        catsLst.forEach(element => {
            allCats.map((cat, index) => {
                if (cat._id === element) {
                    (index > 1 && catStr != "") ? catStr += "," + cat.catHead : catStr = cat.catHead;
                }
            })
        });

        return catStr;
    }

    const getAllRests = async () => {

        setLoading(true);

        let RstList = await utilsRests.getRestSmDetails();
        setRestaurentlisttable(RstList);

        let CatList = await utilsCategurys.getAllCategurys();
        setAllCats(CatList);

        setSrchItm(
            <DTSearchBar searchList={RstList} CatList={CatList}
                serachRslt={(lstBack) => setRestaurentlisttable(lstBack)} />
        )

        setLoading(false);
    }

    const addstars = (e) => {
        var elem = e.target,
            parentTask = elem.closest('.ms-rating-item');
        $(parentTask).prevAll().removeClass('rated');
        $(parentTask).addClass('rated');
        $(parentTask).nextAll().addClass('rated');
    }

    const removeBuis = (restId) => {
        setThePopUp(<EraseCheck title={'האם בטוח למחוק את העסק?'} sucecssFunc={() => removeBuisConfirmd(restId)} />)
    }
    const removeBuisConfirmd = async (restId) => {
        let mdlist = await utilsRests.deleteRest(restId);
        getAllRests();
    }

    const updateRestStatus = async (restId, stat) => {
        let mdlist = await utilsRests.updaterestStatus(restId, stat);

    }

    const updateOnlineBuis = async (restId, onOff, indx) => {
        let mdlist = await utilsRests.updateOnlineBuis(restId, onOff);
        let tempo = [...restaurentlisttable]
        tempo[indx].buissIsOnline = onOff;
        setRestaurentlisttable(tempo);
    }

    const updateDelveryDoing = async (restId, doingOrNot, indx) => {

        let mdlist = await utilsRests.updateDelveryDoing(restId, doingOrNot);
        let tempo = [...restaurentlisttable]
        tempo[indx].Delivery.Doing = doingOrNot;
        setRestaurentlisttable(tempo);
    }

    const updateTakeAwayDoing = async (restId, doingOrNot, indx) => {
        let mdlist = await utilsRests.updateTakeAwayDoing(restId, doingOrNot);
        let tempo = [...restaurentlisttable]
        tempo[indx].TakeAway.Doing = doingOrNot;
        setRestaurentlisttable(tempo);
    }

    const ClearDeliveryDelay = async (restId, indx) => {
        let obj = { delaydVal: "0", delayCnt: "" };
        let mdlist = await utilsRests.setDelayClosing(restId, "closeDeliverDelay", obj);
        let tempo = [...restaurentlisttable];
        tempo[indx].closeDeliverDelay = obj;
        setRestaurentlisttable(tempo);
    }

    const ClearTakeAwayDelay = async (restId, indx) => {
        let obj = { delaydVal: "0", delayCnt: "" };
        let mdlist = await utilsRests.setDelayClosing(restId, "closeTakeDelay", obj);
        let tempo = [...restaurentlisttable];
        tempo[indx].closeTakeDelay = obj;
        setRestaurentlisttable(tempo);
    }


    const updateBuisReservation = async (restId, doingOrNot, indx) => {
        let mdlist = await utilsRests.updateBuisReservation(restId, doingOrNot);
        let tempo = [...restaurentlisttable]
        tempo[indx].buisReservation = doingOrNot;
        setRestaurentlisttable(tempo);
    }


    const updateCRType = async (restId, CRT) => {
        let mdlist = await utilsRests.updaterestCRType(restId, CRT);

    }


    const goTORest = (itmId) => {
        history.push("/Rest/" + itmId)
    }

    const orderBythis = (byWhat, nullReplace) => {
        setRestaurentlisttable(myFunctions.sortTheBuisLst(restaurentlisttable, byWhat, reversTheList, nullReplace))
        setReversTheList(!reversTheList)

    }

    const getItemTake = (item, i) => {
        {

            if (item.closeTakeDelay !== null && item.closeTakeDelay !== undefined && item.closeTakeDelay.delaydVal !== "0") {
                return (
                    <div className={"bxCheckOnLst"} onClick={() => ClearTakeAwayDelay(item._id, i)}>
                        <p className={"pItm bold inOrange"}>O</p>
                    </div>
                )
            }
            else if
                (item.TakeAway.Doing) {
                return (
                    <div className={"bxCheckOnLst"} onClick={() => updateTakeAwayDoing(item._id, false, i)}>
                        <p className={"pItm bold inGreen"}>V</p>
                    </div>
                )
            }
            else {
                return (
                    <div className={"bxCheckOnLst"} onClick={() => updateTakeAwayDoing(item._id, true, i)}>
                        <p className={"pItm bold inRed"}>X</p>
                    </div>
                )
            }
        }
    }

    const getItemDelivery = (item, i) => {

        if (item.closeDeliverDelay !== null && item.closeDeliverDelay !== undefined && item.closeDeliverDelay.delaydVal !== "0") {
            return (
                <div className={"bxCheckOnLst"} onClick={() => ClearDeliveryDelay(item._id, i)}>
                    <p className={"pItm bold inOrange"}>O</p>
                </div>
            )
        }
        else if
            (item.Delivery.Doing) {
            return (

                <div className={"bxCheckOnLst"} onClick={() => updateDelveryDoing(item._id, false, i)}>
                    <p className={"pItm bold inGreen"}>V</p>
                </div>
            )
        }
        else {
            return (
                <div className={"bxCheckOnLst"} onClick={() => updateDelveryDoing(item._id, true, i)}>
                    <p className={"pItm bold inRed"}>X</p>
                </div>
            )
        }
    }

    return (
        <>
            <div className="ms-content-wrapper">
                {loading ? <MySpinner_Wait /> :
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            {/* Active Orders Graph */}
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="ms-panel">
                                        <div className="ms-panel-header DTSearchBar" >
                                            <div className={"DTSearchBarWrap"}>
                                                <div className={"DTSearchBarIN"}><h6>כל העסקים</h6>
                                                    {srchItm}
                                                </div>
                                            </div>

                                            <div className={"buisTollBar"}>
                                                <Stack direction="row" spacing={2}>
                                                    <Link to={`/Rest`}>
                                                        <Button variant="contained" className={"theSBtn"}
                                                            startIcon={<AddBoxOutlined style={{ color: 'white' }} />}>
                                                            חדש
                                                        </Button>
                                                    </Link>
                                                </Stack>

                                            </div>
                                        </div>
                                        <div className="ms-panel-body">
                                            <div className="table-responsive">
                                                <table className="table table-hover thead-primary">
                                                    <thead>
                                                        <tr>
                                                            <th scope="row"></th>

                                                            <th scope="col" className={"pointing"} onClick={() => orderBythis("buissName", "")}>שם העסק</th>
                                                            <th scope="col" className={"pointing"} onClick={() => orderBythis("buisCats", "")}>קטגוריות</th>
                                                            {/* <th scope="col">דירוג</th> */}
                                                            <th scope="col" className={"centerText pointing"} onClick={() => orderBythis("CRType", "tablet")}>סוג קופה</th>
                                                            <th scope="col" className={"centerText pointing"} onClick={() => orderBythis("restStatus", "פעיל")}>סטטוס</th>
                                                            <th scope="col" className={"centerText pointing"} onClick={() => orderBythis("buissIsOnline", false)}>אונליין</th>
                                                            <th scope="col" className={"centerText pointing"} onClick={() => orderBythis("Delivery")}>משלוחים</th>
                                                            <th scope="col" className={"centerText pointing"} onClick={() => orderBythis("TakeAway")}>איסוף</th>
                                                            <th scope="col" className={"centerText pointing"} onClick={() => orderBythis("buisReservation")}>מקום</th>
                                                            <th scope="col" className={"centerText pointing"} onClick={() => orderBythis("pageUrlFix", "")}>קישור</th>
                                                            <th scope="col" className={"centerText pointing"} > ערוך / מחק</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {restaurentlisttable.map((item, i) => (
                                                            <tr key={item._id} itmid={item._id}  >
                                                                <td scope="row" className={"pointing"} onClick={() => goTORest(item._id)}>({parseInt(i) + 1}) </td>
                                                                <td scope="row" className={"pointing"} onClick={() => goTORest(item._id)}>{item.buissName} </td>
                                                                {/* <td>{item.buissKitchType}</td> */}

                                                                <td className={"pointing"} onClick={() => goTORest(item._id)}>
                                                                    {getCatsNames(item.buisCats)}
                                                                </td>

                                                                <td className={"pointing"}>
                                                                    <div className={"CRTypeSelect"}>
                                                                        <CRTypeSelect restCRT={item.CRType ? item.CRType : "tablet"} onLeft={"onLeft"}
                                                                            CRTUpdate={(CRT) => updateCRType(item._id, CRT)} />
                                                                    </div>
                                                                </td>

                                                                <td className={"pointing"}>
                                                                    <div className={"RestStatusSelect"}>
                                                                        <RestStatusSelect restStatus={item.restStatus ? item.restStatus : "פעיל"}
                                                                            statusUpdate={(stat) => updateRestStatus(item._id, stat)} />
                                                                    </div>
                                                                </td>
                                                                <td scope="row" className={"pointing centerText"} >
                                                                    <div className={"bxCheckOnLst"} onClick={() => updateOnlineBuis(item._id, !item.buissIsOnline, i)}>
                                                                        {item.buissIsOnline ? <p className={"bold inGreen"}>V</p> : <p className={"bold inRed"}>X</p>}
                                                                    </div>

                                                                </td>
                                                                <td scope="row" className={"pointing centerText ItemDelivery"}>

                                                                    {getItemDelivery(item, i)}

                                                                </td>
                                                                <td scope="row" className={"pointing centerText ItemTake"} >

                                                                    {getItemTake(item, i)}

                                                                </td>
                                                                <td scope="row" className={"pointing centerText"} >
                                                                    <div className={"bxCheckOnLst"} onClick={() => updateBuisReservation(item._id, !item.buisReservation, i)}>
                                                                        {item.buisReservation ? <p className={"bold inGreen"}>V</p> : <p className={"bold inRed"}>X</p>}
                                                                    </div>
                                                                </td>

                                                                <td className={"pointing"}>
                                                                    <div className={"RestStatusSelect"}>
                                                                        <p onClick={() => window.open("https://ashdodmenu.co.il/restaurants/" + item.pageUrlFix, "_blank")}>{item.pageUrlFix}</p>

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className={"eraseEditBlock"}>
                                                                        <div className={"divAsIcon pointing"}><Link to={`/Rest/${item._id}`}><i className="fas fa-pencil-alt text-secondary" /></Link></div>
                                                                        <div className={"divAsIcon pointing"} onClick={() => removeBuis(item._id)}><i className="far fa-trash-alt ms-text-danger" /></div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {thePopUp}
        </>
    );


}

export default Restaurantcontent;

